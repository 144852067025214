import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators, Dispatch } from 'redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import RemoveIcon from '../icons/RemoveIcon';
import XLSIcon from '../icons/XLSIcon';
import LinkIcon from '../icons/LinkIcon';
import Action from '../atoms/Action';
import Download from '../atoms/Download';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import Dialog from '../molecules/Dialog';
import AddRemoveColumns from './AddRemoveColumns';
import UsersTable, { ManagedUserColumn, USER_ALL_COLUMNS, USER_COLUMN_TITLE } from '../molecules/UsersTable';
import ProgramsTable, {
  ManagedProgramColumn,
  PROGRAM_ALL_COLUMNS,
  PROGRAM_COLUMN_TITLE,
} from '../molecules/ProgramsTable';
import HsCombineTypeTable, { ManagedHsCombineTypeColumn, HS_COMBINE_TYPE_ALL_COLUMNS, HS_COMBINE_TYPE_COLUMN_TITLE } from '../molecules/HsCombineTypeTable';
import UsageTable from '../molecules/UsageTable';
import StagingTable from '../molecules/StagingTable';
import PageLimitDropdown from '../molecules/PageLimitDropdown';
import gql from '../services/gql';
import exportXLSReport, { ERROR_MESSAGE_CHECK_EMAIL, XLSExportType } from '../services/export-xls-report';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { ScreenSize, isDesktop } from '../services/screen-size';
import { State } from '../redux/reducers';
import { fetchUserSettings, saveManageUsersColumns, saveManageProgramsColumns } from '../redux/dispatchers/user-settings';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_DARK_GRAY,
  COLOR_SHADOW,
  COLOR_BORDER,
  COLOR_BLUE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import AccessLevel from '../types/AccessLevel';
import User from '../types/User';
import Program from '../types/Program';
import ManagedUser from '../types/ManagedUser';
import Column from '../types/Column';
import HsCombineType from '../types/HsCombineType';
import { TEAM_CAMP_STRING } from '../types/ValidationRule';

interface ManageTableProps {
  className?: string;
  user: User;
  adminAccess?: boolean;
  tab: TabName;
  savedManageUsersColumns: Column[] | null;
  savedManageProgramsColumns: Column[] | null;
  screenSize: ScreenSize;
  fetchUserSettings: () => void;
  saveManageUsersColumns: (manageUsersColumns:Column[]) => void;
  saveManageProgramsColumns: (manageProgramsColumns:Column[]) => void;
}

enum TabName {
  USERS = 'users',
  PROGRAMS = 'programs',
  COMBINES = 'combines',
  USAGE = 'usage',
  STAGING = 'staging',
}

const TABS = [
  TabName.USERS,
  TabName.PROGRAMS,
  TabName.COMBINES,
  TabName.USAGE,
  // TabName.STAGING,
];

const TAB_TITLE = {
  [TabName.USERS]: 'Users',
  [TabName.PROGRAMS]: 'Programs',
  [TabName.COMBINES]: 'Combines',
  [TabName.USAGE]: 'Usage Reports',
  [TabName.STAGING]: 'Staging',
};

const TAB_COMPONENT = {
  [TabName.USERS]: UsersTable,
  [TabName.PROGRAMS]: ProgramsTable,
  [TabName.COMBINES]: HsCombineTypeTable,
  [TabName.USAGE]: UsageTable,
  [TabName.STAGING]: StagingTable,
};

const TAB_MUTATION_QUERY = {
  [TabName.USERS]: 'deleteUsers',
  [TabName.PROGRAMS]: 'deletePrograms',
  [TabName.COMBINES]: 'deleteHsCombineType',
  [TabName.USAGE]: '',
  [TabName.STAGING]: '',
};

const ALL_COLUMNS = {
  [TabName.USERS]: USER_ALL_COLUMNS,
  [TabName.PROGRAMS]: PROGRAM_ALL_COLUMNS,
  [TabName.COMBINES]: HS_COMBINE_TYPE_ALL_COLUMNS,
  [TabName.USAGE]: HS_COMBINE_TYPE_ALL_COLUMNS,
  [TabName.STAGING]: HS_COMBINE_TYPE_ALL_COLUMNS,
};

const ALL_COLUMN_TITLE = {
  [TabName.USERS]: USER_COLUMN_TITLE,
  [TabName.PROGRAMS]: PROGRAM_COLUMN_TITLE,
  [TabName.COMBINES]: HS_COMBINE_TYPE_COLUMN_TITLE,
  [TabName.USAGE]: HS_COMBINE_TYPE_COLUMN_TITLE,
  [TabName.STAGING]: HS_COMBINE_TYPE_COLUMN_TITLE,
};

const TABLE_NAME = {
  [TabName.USERS]: StorageKey.MANAGE_USER,
  [TabName.PROGRAMS]: StorageKey.MANAGE_PROGRAMS,
  [TabName.COMBINES]: StorageKey.MANAGE_HS_COMBINE_TYPE,
  [TabName.USAGE]: StorageKey.MANAGE_USAGE,
  [TabName.STAGING]: StorageKey.MANAGE_STAGING
};

const useStyles = makeStyles(theme => ({
  manageTable: {
    position: 'relative',
  },

  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },
  tableWrapper: {
    width: '100%',
  },

  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h2,
    margin: 0,
  },

  tabsWrapper: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    minWidth: '56px',
  },
  tabs: {
    minHeight: 'initial',
    margin: '0 auto',
  },
  tabsIndicator: {
    height: '1px',
  },
  tab: {
    minWidth: 'auto',
    minHeight: '50px',
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    fontFamily: FONT_PROXIMA_NOVA,
    borderBottom: '1px solid transparent',
    color: COLOR_TEXT,
    textTransform: 'none',
    transition: 'border 0.3s',

    '&:hover': {
      borderBottomColor: COLOR_DARK_GRAY,
    },
  },

  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
  },
  dropdownWrapper: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    color: COLOR_TEXT,
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(2),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },

  actionRow: {
    backgroundColor: COLOR_WHITE,
    padding: theme.spacing(1.5, 2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',

    '&:last-of-type': {
      borderTop: `1px solid ${COLOR_BORDER}`,
    }
  },
  actions: {
    display: 'flex',
  },
  action: {
    marginLeft: theme.spacing(2),

    '&:first-of-type': {
      marginLeft: 0,
      paddingLeft: 0,
    },

    '&:hover': {
      '& $actionText': {
        color: COLOR_BLUE,
      },
    },
  },
  actionIcon: {
    width: '32px',
    height: '32px',
  },
  xlsIcon: {
    width: '22px',
    height: '22px',
  },
  resetPasswordLinkIcon: {
    width: '22px',
    height: '22px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
  },
  disabledAction: {
    outlineColor: 'transparent',
    color: COLOR_DARK_GRAY,
    textDecoration: 'none',

    '& $actionText': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $actionText': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  actionSelected: {
    '&:before': {
      display: 'block',
    },
  },

  actionLinks: {
    display: 'flex',
    minHeight: '40px',
    marginLeft: 'auto',
  },
  divider: {
    margin: theme.spacing(0,2),
  },
  addRemoveColumns: {
    margin: 0,
    padding: 0,
    fontSize: theme.typography.pxToRem(16),
  },
  download: {
    margin: 0,
    padding: 0,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
  },

  resetPasswordUrlText: {
    wordBreak: 'break-all',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(26),
  },

  '@media (max-width: 1023px)': {
    actionRow: {
      flexWrap: 'wrap',
    },

    actionLinks: {
      justifyContent: 'flex-start',
      width: '100%',
      minHeight: '30px',
      marginTop: theme.spacing(2),
    },
  },

  [MEDIA.MOBILE]: {
    tabs: {
      margin: 0,
    },

    actionRow: {
      flexWrap: 'wrap',
      padding: theme.spacing(1.5),
    },

    dropdowns: {
      width: '100%',
      marginLeft: 0,
    },
    dropdownWrapper: {
      marginTop: theme.spacing(1),
      marginLeft: 0,
    },
  },

  [MEDIA.DESKTOP]: {
    action: {
      marginLeft: theme.spacing(4),
    },

    divider: {
      margin: theme.spacing(0,4),
    },
  },

  '@media (min-width: 1601px)': {
    tabsWrapper: {
      minHeight: '50px',
      marginTop: '-50px',
    },
  },
}), { name: ManageTable.name });

function ManageTable (props:ManageTableProps) {
  const {
    className,
    user,
    adminAccess,
    tab,
    savedManageUsersColumns,
    savedManageProgramsColumns,
    screenSize,
    fetchUserSettings,
    saveManageUsersColumns,
    saveManageProgramsColumns,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [managedUsers, setManagedUsers] = useState<ManagedUser[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [hsCombineType, setHsCombineType] = useState<HsCombineType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>((clientStorage.get(TABLE_NAME[tab]) || {}).currentPage || 1);
  const [limit, setLimit] = useState<number>((clientStorage.get(TABLE_NAME[tab]) || {}).limit || 10);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(TABLE_NAME[tab]) || {}).limit || 10);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const [columns, setColumns] = useState<Column[]>((ALL_COLUMNS[tab] as Array<ManagedUserColumn|ManagedProgramColumn|ManagedHsCombineTypeColumn>)
    .map((columnName:ManagedUserColumn|ManagedProgramColumn|ManagedHsCombineTypeColumn) => ({
      name: columnName as string,
      selected: true,
    } as Column)
  ));

  const [selectedTab, setSelectedTab] = useState<number>(TABS.indexOf(tab));
  const [accessLevels, setAccessLevels] = useState<AccessLevel[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [resetPasswordUrl, setResetPasswordUrl] = useState<string>('');

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  useEffect(() => {
    fetchAccessLevels();
    fetchUserSettings();
  }, []);

  useEffect(() => {
    if (user.canClearStagingPlayers) {
      if (!TABS.includes(TabName.STAGING)) {
        TABS.push(TabName.STAGING);
      }
    }

    if (TABS[selectedTab] !== tab) {
      setSelectedTab(TABS.indexOf(tab));
    }
  }, [tab, selectedTab]);

  useEffect(() => {
    setCurrentPage((clientStorage.get(TABLE_NAME[tab]) || {}).currentPage || 1);
    setLimit((clientStorage.get(TABLE_NAME[tab]) || {}).limit || 10);

    if (TABS[selectedTab] === TabName.USERS) {
      fetchManagedUsers();
    } else if (TABS[selectedTab] === TabName.PROGRAMS) {
      fetchPrograms();
    } else if (TABS[selectedTab] === TabName.COMBINES) {
      fetchHsCombineTypes();
    } else if (tab === TabName.USAGE || tab === TabName.STAGING) {
      setLoading(false);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (TABS[selectedTab] === TabName.USERS) {
      if (savedManageUsersColumns?.length) {
        const isNewColumnPresent = savedManageUsersColumns.some((column:Column) => [
          ManagedUserColumn.PROGRAM_NAME as string,
          ManagedUserColumn.LAST_LOGIN_IP as string,
          ManagedUserColumn.IP_ORGANIZATION as string,
          ManagedUserColumn.IP_CITY_STATE as string,
        ].includes(column.name));
        if (!isNewColumnPresent) {
          const savedColumns = [...savedManageUsersColumns,
            {
              name: ManagedUserColumn.PROGRAM_NAME,
              selected: false,
            },
            {
              name: ManagedUserColumn.LAST_LOGIN_IP,
              selected: false,
            },
            {
              name: ManagedUserColumn.IP_ORGANIZATION,
              selected: false,
            },
            {
              name: ManagedUserColumn.IP_CITY_STATE,
              selected: false,
            },
           ]
          setColumns(savedColumns);
        } else {
          setColumns(savedManageUsersColumns);
        }
      } else {
        setColumns((ALL_COLUMNS[TabName.USERS] as ManagedUserColumn[])
          .map((columnName:ManagedUserColumn) => ({
              name: columnName as string,
              selected: true,
            } as Column)
        ));
      }
    } else if (TABS[selectedTab] === TabName.PROGRAMS) {
      if (savedManageProgramsColumns?.length) {
        setColumns(savedManageProgramsColumns);
      } else {
        setColumns((ALL_COLUMNS[TabName.PROGRAMS] as ManagedProgramColumn[])
          .map((columnName:ManagedProgramColumn) => ({
              name: columnName as string,
              selected: true,
            } as Column)
        ));
      }
    } else if (TABS[selectedTab] === TabName.COMBINES) {
      setColumns((ALL_COLUMNS[TabName.COMBINES] as ManagedHsCombineTypeColumn[])
          .map((columnName:ManagedHsCombineTypeColumn) => ({
              name: columnName as string,
              selected: true,
            } as Column)
        ));
    }
  }, [savedManageUsersColumns, savedManageProgramsColumns, selectedTab]);

  useEffect(() => {
    clientStorage.save(TABLE_NAME[tab], { limit, currentPage });
  }, [tab, limit, currentPage]);

  useEffect(() => {
    if (TABS[selectedTab] === TabName.USERS && !!managedUsers?.length && prevLimit !== limit) {
      setCurrentPage(1);
      setPrevLimit(limit);
    }
  }, [managedUsers?.length, limit, selectedTab]);

  useEffect(() => {
    if (TABS[selectedTab] === TabName.PROGRAMS && !!programs?.length && prevLimit !== limit) {
      setCurrentPage(1);
      setPrevLimit(limit);
    }
  }, [programs?.length, limit, selectedTab]);

  useEffect(() => {
    if (TABS[selectedTab] === TabName.COMBINES && !!hsCombineType?.length && prevLimit !== limit) {
      setCurrentPage(1);
      setPrevLimit(limit);
    }
  }, [hsCombineType?.length, limit, selectedTab]);

  useEffect(() => {
    if (dialogOpen) {
      fetchResetPasswordLink();
    }
  }, [dialogOpen]);

  function fetchAccessLevels() {
    gql(`
      accessLevels {
        id
        name
        type
      }
    `)
      .then((data: any) => data.accessLevels as AccessLevel[])
      .then((accessLevels: AccessLevel[]) => {
        if (accessLevels && accessLevels.length)
          setAccessLevels(accessLevels);
        else
          showToast('Failed to fetch Access Levels.', ToastType.ERROR);
      })
      .catch(() => {
        showToast('Failed to fetch Access Levels.', ToastType.ERROR);
      });
  }

  function fetchManagedUsers () {
    setLoading(true);

    gql(`
      managedUsers {
        id
        firstName
        lastName
        email
        accessLevel {
          name
        }
        program {
          expirationDate
          name
          startDate
          createdAt
        }
        lastLoginData {
          userId
          createdAt
          ipAddress
          locationDetails
        }
        rank
        achievements
        lastLogin
        last30DaysLoginCount
        totalLogins
        savedPlayers
        alerts
        pinnedPlayers
        exportedPlayers
        isSMSOptedOut
        invoice
        collegeTeam {
          name
        }
        nflTeam {
          name
        }
        company {
          name
        }
        startDate
        expirationDate
        createdAt
        updatedAt
      }
    `)
      .then((data:any) => data.managedUsers as ManagedUser[])
      .then((managedUsers:ManagedUser[]) => {
        if (!managedUsers || !managedUsers.length) {
          showToast('Failed to fetch users.', ToastType.ERROR);
        } else {
          setManagedUsers(managedUsers);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Failed to fetch users', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function fetchPrograms () {
    setLoading(true);

    gql(`
      programs {
        id
        name
        accessLevelId
        collegeId
        companyId
        nflTeamId
        startDate
        expirationDate
        invoice
        hasTransferPortal
        isEnabled
        isEmailDisabled
        createdAt
      }
    `)
      .then((data: any) => data.programs as Program[])
      .then((programs: Program[]) => {
        if (programs && programs.length) {
          setPrograms(programs);
        } else {
          showToast('Failed to fetch Programs.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Failed to fetch Programs.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function fetchHsCombineTypes () {
    setLoading(true);

    gql(`
      hsCombineTypes {
        id
        type
        teamCamp
        isAccessControlled
      }
    `)
      .then((data: any) => data.hsCombineTypes as HsCombineType[])
      .then((hsCombineTypes: HsCombineType[]) => {
        setHsCombineType(hsCombineTypes);
        setLoading(false)
      })
      .catch(() => {
        showToast('Failed to fetch hsCombineType.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function fetchResetPasswordLink() {
    setLoading(true);

    gql(`
      generateResetPasswordUrl(userId: ${selectedItems[0].id})
    `)
      .then((data: any) => data.generateResetPasswordUrl as string)
      .then((resetPasswordUrl: string) => {
        if (!!resetPasswordUrl) {
          setResetPasswordUrl(resetPasswordUrl);
        } else {
          showToast('Failed to fetch Reset Password Url.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Failed to fetch Reset Password Url.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onDelete () {
    setLoading(true);
    let tempSelectedItems = selectedItems;
    if (TABS[selectedTab] == TabName.COMBINES && tempSelectedItems?.find((sitem: any) => sitem.type == TEAM_CAMP_STRING)) {
      tempSelectedItems = tempSelectedItems.filter(item => item.type !== TEAM_CAMP_STRING);
    }

    const ids = tempSelectedItems.map((item: any) => item.id);

    gql(`
      mutation {
        ${TAB_MUTATION_QUERY[TABS[selectedTab]]}(ids: [${ids}])
      }
    `)
      .then((data:any) => data[TAB_MUTATION_QUERY[TABS[selectedTab]]] as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast('Deleted', ToastType.SUCCESS);
          setSelectedItems([]);

          if (TABS[selectedTab] === TabName.USERS) {
            fetchManagedUsers();
          } else if (TABS[selectedTab] === TabName.PROGRAMS) {
            fetchPrograms();
          } else if (TABS[selectedTab] === TabName.COMBINES) {
            fetchHsCombineTypes();
          }
        }
        else
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onSelectColumns (selectedColumnNames:string[]) {
    const saveColumns = TABS[selectedTab] === TabName.USERS
      ? saveManageUsersColumns
      : saveManageProgramsColumns;

    saveColumns(columns.map((column:Column) => ({
      name: column.name,
      selected: selectedColumnNames.includes(column.name),
    } as Column)));
  }

  function onReorderColumns (reorderedColumnNames:string[]) {
    const saveColumns = TABS[selectedTab] === TabName.USERS
      ? saveManageUsersColumns
      : saveManageProgramsColumns;

    saveColumns(reorderedColumnNames
      .map((columnName:string) => columns.find((column:Column) => column.name === columnName))
      .filter(Boolean) as Column[]
    );
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function onTabChange (event: React.ChangeEvent<{}>, newValue: number) {
    history.push(`/manage/${TABS[newValue]}${history.location.search}`);
    setSelectedTab(newValue);
    setSelectedItems([]);
  }

  function downloadXLSReport(ids:number[]) {
    return () => {
      setLoading(true);

      const exportType:XLSExportType = TABS[selectedTab] === TabName.USERS
        ? XLSExportType.USERS
        : XLSExportType.PROGRAMS;

      exportXLSReport(exportType, { ids })
        .catch(error => {
          if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
            showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
          } else {
            console.error(error);
            showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
          }
        })
        .finally(() => setLoading(false));
    };
  }

  function onCopy() {
    navigator.clipboard.writeText(resetPasswordUrl);
    showToast('Copied.', ToastType.SUCCESS);
    setDialogOpen(false);
    setResetPasswordUrl('');
  }

  const actionDisabled = !selectedItems.length;
  const resetPasswordActionDisabled = selectedItems.length !== 1;
  const TabComponent = TAB_COMPONENT[TABS[selectedTab]] as any;
  const isDesktopScreenSize = isDesktop(screenSize);

  return (
    <div className={clsx(classes.manageTable, className)}>
      <Loader inProgress={loading} />

      <div className={classes.tabsWrapper}>
        <Tabs
          className={classes.tabs}
          classes={{ indicator: classes.tabsIndicator }}
          value={selectedTab}
          onChange={onTabChange}
        >
          {TABS.map((tab:TabName, index) => (
            <Tab
              key={index}
              className={classes.tab}
              label={TAB_TITLE[tab]}
              disableRipple
            />
          ))}
        </Tabs>
      </div>

      <div className={classes.header}>
        <div className={classes.actionRow}>
          <div className={classes.headerTitle}>{TAB_TITLE[TABS[selectedTab]]}</div>

            {
              (![TabName.USAGE, TabName.STAGING].includes(TABS[selectedTab]) ) && (
              <div className={classes.dropdowns}>
                <div className={classes.dropdownWrapper}>
                  Show
                  <PageLimitDropdown
                    className={clsx(classes.dropdown, classes.limitDropDown)}
                    selectorRootClassName={classes.dropdownSelector}
                    value={String(limit)}
                    onChange={(limit:string) => setLimit(Number(limit))}
                  />
                  {TAB_TITLE[TABS[selectedTab]]}
                </div>
              </div>
              )
            }
        </div>
          {
            (![TabName.USAGE, TabName.STAGING].includes(TABS[selectedTab]) ) && (
              <div className={classes.actionRow}>
                <div className={classes.actions}>
                  <Action
                    className={clsx(classes.action, actionDisabled && classes.disabledAction)}
                    icon={RemoveIcon}
                    iconClassName={classes.actionIcon}
                    disabled={actionDisabled}
                    onClick={onDelete}
                  >
                    <span className={classes.actionText}>Delete</span>
                  </Action>

                  {(TABS[selectedTab] !== TabName.COMBINES) && (
                    <Action
                      className={clsx(classes.action, actionDisabled && classes.disabledAction)}
                      icon={XLSIcon}
                      iconClassName={clsx(classes.actionIcon, classes.xlsIcon)}
                      disabled={actionDisabled}
                      onClick={downloadXLSReport(selectedItems.map(item => item.id))}
                    >
                      <span className={classes.actionText}>Report</span>
                    </Action>
                  )

                  }

                  {(adminAccess && TABS[selectedTab] === TabName.USERS) && (
                    <Action
                      className={clsx(classes.action, resetPasswordActionDisabled && classes.disabledAction)}
                      icon={LinkIcon}
                      iconClassName={classes.resetPasswordLinkIcon}
                      disabled={resetPasswordActionDisabled}
                      onClick={() => setDialogOpen(true)}
                    >
                      <span className={classes.actionText}>Generate Reset Password Link</span>
                    </Action>
                  )}
                </div>

                <div className={classes.actionLinks}>
                  {
                    (TABS[selectedTab] !== TabName.COMBINES) && (
                      <AddRemoveColumns
                        className={classes.addRemoveColumns}
                        columns={columns.map((column:Column) => ({
                          // @ts-ignore
                          content: ALL_COLUMN_TITLE[TABS[selectedTab]][column.name],
                          value: column.name,
                        }))}
                        selectedColumns={columns
                          .filter((column:Column) => column.selected)
                          .map((column:Column) => column.name)
                        }
                        onSelect={onSelectColumns}
                        onReorder={onReorderColumns}
                      />)
                  }

                  {(user.accessLevel.exportAllowanceType !== 'none') && (TABS[selectedTab] !== TabName.COMBINES) && (
                    <>
                      <Divider
                        className={classes.divider}
                        orientation='vertical'
                        light
                        flexItem
                      />

                      <Download
                        className={classes.download}
                        label='Export Full Report (XLS)'
                        onClick={downloadXLSReport(((tab === TabName.USERS ? managedUsers : programs) as any[] || []).map((item:any) => item.id))}
                      />
                    </>
                  )}
                </div>
              </div>
            )
          }
      </div>

      <TabComponent
        className={classes.tableWrapper}
        managedUsers={tab === TabName.USERS ? managedUsers : undefined}
        setManagedUsers={tab === TabName.USERS ? setManagedUsers : undefined}
        programs={tab === TabName.PROGRAMS ? programs : undefined}
        setPrograms={tab === TabName.PROGRAMS ? setPrograms : undefined}
        hsCombineType={tab === TabName.COMBINES ? hsCombineType : undefined}
        setHsCombineType={tab === TabName.COMBINES ? setHsCombineType : undefined}
        limit={limit}
        currentPage={currentPage}
        accessLevels={accessLevels}
        selectedItems={selectedItems}
        selectedColumns={columns
          .filter((column:Column) => column.selected)
          .map((column:Column) => column.name)
        }
        isDesktopScreenSize={isDesktopScreenSize}
        setCurrentPage={(page:number) => setCurrentPage(page)}
        setSelectedItems={(items:any) => setSelectedItems(items)}
      />

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>

      <Dialog
        open={dialogOpen}
        title='Reset Password URL'
        actions={[
          {
            name: 'Cancel',
            disabled: loading,
            onClick: () => setDialogOpen(false),
          },
          {
            name: 'Copy',
            primary: true,
            disabled: loading,
            onClick: onCopy,
          },
        ]}
        onClose={() => setDialogOpen(false)}
      >
        <Loader inProgress={loading} />

        {!loading && resetPasswordUrl && (
          <div className={classes.resetPasswordUrlText}>
            {resetPasswordUrl}
          </div>
        )}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state:State) => {
  return {
    savedManageUsersColumns: state.userSettings.manageUsersColumns,
    savedManageProgramsColumns: state.userSettings.manageProgramsColumns,
    adminAccess: state.ui.adminAccess,
    screenSize: state.ui.screenSize,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchUserSettings,
      saveManageUsersColumns,
      saveManageProgramsColumns,
    },
    dispatch,
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageTable);
