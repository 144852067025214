import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BackIcon from '../icons/BackIcon';
import LinkIcon from '../icons/LinkIcon';
import PinIcon from '../icons/PinIcon';
import PDFIcon from '../icons/PDFIcon';
import CompareIcon from '../icons/CompareIcon';
import SaveIcon from '../icons/SaveIcon';
import AlertIcon from '../icons/AlertIcon';
import RemoveIcon from '../icons/RemoveIcon';
import FreshmanIcon from '../icons/FreshmanIcon';
import Score, { SCORE_TYPE } from '../atoms/Score';
import Loader from '../atoms/Loader';
import Action from '../atoms/Action';
import Avatar from '../atoms/Avatar';
import CFCLogo from '../atoms/CFCLogo';
import Tooltip from '../atoms/Tooltip';
import { ToastType } from '../atoms/Toast';
import EditButton from '../atoms/EditButton';
import TeamAutoComplete from '../molecules/TeamAutoComplete';
import MultiSportList from '../molecules/MultiSportList';
import ConfirmDialog from './ConfirmDialog';
import { PlayerPDFQueryParameter, TEAM_TYPE } from '../templates/PlayerPageTemplate';
import gql from '../services/gql';
import { getPrimaryHighSchool } from '../services/player';
import exportPDFReport, { PDFExportPage } from '../services/export-pdf-report';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_TEXT,
  COLOR_DARK_GRAY,
  COLOR_ORANGE,
  COLOR_BACKGROUND_WARM,
  COLOR_MIDDLE_GRAY,
  COLOR_WHITE,
  COLOR_BLUE,
  COLOR_SHADOW,
  COLOR_GREEN,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Player from '../types/Player';
import Team from '../types/Team';
import User from '../types/User';
import Position from '../types/Position';

interface PlayerProfileHeaderProps {
  player?: Player;
  loading: boolean;
  divITeams: Team[];
  nflTeams: Team[];
  selectedTeamType: string;
  selectedCollegeTeam?: Team;
  selectedNFLTeam?: Team;
  selectedPosition?: Position;
  user?: User;
  onCollegeTeamSelected: (team:Team | null) => void;
  onNFLTeamSelected: (team:Team | null) => void;
  setLoading: (isLoading:boolean) => void;
  showToast: (message:any, type:ToastType) => void;
  onEditPlayer: () => void,
  isNFL?: boolean;
  isAdmin?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    boxSizing: 'border-box',
    display: 'flex',
    position: 'relative',
    minHeight: '200px',
    background: COLOR_BACKGROUND_LIGHT,
  },

  backButton: {
    display: 'flex',
    alignItems: 'center',
    appearance: 'none',
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    background: 'none',
    border: 0,
    padding: 0,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_DARK_GRAY,
    cursor: 'pointer',
    transition: 'color 0.3s',
    outline: 'none',
    zIndex: 2,

    '&:focus $backButtonIcon': {
      border: `1px solid ${COLOR_DARK_GRAY}`,
      borderRadius: '50%',
    },
    '&:hover': {
      color: COLOR_TEXT,
    },
    '&:hover:focus $backButtonIcon': {
      borderColor: `1px solid ${COLOR_TEXT}`,
      borderRadius: '50%',
    },
  },
  backButtonIcon: {
    width: '32px',
    height: '32px',
    color: COLOR_DARK_GRAY,
  },
  backButtonText: {
    marginLeft: theme.spacing(1),
  },

  headerLeftContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    paddingLeft: 266,
    zIndex: 1,
  },

  playerIntro: {
    display: 'none',
  },

  playerIntroContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  avatarContainer: {
    width: 'fit-content',
    position: 'relative',
  },
  avatar: {
    width: '100px',
    height: '100px',
  },

  editAction: {
    position: 'absolute',
    color: COLOR_BLUE,
    bottom: '0px',
    right: '-4px',
    background: COLOR_WHITE,
    zIndex: 10,
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_SHADOW}`,
    cursor: 'pointer',
  },

  playerName: {
    margin: theme.spacing(1.5, 1.5, 0, 0),
    ...theme.typography.h2,
    fontSize: theme.typography.pxToRem(30),
    color: COLOR_TEXT,
  },

  cfcLogo: {
    width: '30px',
    margin: theme.spacing(1.5, 1.5, 0, 0),
  },

  playerScoreWrapper: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '150px',
    marginLeft: theme.spacing(4),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  proCombineWrapper: {
    marginLeft: theme.spacing(2.5),
  },

  playerScoreRow: {
    display: 'flex',
    alignItems: 'center',
  },
  playerScoreLabel: {
    color: COLOR_TEXT,
  },
  playerScore: {
    width: 70,
    height: 70,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 700,
    marginTop: theme.spacing(1),

    '&:before': {
      border: `5px solid ${COLOR_MIDDLE_GRAY}`,
    }
  },
  noScoreText: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 700,
  },
  checkIconClass: {
    height: 36,
    width: 36,
  },

  freshmanIcon: {
    width: 48,
    height: 48,
    cursor: 'help',
  },
  paiFreshmanIcon: {
    color: COLOR_ORANGE,
  },
  combineFreshmanIcon: {
    color: COLOR_GREEN,
  },

  multiSport: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70px',
    marginTop: theme.spacing(1),
  },
  multiSportIcon: {
    width: '32px',
    height: '32px',
    margin: theme.spacing(.5,1),

    '&:first-of-type': {
      marginLeft: theme.spacing(1),
    },
  },
  specialistLabel: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5, 2.5),
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '1em',
    color: COLOR_WHITE,
    borderRadius: '18px',
    background: COLOR_DARK_GRAY,
  },

  teamDropDownWrapper: {
    boxSizing: 'border-box',
    position: 'absolute',
    top: theme.spacing(5),
    right: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
  },
  teamDropDownLabel: {
    ...theme.typography.h2,
    color: COLOR_TEXT,
  },
  teamSelect: {
    minWidth: '340px',
    marginLeft: theme.spacing(2),

    '& input': {
      boxSizing: 'border-box',
      height: '70px',
    },
  },
  withNFLTeamSelect: {
    width: '224px',
    minWidth: '224px',
  },

  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    height: '45px',
    zIndex: 1,
  },
  action: {
    padding: theme.spacing(1.5, 2),
    borderRight: `1px solid ${COLOR_MIDDLE_GRAY}`,
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    background: COLOR_BACKGROUND_WARM,
    fontSize: theme.typography.pxToRem(16),
    transition: 'background 0.3s',

    '&:last-child': {
      borderRight: 0,
    },

    '&:hover': {
      background: COLOR_BACKGROUND_LIGHT,
      textDecoration: 'none',

      '& $actionIcon': {
        color: COLOR_ORANGE,
      },
      '& $actionText': {
        color: COLOR_TEXT,
      },
    },
  },
  actionIcon: {
    width: '22px',
    height: '22px',
  },
  actionIconSmall: {
    width: '18px',
    height: '18px',
  },
  actionText: {
    color: COLOR_TEXT,
  },

  '@media (max-width: 1780px)': {
    headerLeftContainer: {
      width: '46%',
      paddingLeft: '220px',
    },
  },

  '@media (max-width: 1680px)': {
    headerLeftContainer: {
      position: 'absolute',
      top: theme.spacing(3.5),
      left: 0,
      width: 'auto',
      paddingLeft: 100,
    },
  },

  '@media (max-width: 1440px)': {
    backButton: {
      top: '10px',
      fontSize: theme.typography.pxToRem(12),
    },
    backButtonIcon: {
      width: '22px',
      height: '22px',
    },

    headerLeftContainer: {
      top: theme.spacing(5.5),
      justifyContent: 'flex-start',
      paddingLeft: 30,
    },

    playerScoreWrapper: {
      maxWidth: '120px',
      marginLeft: theme.spacing(3),
    },

    teamDropDownWrapper: {
      top: theme.spacing(5.5),
    },

    actions: {
      height: '44px',
      marginTop: '20px',
    },
    action: {
      padding: theme.spacing(1, 2),
      fontSize: theme.typography.pxToRem(14),
    },
    actionIcon: {
      width: '18px',
      height: '18px',
    },
  },

  '@media (min-width: 1320px) and (max-width: 1440px)': {
    headerLeftContainer: {
      paddingLeft: 30,
    },
  },

  '@media (min-width: 1280px) and (max-width: 1440px)': {
    playerScore: {
      width: 50,
      height: 50,
      fontSize: theme.typography.pxToRem(16),
    },
    checkIconClass: {
      width: 24,
      height: 24,
    },
    multiSport: {
      height: '50px',
    },
    multiSportIcon: {
      width: 28,
      height: 28,
      margin: theme.spacing(.5),
    },
  },

  '@media (min-width: 1280px)': {
    action: {
      '&:first-of-type': {
        borderRadius: '5px 0 0 0',
      },
    },
  },

  '@media (max-width: 1279px)': {
    header: {
      flexWrap: 'wrap',
      minHeight: 0,
      paddingTop: 44,
    },

    teamDropDownWrapper: {
      top: 20,
      right: 20,
    },
    teamSelect: {
      minWidth: 0,

      '& input': {
        height: 'auto',
      },
    },

    headerLeftContainer: {
      position: 'relative',
      top: 'inherit',
      justifyContent: 'center',
      width: '100%',
      marginTop: theme.spacing(4),
      padding: theme.spacing(0,5),
    },

    playerIntro: {
      display: 'block',
    },

    playerScoreWrapper: {
      maxWidth: '144px',
    },

    playerScore: {
      width: 44,
      height: 44,
      fontSize: theme.typography.pxToRem(16),
    },
    multiSport: {
      height: 44,
    },
    noScoreText: {
      fontSize: theme.typography.pxToRem(14),
    },

    actions: {
      position: 'relative',
      bottom: 'inherit',
      width: '100%',
    },
    action: {
      flexGrow: 1,
      justifyContent: 'center',
    },
  },

  [MEDIA.MOBILE]: {
    backButton: {
      left: theme.spacing(2),
    },

    teamDropDownWrapper: {
      position: 'relative',
      top: 'inherit',
      right: 'inherit',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(0,2),
    },
    teamDropDownLabel: {
      margin: theme.spacing(1),
    },
    teamSelect: {
      minWidth: 0,
      width: '100%',
      maxWidth: '300px',
      margin: theme.spacing(0,1),
    },

    headerLeftContainer: {
      flexWrap: 'wrap',
      padding: theme.spacing(0,2),
    },

    playerIntro: {
      width: '100%',
    },

    playerIntroContent: {
      justifyContent: 'center',
    },

    avatarContainer: {
      margin: '0 auto',
    },

    playerScoreWrapper: {
      margin: theme.spacing(3,2,0,2),

      '&:first-of-type': {
        marginLeft: theme.spacing(2),
      },
    },

    actions: {
      flexWrap: 'wrap',
      height: 'auto',
      maxWidth: '400px',
      margin: '10px auto 20px auto',
      padding: theme.spacing(0,2),
    },
    action: {
      flexGrow: 0,
      justifyContent: 'flex-start',
      width: 'calc(50% - 5px)',
      marginTop: '10px',
      padding: theme.spacing(1.5,2),
      border: 0,
      borderRadius: '30px',

      '&:nth-child(odd)': {
        marginRight: '10px',
      },
    },
  },
}), { name: PlayerProfileHeader.name });

export default function PlayerProfileHeader (props:PlayerProfileHeaderProps) {
  const {
    player,
    loading,
    divITeams,
    nflTeams,
    selectedTeamType,
    selectedCollegeTeam,
    selectedNFLTeam,
    selectedPosition,
    onCollegeTeamSelected,
    onNFLTeamSelected,
    setLoading,
    isNFL,
    isAdmin,
    user,
    showToast,
    onEditPlayer,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [isPinned, setIsPinned] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [hasAlert, setHasAlert] = useState<boolean>(false);

  const [archivePlayerDialogOpen, setArchivePlayerDialogOpen] = useState<boolean>(false);
  const [removePlayerDialogOpen, setRemovePlayerDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (player) {
      setLoading(true);

      if (player.isArchived) {
        setIsArchived(true);
      }

      Promise.allSettled([
        checkIsPinned(player.id),
        checkIsSavedPlayer(player.id),
        checkPlayerHasAlert(player.id),
      ])
      .finally(() => setLoading(false));
    }
  }, [player]);
  const playerHasColleges = player && player.playerColleges && player.playerColleges.length > 0;
  const playerHasNFLTeams = player && player.playerNFLTeams && player.playerNFLTeams.length > 0;

  function checkIsPinned (id:number) {
    return gql(`
      isPinned (playerId: ${id})
    `)
      .then((data: any) => data && data.isPinned as boolean)
      .then((pinnedStatus: boolean) => setIsPinned(pinnedStatus));
  }

  function checkIsSavedPlayer (id:number) {
    return gql(`
      isSavedPlayer (playerId: ${id})
    `)
      .then((data:any) => data.isSavedPlayer as boolean)
      .then((isSaved:boolean) => setIsSaved(isSaved))
      .catch(console.error);
  }

  function checkPlayerHasAlert (id:number) {
    return gql(`
      hasAlert (playerId: ${id})
    `)
      .then((data:any) => data.hasAlert as boolean)
      .then((hasAlert:boolean) => setHasAlert(hasAlert))
      .catch(console.error)
  }

  function onToggleArchivePlayer () {
    setArchivePlayerDialogOpen(false);

    if (!player || !player.slug) return;

    setLoading(true);

    gql(`
      mutation {
        toggleArchive(slug: "${player.slug}")
      }
    `)
      .then((data:any) => data.toggleArchive as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast(player.isArchived ? 'Unarchived' : 'Archived', ToastType.SUCCESS);

          setIsArchived(!isArchived);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function onRemovePlayer () {
    setRemovePlayerDialogOpen(false);

    if (!player || !player.slug) return;

    setLoading(true);

    gql(`
      mutation {
        deletePlayer(slug: "${player.slug}")
      }
    `)
      .then((data:any) => data.deletePlayer as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast('Removed', ToastType.SUCCESS);

          return new Promise<void>((resolve) => {
            setTimeout(() => {
              history.replace('/dashboard');
              resolve();
            }, 2000);
          });
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function pinPlayer() {
    setLoading(true);

    gql(`
      mutation {
        pin (playerIds: [${player && player.id}])
      }
    `)
      .then((data:any) => ({
        success: data.pin as boolean,
        errorMessage: data.errorMessage,
      }))
      .then(({ success, errorMessage }:{ success:boolean, errorMessage:string }) => {
        setIsPinned(success);

        if (errorMessage) {
          showToast(errorMessage, ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function unpinPlayer() {
    setLoading(true);

    gql(`
      mutation {
        unpin (playerIds: [${player && player.id}])
      }
    `)
      .then((data: any) => data && data.unpin as boolean)
      .then((success: boolean) => setIsPinned(!success))
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function savePlayer() {
    setLoading(true);

    gql(`
      mutation {
        savePlayers (playerIds: [${player && player.id}], folderId: null)
      }
    `)
      .then((data:any) => data.savePlayers as boolean)
      .then((isSaved:boolean) => setIsSaved(isSaved))
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function unsavePlayer() {
    setLoading(true);

    gql(`
      mutation {
        unsavePlayers (playerIds: [${player && player.id}])
      }
    `)
      .then((data:any) => data.unsavePlayers as boolean)
      .then((isSaved:boolean) => setIsSaved(!isSaved))
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function addAlert() {
    setLoading(true);

    gql(`
      mutation {
        addAlerts (playerIds: [${player && player.id}])
      }
    `)
      .then((data:any) => data.addAlerts as boolean)
      .then((alertStatus:boolean) => setHasAlert(alertStatus))
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function removeAlert () {
    setLoading(true);

    gql(`
      mutation {
        removeAlerts (playerIds: [${player && player.id}])
      }
    `)
      .then((data:any) => data.removeAlerts as boolean)
      .then((alertRemoved:boolean) => setHasAlert(!alertRemoved))
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function generateAndDownloadPDFReport () {
    if (!player?.slug) return;

    setLoading(true);

    let exportQueryParameters:any = {
      [PlayerPDFQueryParameter.PROFILE_TYPE]: isNFL ? 'nfl' : 'college',
    };
    if (selectedCollegeTeam?.id) {
      exportQueryParameters[PlayerPDFQueryParameter.SELECTED_COLLEGE_TEAM_ID] = selectedCollegeTeam.id;
    }
    if (isNFL && selectedNFLTeam?.id) {
      exportQueryParameters[PlayerPDFQueryParameter.SELECTED_NFL_TEAM_ID] = selectedNFLTeam.id;
    }
    if (selectedPosition) {
      exportQueryParameters[PlayerPDFQueryParameter.SELECTED_POSITION] = selectedPosition;
    }
    if (selectedTeamType) {
      let selectedTeamForQueryParameters = 'high-school';
      if (selectedTeamType === TEAM_TYPE.COLLEGE) {
        selectedTeamForQueryParameters = 'college';
      } else if (selectedTeamType === TEAM_TYPE.NFL) {
        selectedTeamForQueryParameters = 'nfl';
      }
      exportQueryParameters[PlayerPDFQueryParameter.SELECTED_TEAM_TYPE] = selectedTeamForQueryParameters;
    }

    exportPDFReport(
      PDFExportPage.PLAYER,
      player.slug,
      exportQueryParameters,
    )
      .catch(error => {
        console.error(error);
        showToast(<>Failed to download PDF report. <br />({error.message})</>, ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(window.location.href);
    showToast('Copied to clipboard', ToastType.INFO);
  }

  const primaryHighSchool = getPrimaryHighSchool(player);

  return (
    <>
      <header className={classes.header}>
        <Loader inProgress={loading} />

        <button
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          <BackIcon className={classes.backButtonIcon} />
          <span className={classes.backButtonText}>Back</span>
        </button>

        {(user?.accessLevel?.canTeamSelectCollege || user?.accessLevel?.canTeamSelectNFL) && (
          <div className={classes.teamDropDownWrapper}>
            {selectedCollegeTeam && (
              <>
                <span className={classes.teamDropDownLabel}>TeamSelect:</span>

                {user?.accessLevel?.canTeamSelectCollege && (
                  <TeamAutoComplete
                    className={clsx(classes.teamSelect, isNFL && classes.withNFLTeamSelect)}
                    teams={divITeams}
                    selectedTeam={selectedCollegeTeam}
                    onSelect={onCollegeTeamSelected}
                  />
                )}

                {(isNFL && user?.accessLevel?.canTeamSelectNFL) && (
                  <TeamAutoComplete
                    className={clsx(classes.teamSelect, classes.withNFLTeamSelect)}
                    teams={nflTeams}
                    selectedTeam={selectedNFLTeam}
                    onSelect={onNFLTeamSelected}
                  />
                )}
              </>
            )}
          </div>
        )}

        <div className={classes.headerLeftContainer}>
          {player && (
            <>
              <div className={classes.playerIntro}>
                <div className={classes.avatarContainer}>
                  <Avatar
                    className={classes.avatar}
                    src={player.photoUrl}
                    initials={`${(player.firstName || ' ')[0]}${(player.lastName || ' ')[0]}`.trim()}
                    alt='Profile picture'
                  />

                  {isAdmin && (
                    <EditButton
                      className={classes.editAction}
                      onClick={onEditPlayer}
                    />
                  )}
                </div>

                <div className={classes.playerIntroContent}>
                  <h1 className={classes.playerName}>
                    {player.firstName} {player.lastName}
                  </h1>

                  {primaryHighSchool?.state && (
                    <CFCLogo
                      className={classes.cfcLogo}
                      state={primaryHighSchool.state}
                    />
                  )}
                </div>
              </div>

              <div className={classes.playerScoreWrapper}>
                <span className={classes.playerScoreLabel}>PAI:</span>

                <div className={classes.playerScoreRow}>
                  <Score
                    className={classes.playerScore}
                    noScoreTextClass={classes.noScoreText}
                    type={SCORE_TYPE.PAI}
                    scoreList={player.pai}
                    position={selectedPosition}
                  />

                  {!!player?.paiHasFreshmanData && (
                    <Tooltip title='Freshman Data'>
                      <FreshmanIcon className={clsx(classes.freshmanIcon, classes.paiFreshmanIcon)} />
                    </Tooltip>
                  )}
                </div>
              </div>

              <div className={classes.playerScoreWrapper}>
                <span className={classes.playerScoreLabel}>HS Combine:</span>

                <div className={classes.playerScoreRow}>
                  <Score
                    className={classes.playerScore}
                    noScoreTextClass={classes.noScoreText}
                    checkIconClass={classes.checkIconClass}
                    type={SCORE_TYPE.COMBINE}
                    scoreList={player.combine}
                    position={selectedPosition}
                    combines={player.hsCombines}
                  />

                  {!!player?.combineHasFreshmanData && (
                    <Tooltip title='Freshman Data'>
                      <FreshmanIcon className={clsx(classes.freshmanIcon, classes.combineFreshmanIcon)} />
                    </Tooltip>
                  )}
                </div>
              </div>

              {player?.ppi && (user?.accessLevel?.hasCollegeAccess || user?.accessLevel?.hasNFLAccess) && (
                <div className={clsx(classes.playerScoreWrapper, classes.proCombineWrapper)}>
                  <span className={classes.playerScoreLabel}>PPI Score:</span>

                  <div className={classes.playerScoreRow}>
                    <Score
                      className={classes.playerScore}
                      checkIconClass={classes.checkIconClass}
                      type={SCORE_TYPE.PPI}
                      value={player.ppi || undefined}
                      position={selectedPosition}
                    />
                  </div>
                </div>
              )}

              {(player?.nflCombineScore || !!player?.nflCombines?.length) && (
                <div className={clsx(classes.playerScoreWrapper, classes.proCombineWrapper)}>
                  <span className={classes.playerScoreLabel}>Pro Combine:</span>

                  <div className={classes.playerScoreRow}>
                    <Score
                      className={classes.playerScore}
                      checkIconClass={classes.checkIconClass}
                      type={SCORE_TYPE.PRO_COMBINE}
                      value={player.nflCombineScore || undefined}
                      position={selectedPosition}
                      combines={player.nflCombines}
                    />
                  </div>
                </div>
              )}

              <div className={classes.playerScoreWrapper}>
                <span className={classes.playerScoreLabel}>Multi-sport:</span>
                {(player.multiSport && player.multiSport.length) ? (
                  <MultiSportList
                    className={classes.multiSport}
                    iconClassName={classes.multiSportIcon}
                    list={player.multiSport}
                  />
                ) : (
                  <div className={classes.multiSport}>
                    <div className={classes.specialistLabel}>Specialist</div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div className={classes.actions}>
          {isAdmin && (
            <Action
              className={classes.action}
              icon={RemoveIcon}
              iconClassName={classes.actionIcon}
              onClick={() => setArchivePlayerDialogOpen(true)}
            >
              <span className={classes.actionText}>
                {isArchived ? 'Unarchive' : 'Archive'}
              </span>
            </Action>
          )}

          {isAdmin && (
            <Action
              className={classes.action}
              icon={RemoveIcon}
              iconClassName={classes.actionIcon}
              onClick={() => setRemovePlayerDialogOpen(true)}
            >
              <span className={classes.actionText}>Remove</span>
            </Action>
          )}

          <Action
            className={classes.action}
            icon={LinkIcon}
            iconClassName={classes.actionIcon}
            onClick={copyToClipboard}
          >
            <span className={classes.actionText}>Link</span>
          </Action>

          <Action
            className={classes.action}
            icon={PinIcon}
            iconClassName={clsx(classes.actionIcon,classes.actionIconSmall)}
            onClick={isPinned ? unpinPlayer : pinPlayer}
          >
            <span className={classes.actionText}>{isPinned ? 'Unpin' : 'Pin'}</span>
          </Action>

          <Action
            className={classes.action}
            icon={PDFIcon}
            iconClassName={clsx(classes.actionIcon,classes.actionIconSmall)}
            onClick={generateAndDownloadPDFReport}
            disabled={loading}
          >
            <span className={classes.actionText}>Report</span>
          </Action>

          {user?.accessLevel?.canComparePlayers && (
            <Action
              className={classes.action}
              icon={CompareIcon}
              iconClassName={classes.actionIcon}
              href={player && player.slug ? `/compare/${player.slug}` : '/compare'}
            >
              <span className={classes.actionText}>Compare</span>
            </Action>
          )}

          <Action
            className={classes.action}
            icon={SaveIcon}
            iconClassName={clsx(classes.actionIcon,classes.actionIconSmall)}
            onClick={isSaved ? unsavePlayer : savePlayer}
          >
            <span className={classes.actionText}>{isSaved ? 'Unsave' : 'Save'}</span>
          </Action>
          {!isNFL && !(playerHasColleges || playerHasNFLTeams) && (
            <Action
              className={classes.action}
              icon={AlertIcon}
              iconClassName={classes.actionIcon}
              onClick={hasAlert ? removeAlert : addAlert}
            >
              <span className={classes.actionText}>{hasAlert ? 'Remove Alert' : 'Alert'}</span>
            </Action>
          )}
        </div>
      </header>

      <ConfirmDialog
        open={archivePlayerDialogOpen}
        confirmActionText={`Yes, ${isArchived ? 'Unarchive' : 'Archive'}`}
        onConfirm={onToggleArchivePlayer}
        onClose={() => setArchivePlayerDialogOpen(false)}
        onCancel={() => setArchivePlayerDialogOpen(false)}
      />

      <ConfirmDialog
        open={removePlayerDialogOpen}
        confirmActionText={`Yes, Remove`}
        onConfirm={onRemovePlayer}
        onClose={() => setRemovePlayerDialogOpen(false)}
        onCancel={() => setRemovePlayerDialogOpen(false)}
      />
    </>
  );
}
