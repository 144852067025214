export enum StorageKey {
  ACHIEVEMENTS = 'achievements',
  ALERTS_TABLE = 'alerts-table',
  ALL_STATES = 'all-states',
  ALL_STATES_EXPIRATION_DATE = 'all-states-expiration-date',
  COLLEGE_TEAMS = 'college-teams',
  JUCO_TEAMS = 'juco-teams',
  COLLEGE_TEAMS_EXPIRATION_DATE = 'college-teams-expiration-date',
  COMPARE_TABLE = 'compare-table',
  CONFIGURATIONS = 'configurations',
  CONFIGURATIONS_EXPIRATION_DATE = 'configurations-expiration-date',
  DIV_I_TEAMS = 'div-I-teams',
  DIV_I_TEAMS_EXPIRATION_DATE = 'div-I-teams-expiration-date',
  EXPORTS_TABLE = 'exports-table',
  IMPORT_TABLE = 'import-table',
  MANAGE_PROGRAMS = 'manage-programs',
  MANAGE_USER = 'manage-user',
  MANAGE_HS_COMBINE_TYPE = 'manage-hs-combine-type',
  MANAGE_USAGE = 'manage-usage',
  MANAGE_STAGING = 'staging',
  MESSAGE_DRAFT_TABLE = 'message-draft-table',
  MESSAGE_INBOX_TABLE = 'message-inbox-table',
  MESSAGE_SENT_TABLE = 'message-sent-table',
  NFL_TEAMS = 'nfl-teams',
  NFL_TEAMS_EXPIRATION_DATE = 'nfl-teams-expiration-date',
  SAVED_PLAYER_TABLE = 'saved-player-table',
  SAVED_SEARCH_TABLE = 'saved-search-table',
  SEARCH_FILTERS = 'search-filters',
  SEARCH_PAGE = 'search-page',
  SELECTED_COLLEGE_TEAM = 'selected-college-team',
  SELECTED_NFL_TEAM = 'selected-nfl-team',
  STAFF_RECORD_TABLE = 'staff-record-table',
  TEAM_PLAYERS_TABLE = 'team-players-table',
  TOKEN = 'user-token',
  USER = 'user',
  USER_EXPIRATION_DATE = 'user-expiration-date',
  VIEW_MODE = 'view-mode',
  CONFERENCES_FBS = 'fbs-conferences',
  CONFERENCES_FCS = 'fcs-conferences',
  CONFERENCES_FBS_EXPIRATION_DATE = 'fbs-expiration-date',
  CONFERENCES_FCS_EXPIRATION_DATE = 'fcs-expiration-date',
}

export function save (key:StorageKey, data:any) {
  if (!data) return;

  if (typeof data === 'object') {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.setItem(key, data);
  }
}

export function get (key:StorageKey):any | null {
  let valueToReturn;

  const stringValue = localStorage.getItem(key);
  try {
    valueToReturn = JSON.parse(stringValue || '');
  } catch (error) {
    valueToReturn = stringValue;
  }
  return valueToReturn;
}

export function remove (key:StorageKey) {
  localStorage.removeItem(key);
}

export function clearAll() {
  localStorage.clear();
}
