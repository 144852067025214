import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import PlusIcon from '../icons/PlusIcon';
import Input from '../atoms/Input';
import DropDown from '../atoms/DropDown';
import Toast, { ToastType } from '../atoms/Toast';
import Button from '../atoms/Button';
import Checkbox from '../atoms/Checkbox';
import DatePicker from '../atoms/DatePicker';
import TeamAutoComplete from '../molecules/TeamAutoComplete';
import CreateCompanyModal from '../molecules/CreateCompanyModal';
import gql, { serializeGQLObject } from '../services/gql';
import { State } from '../redux/reducers';
import { fetchCollegeTeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import AccessLevel, { AccessLevelType, DraftProjectorType, TransferPortalType } from '../types/AccessLevel';
import Company from '../types/Company';
import Program from '../types/Program';
import Team from '../types/Team';
import ConfirmDialog from './ConfirmDialog';

interface AddEditProgramFormProps {
  className?: string;
  isEnabled: boolean;
  isEditMode: boolean;
  program?: Program;
  loading: boolean;
  nflDraftYear?: number;
  setLoading: (loading:boolean) => void;
  collegeTeams: Team[];
  nflTeams: Team[];
  fetchCollegeTeams: () => void;
  fetchNFLTeams: () => void;
}

const MIN_NFL_ACCESS_YEAR = 2000;
const MIN_HISTORIC_COLLEGE_ACCESS_YEAR = 1980;

const useStyles = makeStyles(theme => ({
  addEditProgramForm: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },

  input: {
    width: '100%',
    maxWidth: '400px',
    padding: 0,
    marginBottom: theme.spacing(4),
  },
  dropDown: {
    width: '100%',
    maxWidth: '400px',
    marginBottom: theme.spacing(4),
    padding: 0,
  },
  dropDownSelect: {
    height: '50px',
  },
  teamSelect: {
    maxWidth: '400px',
    marginBottom: theme.spacing(4),

    '& input': {
      boxSizing: 'border-box',
      height: '50px',
    },
  },

  plusIcon: {
    height: '12px',
    width: '12px',
    marginRight: theme.spacing(1),
  },

  dateWrapper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  date: {
    marginRight: theme.spacing(4),
    marginBottom: 0,
  },
  dateCheckboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  apiKeyWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  apiKey: {
    margin: theme.spacing(2, 4, 0, 0),
  },
  apiButton: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '50px',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),

    '&:last-of-type': {
      marginRight: 0,
    },
  },

  invoice: {
    margin: theme.spacing(5, 0, 0, 0),
  },

  dropdownWrapper: {
    maxWidth: '400px',
  },
  dropdownLabel: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1.5),
  },

  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  action: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),

    '&:last-of-type': {
      marginRight: 0,
    },
  },

  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  disabledCheckboxWrapper: {
    color: '#aaa',
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },

  sectionTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  apiCheckboxes: {
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  apiCheckboxesRow: {
    display: 'flex',
    alignItems: 'center',

    '&:not(:first-of-type)': {
      marginTop: theme.spacing(3),
    },
  },
  apiCheckbox: {
    marginTop: 0,
  },
  ncaaIdCheckbox: {
    marginLeft: 100,
  },
  draftEligiblePlayersCheckbox: {
    marginLeft: 12,
  },

  warRomCheckbox: {
    marginLeft: 19,
  },

  startYearInput: {
    width: 145,
    margin: theme.spacing(0, 0, 0, 'auto'),
  },

  companyDropdownWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  companyWrapper: {
    display: 'flex',
  },
  companyName: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  companyLogo: {
    height: 24,
    marginLeft: theme.spacing(2),
  },
  createCompanyButton: {
    height: '50px',
    marginLeft: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '50px',
  },

  '@media (max-width: 1023px)': {
    dateWrapper: {
      maxWidth: '400px',
    },
    date: {
      maxWidth: 'none',
      flexShrink: 0,
    },
    dateCheckboxWrapper: {
      marginTop: theme.spacing(2),
    },

    apiKeyWrapper: {
      maxWidth: '400px',
    },
    apiKey: {
      maxWidth: 'none',
      flexShrink: 0,
    },
  },
}), { name: AddEditProgramForm.name });

function AddEditProgramForm (props: AddEditProgramFormProps) {
  const {
    className,
    isEnabled = false,
    isEditMode = false,
    program,
    loading,
    nflDraftYear,
    setLoading,
    collegeTeams,
    nflTeams,
    fetchCollegeTeams,
    fetchNFLTeams,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [accessLevels, setAccessLevels] = useState<AccessLevel[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);

  const [selectedAccessLevelId, setSelectedAccessLevelId] = useState<number | null>(null);
  const [selectedCollegeTeam, setSelectedCollegeTeam] = useState<Team | null>(null);
  const [selectedNFLTeam, setSelectedNFLTeam] = useState<Team | null>(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(null);
  const [showCreateCompanyModal, setShowCreateCompanyModal] = useState<boolean>(false);

  const [subscriptionStartDate, setSubscriptionStartDate] = useState<Date | null>(null);
  const [subscriptionStartsNow, setSubscriptionStartsNow] = useState<boolean>(true);

  const [expirationDate, setExpirationDate] = useState<Date | null>(null);
  const [noExpirationDate, setNoExpirationDate] = useState<boolean>(true);

  const [apiKey, setApiKey] = useState<string>('');
  const [standardApiAccess, setStandardApiAccess] = useState<boolean>(false);
  const [ncaaIdApiAccess, setNcaaIdApiAccess] = useState<boolean>(false);
  const [currentCollegePlayersAccess, setCurrentCollegePlayersAccess] = useState<boolean>(false);
  const [draftEligiblePlayersAccess, setDraftEligiblePlayersAccess] = useState<boolean>(false);
  const [accessSpeedPowerOnly, setAccessSpeedPowerOnly] = useState<boolean>(false);
  const [warRoom, setWarRoomAccess] = useState<boolean>(false);

  const [nflPlayersAccess, setNFLPlayersAccess] = useState<boolean>(false);
  const [nflStartYear, setNflStartYear] = useState<number|null>(null);
  const [nflStartYearError, setNFLStartYearError] = useState<string>('');

  const [historicCollegePlayersAccess, setHistoricCollegePlayersAccess] = useState<boolean>(false);
  const [historicCollegePlayersStartYear, setHistoricCollegePlayersStartYear] = useState<number|null>(null);
  const [historicCollegePlayersStartYearError, setHistoricCollegePlayersStartYearError] = useState<string>('');

  const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<number | null>(null);
  const [hasTransferPortal, setHasTransferPortal] = useState<boolean>(false);
  const [hasAutoDepthChart, setHasAutoDepthChart] = useState<boolean>(false);
  const [hasDraftProjector, setHasDraftProjector] = useState<boolean>(false);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [removeApiKeyDialoge, setRemoveApiKeyDialoge] = useState<boolean>(false);

  const selectedAccessLevel = accessLevels.find((accessLevel:AccessLevel) => accessLevel.id === selectedAccessLevelId);
  const selectedAccessLevelType:AccessLevelType | null = selectedAccessLevel ? selectedAccessLevel.type : null;
  const selectedCollegeTeamName = selectedCollegeTeam ? selectedCollegeTeam.name : '';
  const selectedNFLTeamName = selectedNFLTeam ? selectedNFLTeam.name : '';
  const selectedCompany = companies.find((company:Company) => company.id === selectedCompanyId);
  const selectedCompanyName = selectedCompany ? selectedCompany.name : '';
  const depthChartAccessEnable = selectedAccessLevel ? [3,4,13,14,6].includes(selectedAccessLevel.id) : false;

  useEffect(() => {
    setLoading(true);

    Promise.allSettled([
      fetchAccessLevels(),
      fetchCompanies(),
    ])
      .finally(() => setLoading(false));

    fetchCollegeTeams();
    fetchNFLTeams();
  }, []);

  useEffect(() => {
    if (
      ((isEditMode && program) || !isEditMode)
      && accessLevels && accessLevels.length
      && collegeTeams && collegeTeams.length
      && nflTeams && nflTeams.length
      && companies && companies.length
    ) {
      setLoading(false);
    }
  }, [program, isEditMode, accessLevels, collegeTeams, nflTeams, companies]);

  useEffect(() => {
    if (isEditMode
      && !selectedAccessLevelId
      && program
      && collegeTeams && collegeTeams.length
      && nflTeams && nflTeams.length
    ) {
      if (program.accessLevelId) {
        setSelectedAccessLevelId(program.accessLevelId);
      }

      if (program.collegeId) {
        const collegeTeam = collegeTeams.find((team:Team) => team.id === program.collegeId);
        if (collegeTeam) {
          setSelectedCollegeTeam(collegeTeam);
        }
      }

      if (program.nflTeamId) {
        const nflTeam = nflTeams.find((team:Team) => team.id === program.nflTeamId);
        if (nflTeam) {
          setSelectedNFLTeam(nflTeam);
        }
      }

      if (program.companyId) {
        setSelectedCompanyId(program.companyId);
      }

      if (program.startDate) {
        setSubscriptionStartDate(new Date(program.startDate));
        setSubscriptionStartsNow(false);
      } else {
        setSubscriptionStartDate(null);
        setSubscriptionStartsNow(true);
      }

      if (program.expirationDate) {
        setExpirationDate(new Date(program.expirationDate));
        setNoExpirationDate(false);
      } else {
        setSubscriptionStartDate(null);
        setNoExpirationDate(true);
      }

      if (program.apiKey) {
        setApiKey(program.apiKey);
      }

      if (program.apiAccessCurrentCollegePlayers) {
        setCurrentCollegePlayersAccess(true);
        setDraftEligiblePlayersAccess(false);
      }

      if (program.apiAccessDraftEligiblePlayers) {
        setDraftEligiblePlayersAccess(true);
        setCurrentCollegePlayersAccess(false);
      }

      if (program.apiAccessNFLPlayers && program.apiAccessNFLStartYear) {
        setNflStartYear(program.apiAccessNFLStartYear);
        setNFLPlayersAccess(true);
      } else {
        setNflStartYear(MIN_NFL_ACCESS_YEAR);
        setNFLPlayersAccess(false);
      }

      if (program.apiAccessHistoricCollegePlayers && program.apiAccessHistoricCollegePlayersStartYear) {
        setHistoricCollegePlayersStartYear(program.apiAccessHistoricCollegePlayersStartYear);
        setHistoricCollegePlayersAccess(true);
      } else {
        setHistoricCollegePlayersStartYear(MIN_HISTORIC_COLLEGE_ACCESS_YEAR);
        setHistoricCollegePlayersAccess(false);
      }

      if (program.isEmailDisabled) {
        setIsEmailDisabled(true);
      }

      if (program.standardApiAccess) {
        setStandardApiAccess(true);
      }

      if (program.ncaaIdApiAccess) {
        setNcaaIdApiAccess(true);
      }

      if (program.apiAccessSpeedPowerOnly) {
        setAccessSpeedPowerOnly(true);
      }

      if (program.invoice) {
        setInvoice(program.invoice);
      }

      if (program.autoDepthChartEnabled) {
        setHasAutoDepthChart(program.autoDepthChartEnabled);
      }

      if (program.enableWarRoomResponse) {
        setWarRoomAccess(program.enableWarRoomResponse);
      }
    }
  }, [
    isEditMode,
    program,
    selectedAccessLevelId,
    collegeTeams,
    nflTeams,
  ]);

  useEffect(() => {
    if (program && selectedAccessLevel && program.accessLevelId === selectedAccessLevel.id) {
      setHasTransferPortal(program.hasTransferPortal)
      setHasDraftProjector(program.hasDraftProjector);
      setHasAutoDepthChart(program.autoDepthChartEnabled);
      setWarRoomAccess(program.enableWarRoomResponse);
    } else if (selectedAccessLevel) {
      setHasTransferPortal(selectedAccessLevel.transferPortal === TransferPortalType.YES)
      setHasDraftProjector(selectedAccessLevel.draftProjector === DraftProjectorType.YES);
      if (selectedAccessLevel.transferPortal === TransferPortalType.YES_IF_ACTIVATED) {
        setHasTransferPortal(hasTransferPortal)
      }
    }
  }, [program, selectedAccessLevel]);

  useEffect(() => {
    if (nflStartYear && nflStartYear < MIN_NFL_ACCESS_YEAR) {
      setNFLStartYearError(`Can't be less than ${MIN_NFL_ACCESS_YEAR}`);
    } else if (nflStartYearError) {
      setNFLStartYearError('');
    }
  }, [nflStartYear, nflStartYearError]);

  useEffect(() => {
    if (historicCollegePlayersStartYear && historicCollegePlayersStartYear < MIN_HISTORIC_COLLEGE_ACCESS_YEAR) {
      setHistoricCollegePlayersStartYearError(`Can't be less than ${MIN_HISTORIC_COLLEGE_ACCESS_YEAR}`);
    } else if (historicCollegePlayersStartYearError) {
      setHistoricCollegePlayersStartYearError('');
    }
  }, [historicCollegePlayersStartYear, historicCollegePlayersStartYearError]);

  function fetchAccessLevels () {
    return gql(`
      accessLevels {
        id
        name
        type
        hasAPI
        transferPortal
        draftProjector
      }
    `)
      .then((data:any) => data.accessLevels as AccessLevel[])
      .then((accessLevels:AccessLevel[]) => {
        if (accessLevels && accessLevels.length) {
          setAccessLevels(accessLevels);
        } else {
          showToast('Failed to fetch Access Levels.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Failed to fetch Access Levels.', ToastType.ERROR);
      });
  }

  function fetchCompanies () {
    return gql(`
      companies {
        id
        name
        logoUrl
      }
    `)
      .then((data:any) => data.companies as Company[])
      .then((companies:Company[]) => {
        if (companies && companies.length) {
          setCompanies(companies.sort((company1:Company, company2:Company) => {
            if (company1.name > company2.name) return 1;
            if (company1.name < company2.name) return -1;
            return 0;
          }));
        } else {
          showToast('Failed to fetch companies.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Failed to fetch companies.', ToastType.ERROR);
      });
  }

  function generateApiKey () {
    setLoading(true);

    gql(`
      generateApiKey
    `)
      .then((data:any) => data.generateApiKey as string)
      .then((apiKey:string) => {
        if (apiKey && apiKey.length) {
          setApiKey(apiKey);
          if (!standardApiAccess && !ncaaIdApiAccess) {
            setStandardApiAccess(true);
          }
        } else {
          showToast('Failed to generate API Key. Try again.', ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast('Failed to generate API Key. Try again.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function saveProgram () {
    const programName = selectedCompanyName || selectedNFLTeamName || selectedCollegeTeamName;

    if (!programName) {
      return showToast('Can\'t save a program without name. Please contact our Support Team.', ToastType.ERROR);
    }

    setLoading(true);

    const programPayload = {
      name: programName,
      accessLevelId: selectedAccessLevelId,
      collegeId: selectedCollegeTeam && selectedCollegeTeam.id ? selectedCollegeTeam.id : null,
      nflTeamId: selectedNFLTeam && selectedNFLTeam.id ? selectedNFLTeam.id : null,
      companyId: selectedCompanyId || null,
      startDate: subscriptionStartsNow ? null : subscriptionStartDate,
      expirationDate: noExpirationDate ? null : expirationDate,
      apiKey,
      standardApiAccess,
      ncaaIdApiAccess,
      apiAccessCurrentCollegePlayers: currentCollegePlayersAccess,
      apiAccessDraftEligiblePlayers: draftEligiblePlayersAccess,
      apiAccessNFLPlayers: nflPlayersAccess,
      apiAccessSpeedPowerOnly: accessSpeedPowerOnly,
      apiAccessNFLStartYear: nflStartYear,
      apiAccessHistoricCollegePlayers: historicCollegePlayersAccess,
      apiAccessHistoricCollegePlayersStartYear: historicCollegePlayersStartYear,
      autoDepthChartEnabled: hasAutoDepthChart,
      enableWarRoomResponse: warRoom,
      invoice,
      isEnabled,
      isEmailDisabled,
      hasTransferPortal,
      hasDraftProjector,
    } as any;
    const newProgramTeamId = selectedNFLTeam && selectedNFLTeam.id ? selectedNFLTeam.id : selectedCollegeTeam && selectedCollegeTeam.id ? selectedCollegeTeam.id : null;
    let programTeamId = program && program.id ? program.programTeamId : newProgramTeamId
    programPayload.programTeamId = programTeamId;

    if (isEditMode && program && program.id) {
      programPayload.id = program.id;
    }

    gql(`
      mutation {
        saveProgram (
          program: ${serializeGQLObject(programPayload)}
        )
      }
    `)
      .then((data:any) => data.saveProgram as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast(`${isEditMode ? 'Updated' : 'Program Added'}`, ToastType.SUCCESS);

          if (!isEditMode) {
            resetData();
          }
        } else {
          showToast(`Failed to ${isEditMode ? 'update' : 'add'} program.`, ToastType.ERROR);
        }
      })
      .catch(() => {
        showToast(`Failed to ${isEditMode ? 'update' : 'add'} program.`, ToastType.ERROR);
      })
      .finally(() =>  setLoading(false));
  }

  function onCreateCompany (newCompany:Company|null) {
    if (newCompany?.id) {
      setCompanies([
        ...companies,
        newCompany,
      ]);
      setSelectedCompanyId(newCompany.id);
      setShowCreateCompanyModal(false);
    }
  }

  function resetData () {
    setSelectedAccessLevelId(null);
    setSelectedCompanyId(null);
    setSelectedCollegeTeam(null);
    setSelectedNFLTeam(null);
    setApiKey('');
    setStandardApiAccess(false);
    setNcaaIdApiAccess(false);
    setCurrentCollegePlayersAccess(false);
    setDraftEligiblePlayersAccess(false);
    setNFLPlayersAccess(false);
    setNflStartYear(null);
    setInvoice(null);
    setIsEmailDisabled(false);
    setSubscriptionStartDate(null);
    setSubscriptionStartsNow(true);
    setExpirationDate(null);
    setNoExpirationDate(true);
  }

  function customResetData () {
    if ([7,8,9,10,11].includes(selectedAccessLevelId || 0)) {
      setApiKey('');
    }
    if ([1,2,3,4,12,13,14].includes(selectedAccessLevelId || 0)) {
      setSelectedNFLTeam(null);
    }
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const accessLevelItems = accessLevels.map((accessLevel: AccessLevel) => ({
    content: accessLevel.name,
    value: accessLevel.id
  }));

  function removeApiKey() {
    setApiKey(''); 
    setRemoveApiKeyDialoge(false);
    setStandardApiAccess(false);
    setNcaaIdApiAccess(false);
    setCurrentCollegePlayersAccess(false);
    setDraftEligiblePlayersAccess(false);
    setNFLPlayersAccess(false);
    setAccessSpeedPowerOnly(false);
  }

  return (
    <>
      <div className={clsx(classes.addEditProgramForm, className)}>
        <div className={classes.dropdownWrapper}>
          <div className={classes.dropdownLabel}>Access Level:</div>

          <DropDown
            className={classes.dropDown}
            selectorClassName={classes.dropDownSelect}
            emptyItem={{
              value: '',
              content: 'Access Level'
            }}
            items={accessLevelItems}
            value={selectedAccessLevelId || ''}
            onChange={(accessLevelId:number | string) => {
              isEditMode && customResetData();
              !isEditMode && resetData();
              setSelectedAccessLevelId(Number(accessLevelId) || null);
            }}
          />
        </div>

        {!!selectedAccessLevelId && (
          <>
            <div className={classes.dropdownWrapper}>
              <div className={classes.dropdownLabel}>College Team:</div>
              <TeamAutoComplete
                className={classes.teamSelect}
                label='College Team'
                teams={collegeTeams}
                selectedTeam={selectedCollegeTeam}
                onSelect={(team:Team | null) => team && setSelectedCollegeTeam(team)}
              />
            </div>

            {(selectedAccessLevelType !== AccessLevelType.COLLEGE) && (
              <div className={classes.dropdownWrapper}>
                <div className={classes.dropdownLabel}>NFL Team:</div>
                <TeamAutoComplete
                  className={classes.teamSelect}
                  label='NFL Team'
                  teams={nflTeams}
                  selectedTeam={selectedNFLTeam}
                  onSelect={(team:Team | null) => team && setSelectedNFLTeam(team)}
                />
              </div>
            )}
          </>
        )}

        {!!selectedAccessLevelId && (
          <div className={classes.companyDropdownWrapper}>
            <div className={classes.dropdownLabel}>Company:</div>

            <div className={classes.companyWrapper}>
              <DropDown
                className={classes.dropDown}
                selectorClassName={classes.dropDownSelect}
                emptyItem={{
                  value: '',
                  content: 'Company'
                }}
                items={companies.map((company:Company) => ({
                  content: (
                    <div className={classes.companyName}>
                      {company.name}
                      {company?.logoUrl && (
                        <img
                          className={classes.companyLogo}
                          src={company.logoUrl}
                          alt={`${company.name} logo`}
                        />
                      )}
                    </div>
                  ),
                  value: company.id,
                }))}
                value={selectedCompanyId || ''}
                onChange={(companyId:number | string) => setSelectedCompanyId(Number(companyId) || null)}
              />

              <Button
                className={classes.createCompanyButton}
                disabled={loading}
                onClick={() => setShowCreateCompanyModal(true)}
              >
                Create a Company
              </Button>
            </div>
          </div>
        )}

        <div className={classes.dateWrapper}>
          <DatePicker
            className={clsx(classes.input, classes.date)}
            label='Subscription Start Date'
            value={subscriptionStartsNow ? null : subscriptionStartDate}
            disabled={subscriptionStartsNow}
            onChange={(subscriptionStartDate:Date | null) => setSubscriptionStartDate(subscriptionStartDate)}
          />

          <div className={classes.dateCheckboxWrapper}>
            <Checkbox
              className={classes.checkbox}
              checked={subscriptionStartsNow}
              onChange={() => setSubscriptionStartsNow(!subscriptionStartsNow)}
            />
            Subscription starts now
          </div>
        </div>

        <div className={classes.dateWrapper}>
          <DatePicker
            className={clsx(classes.input, classes.date)}
            label='Expiration Date'
            value={noExpirationDate ? null : expirationDate}
            disabled={noExpirationDate}
            onChange={(expirationDate:Date | null) => setExpirationDate(expirationDate)}
          />

          <div className={classes.dateCheckboxWrapper}>
            <Checkbox
              className={classes.checkbox}
              checked={noExpirationDate}
              onChange={() => setNoExpirationDate(!noExpirationDate)}
            />
            No expiration date
          </div>
        </div>

        {(selectedAccessLevel?.hasAPI) && (
          <>
            <div className={classes.apiKeyWrapper}>
              <Input
                className={clsx(classes.input, classes.apiKey)}
                label='API Key'
                value={apiKey}
                disabled
              />

              <Button
                className={classes.apiButton}
                disabled={loading}
                onClick={generateApiKey}
              >
                Generate API Key
              </Button>

              <Button
                className={classes.apiButton}
                disabled={loading || !apiKey.length}
                onClick={() => navigator.clipboard.writeText(apiKey)}
              >
                Copy API Key
              </Button>

              <Button
                className={classes.apiButton}
                disabled={loading || !apiKey.length}
                onClick={() => setRemoveApiKeyDialoge(true)}
              >
                Remove API Key
              </Button>
            </div>
          </>
        )}

          <ConfirmDialog
            title={"Are you sure you want to remove API Access?"}
            open={removeApiKeyDialoge}
            confirmActionText={`Remove`}
            onConfirm={() => removeApiKey()}
            onClose={() => setRemoveApiKeyDialoge(false)}
            onCancel={() => setRemoveApiKeyDialoge(false)}
          />

        {(selectedAccessLevel && apiKey) && (
          <>
            <h3 className={classes.sectionTitle}>
              API Access Permissions
            </h3>

            <div className={classes.apiCheckboxes}>
              <div className={classes.apiCheckboxesRow}>
                <div className={clsx(classes.checkboxWrapper, classes.apiCheckbox)}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={standardApiAccess}
                    onChange={() => {
                      setStandardApiAccess(!standardApiAccess); 
                      if(standardApiAccess) setWarRoomAccess(false);
                    }
                    }
                  />
                  API Access
                </div>

                <div className={clsx(classes.checkboxWrapper, classes.apiCheckbox, classes.ncaaIdCheckbox)}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={ncaaIdApiAccess}
                    onChange={() => setNcaaIdApiAccess(!ncaaIdApiAccess)}
                  />
                  NCAA Id Access
                </div>
              </div>

              <div className={classes.apiCheckboxesRow}>
                <div className={clsx(classes.checkboxWrapper, classes.apiCheckbox)}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={currentCollegePlayersAccess}
                    onChange={() => {
                      if (currentCollegePlayersAccess) {
                        setCurrentCollegePlayersAccess(false);

                        if (!draftEligiblePlayersAccess && historicCollegePlayersAccess) {
                          setHistoricCollegePlayersAccess(false);
                        }
                      } else {
                        setCurrentCollegePlayersAccess(true);
                        setDraftEligiblePlayersAccess(false);
                      }
                    }}
                  />
                  Current College Players
                </div>

                <div className={clsx(classes.checkboxWrapper, classes.apiCheckbox, classes.draftEligiblePlayersCheckbox)}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={draftEligiblePlayersAccess}
                    onChange={() => {
                      if (draftEligiblePlayersAccess) {
                        setDraftEligiblePlayersAccess(false);

                        if (!currentCollegePlayersAccess && historicCollegePlayersAccess) {
                          setHistoricCollegePlayersAccess(false);
                        }
                      } else {
                        setDraftEligiblePlayersAccess(true);
                        setCurrentCollegePlayersAccess(false);
                      }
                    }}
                  />
                  Draft Eligible Players
                </div>
              </div>

              <div className={classes.apiCheckboxesRow}>
                <div className={clsx(classes.checkboxWrapper, classes.apiCheckbox)}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={accessSpeedPowerOnly}
                    onChange={() => setAccessSpeedPowerOnly(!accessSpeedPowerOnly)}
                  />
                  Only Speed and Power
                </div>
                <div className={clsx(classes.checkboxWrapper, classes.apiCheckbox, classes.warRomCheckbox)}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={warRoom}
                    disabled={!standardApiAccess || selectedAccessLevel.type !== 'college'}
                    onChange={() => setWarRoomAccess(!warRoom)}
                  />
                  War Room
                </div>
              </div>

              {(currentCollegePlayersAccess || draftEligiblePlayersAccess) && (
                <div className={classes.apiCheckboxesRow}>
                  <div className={clsx(classes.checkboxWrapper, classes.apiCheckbox)}>
                    <Checkbox
                      className={classes.checkbox}
                      checked={historicCollegePlayersAccess}
                      onChange={() => {
                        setHistoricCollegePlayersAccess(!historicCollegePlayersAccess)
                        setHistoricCollegePlayersStartYear(MIN_HISTORIC_COLLEGE_ACCESS_YEAR);
                      }}
                    />
                    Historic College Players
                  </div>

                  {historicCollegePlayersAccess && (
                    <Input
                      className={clsx(classes.input, classes.startYearInput)}
                      type='number'
                      min={MIN_HISTORIC_COLLEGE_ACCESS_YEAR}
                      label='Grad. Year Start'
                      value={String(historicCollegePlayersStartYear)}
                      error={historicCollegePlayersStartYearError}
                      onChange={(value:string) => setHistoricCollegePlayersStartYear(parseInt(value, 10))}
                    />
                  )}
                </div>
              )}
              
              {(selectedAccessLevel.type === AccessLevelType.NFL || selectedAccessLevel.type === AccessLevelType.ADMIN) && (
                <div className={classes.apiCheckboxesRow}>
                  <div className={clsx(classes.checkboxWrapper, classes.apiCheckbox)}>
                    <Checkbox
                      className={classes.checkbox}
                      checked={nflPlayersAccess}
                      onChange={() => {
                        if (nflPlayersAccess) {
                          setNFLPlayersAccess(false);
                          setNflStartYear(MIN_NFL_ACCESS_YEAR);
                        } else {
                          setNflStartYear(
                            typeof nflDraftYear === 'number'
                              ? Math.max(nflDraftYear - 9, MIN_NFL_ACCESS_YEAR)
                              : MIN_NFL_ACCESS_YEAR
                          );
                          setNFLPlayersAccess(true);
                        }
                      }}
                    />
                    NFL Players
                  </div>

                  {nflPlayersAccess && (
                    <Input
                      className={clsx(classes.input, classes.startYearInput)}
                      type='number'
                      min={MIN_NFL_ACCESS_YEAR}
                      label='Draft Year Start'
                      value={String(nflStartYear)}
                      error={nflStartYearError}
                      onChange={(value:string) => setNflStartYear(parseInt(value, 10))}
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}

        <Input
          className={clsx(classes.input, classes.invoice)}
          label='Invoice #'
          value={String(invoice || '')}
          onChange={(value:string) => setInvoice(parseInt(value, 10))}
        />

        {selectedAccessLevel && (
          <div className={clsx(
            classes.checkboxWrapper,
            selectedAccessLevel.transferPortal !== TransferPortalType.YES_IF_ACTIVATED && classes.disabledCheckboxWrapper,
          )}>
            <Checkbox
              className={classes.checkbox}
              checked={hasTransferPortal}
              disabled={selectedAccessLevel.transferPortal !== TransferPortalType.YES_IF_ACTIVATED}
              onChange={() => setHasTransferPortal(!hasTransferPortal)}
            />
            Transfer Portal
          </div>
        )}

        {selectedAccessLevel && depthChartAccessEnable && (
          <div className={clsx(
            classes.checkboxWrapper,
          )}>
            <Checkbox
              className={classes.checkbox}
              checked={hasAutoDepthChart}
              onChange={() => setHasAutoDepthChart(!hasAutoDepthChart)}
            />
            Auto Depth Chart
          </div>
        )}

        {selectedAccessLevel && (
          <div className={clsx(
            classes.checkboxWrapper,
            selectedAccessLevel.draftProjector !== DraftProjectorType.YES_IF_ACTIVATED && classes.disabledCheckboxWrapper,
          )}>
            <Checkbox
              className={classes.checkbox}
              checked={hasDraftProjector}
              disabled={selectedAccessLevel.draftProjector !== DraftProjectorType.YES_IF_ACTIVATED}
              onChange={() => setHasDraftProjector(!hasDraftProjector)}
            />
            Draft Projector
          </div>
        )}

        <div className={classes.checkboxWrapper}>
          <Checkbox
            className={classes.checkbox}
            checked={isEmailDisabled}
            onChange={() => setIsEmailDisabled(!isEmailDisabled)}
          />
          Unsubscribe From Emails (Entire Staff)
        </div>

        <div className={classes.actions}>
          <Button
            className={classes.action}
            primary
            disabled={
              loading
              || !selectedAccessLevelId
              || (!subscriptionStartDate && !subscriptionStartsNow)
              || (!expirationDate && !noExpirationDate)
              || !!nflStartYearError
            }
            onClick={saveProgram}
          >
            {!isEditMode && <PlusIcon className={classes.plusIcon} />}
            {isEditMode ? 'Update Program' : 'Add Program'}
          </Button>

          <Button
            className={classes.action}
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </div>

        <Toast
          visible={toastVisible}
          type={toastType}
          onHide={() => setToastVisible(false)}
        >
          {toastMessage}
        </Toast>
      </div>

      <CreateCompanyModal
        open={showCreateCompanyModal}
        onClose={() => setShowCreateCompanyModal(false)}
        onCreate={onCreateCompany}
        showToast={showToast}
      />
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    collegeTeams: state.teams.collegeTeams,
    nflTeams: state.teams.nflTeams,
    nflDraftYear: state.configurations.nflDraftYear,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
      fetchNFLTeams,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditProgramForm);
//
