import College from '../types/College';
import HighSchool from '../types/HighSchool';
import NFLTeam from '../types/NFLTeam';
import Player from '../types/Player';
import DashboardPlayer from '../types/DashboardPlayer';
import ComparePlayer from '../types/ComparePlayer';
import QuickSearchPlayer from '../types/QuickSearchPlayer';
import HighSchoolCombine from '../types/HighSchoolCombine';
import { PlayerColumn } from '../types/PlayerColumn';
import { Order } from '../types/Order';
import Link, { LinkType } from '../types/Link';

export enum PlayerType {
  HIGH_SCHOOL = 'highSchool',
  COLLEGE = 'college',
  NFL = 'nfl'
}

const COMBINE_EVENTS_ORDER = {
  [PlayerColumn.COMBINE_ARM]: Order.asc,
  [PlayerColumn.COMBINE_HAND]: Order.asc,
  [PlayerColumn.COMBINE_40M_DASH]: Order.asc,
  [PlayerColumn.COMBINE_10_SPLIT]: Order.asc,
  [PlayerColumn.COMBINE_MPH]: Order.asc,
  [PlayerColumn.COMBINE_SHUTTLE]: Order.asc,
  [PlayerColumn.COMBINE_3_CONE]: Order.asc,
  [PlayerColumn.COMBINE_VERTICAL_JUMP]: Order.desc,
  [PlayerColumn.COMBINE_BROAD_JUMP]: Order.desc,
  [PlayerColumn.COMBINE_POWER_TOSS]: Order.desc,
  [PlayerColumn.COMBINE_WINGSPAN]: Order.asc,
};

export function getPrimaryHighSchool (player?:Player|DashboardPlayer|ComparePlayer|QuickSearchPlayer):HighSchool|undefined {
  return (player?.highSchools || []).find((highSchool:HighSchool) => !!highSchool?.isPrimary);
}

export function getPrimaryCollege (player:Player|ComparePlayer|QuickSearchPlayer|DashboardPlayer):College|undefined {
  return (player.playerColleges || []).find((college:College) => !!college?.isPrimary);
}

export function getPrimaryNFLTeam (player:Player|ComparePlayer|QuickSearchPlayer):NFLTeam|undefined {
  return (player.playerNFLTeams || []).find((nflTeam:NFLTeam) => !!nflTeam?.isPrimary);
}

export function getPrimaryPosition (player:Player|ComparePlayer, playerType:PlayerType) {
  switch (playerType) {
    case PlayerType.HIGH_SCHOOL: {
      const primaryHS = getPrimaryHighSchool(player);
      const primaryPosition = ((primaryHS || {}).positions || [])
        .find((position:any) => position && position.isPrimary);

      return primaryPosition ? primaryPosition.code : '';
    }
    case PlayerType.COLLEGE: {
      const primaryCollege = getPrimaryCollege(player);
      const primaryPosition = ((primaryCollege || {}).positions || [])
        .find((position:any) => position && position.isPrimary);

      return primaryPosition ? primaryPosition.code : '';
    }
    case PlayerType.NFL: {
      const primaryNFLTeam = getPrimaryNFLTeam(player);
      const primaryPosition = ((primaryNFLTeam || {}).positions || [])
        .find((position:any) => position && position.isPrimary);

      return primaryPosition ? primaryPosition.code : '';
    }
    default:
      break;
  }
}

export function sortDraftRound (value1:string | number, value2:string | number, order:string):number {
  let result = 0;
  value1 = typeof value1 === 'string' ? -1 : value1;
  value2 = typeof value2 === 'string' ? -1 : value2;

  if (value1 === -1)
    result = 1;
  else if (value2 === -1)
    result = -1;
  else if (value1 === 0)
    result = order === Order.asc ? 1 : -1;
  else if (value2 === 0)
    result = order === Order.asc ? -1 : 1;
  else result = order === Order.asc ? (value1 - value2) : (value2 - value1);

  return result;
}

export function sortPlayersByLastName (player1:Player, player2:Player):number {
  const name1 = `${player1.lastName} ${player1.firstName}`;
  const name2 = `${player2.lastName} ${player2.firstName}`;

  if (name1 > name2) return 1;
  if (name1 < name2) return -1;
  return 0;
}

export function isValidDOB (formattedDate:string):boolean {
  if (!formattedDate) return false;

  const FIRST_VALID_YEAR = 1900;

  const dobParts = formattedDate.split('/');

  if (dobParts.length !== 3) return false;

  const dobYear = Number(dobParts[dobParts.length - 1]);

  return dobYear > FIRST_VALID_YEAR;
}

export function getBestCombineEventForColumn (column:PlayerColumn, hsCombines:HighSchoolCombine[]):number {
  const eventValuesInASCOrder = (hsCombines || [])
    // @ts-ignore
    .map((hsCombine:HighSchoolCombine) => hsCombine[column])
    .filter(Boolean)
    .sort();

  // @ts-ignore
  const eventOrder:Order = COMBINE_EVENTS_ORDER[column] || Order.asc;

  return eventOrder === Order.asc
    ? eventValuesInASCOrder[0] || 0
    : eventValuesInASCOrder[eventValuesInASCOrder.length - 1] || 0
}

export function addSpaceBetweenNumAndAlpha (str: string): string {
  const multipleEvents = str.split('/');
  if (multipleEvents.length > 1) {
    let firstEvent = multipleEvents[0];
    let secondEvent = multipleEvents[1];
    firstEvent = eventsToDisplayDiff.includes(firstEvent)
      ? firstEvent.replace(/(\d)([A-Za-z])/g, "$1 $2")
      : firstEvent
    secondEvent = eventsToDisplayDiff.includes(secondEvent)
      ? secondEvent.replace(/(\d)([A-Za-z])/g, "$1 $2")
      : secondEvent;
    return `${firstEvent}/${secondEvent}`
  }
  if (eventsToDisplayDiff.includes(str)) return str.replace(/(\d)([A-Za-z])/g, "$1 $2");
  return str;
};

export const eventsToDisplayDiff = ['10Split', '20Split'];

export function objectWithMaxCombine(combines: Array<any>, fieldName: string)
{
  return combines.reduce((max, current): any => {
    return current[fieldName] > max[fieldName] ? current : max;
  }, combines[0])
}

export function getMostRecentDataByProperty(combines: any[], propertyName: string): any {
  if (!combines.length) {
    return null;
  }
  const sortedData = combines.sort((a, b) => new Date(b.combineDate).getTime() - new Date(a.combineDate).getTime());
  for (let i=0; i<= sortedData.length - 1; i++) {
    const item = sortedData[i];
    if (item[propertyName] && item.hasOwnProperty(propertyName)) {
      return item[propertyName];
    }
  }

  return null;
}

export function capitalizeFirstLetter(str: string): string {
  // Check if the input string is not empty
  if (str && typeof str === 'string') {
    // Uppercase the first letter and concatenate the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    // Return the input unchanged if it's not a valid string
    return str;
  }
}

export function formatDate(date: Date | string | null): string {
  // Check if the input string is not empty
  if (!date) {
    return '';
  }
  const dateformat = new Date(date); // You can use any JavaScript Date object here
  if (!dateformat) {
    return date.toString();
  }
  
  const formattedDate = dateformat.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
  return formattedDate;
}

export function prepareLinks(player: Player | undefined): Link[] {
  if (!player) {
    return [];
  }
  const links: Link[] = [];
  if (player.linkMilesplit) {
    links.push({
      key: LinkType.milesplit,
      value: player.linkMilesplit,
    });
  }
  if (player.linkMaxpreps) {
    links.push({
      key: LinkType.maxpreps,
      value: player.linkMaxpreps,
    });
  }
  if (player.link247) {
    links.push({
      key: LinkType.twoFourSeven,
      value: player.link247,
    });
  }
  if (player.linkRivals) {
    links.push({
      key: LinkType.rivals,
      value: player.linkRivals,
    });
  }
  if (player.linkAthleticNet) {
    links.push({
      key: LinkType.athletic,
      value: player.linkAthleticNet,
    });
  }
  if (player.linkPff) {
    links.push({
      key: LinkType.pff,
      value: player.linkPff,
    });
  }
  if (player.linkOn3) {
    links.push({
      key: LinkType.on3,
      value: player.linkOn3,
    });
  }
  if (player.linkSSA) {
    links.push({
      key: LinkType.ssa,
      value: player.linkSSA,
    });
  }
  if (player.linkInstagram) {
    links.push({
      key: LinkType.instagram,
      value: player.linkInstagram,
    });
  }
  if (player.linkDirectAthletics) {
    links.push({
      key: LinkType.directAthletics,
      value: player.linkDirectAthletics,
    });
  }
  if (player.linkTwitter) {
    links.push({
      key: LinkType.twitter,
      value: player.linkTwitter,
    });
  }
  if (player.linkHudl) {
    links.push({
      key: LinkType.hudl,
      value: player.linkHudl,
    });
  }
  if (player.linkTrackingFootball) {
    links.push({
      key: LinkType.trackingFootball,
      value: player.linkTrackingFootball,
    });
  }
  return links;
}
