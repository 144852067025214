import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import get from 'lodash/get';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Score, { SCORE_TYPE } from '../atoms/Score';
import Checkbox from '../atoms/Checkbox';
import TableHeadCell from '../atoms/TableHeadCell';
import NotAvailable from '../atoms/NotAvailable';
import Input from '../atoms/Input';
import EditButton from '../atoms/EditButton';
import CancelButton from '../atoms/CancelButton';
import DoneButton from '../atoms/DoneButton';
import Loader from '../atoms/Loader';
import Tooltip from '../atoms/Tooltip';
import Download from '../atoms/Download';
import ColorCode from '../atoms/ColorCode';
import DragNDropDots from '../atoms/DragNDrop';
import SelectPlayer from '../molecules/SelectPlayer';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_DARK_GRAY,
  COLOR_GREEN,
  COLOR_LIGHT_GRAY, COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import DepthChartPosition from '../types/DepthChartPosition';
import DepthChartPlayer from '../types/DepthChartPlayer';
import Player from '../types/Player';
import { Color } from '../types/Color';
import Avatar from '../atoms/Avatar';
import MultiSportList from '../molecules/MultiSportList';
import SpeedRecruitIcon from '../icons/SpeedRecruitIcon';
import PowerRecruitIcon from '../icons/PowerRecruitIcon';

interface DepthChartProps {
  className?: string;
  depthChartPositions: DepthChartPosition[];
  selectedPlayers: DepthChartPlayer[];
  setSelectedPlayers: (players:DepthChartPlayer[]) => void;
  editedPlayers: DepthChartPlayer[];
  setEditedPlayers: (players:DepthChartPlayer[]) => void;
  teamPlayers: Player[];
  fetchPlayerDetails: (playerId:number) => Promise<Player | void>;
  createDepthChartPlayerFromPlayer: (depthChartPlayer:DepthChartPlayer) => (player:Player | void) => Promise<DepthChartPlayer>;
  printed?: boolean;
  statsBeingCalculated?: boolean;
  onUpdatePositions: (positions:DepthChartPosition[]) => void;
  onUpdatePlayers: (players:DepthChartPlayer[]) => void;
  onUpdateLabel: (position:DepthChartPosition, label:string) => void;
  downloadIndividualPlayerPDF: (slug:string) => () => void;
}

const DEPTH_CHART_COLUMN = {
  NUMBER: 'number',
  NAME: 'name',
  HEIGHT: 'height',
  WEIGHT: 'weight',
  CLASS: 'class',
  PDF: 'pdf',
  PAI_SCORE: 'pai',
  PPI_SCORE: 'ppi',
  COMBINE_SCORE: 'combine',
  PAI_PERCENTILE: 'pai-percentile',
  COMBINE_PERCENTILE: 'combine-percentile',
  PPI_PERCENTILE: 'ppi-percentile',
  NOTES: 'notes',
  MULTI_SPORT: 'multiSport',
};

const DEFAULT_COLUMNS = [
  DEPTH_CHART_COLUMN.NUMBER,
  DEPTH_CHART_COLUMN.NAME,
  DEPTH_CHART_COLUMN.MULTI_SPORT,
  DEPTH_CHART_COLUMN.HEIGHT,
  DEPTH_CHART_COLUMN.WEIGHT,
  DEPTH_CHART_COLUMN.CLASS,
  DEPTH_CHART_COLUMN.PDF,
  DEPTH_CHART_COLUMN.PAI_SCORE,
  DEPTH_CHART_COLUMN.PAI_PERCENTILE,
  DEPTH_CHART_COLUMN.COMBINE_SCORE,
  DEPTH_CHART_COLUMN.COMBINE_PERCENTILE,
  DEPTH_CHART_COLUMN.PPI_SCORE,
  DEPTH_CHART_COLUMN.PPI_PERCENTILE,
  DEPTH_CHART_COLUMN.NOTES,
];

const DEPTH_CHART_COLUMN_TITLE = {
  [DEPTH_CHART_COLUMN.NUMBER]: 'No.',
  [DEPTH_CHART_COLUMN.NAME]: 'Name',
  [DEPTH_CHART_COLUMN.HEIGHT]: 'HT',
  [DEPTH_CHART_COLUMN.MULTI_SPORT]: 'Multi Sport',
  [DEPTH_CHART_COLUMN.WEIGHT]: 'WT',
  [DEPTH_CHART_COLUMN.CLASS]: 'Class',
  [DEPTH_CHART_COLUMN.PDF]: '',
  [DEPTH_CHART_COLUMN.PAI_SCORE]: 'PAI',
  [DEPTH_CHART_COLUMN.COMBINE_SCORE]: 'Com.',
  [DEPTH_CHART_COLUMN.PPI_SCORE]: 'PPI',
  [DEPTH_CHART_COLUMN.PAI_PERCENTILE]: <>Div&#8209;1</>,
  // [DEPTH_CHART_COLUMN.COMBINE_PERCENTILE]: <>Pos.&nbsp;%</>,
  [DEPTH_CHART_COLUMN.COMBINE_PERCENTILE]: <>Div&#8209;1</>,
  [DEPTH_CHART_COLUMN.PPI_PERCENTILE]: <>Div&#8209;1</>,
  [DEPTH_CHART_COLUMN.NOTES]: 'Notes',
};

const useStyles = makeStyles((theme: Theme) => ({
  depthChartWrap: {
    overflow: 'auto',
  },

  depthChart: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },
  isDragging: {
    '& $tableRow': {
      background: `${COLOR_WHITE} !important`,
    },
  },

  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: 'auto',
    },
  },

  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n - 1)': {
      '& $playerLoaderSpinner': {
        marginTop: theme.spacing(0.5),
      },
    },
    '&:nth-of-type(2n)': {
      '& $playerLoaderSpinner': {
        marginTop: theme.spacing(-0.5),
      },
    },

    '&:nth-of-type(4n - 1)': {
      background: COLOR_WHITE,
    },
    '&:nth-of-type(4n)': {
      background: COLOR_WHITE,
    },

    '&:hover': {
      '& $dragNDropDots': {
        opacity: 1,
      },
    },
  },
  editedTableRow: {
    '& $dragNDropDots': {
      display: 'none',
    },
  },
  tableRowDragging: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  tableCell: {
    padding: theme.spacing(0, 2.5),
    fontSize: theme.typography.pxToRem(14),
    border: 0,
  },
  edited: {},

  positionCell: {
    width: '8%',
    minWidth: '110px',
    paddingLeft: '20px',
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1,
    fontWeight: 300,

    '&$edited': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
  },

  checkBoxCell: {
    position: 'relative',
    width: '50px',
    minWidth: 0,
    height: '50px',
    padding: '5px 10px',
  },

  dndHeadCell: {
    width: '16px',
    padding: 0,
  },
  dndCell: {
    width: '16px',
    padding: 0,
  },
  dragNDropDots: {
    opacity: 0,
    transition: 'opacity 0.2s',
  },

  jerseyNumberHeadCell: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    textAlign: 'center',
    borderLeft: 0,
  },
  jerseyNumberCell: {
    width: '25px',
    padding: theme.spacing(0),

    '&$edited': {
      width: '55px',
      paddingLeft: '2px',
      paddingRight: '22px',
    },
  },

  nameCell: {
    position: 'relative',
    minWidth: '180px',
    '&$edited': {
      padding: theme.spacing(1, 0.5),
    },
  },

  playerNameWithLocationIcon: {
    display: 'flex',
    alignItems: 'center',
  },

  playerAvatar: {
    flexShrink: 0,
    width: '32px',
    height: '32px',
  },

  playerLink: {
    display: 'flex',
    alignItems: 'center',
    color: COLOR_BLUE,
    fontSize: theme.typography.pxToRem(16),
    paddingLeft: '8px',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    }
  },

  duplicatePlayer: {
    marginRight: theme.spacing(1),
  },

  playerLoader: {
    background: 'none',
  },
  playerLoaderSpinner : {
    width: '24px !important',
    height: '24px !important',
  },

  selectPlayer: {
    paddingRight: theme.spacing(4),
  },

  heightHeadCell: {
    paddingRight: theme.spacing(1),

    '&:after': {
      content: '""',
      width: '1px',
      height: '12px',
      position: 'absolute',
      top: '13px',
      right: 0,
      background: COLOR_DARK_GRAY,
    },
  },
  heightCell: {
    width: '25px',
    paddingLeft: '20px',
    paddingRight: theme.spacing(1.2),

    '&$edited': {
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(0.5),
    },
  },

  weightHeadCell: {
    paddingLeft: theme.spacing(1.2),
    paddingRight: theme.spacing(1.2),
    borderLeft: 0,

    '&:after': {
      content: '""',
      width: '1px',
      height: '12px',
      position: 'absolute',
      top: '13px',
      right: 0,
      background: COLOR_DARK_GRAY,
    },
  },
  weightCell: {
    width: '25px',
    paddingLeft: theme.spacing(1.2),
    paddingRight: theme.spacing(1.2),

    '&$edited': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.25),
    },
  },

  classHeadCell: {
    paddingLeft: theme.spacing(1.2),
    paddingRight: theme.spacing(0),
    borderLeft: 0,
  },
  classCell: {
    width: '25px',
    paddingLeft: theme.spacing(1.2),
    paddingRight: 0,

    '&$edited': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },

  pdfHeadCell: {
    width: '35px',
    paddingLeft: theme.spacing(0),
    paddingRight: 0,
    borderLeft: 0,
  },
  pdfCell: {
    padding: theme.spacing(1, 0.5, 1,0),
  },
  pdfReport: {
    marginLeft: 0,
    color: '#b0b1b4',
  },

  playerScoreHeadCell: {
    width: '24px',
    padding: theme.spacing(0),
    textAlign: 'center',
  },
  playerScoreCell: {
    textAlign: 'center',
    padding: theme.spacing(0.75),
  },

  playerScore: {
    width: '32px',
    height: '32px',
    fontSize: theme.typography.pxToRem(14),
  },

  paiPercentileHeadCell: {
    width: '34px',
    textAlign: 'center',
    padding: theme.spacing(0.75),
    borderLeft: 0,
  },
  paiPercentileCell: {
    padding: theme.spacing(0),
    textAlign: 'center',
    fontWeight: 600,
    color: COLOR_ORANGE,
  },

  combinePercentileHeadCell: {
    width: '46px',
    textAlign: 'center',
    padding: theme.spacing(0.75),
    borderLeft: 0,
  },
  combinePercentileCell: {
    padding: theme.spacing(0),
    textAlign: 'center',
    fontWeight: 600,
    color: COLOR_GREEN,
  },
  ppiPercentileHeadCell: {
    width: '14px',
    textAlign: 'center',
    padding: theme.spacing(0.75),
    borderLeft: 0,
  },
  ppiPercentileCell: {
    padding: theme.spacing(0),
    textAlign: 'center',
    fontWeight: 600,
    color: COLOR_BLUE,
  },
  multiSportHeadCell: {
    width: '44px',
    padding: 0,
    textAlign: 'center',
  },
  multiSportCell: {
    paddingRight: 0,
  },
  notesHeadCell: {
    paddingRight: 0,
  },
  notesCell: {
    paddingRight: 0,

    '&$edited': {
      paddingLeft: theme.spacing(1.25),
    },
  },

  editHeadCell: {
    borderLeft: 0,
  },
  editCell: {
    padding: theme.spacing(1, 2, 1, 0),
  },
  editButton: {
    width: '32px',
    height: '32px',

    '&:hover': {
      color: COLOR_BLUE,
    },
  },
  editButtonIcon: {
    width: '32px',
    height: '32px',
  },

  actions: {
    height: '32px',
    minWidth: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  action: {
    marginLeft: theme.spacing(0.5),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  input: {
    width: '100%',
    padding: 0,
  },
  inputField: {
    padding: theme.spacing(.5),
  },
  positionInputField: {
    width: '40px',
  },
  classInputField: {
    paddingLeft: theme.spacing(.5),
    paddingRight: theme.spacing(.5),
  },

  hidden: {
    display: 'none',
  },

  [MEDIA.DESKTOP]: {
    tableCell: {
      fontSize: theme.typography.pxToRem(16),
    },

    positionCell: {
      fontSize: theme.typography.pxToRem(18),
    },
  },

  [MEDIA.PRINT]: {
    pdfReport: {
      visibility: 'hidden',
    },
  },
}), { name: DepthChart.name });

export default function DepthChart (props: DepthChartProps) {
  const {
    className,
    printed = false,
    depthChartPositions = [],
    selectedPlayers = [],
    setSelectedPlayers = () => {},
    editedPlayers = [],
    teamPlayers = [],
    statsBeingCalculated = false,
    fetchPlayerDetails,
    createDepthChartPlayerFromPlayer,
    setEditedPlayers = () => {},
    onUpdatePositions = () => {},
    onUpdatePlayers = () => {},
    onUpdateLabel = () => {},
    downloadIndividualPlayerPDF = () => () => {},
  } = props;
  const classes = useStyles();

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [loadingDepthChartPlayerDetailsId, setLoadingDepthChartPlayerDetailsId] = useState<number>(0);
  const [originalEditedPlayers, setOriginalEditedPlayers] = useState<DepthChartPlayer[]>([]);

  function onCheckBoxChange (checkedPlayer:DepthChartPlayer) {
    return (value:boolean) => {
      if (value) {
        setSelectedPlayers([...selectedPlayers, checkedPlayer]);
      } else {
        const selectedItemsWithoutCheckedPlayer = [...selectedPlayers];
        const removeIndex = selectedPlayers.findIndex(player => player.id === checkedPlayer.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedPlayer.splice(removeIndex, 1);
        }

        setSelectedPlayers(selectedItemsWithoutCheckedPlayer);
      }
    };
  }

  function onEdit (editedPlayer:DepthChartPlayer) {
    return () => {
      const positionWithEditedPlayer:any = depthChartPositions
        .find((position:DepthChartPosition) => position.players.some(player => player.id === editedPlayer.id));

      if (positionWithEditedPlayer && positionWithEditedPlayer.players && !!positionWithEditedPlayer.players.length) {
        setEditedPlayers([...editedPlayers, ...positionWithEditedPlayer.players]);
        setOriginalEditedPlayers([...editedPlayers, ...positionWithEditedPlayer.players]);
      }
    };
  }

  function onCancelEdit (editedPlayer:DepthChartPlayer) {
    return () => {
      const positionWithEditedPlayer:any = depthChartPositions
        .find((position:DepthChartPosition) => position.players.some(player => player.id === editedPlayer.id));

      if (!positionWithEditedPlayer || !positionWithEditedPlayer.players || !positionWithEditedPlayer.players.length) {
        return;
      }

      const playerId1 = get(positionWithEditedPlayer, 'players[0].id');
      const playerId2 = get(positionWithEditedPlayer, 'players[1].id');
      const originalPlayer1 = typeof playerId1 === 'number'
        ? originalEditedPlayers.find(player => player.id === playerId1)
        : undefined;
      const originalPlayer2 = typeof playerId2 === 'number'
        ? originalEditedPlayers.find(player => player.id === playerId2)
        : undefined;

      const originalEditedPlayersWithoutCancelledPlayers = [...originalEditedPlayers];

      if (originalPlayer1) {
        const originalRemoveIndex1 = originalEditedPlayers.findIndex(player => player.id === originalPlayer1.id);
        if (originalRemoveIndex1 > -1) {
          originalEditedPlayersWithoutCancelledPlayers.splice(originalRemoveIndex1, 1);
        }
      }

      if (originalPlayer2) {
        const originalRemoveIndex2 = originalEditedPlayersWithoutCancelledPlayers.findIndex(player => player.id === originalPlayer2.id);
        if (originalRemoveIndex2 > -1) {
          originalEditedPlayersWithoutCancelledPlayers.splice(originalRemoveIndex2, 1);
        }
      }

      onUpdatePlayers([originalPlayer1, originalPlayer2].filter(Boolean) as DepthChartPlayer[]);

      setOriginalEditedPlayers(originalEditedPlayersWithoutCancelledPlayers);
      stopEditing(editedPlayer);
    };
  }

  function onAcceptEdit (editedPlayer:DepthChartPlayer) {
    return () => stopEditing(editedPlayer);
  }

  function stopEditing (editedPlayer:DepthChartPlayer) {
    const positionWithEditedPlayer:any = depthChartPositions
      .find((position:DepthChartPosition) => position.players.some(player => player.id === editedPlayer.id));
    const originalPlayer1 = positionWithEditedPlayer.players[0];
    const originalPlayer2 = positionWithEditedPlayer.players[1];

    const editedPlayersWithoutCancelledPlayers = [...editedPlayers];

    const removeIndex1 = editedPlayers.findIndex(player => player.id === originalPlayer1.id);
    if (removeIndex1 > -1) {
      editedPlayersWithoutCancelledPlayers.splice(removeIndex1, 1);
    }
    const removeIndex2 = editedPlayersWithoutCancelledPlayers.findIndex(player => player.id === originalPlayer2.id);
    if (removeIndex2 > -1) {
      editedPlayersWithoutCancelledPlayers.splice(removeIndex2, 1);
    }

    setEditedPlayers(editedPlayersWithoutCancelledPlayers);
  }

  function onInputChange (fieldName:string, editedPlayer:DepthChartPlayer) {
    return (value:string) => {
      const updatedPlayer:any = {...editedPlayer};
      updatedPlayer[fieldName] = value;

      onUpdatePlayers([updatedPlayer as DepthChartPlayer]);
    };
  }

  function onLabelChange (depthChartPosition:DepthChartPosition) {
    return (value:string) => {
      onUpdateLabel(depthChartPosition, value);
    };
  }

  function onSelectPlayer (editedDepthChartPlayer:DepthChartPlayer) {
    return (selectedPlayer:Player) => {
      setLoadingDepthChartPlayerDetailsId(editedDepthChartPlayer.id);

      fetchPlayerDetails(selectedPlayer.id)
        .then(createDepthChartPlayerFromPlayer(editedDepthChartPlayer))
        .then((newDepthChartPlayer:DepthChartPlayer) => {
          if (newDepthChartPlayer) {
            onUpdatePlayers([newDepthChartPlayer]);
          }
        })
        .finally(() => setLoadingDepthChartPlayerDetailsId(0))
    };
  }

  function onUpdateCustomName (editedPlayer:DepthChartPlayer) {
    return (customName:string) => {
      const updatedPlayer:any = {...editedPlayer};
      updatedPlayer.playerName = customName;

      onUpdatePlayers([updatedPlayer as DepthChartPlayer]);
    };
  }

  function onDragStart () {
    setIsDragging(true);
  }

  function onDragEnd (result:any) {
    setIsDragging(false);

    if (!result.destination) return;

    const reorderedPositions = [...depthChartPositions] as any[];

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;

    const fromPositionIndex = Math.floor(fromIndex / 2);
    const toPositionIndex = Math.floor(toIndex / 2);

    const fromPositionPlayerIndex = fromIndex % 2;
    const toPositionPlayerIndex = toIndex % 2;

    const fromPlayer = (reorderedPositions[fromPositionIndex] as DepthChartPosition).players[fromPositionPlayerIndex];
    const toPlayer = (reorderedPositions[toPositionIndex] as DepthChartPosition).players[toPositionPlayerIndex];

    (reorderedPositions[fromPositionIndex] as DepthChartPosition).players[fromPositionPlayerIndex] = {
      ...toPlayer,
      id: fromPlayer.id,
    } as DepthChartPlayer;
    (reorderedPositions[toPositionIndex] as DepthChartPosition).players[toPositionPlayerIndex] = {
      ...fromPlayer,
      id: toPlayer.id,
    } as DepthChartPlayer;

    onUpdatePositions(reorderedPositions);
  }

  function renderRow (index:number, depthChartPosition:DepthChartPosition, player:DepthChartPlayer) {
    const label = depthChartPosition.label;
    const checked = (!!player && !!player.id)
      && !!selectedPlayers.find((oneOfSelected:DepthChartPlayer) => oneOfSelected.id === player.id);
    const edited = (!!player && !!player.id) && !!editedPlayers.find((oneOfEdited:DepthChartPlayer) => oneOfEdited.id === player.id);
    const even = (index + 1) % 2 === 1;
    const draggableItemId = `${player && player.id ? player.id : ''}_${index}`;

    return (
      <Draggable
        key={index}
        draggableId={draggableItemId}
        index={index}
      >
        {({ draggableProps, dragHandleProps, innerRef }, snapshot) => (
          <TableRow
            className={clsx(
              classes.tableRow,
              edited && classes.editedTableRow,
              snapshot.isDragging && classes.tableRowDragging
            )}
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
          >
            {(even || isDragging) && (
              <TableCell
                key='position'
                className={clsx(classes.tableCell, classes.positionCell, edited && classes.edited)}
                rowSpan={isDragging ? 1 : 2}
              >
                {edited
                ? <Input
                    className={classes.input}
                    inputFieldClassName={clsx(classes.inputField, classes.positionInputField)}
                    fontSize={18}
                    value={label}
                    autoFocus
                    onChange={onLabelChange(depthChartPosition)}
                  />
                : label
                }
              </TableCell>
            )}

            <TableCell
              key='check-box-cell'
              className={clsx(
                classes.tableCell,
                classes.checkBoxCell,
                printed && classes.hidden,
              )}
            >
              {!!player && (
                <Checkbox
                  checked={checked}
                  onChange={onCheckBoxChange(player)}
                />
              )}
            </TableCell>

            <TableCell
              className={clsx(
                classes.tableCell,
                classes.dndCell,
                printed && classes.hidden,
              )}>
              <DragNDropDots className={classes.dragNDropDots} />
            </TableCell>

            {tableColumns.map((tableColumn:any) => (
              <TableCell
                key={tableColumn.value}
                className={clsx(
                  classes.tableCell,
                  tableColumn.className,
                  edited && classes.edited
                )}
                style={{padding: tableColumn.value == "multiSport" ? '0px' : ''}}
              >
                {tableColumn.renderContent(player)}
              </TableCell>
            ))}

            {((even || isDragging) && !printed) && (
              <TableCell
                key='edit-cell'
                className={clsx(classes.tableCell, classes.editCell)}
                rowSpan={isDragging ? 1 : 2}
              >
                <div className={classes.actions}>
                  {!edited && (
                    <Tooltip title='Edit Row'>
                      <div className={classes.action}>
                        <EditButton
                          className={classes.editButton}
                          iconClassName={classes.editButtonIcon}
                          onClick={onEdit(player)}
                          disabled={statsBeingCalculated}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {edited && (
                    <>
                      <Tooltip title='Cancel'>
                        <div className={classes.action}>
                          <CancelButton onClick={onCancelEdit(player)} />
                        </div>
                      </Tooltip>

                      <Tooltip title='Save'>
                        <div className={classes.action}>
                          <DoneButton onClick={onAcceptEdit(player)} />
                        </div>
                      </Tooltip>
                    </>
                  )}
                </div>
              </TableCell>
            )}
          </TableRow>
        )}
      </Draggable>
    );
  }

  const tableColumns = (printed ? DEFAULT_COLUMNS.filter(col => col != DEPTH_CHART_COLUMN.NOTES ) : DEFAULT_COLUMNS).map((column:string) => {
    switch (column) {
      case DEPTH_CHART_COLUMN.NUMBER:
        return {
          value: DEPTH_CHART_COLUMN.NUMBER,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.NUMBER],
          headClassName: classes.jerseyNumberHeadCell,
          className: classes.jerseyNumberCell,
          renderContent: (player:DepthChartPlayer) => {
            const edited = player?.id && !!editedPlayers.find(oneOfEdited => oneOfEdited.id === player.id);

            return edited
              ? <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  fontSize={16}
                  value={player?.jerseyNumber || ''}
                  autoFocus
                  onChange={onInputChange('jerseyNumber', player)}
                />
              : player?.jerseyNumber || '';
          },
        };
      case DEPTH_CHART_COLUMN.NAME:
        return {
          value: DEPTH_CHART_COLUMN.NAME,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.NAME],
          className: classes.nameCell,
          renderContent: (player:DepthChartPlayer) => {
            const edited = player?.id && !!editedPlayers.find(oneOfEdited => oneOfEdited.id === player.id);
            const playerIsLoading = player?.id === loadingDepthChartPlayerDetailsId;

            if (player && player.id) {
              if (playerIsLoading) {
                return (
                  <Loader
                    className={classes.playerLoader}
                    spinnerClassName={classes.playerLoaderSpinner}
                    inProgress
                  />
                );
              }

              if (edited) {
                return (
                  <>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{width: '100%'}}>
                      <SelectPlayer
                        className={classes.selectPlayer}
                        players={teamPlayers}
                        selectedPlayer={player?.player || null}
                        onSelect={onSelectPlayer(player)}
                        customName={player?.playerName || ''}
                        onUpdateCustomName={onUpdateCustomName(player)}
                      />
                    </div>
                    {
                      player?.player?.isSpeedRecruit ?
                      <Tooltip title="Speed Tag">
                        <SpeedRecruitIcon />
                      </Tooltip>
                      : null
                    }
                    {
                      player?.player?.isPowerRecruit ?
                      <Tooltip title="Power Tag">
                        <PowerRecruitIcon className="green"/>
                      </Tooltip>
                      : null
                    }

                  </div>
                  </>
                );
              } else if (player?.player?.slug) {
                return (
                  <div className={classes.playerNameWithLocationIcon}>
                    <Avatar
                      className={classes.playerAvatar}
                      src={player?.player.photoUrl}
                      alt='Profile picture'
                    />
                  <Link
                    className={classes.playerLink}
                    to={`/player/${player.player.slug}?`}
                    target='_blank'
                  >
                    {player.duplicated && (
                      <ColorCode
                        className={classes.duplicatePlayer}
                        color={Color.YELLOW}
                        tooltipText='Duplicated Player'
                      />
                    )}

                    {player.playerName ||
                      (player?.player?.firstName ? `${player.player.firstName} ${player.player?.lastName}` : '')
                    }
                  </Link>
                  {
                    player?.player?.isSpeedRecruit ?
                      <Tooltip title="Speed Tag">
                        <SpeedRecruitIcon />
                      </Tooltip>
                    : null
                  }
                  {
                    player?.player?.isPowerRecruit ?
                      <Tooltip title="Power Tag">
                        <PowerRecruitIcon className="green"/>
                      </Tooltip>
                    : null
                  }
                  </div>
                );
              }

              return null;
            }
          },
        };
      case DEPTH_CHART_COLUMN.MULTI_SPORT:
        return {
          value: DEPTH_CHART_COLUMN.MULTI_SPORT,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.MULTI_SPORT],
          headClassName: classes.multiSportHeadCell,
          className: classes.multiSportCell,
          renderContent: (player:DepthChartPlayer) => {
              const playerDepth = player?.player;
              let playerMulti = (playerDepth?.multiSport) || [];
              if (playerMulti.length > 3) {
                let splicedSport = JSON.stringify(playerMulti);
                const splicedSportFinal = JSON.parse(splicedSport).splice(0, 3);
                playerMulti = splicedSportFinal;
              }
              return playerMulti?.length > 0 ?
                (<MultiSportList
                  className={classes.multiSport}
                  iconClassName={classes.multiSportIcon}
                  list={playerMulti}
                />)
                : null
          },
        };
      case DEPTH_CHART_COLUMN.HEIGHT:
        return {
          value: DEPTH_CHART_COLUMN.HEIGHT,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.HEIGHT],
          headClassName: classes.heightHeadCell,
          className: classes.heightCell,
          renderContent: (player:DepthChartPlayer) => {
            const edited = (player && player.id)
              && !!editedPlayers.find(oneOfEdited => oneOfEdited.id === player.id);

            return edited
              ? (
                <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  fontSize={16}
                  value={(player && player.height) ? String(player.height) : ''}
                  autoFocus
                  onChange={onInputChange('height', player)}
                />
              )
              : player?.height || '';
          },
        };
      case DEPTH_CHART_COLUMN.WEIGHT:
        return {
          value: DEPTH_CHART_COLUMN.WEIGHT,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.WEIGHT],
          headClassName: classes.weightHeadCell,
          className: classes.weightCell,
          renderContent: (player:DepthChartPlayer) => {
            const edited = player?.id
              && !!editedPlayers.find(oneOfEdited => oneOfEdited.id === player.id);

            return edited
              ? (
                <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  fontSize={16}
                  value={player?.weight ? String(player.weight) : ''}
                  autoFocus
                  onChange={onInputChange('weight', player)}
                />
              )
              : player?.weight || ''
          },
        };
      case DEPTH_CHART_COLUMN.CLASS:
        return {
          value: DEPTH_CHART_COLUMN.CLASS,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.CLASS],
          headClassName: classes.classHeadCell,
          className: classes.classCell,
          renderContent: (player:DepthChartPlayer) => {
            const edited = player?.id
              && !!editedPlayers.find(oneOfEdited => oneOfEdited.id === player.id);

            return edited
              ? (
                <Input
                  className={classes.input}
                  inputFieldClassName={clsx(classes.inputField, classes.classInputField)}
                  fontSize={16}
                  value={player?.class ? String(player.class) : ''}
                  autoFocus
                  onChange={onInputChange('class', player)}
                />
              )
              : player?.class || ''
          },
        };
      case DEPTH_CHART_COLUMN.PDF:
        return {
          value: DEPTH_CHART_COLUMN.PDF,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.PDF],
          headClassName: classes.pdfHeadCell,
          className: classes.pdfCell,
          renderContent: (player:DepthChartPlayer) => {
            return (player?.player?.slug) && (
              <Download
                className={classes.pdfReport}
                label=''
                onClick={downloadIndividualPlayerPDF(player.player.slug)}
              />
            );
          },
        };
      case DEPTH_CHART_COLUMN.PAI_SCORE:
        return {
          value: DEPTH_CHART_COLUMN.PAI_SCORE,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.PAI_SCORE],
          headClassName: classes.playerScoreHeadCell,
          className: classes.playerScoreCell,
          renderContent: (player:DepthChartPlayer) => {
            if (!player || !player.player) return null;

            const pai = (player?.player?.pai) || [];
            return (
              <Score
                className={classes.playerScore}
                type={SCORE_TYPE.PAI}
                scoreList={pai}
              />
            );
          },
        };
      case DEPTH_CHART_COLUMN.PPI_SCORE:
      return {
        value: DEPTH_CHART_COLUMN.PPI_SCORE,
        title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.PPI_SCORE],
        headClassName: classes.playerScoreHeadCell,
        className: classes.playerScoreCell,
        renderContent: (player:DepthChartPlayer) => {
          if (!player || !player.player) return null;

          const ppi = (player?.player?.ppi);
          return (
            <Score
              className={classes.playerScore}
              type={SCORE_TYPE.PPI}
              value={ppi || undefined}
            />
          );
        },
      };
      case DEPTH_CHART_COLUMN.PPI_PERCENTILE:
        return {
          value: DEPTH_CHART_COLUMN.PPI_PERCENTILE,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.PPI_PERCENTILE],
          headClassName: classes.ppiPercentileHeadCell,
          className: classes.ppiPercentileCell,
          renderContent: (player:DepthChartPlayer) => {
            return !!player?.player
              ? (player.ppiPercentile ? `${player.ppiPercentile}%` : '')
              : null;
          },
        };
      case DEPTH_CHART_COLUMN.COMBINE_SCORE:
        return {
          value: DEPTH_CHART_COLUMN.COMBINE_SCORE,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.COMBINE_SCORE],
          headClassName: classes.playerScoreHeadCell,
          className: classes.playerScoreCell,
          renderContent: (player:DepthChartPlayer) => {
            if (!player || !player.player) return null;

            const combine = player?.player?.combine || 0;
            const hsCombines = player?.player?.hsCombines || [];

            return (
              <Score
                className={classes.playerScore}
                type={SCORE_TYPE.COMBINE}
                scoreList={combine}
                combines={hsCombines}
              />
            );
          },
        };
      case DEPTH_CHART_COLUMN.PAI_PERCENTILE:
        return {
          value: DEPTH_CHART_COLUMN.PAI_PERCENTILE,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.PAI_PERCENTILE],
          headClassName: classes.paiPercentileHeadCell,
          className: classes.paiPercentileCell,
          renderContent: (player:DepthChartPlayer) => {
            return !!player?.player
              ? (player.paiPercentile ? `${player.paiPercentile}%` : '')
              : null;
          },
        };
      case DEPTH_CHART_COLUMN.COMBINE_PERCENTILE:
        return {
          value: DEPTH_CHART_COLUMN.COMBINE_PERCENTILE,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.COMBINE_PERCENTILE],
          headClassName: classes.combinePercentileHeadCell,
          className: classes.combinePercentileCell,
          renderContent: (player:DepthChartPlayer) => {
            return !!player?.player
              ? (player.combinePercentile ? `${player.combinePercentile}%` : '')
              : null;
          },
        };
      case DEPTH_CHART_COLUMN.NOTES:
        return {
          value: DEPTH_CHART_COLUMN.NOTES,
          title: DEPTH_CHART_COLUMN_TITLE[DEPTH_CHART_COLUMN.NOTES],
          headClassName: classes.notesHeadCell,
          className: classes.notesCell,
          renderContent: (player:DepthChartPlayer) => {
            const edited = player?.id
              && !!editedPlayers.find(oneOfEdited => oneOfEdited.id === player.id);

            return edited
              ? (
                <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  fontSize={16}
                  value={(player && player.note) || ''}
                  autoFocus
                  onChange={onInputChange('note', player)}
                />
              )
              : player?.note || '';
          },
        };
      default:
        return null;
    }
  });

  return (
    <DragDropContext
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId='depth-chart'>
        {({ droppableProps, placeholder, innerRef }) => (
          <div className={classes.depthChartWrap}>
            <Table
              className={clsx(
                classes.depthChart,
                isDragging && classes.isDragging,
                className,
              )}
              ref={innerRef}
              {...droppableProps}
            >
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableHeadCell key='position'>Pos.</TableHeadCell>

                  <TableHeadCell
                    key='check-box-cell'
                    className={clsx(
                      classes.checkBoxCell,
                      printed && classes.hidden,
                    )}
                  >
                    <Checkbox
                      checked={selectedPlayers.length === (depthChartPositions.length * 2)}
                      onChange={() => (selectedPlayers.length === (depthChartPositions.length * 2))
                        ? setSelectedPlayers([])
                        : setSelectedPlayers(depthChartPositions
                          .map(position => position && position.players)
                          .flat() as DepthChartPlayer[]
                        )
                      }
                    />
                  </TableHeadCell>

                  <TableHeadCell
                    key='dnd-dots'
                    className={clsx(
                      classes.dndHeadCell,
                      printed && classes.hidden,
                    )}
                  >&nbsp;</TableHeadCell>

                  {tableColumns.map((tableColumn:any) => (
                    <TableHeadCell
                      key={tableColumn.value}
                      className={tableColumn.headClassName}
                    >
                      {tableColumn.title}
                    </TableHeadCell>
                  ))}

                  <TableHeadCell
                    key='edit-cell'
                    className={clsx(
                      classes.editHeadCell,
                      printed && classes.hidden,
                    )}
                  >{''}</TableHeadCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {depthChartPositions
                  .map((depthChartPosition:DepthChartPosition, index:number) => [
                    renderRow(index + index, depthChartPosition, depthChartPosition.players[0]),
                    renderRow(index + index + 1, depthChartPosition, depthChartPosition.players[1]),
                  ])
                  .flat()
                }
              </TableBody>
            </Table>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
