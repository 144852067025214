import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SpeedIcon from '../icons/SpeedIcon';
import PowerIcon from '../icons/PowerIcon';
import PowerRecruitIcon from '../icons/PowerRecruitIcon';
import SpeedRecruitIcon from '../icons/SpeedRecruitIcon';
import EnduranceIcon from '../icons/EnduranceIcon';
import ListItem from '../atoms/ListItem';
import NamedBox from '../atoms/NamedBox';
import ProgressLine from '../atoms/ProgressLine';
import Tooltip from '../atoms/Tooltip';
import TrackFieldRating from '../types/TrackFieldRating';
import EventRating from '../types/EventRating';
import Player from '../types/Player';
import Grid from '@material-ui/core/Grid';
import { addSpaceBetweenNumAndAlpha } from '../services/player';

interface PlayerTrackAndFieldRatingProps {
  className?: string;
  trackFieldRating?: TrackFieldRating;
  isNFL?: boolean;
  player?: Player;
}

export const RECRUIT_TOOLTIP = {
  POWER: 'Player exceeds 75th percentile Division I position performance rating in throwing or jumping field event.',
  SPEED: 'Player exceeds 75th percentile Division I position performance in track sprint or sprint relay event.',
};

const BETTER_THAN_TOOLTIP_TEXT = 'Better than D-I position';

const useStyles = makeStyles((theme: Theme) => ({
  playerTrackAndFieldRating: {},

  progressLineWrapper: {
    width: '100%',
  },
  progressLine: {
    maxWidth: 350,
    paddingRight: 0,
    marginLeft: 'auto',
    cursor: 'help',
  },
  icon: {
    height: '25px',
    marginRight: theme.spacing(1),
  },
}), { name: PlayerTrackAndFieldRating.name });

export default function PlayerTrackAndFieldRating (props: PlayerTrackAndFieldRatingProps) {
  const {
    className,
    trackFieldRating,
    isNFL,
    player
  } = props;
  const classes = useStyles();
  
  if (!trackFieldRating) return null;

  function isSpeedRecruitEnable ():boolean {
    if (player && player?.aTierCalculated >= 4) {
      return true;
    }
    return false;
  }

  function isPowerRecruitEnable ():boolean {
    if (player && player?.bTierCalculated >= 4) {
      return true;
    }
    return false;
  }

  function getTheBestEvent (events:EventRating[]):EventRating {
    const sortedEvents = (events && events.length)
      ? events.sort((firstEvent:EventRating, secondEvent:EventRating) => {
          if (firstEvent.percentile > secondEvent.percentile) return -1;
          if (firstEvent.percentile < secondEvent.percentile) return 1;
          return 0;
        })
      : [];

    // combine 2 event names if their results are the same (edge case)
    if (sortedEvents.length > 1 && sortedEvents[1].percentile === sortedEvents[0].percentile) {
      return {
        name: `${sortedEvents[0].name}/${sortedEvents[1].name}`,
        percentile: sortedEvents[0].percentile,
      } as EventRating;
    }

    return sortedEvents[0];
  }

  const bestSpeedEvent = getTheBestEvent(trackFieldRating.speed);
  const bestPowerEvent = getTheBestEvent(trackFieldRating.power);
  const bestEnduranceEvent = getTheBestEvent(trackFieldRating.endurance);
  const noEvents = !bestPowerEvent && !bestSpeedEvent && !bestEnduranceEvent;

  if (noEvents) return null;

  return (
    <NamedBox
      className={clsx(classes.playerTrackAndFieldRating, className)}
      name={isNFL
        ? 'High School Track & Field Rating'
        : 'Track & Field Rating'
      }
    >
      {bestSpeedEvent && (
        <ListItem
          icon={SpeedIcon}
          title='Speed:'
        >
          <Grid container>
            <Grid item lg={1} xs={2}>
            {
            isSpeedRecruitEnable() && (
              <Tooltip title={RECRUIT_TOOLTIP.SPEED}>
                <SpeedRecruitIcon />
              </Tooltip>
              )
            }
            </Grid>
            <Grid item lg={3} xs={5}>
            <b>{ addSpaceBetweenNumAndAlpha(bestSpeedEvent.name)}</b>
            </Grid>
            <Grid item lg xs>
            <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
              <div className={classes.progressLineWrapper}>
                <ProgressLine
                  className={classes.progressLine}
                  value={bestSpeedEvent.percentile}
                />
              </div>
            </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
      )}

      {bestPowerEvent && (
        <ListItem
          icon={PowerIcon}
          title='Power:'
        >
          <Grid container>
            <Grid item lg={1} xs={2}>
              {
                isPowerRecruitEnable() && (
                  <Tooltip title={RECRUIT_TOOLTIP.POWER}>
                    <PowerRecruitIcon />
                  </Tooltip>
                )
              }
            </Grid>
            <Grid item lg={3} xs={5}>
            <b>{ addSpaceBetweenNumAndAlpha(bestPowerEvent.name)}</b>
            </Grid>
            <Grid item lg xs>
              <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
                <div className={classes.progressLineWrapper}>
                  <ProgressLine
                    className={classes.progressLine}
                    value={bestPowerEvent.percentile}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
      )}

      {bestEnduranceEvent && (
        <ListItem
          icon={EnduranceIcon}
          title='Endurance:'
        >
          <Grid container>
            <Grid item lg={1} xs={2}>
            </Grid>
            <Grid item lg={3} xs={5}>
              <b>{bestEnduranceEvent.name}</b>
            </Grid>
            <Grid item lg xs>
              <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
              <div className={classes.progressLineWrapper}>
                <ProgressLine
                  className={classes.progressLine}
                  value={bestEnduranceEvent.percentile}
                />
              </div>
            </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </NamedBox>
  );
}
