import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Sport } from '../types/Sport';
import SportIcon from '../atoms/SportIcon';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_DARK_GRAY, COLOR_WHITE } from '../styles/colors';

interface MultiSportChartProps {
  className?: string;
  sports: Sport[];
}

const DEFAULT_SPORT_LABEL = 'Football Only';

const useStyles = makeStyles((theme: Theme) => ({
  multiSportChart: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
    boxSizing: 'border-box',
  },
  chart: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sportWrapper: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  sportIcon: {
    width: '48px',
    height: '48px',
  },
  sportName: {
    marginTop: theme.spacing(2),
    padding: '7px 16px',
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '1em',
    color: COLOR_WHITE,
    borderRadius: '12px',
    background: COLOR_DARK_GRAY,
  },
}), { name: MultiSportChart.name });

export default function MultiSportChart (props: MultiSportChartProps) {
  const {
    className,
    sports = [],
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.multiSportChart, className)}>
      <div className={classes.chart}>
        {!!sports.length && sports.map((sport:Sport, index:number) => {
          const sportName =  sport[0].toUpperCase() + sport.replace('_', '').slice(1).toLowerCase();

          return (
            <div
              key={index}
              className={classes.sportWrapper}
            >
              <SportIcon
                className={classes.sportIcon}
                sport={sport}
              />
              <div className={classes.sportName}>{sportName}</div>
            </div>
          );
        })}

        {!sports.length && (
          <div
            className={classes.sportWrapper}
          >
            <SportIcon
              className={classes.sportIcon}
              sport={Sport.Rugby}
            />
            <div className={classes.sportName}>{DEFAULT_SPORT_LABEL}</div>
          </div>
        )}
      </div>
    </div>
  );
}
