import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isArray } from 'lodash';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ComparePlayersIcon from '../icons/CompareIcon';
import FilterIcon from '../icons/FilterIcon';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import Checkbox from '../atoms/Checkbox';
import Loader from '../atoms/Loader';
import DropDown from '../atoms/DropDown';
import Download from '../atoms/Download';
import Accordion from '../atoms/Accordion';
import Slider from '../atoms/Slider';
import Toast, { ToastType } from '../atoms/Toast';
import { getScoreValue } from '../atoms/Score';
import AddRemoveColumns from '../organisms/AddRemoveColumns';
import QuickSearchAutoComplete from '../molecules/QuickSearchAutoComplete';
import MultiSelect, { MultiSelectItem } from '../molecules/MultiSelect';
import ComparePlayersTable, {
  CompareResultType,
  CompareTableColumn,
  MAX_COMPARE_PLAYERS_NUMBER,
  TABLE_ALL_COLUMNS,
  TABLE_COLUMN_TITLE,
} from '../organisms/ComparePlayersTable';
import PageContainer from './PageContainer';
import PdfPageContainer from './PdfPageContainer';
import gql from '../services/gql';
import exportXLSReport, { ERROR_MESSAGE_CHECK_EMAIL, XLSExportType } from '../services/export-xls-report';
import exportPDFReport, { PDFExportPage } from '../services/export-pdf-report';
import { getMaxHighSchoolClassYear } from '../services/user';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { State } from '../redux/reducers';
import { fetchDivITeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import { fetchStates } from '../redux/dispatchers/states';
import { fetchUserSettings, saveComparePlayersColumnsAndType } from '../redux/dispatchers/user-settings';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_BACKGROUND_LIGHT, COLOR_BORDER, COLOR_LIGHT_GRAY, COLOR_SHADOW, COLOR_TEXT } from '../styles/colors';
import MEDIA from '../styles/media';
import ComparePlayer from '../types/ComparePlayer';
import StateType from '../types/State';
import User from '../types/User';
import Team from '../types/Team';
import Conference from '../types/Conference';
import QuickSearchPlayer from '../types/QuickSearchPlayer';
import Player from '../types/Player';
import HighSchool from '../types/HighSchool';
import Column from '../types/Column';
import Action from '../atoms/Action';
import PinIcon from '../icons/PinIcon';
import SaveIcon from '../icons/SaveIcon';
import AlertIcon from '../icons/AlertIcon';

interface ComparePlayerPageTemplateProps {
  currentRecruitingYear: number;
  nflDraftYear: number;
  dashboardRolloverDay?: number;
  dashboardRolloverMonth?: number;
  rolloverDay?: number;
  rolloverMonth?: number;
  user?: User;
  isNFL?: boolean;
  printed?: boolean;
  divITeams: Team[];
  divITeamsLoading: boolean;
  nflTeams: Team[];
  nflTeamsLoading: boolean;
  states: StateType[];
  statesLoading: boolean;
  fetchStates: () => void;
  fetchUserSettings: () => void;
  userSettingsLoaded: boolean;
  savedColumns: Column[] | null;
  comparePlayersResultType: CompareResultType | null;
  saveComparePlayersColumnsAndType: (comparePlayersColumns:Column[], comparePlayersResultType?:CompareResultType) => void;
  fetchDivITeams: () => void;
  fetchNFLTeams: () => void;
}

export interface ResultTypeItem {
  value: string;
  content: string;
}

const COMPARISON_ALGORITHM_FACTORS = {
  CURRENT_PLAYERS: 'currentPlayers',
  PLAYER_TYPE: 'playerType',
  POSITION: 'position',
  BODY_TYPE: 'bodyType',
  STAR_RATING: 'starRating',
  PAI: 'pai',
  COMBINE: 'combine',
  MULTI_SPORT: 'multiSport',
  ARCHIVES: 'archives',
  OFFERED_BY: 'offeredBy'
};

enum TransferStatus {
  Active = 'active',
  Withdrawn = 'withdrawn',
  Matriculated = 'matriculated',
}
const TRANSFER_STATUSES = [
  TransferStatus.Active,
  TransferStatus.Withdrawn,
  TransferStatus.Matriculated,
];

const FILTER_DRAWER_CONTENT = {
  [CompareResultType.HIGH_SCHOOL]: {
    TITLE: 'Filter High School Players',
    COMPARISON_ALGORITHM: [
      {
        textToDisplay: 'Position',
        value: COMPARISON_ALGORITHM_FACTORS.POSITION,
        disabled: true,
      },
      {
        textToDisplay: 'Body Type',
        value: COMPARISON_ALGORITHM_FACTORS.BODY_TYPE,
        disabled: true,
      },
      {
        textToDisplay: 'Star Rating',
        value: COMPARISON_ALGORITHM_FACTORS.STAR_RATING,
        disabled: false,
      },
      {
        textToDisplay: 'PAI',
        value: COMPARISON_ALGORITHM_FACTORS.PAI,
        disabled: false,
      },
      {
        textToDisplay: 'Combine',
        value: COMPARISON_ALGORITHM_FACTORS.COMBINE,
        disabled: false,
      },
      {
        textToDisplay: 'Multi-sport',
        value: COMPARISON_ALGORITHM_FACTORS.MULTI_SPORT,
        disabled: false,
      },
    ],
    PLAYER_TYPE: null,
    TRANSFER_PORTAL: false,
    TRANSFER_YEARS: false,
    OFFERED_BY: [
      {
        textToDisplay: 'FBS',
        value: 'FBS'
      },
      {
        textToDisplay: 'FCS',
        value: 'FCS'
      },
      {
        textToDisplay: 'Power 5',
        value: 'P5'
      },
      {
        textToDisplay: 'Group 5',
        value: 'G5'
      },
    ],
  },
  [CompareResultType.COLLEGE]: {
    TITLE: 'Filter College Players',
    COMPARISON_ALGORITHM: [
      {
        textToDisplay: 'Position',
        value: COMPARISON_ALGORITHM_FACTORS.POSITION,
        disabled: true,
      },
      {
        textToDisplay: 'Body Type',
        value: COMPARISON_ALGORITHM_FACTORS.BODY_TYPE,
        disabled: true,
      },
      {
        textToDisplay: 'Star Rating',
        value: COMPARISON_ALGORITHM_FACTORS.STAR_RATING,
        disabled: false,
      },
      {
        textToDisplay: 'PAI',
        value: COMPARISON_ALGORITHM_FACTORS.PAI,
        disabled: false,
      },
      {
        textToDisplay: 'Combine',
        value: COMPARISON_ALGORITHM_FACTORS.COMBINE,
        disabled: false,
      },
      {
        textToDisplay: 'Multi-sport',
        value: COMPARISON_ALGORITHM_FACTORS.MULTI_SPORT,
        disabled: false,
      },
    ],
    PLAYER_TYPE: [
      {
        textToDisplay: 'FBS',
        value: 'FBS'
      },
      {
        textToDisplay: 'FCS',
        value: 'FCS'
      },
      {
        textToDisplay: 'Power 5',
        value: 'P5'
      },
      {
        textToDisplay: 'Group 5',
        value: 'G5'
      },
      {
        textToDisplay: 'Non-Division I teams',
        value: 'Non-Div-I'
      },
      {
        textToDisplay: 'All-Conference',
        value: 'All-Conference'
      },
      {
        textToDisplay: 'All-American',
        value: 'All-American'
      },
    ],
    TRANSFER_PORTAL: true,
    TRANSFER_YEARS: true,
    OFFERED_BY: null,
  },
  [CompareResultType.NFL]: {
    TITLE: 'Filter NFL Players',
    COMPARISON_ALGORITHM: [
      {
        textToDisplay: 'Position',
        value: COMPARISON_ALGORITHM_FACTORS.POSITION,
        disabled: true,
      },
      {
        textToDisplay: 'Body Type',
        value: COMPARISON_ALGORITHM_FACTORS.BODY_TYPE,
        disabled: true,
      },
      {
        textToDisplay: 'Star Rating',
        value: COMPARISON_ALGORITHM_FACTORS.STAR_RATING,
        disabled: false,
      },
      {
        textToDisplay: 'PAI',
        value: COMPARISON_ALGORITHM_FACTORS.PAI,
        disabled: false,
      },
      {
        textToDisplay: 'Combine',
        value: COMPARISON_ALGORITHM_FACTORS.COMBINE,
        disabled: false,
      },
      {
        textToDisplay: 'Multi-sport',
        value: COMPARISON_ALGORITHM_FACTORS.MULTI_SPORT,
        disabled: false,
      },
    ],
    PLAYER_TYPE: [
      {
        textToDisplay: 'AFC Only',
        value: 'AFC'
      },
      {
        textToDisplay: 'NFC Only',
        value: 'NFC'
      },
      {
        textToDisplay: 'Pro Bowl',
        value: 'Pro-Bowl'
      },
      {
        textToDisplay: 'All-Pro',
        value: 'All-Pro'
      },
      {
        textToDisplay: 'UDFA Only',
        value: 'UDFA-Only'
      },
    ],
    TRANSFER_PORTAL: false,
    TRANSFER_YEARS: false,
    OFFERED_BY: null,
  },
}

const BE_COMPATIBLE_RESULT_TYPE = {
  [CompareResultType.HIGH_SCHOOL]: 'HighSchool',
  [CompareResultType.COLLEGE]: 'College',
  [CompareResultType.NFL]: 'NFL',
};

const TEAM_SELECT_LABEL = {
  [CompareResultType.HIGH_SCHOOL]: 'All Division I Teams',
  [CompareResultType.COLLEGE]: 'All Division I Teams',
  [CompareResultType.NFL]: 'All NFL Teams',
};

const DEFAULT_ALGO_FILTER = [
  COMPARISON_ALGORITHM_FACTORS.POSITION,
  COMPARISON_ALGORITHM_FACTORS.BODY_TYPE,
];

const RESULT_TYPE_DROPDOWN_ITEM = {
  [CompareResultType.HIGH_SCHOOL]: {
    value: CompareResultType.HIGH_SCHOOL,
    content: 'High School',
  },
  [CompareResultType.COLLEGE]: {
    value: CompareResultType.COLLEGE,
    content: 'College',
  },
  [CompareResultType.NFL]: {
    value: CompareResultType.NFL,
    content: 'NFL',
  },
}

const useStyles = makeStyles<Theme, ComparePlayerPageTemplateProps>(theme => ({
  comparePlayersPageTemplate: {
    minHeight: '100vh',
  },
  page: {
    padding: theme.spacing(5, 0),

    '&:first-of-type': {
      paddingTop: 0,
    },
  },

  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },
  import: {
    padding: theme.spacing(4),
  },

  header: {
    alignItems: 'center',
    color: COLOR_TEXT,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 4, 0),
  },
  headerTitle: {
    margin: theme.spacing(0,2,0,0),
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h2,
  },
  headerIcon: {
    width: (props) => props.printed ? '40px' : '32px',
    height: (props) => props.printed ? '40px' : '32px',
    marginRight: theme.spacing(1),
  },

  comparePlayers: {
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    padding: theme.spacing(4,5,5),
  },

  filterButton: {
    width: '118px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterIcon: {
    height: '32px',
    width: '32px',
    marginRight: theme.spacing(2)
  },

  quickSearchAutoComplete: {
    width: '100%',
    maxWidth: '400px',
    marginRight: theme.spacing(2)
  },
  input: {
    width: '100%',
    maxWidth: '300px',
    backgroundColor: COLOR_BORDER,
    border: 'none',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: theme.typography.pxToRem(16),
    fontFamily: FONT_PROXIMA_NOVA,
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(4.5),

    '&:focus': {
      border: 'none',
      outline: 'none',
    }
  },
  searchIcon: {
    height: '16px',
    width: '16px',
    color: COLOR_TEXT,
    position: 'absolute',
    left: '14px',
    marginRight: theme.spacing(1),
  },

  filterDrawer: {
    width: '90%',
    height: '100%',
    maxWidth: '440px',
    overflow: 'auto',
  },
  drawerHeader: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  drawerHeaderTitle: {
    margin: 0,
    ...theme.typography.h2,
  },
  drawerContent: {
    padding: theme.spacing(4, 0),
  },
  closeButton: {
    marginLeft: 'auto',
  },

  filterWrapper: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(0, 4),

    '&:last-of-type': {
      marginBottom: theme.spacing(0),
    },
  },
  filterContent: {
    padding: theme.spacing(4, 0),
  },
  paddingTop: {
    paddingTop: theme.spacing(4, 0),
  },
  filterTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  filterLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: '30px',
  },

  dropdown:{
    height: '50px',
    width: '100%',
  },

  algoFilters: {},
  checkboxWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },

  checkboxList: {
    marginLeft: theme.spacing(3),
  },

  transferPortalSection: {
    paddingTop: 0,
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  action: {
    minHeight: '50px',
    marginRight: theme.spacing(2),
  },
  anotherAction: {
    minHeight: '50px',
    marginRight: theme.spacing(2),
  },
  actionIcon: {
    width: '18px',
    height: '18px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  actionsRow: {
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderBottom: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
  },
  playerComparisonText: {
    color: COLOR_TEXT,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
  },
  addRemoveColumns: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    overflow: 'auto',
  },
  rowActions: {
    marginLeft: 'auto',
    display: 'flex',
  },
  headerDownloads: {
    marginLeft: 'auto',
    display: 'flex',
  },

  dropdownSelector: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
    fontSize: theme.typography.pxToRem(16),
  },

  teamSelect: {
    minWidth: '260px',

    '& input': {
      boxSizing: 'border-box',
      height: '70px',
    },
  },
  dropdownWrapper: {
    width: '258px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dropdownLabel: {
    width: 'auto',
    marginRight: theme.spacing(1),
  },
  searchTypeDropdown: {
    width: '140px',
  },

  accordion: {
    marginBottom: theme.spacing(1),
  },
  accordionSummary: {
    paddingLeft: theme.spacing(3.5),
    fontSize: theme.typography.pxToRem(14),
  },
  sliderWrapper: {
    margin: theme.spacing(0, 1),
  },
  slider: {
    padding: theme.spacing(3, 0),
  },

  pdfHeader: {
    height: '200px',
    backgroundColor: COLOR_BACKGROUND_LIGHT,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 8),
  },
  pdfHeaderTitle: {
    fontSize: theme.typography.pxToRem(25),
  },

  logo: {
    width: '125px',
    marginLeft: 'auto',
  },

  tableWrapper: {
    padding: theme.spacing(5),
  },

  titleRow: {
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderBottom: 'none',
    padding: theme.spacing(1.5),
  },

  '@media (max-width:1023px)': {
    actionsRow: {
      flexWrap: 'wrap',
    },

    playerComparisonText: {
      width: '100%',
    },

    addRemoveColumns: {
      marginLeft: 0,
      paddingLeft: 0,
    },
  },

  [MEDIA.MOBILE]: {
    header: {
      flexWrap: 'wrap',
      margin: theme.spacing(2,2,0),
    },

    headerTitle: {
      width: '100%',
      margin: theme.spacing(0,0,2,0),
    },

    comparePlayers: {
      padding: theme.spacing(2),
    },

    actionsRow: {
      padding: theme.spacing(2),
    },

    rowActions: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginLeft: 0,
    },

    dropdownWrapper: {
      width: 'auto',
      justifyContent: 'flex-start',
    },
    dropdownLabel: {
      fontSize: theme.typography.pxToRem(14),
    },

    quickSearchAutoComplete: {
      maxWidth: 'none',
      margin: theme.spacing(0,0,2,0),
    },
  },

  [MEDIA.PRINT]: {
    page: {
      pageBreakAfter: 'always',
      pageBreakInside: 'avoid',
      size: 'letter',
      margin: 0,
      printColorAdjust: 'exact',
      filter: 'opacity(1)',

      '&:last-of-type': {
        pageBreakAfter: 'avoid',
      },
    },
  },
}), { name: ComparePlayersPageTemplate.name });

function ComparePlayersPageTemplate (props:ComparePlayerPageTemplateProps) {
  const {
    currentRecruitingYear,
    nflDraftYear,
    dashboardRolloverDay,
    dashboardRolloverMonth,
    rolloverDay,
    rolloverMonth,
    user,
    isNFL,
    printed = false,
    divITeams,
    divITeamsLoading,
    nflTeams,
    nflTeamsLoading,
    states,
    statesLoading,
    fetchStates,
    fetchUserSettings,
    userSettingsLoaded = false,
    savedColumns,
    comparePlayersResultType,
    saveComparePlayersColumnsAndType,
    fetchDivITeams,
    fetchNFLTeams
  } = props;
  const classes = useStyles(props);

  const history = useHistory();
  const { slug } = useParams<any>();

  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;
  const isPastDashboardRolloverDate = dashboardRolloverMonth && dashboardRolloverDay && (currentMonth > dashboardRolloverMonth || (currentMonth === dashboardRolloverMonth && currentDay >= dashboardRolloverDay));
  const isPastRecruitingYearRolloverDate = rolloverMonth && rolloverDay && (currentMonth > rolloverMonth || (currentMonth === rolloverMonth && currentDay >= rolloverDay));

  const HS_YEAR_RANGE = {
    MIN: (isPastDashboardRolloverDate && !isPastRecruitingYearRolloverDate)
      ? currentRecruitingYear + 1
      : currentRecruitingYear,
    MAX: getMaxHighSchoolClassYear(user, currentRecruitingYear),
  };

  const COLLEGE_YEAR_RANGE = {
    MIN: (currentRecruitingYear - 10), //2010//its static do we want to make it dynamic? which is 10 years less than currentRecruitingYear
    MAX: currentRecruitingYear,
  };

  const NFL_YEAR_RANGE = {
    MIN: (nflDraftYear - 20), //2010,
    MAX: nflDraftYear,
  };

  const twoDigitCurrentRecruitingYear:number = Number(currentRecruitingYear.toString().slice(-2));
  const TRANSFER_YEARS = [
    `${twoDigitCurrentRecruitingYear-1}-${twoDigitCurrentRecruitingYear}`,
    `${twoDigitCurrentRecruitingYear-2}-${twoDigitCurrentRecruitingYear-1}`,
    `${twoDigitCurrentRecruitingYear-3}-${twoDigitCurrentRecruitingYear-2}`,
    `${twoDigitCurrentRecruitingYear-4}-${twoDigitCurrentRecruitingYear-3}`,
  ];

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);

  const [comparePlayers, setComparePlayers] = useState<ComparePlayer[]>([]);
  const [compareSourcePlayer, setCompareSourcePlayer] = useState<Player>();

  const [algoFilters, setAlgoFilters] = useState<string[]>(DEFAULT_ALGO_FILTER);
  const [playerTypeFilters, setPlayerTypeFilters] = useState<string[]>([]);
  const [hsClassYearRangeFilter, setHsClassYearRangeFilter] = useState<number | number[]>([HS_YEAR_RANGE.MIN, HS_YEAR_RANGE.MAX]);
  const [collegeTypeHSClassYearRangeFilter, setCollegeTypeHSClassYearRangeFilter] = useState<number | number[]>([COLLEGE_YEAR_RANGE.MIN, COLLEGE_YEAR_RANGE.MAX]);
  const [includeCollegeAllYear, setIncludeCollegeAllYear] = useState<boolean>(false);
  const [nflAllYear, setNFLAllYear] = useState<boolean>(false);
  const [nflDraftYearRangeFilter, setNFLDraftYearRangeFilter] = useState<number | number[]>([NFL_YEAR_RANGE.MIN, NFL_YEAR_RANGE.MAX]);

  const [transferStatusFilter, setTransferStatusFilter] = useState<boolean>(false);
  const [selectedTransferStatusFilters, setSelectedTransferStatusFilters] = useState<TransferStatus[]>([]);

  const [transferYearsFilter, setTransferYearsFilter] = useState<boolean>(false);
  const [selectedTransferYearsFilters, setSelectedTransferYearsFilters] = useState<string[]>([]);

  const [divIConferences, setDivIConferences] = useState<Conference[]>([]);

  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>([]);
  const [selectedConferencesIds, setSelectedConferencesIds] = useState<number[]>([]);

  const [resultTypeItems, setResultTypeItems] = useState<ResultTypeItem[]>([
    RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.HIGH_SCHOOL],
    RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.COLLEGE],
    RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.NFL]
  ]);
  const [searchAndResultType, setSearchAndResultType] = useState<CompareResultType|null>(comparePlayersResultType);
  const [columns, setColumns] = useState<Column[]>(TABLE_ALL_COLUMNS[CompareResultType.HIGH_SCHOOL].map((columnName:CompareTableColumn) => ({
    name: columnName,
    selected: true,
  })));

  const [showCollegeWeightHeight, setShowCollegeWeightHeight] = useState<boolean>(false);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');
  const [filtersUpdated, setFiltersUpdated] = useState<boolean>(false);
  const [activePlayersOnly, setActivePlayersOnly] = useState<boolean>(true);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);

  useEffect(() => {
    fetchUserSettings();
    fetchStates();
    fetchDivITeams();
    fetchNFLTeams();
    fetchDivIConferences();
  }, []);

  useEffect(() => {
    setHsClassYearRangeFilter([HS_YEAR_RANGE.MIN, HS_YEAR_RANGE.MAX]);
  }, [HS_YEAR_RANGE.MAX]);

  useEffect(() => {
    const defaultCol = TABLE_ALL_COLUMNS[comparePlayersResultType || CompareResultType.HIGH_SCHOOL].map((columnName:CompareTableColumn) => ({
      name: columnName,
      selected: true,
    }))
    setColumns(savedColumns?.length ? savedColumns : defaultCol);
  }, [savedColumns, comparePlayersResultType]);

  useEffect(() => {
    if (!searchAndResultType && user?.accessLevel && userSettingsLoaded) {
      const userAccessLevel = user.accessLevel;

      if (!userAccessLevel.canComparePlayers) {
        return history.push(`/dashboard?no-access=${history.location.pathname}`);
      }

      let newResultTypeItems = [...resultTypeItems];
      let selectedResultType = comparePlayersResultType || CompareResultType.HIGH_SCHOOL;

      if (!userAccessLevel.hsYearsMarch1.length) {
        newResultTypeItems = [
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.COLLEGE],
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.NFL],
        ];
        selectedResultType = comparePlayersResultType === CompareResultType.NFL
          ? CompareResultType.NFL
          : CompareResultType.COLLEGE;

        setShowCollegeWeightHeight(true);
      }

      if (!userAccessLevel.hasCollegeAccess) {
        newResultTypeItems = [
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.HIGH_SCHOOL],
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.NFL],
        ];
        selectedResultType = comparePlayersResultType === CompareResultType.NFL
          ? CompareResultType.NFL
          : CompareResultType.HIGH_SCHOOL;
      }

      if (!userAccessLevel.hasNFLAccess) {
        newResultTypeItems = [
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.COLLEGE],
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.NFL],
        ];
        selectedResultType = comparePlayersResultType === CompareResultType.NFL
          ? CompareResultType.NFL
          : CompareResultType.COLLEGE;
      }

      const searchAndResultTypeFromClientStorage = (clientStorage.get(StorageKey.COMPARE_TABLE) || {}).searchAndResultType;

      setResultTypeItems(newResultTypeItems);
      setSearchAndResultType(
        (searchAndResultTypeFromClientStorage && newResultTypeItems.map(item => item.value).includes(searchAndResultTypeFromClientStorage))
          ? searchAndResultTypeFromClientStorage
          : selectedResultType
      );
    }
  }, [user?.accessLevel, userSettingsLoaded]);

  useEffect(() => {
    if (slug) {
      fetchCompareSourcePlayer();
    }
  }, [slug]);

  useEffect(() => {
    if (filtersUpdated && slug && compareSourcePlayer?.slug === slug && searchAndResultType) {
      fetchComparePlayersList();
      setFiltersUpdated(false);
    }
    if (searchAndResultType === CompareResultType.COLLEGE) {
      setActivePlayersOnly(true);
    } else {
      setActivePlayersOnly(false);
    }
  }, [filtersUpdated, slug, compareSourcePlayer, searchAndResultType]);

  useEffect(() => {
    if (userSettingsLoaded && searchAndResultType && searchAndResultType !== comparePlayersResultType) {
      clientStorage.save(StorageKey.COMPARE_TABLE, { searchAndResultType });
      const columnsForSelectedResultType:Column[] = TABLE_ALL_COLUMNS[searchAndResultType].map((columnName:string) => ({
        name: columnName,
        selected: (columns.find((column:Column) => column.name === columnName) || { selected: true }).selected,
      } as Column));

      setColumns(columnsForSelectedResultType);
      saveComparePlayersColumnsAndType(columnsForSelectedResultType, searchAndResultType);
      resetFiltersAndReload({ resetComparisonFactors: false });
    }
  }, [userSettingsLoaded, searchAndResultType, comparePlayersResultType]);

  useEffect(() => {
    if (!searchAndResultType && user?.accessLevel && userSettingsLoaded) {
      const userAccessLevel = user.accessLevel;

      if (!userAccessLevel.canComparePlayers) {
        return history.push(`/dashboard?no-access=${history.location.pathname}`);
      }

      let newResultTypeItems = [...resultTypeItems];
      let selectedResultType = comparePlayersResultType || CompareResultType.HIGH_SCHOOL;

      if (!userAccessLevel.hsYearsMarch1.length) {
        newResultTypeItems = [
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.COLLEGE],
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.NFL],
        ];
        selectedResultType = comparePlayersResultType === CompareResultType.NFL
          ? CompareResultType.NFL
          : CompareResultType.COLLEGE;
      }

      if (!userAccessLevel.hasCollegeAccess) {
        newResultTypeItems = [
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.HIGH_SCHOOL],
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.NFL],
        ];
        selectedResultType = comparePlayersResultType === CompareResultType.NFL
          ? CompareResultType.NFL
          : CompareResultType.HIGH_SCHOOL;
      }

      if (!userAccessLevel.hasNFLAccess) {
        newResultTypeItems = [
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.COLLEGE],
          RESULT_TYPE_DROPDOWN_ITEM[CompareResultType.NFL],
        ];
        selectedResultType = comparePlayersResultType === CompareResultType.NFL
          ? CompareResultType.NFL
          : CompareResultType.COLLEGE;
      }

      const searchAndResultTypeFromClientStorage = (clientStorage.get(StorageKey.COMPARE_TABLE) || {}).searchAndResultType;

      setResultTypeItems(newResultTypeItems);
      setSearchAndResultType(
        (searchAndResultTypeFromClientStorage && newResultTypeItems.map(item => item.value).includes(searchAndResultTypeFromClientStorage))
          ? searchAndResultTypeFromClientStorage
          : selectedResultType
      );
    }
  }, [user?.accessLevel, userSettingsLoaded]);

  function fetchCompareSourcePlayer () {
    if (!pageLoading) setPageLoading(true);

    gql(`
      player(slug:"${slug}") {
        id
        slug
        firstName
        lastName
        highSchools
        hasHighSchool
        hasCollegeTeam
        hasNFLTeam
        compStar
        _247Star
        pai
        combine
      }
    `)
      .then((data:any) => data.player as Player)
      .then((sourcePlayer:Player) => {
        if (sourcePlayer) {
          setCompareSourcePlayer(sourcePlayer);
          setAndUpdateAlgoFilters(sourcePlayer);
          setFiltersUpdated(true);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => setPageLoading(false));
  }

  function fetchComparePlayersList () {
    if (!searchAndResultType) return;

    setPageLoading(true);

    const highSchoolGradYearRange = (searchAndResultType === CompareResultType.COLLEGE)
      ? collegeTypeHSClassYearRangeFilter
      : hsClassYearRangeFilter;

    gql(`
      comparePlayers(
        sourcePlayerSlug: "${slug}",
        resultType: "${BE_COMPATIBLE_RESULT_TYPE[searchAndResultType]}",
        includePai: ${algoFilters.includes(COMPARISON_ALGORITHM_FACTORS.PAI)},
        includeCombine: ${algoFilters.includes(COMPARISON_ALGORITHM_FACTORS.COMBINE)},
        includeStarRating: ${algoFilters.includes(COMPARISON_ALGORITHM_FACTORS.STAR_RATING)},
        includeMultiSport: ${algoFilters.includes(COMPARISON_ALGORITHM_FACTORS.MULTI_SPORT)},
        highSchoolGradYearFrom: ${isArray(highSchoolGradYearRange) ? highSchoolGradYearRange[0] : highSchoolGradYearRange},
        highSchoolGradYearTo: ${isArray(highSchoolGradYearRange) ? highSchoolGradYearRange[1] : highSchoolGradYearRange},
        includeHighSchoolArchive: ${algoFilters.includes(COMPARISON_ALGORITHM_FACTORS.ARCHIVES)},
        highSchoolStates: [${selectedStates.map((state:string) => `"${state}"`)}],
        includeAllYears: ${nflAllYear},
        nflDraftYearFrom: ${isArray(nflDraftYearRangeFilter) ? nflDraftYearRangeFilter[0] : nflDraftYearRangeFilter},
        nflDraftYearTo: ${isArray(nflDraftYearRangeFilter) ? nflDraftYearRangeFilter[1] : nflDraftYearRangeFilter},
        groups: [${playerTypeFilters.map((group:string) => `"${group}"`)}],
        transferStatus: [${selectedTransferStatusFilters.map((transferStatus:TransferStatus) => `"${transferStatus}"`)}],
        transferYears: [${selectedTransferYearsFilters.map((transferYear:string) => `"${transferYear}"`)}],
        conferenceIds: [${selectedConferencesIds}],
        teamIds: [${selectedTeamIds}],
        limit: ${MAX_COMPARE_PLAYERS_NUMBER},
        includeSourcePlayer: true,
        trackPageView: true,
        activeOnly: ${activePlayersOnly}
      ) {
        id
        slug
        firstName
        lastName
        photoUrl
        percentMatch
        _247Star
        compStar
        pai
        combine
        pai
        highSchools
        playerColleges {
          isPrimary
          weight
          height
          team {
            id
            shortName
            logo247
            logoESPN
            logoAlt
          }
        }
        playerNFLTeams {
          isPrimary
          team {
            id
            shortName
            logo247
            logoESPN
            logoAlt
          }
        }
        hsCombines {
          id
        }
      }
    `)
      .then((data:any) => data.comparePlayers as ComparePlayer[])
      .then((comparePlayers:ComparePlayer[]) => {
        processComparePlayersList(comparePlayers || []);
      })
      .catch(error => {
        processComparePlayersList([]);
        console.error(error);
      })
      .finally(() => setPageLoading(false));
  }

  function fetchDivIConferences () {
    gql(`
      divIConferences {
        id
        name
        iconUrl
      }
    `)
      .then((data:any) => data.divIConferences as Conference[])
      .then((conferences:Conference[]) => setDivIConferences(conferences))
      .catch(console.error);
  }

  function setAndUpdateAlgoFilters (sourcePlayer:Player) {
    const hasPAIData = sourcePlayer && typeof getScoreValue(sourcePlayer.pai) === 'number';
    const hasCombineData = sourcePlayer && typeof getScoreValue(sourcePlayer.combine) === 'number';
    const primaryHS:HighSchool = (sourcePlayer.highSchools || []).find((highSchool:HighSchool) => highSchool && highSchool.isPrimary) || {} as HighSchool;
    const hasMultiSportData = sourcePlayer && !!get(primaryHS, 'otherSports', []).length;
    const hasStarRatingData = typeof sourcePlayer?._247Star === 'number' || typeof sourcePlayer?.compStar === 'number';

    let updatedFilters:string[] = [...algoFilters];

    if (hasPAIData && !updatedFilters.includes(COMPARISON_ALGORITHM_FACTORS.PAI)) {
      updatedFilters.push(COMPARISON_ALGORITHM_FACTORS.PAI);
    } else if (!hasPAIData) {
      updatedFilters = updatedFilters.filter(filter => filter !== COMPARISON_ALGORITHM_FACTORS.PAI);
    }

    if (hasCombineData && !updatedFilters.includes(COMPARISON_ALGORITHM_FACTORS.COMBINE)) {
      updatedFilters.push(COMPARISON_ALGORITHM_FACTORS.COMBINE);
    } else if (!hasCombineData) {
      updatedFilters = updatedFilters.filter(filter => filter !== COMPARISON_ALGORITHM_FACTORS.COMBINE);
    }

    if (hasMultiSportData && !updatedFilters.includes(COMPARISON_ALGORITHM_FACTORS.MULTI_SPORT)) {
      updatedFilters.push(COMPARISON_ALGORITHM_FACTORS.MULTI_SPORT);
    } else if (!hasMultiSportData) {
      updatedFilters = updatedFilters.filter(filter => filter !== COMPARISON_ALGORITHM_FACTORS.MULTI_SPORT);
    }

    if (hasStarRatingData && !updatedFilters.includes(COMPARISON_ALGORITHM_FACTORS.STAR_RATING)) {
      updatedFilters.push(COMPARISON_ALGORITHM_FACTORS.STAR_RATING);
    } else if (!hasStarRatingData) {
      updatedFilters = updatedFilters.filter(filter => filter !== COMPARISON_ALGORITHM_FACTORS.STAR_RATING);
    }

    setAlgoFilters(updatedFilters);
  }

  function processComparePlayersList(comparePlayers:ComparePlayer[]) {
    const sortedComparePlayers = comparePlayers
      .map((comparePlayer:ComparePlayer, index:number) => {
        comparePlayer.index = index + 1;
        return comparePlayer;
      })
      .sort((a, b) => a.index - b.index);

    setComparePlayers(sortedComparePlayers);
  }

  function handleSearch(player:QuickSearchPlayer | null) {
    if (player && player.slug) {
      history.push(`/compare/${player.slug}`);
    }
  }

  function applyFilters () {
    fetchComparePlayersList();
    setIsDrawerOpen(false);
  }

  function resetFiltersAndReload (resetOptions:{ resetComparisonFactors?: boolean }) {
    resetFilters(resetOptions);
    setFiltersUpdated(true);
  }

  function resetFilters ({ resetComparisonFactors = false }:{ resetComparisonFactors?: boolean }) {
    if (resetComparisonFactors) {
      setAlgoFilters(DEFAULT_ALGO_FILTER);
    }

    setPlayerTypeFilters([]);
    setHsClassYearRangeFilter([HS_YEAR_RANGE.MIN, HS_YEAR_RANGE.MAX]);
    setNFLDraftYearRangeFilter([NFL_YEAR_RANGE.MIN, NFL_YEAR_RANGE.MAX]);
    setCollegeTypeHSClassYearRangeFilter([COLLEGE_YEAR_RANGE.MIN, COLLEGE_YEAR_RANGE.MAX]);
    setSelectedStates([]);
    setSelectedConferencesIds([]);
    setSelectedTeamIds([]);
    setTransferStatusFilter(false);
    setTransferYearsFilter(false);
    setSelectedTransferStatusFilters([]);
    setSelectedTransferYearsFilters([]);

    setIsDrawerOpen(false);
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function allowedToExportXLS () {
    const allowed = user?.accessLevel?.exportAllowanceType !== 'none';
    const isCollegeAccessLevel = user?.accessLevel?.type === 'college';

    return allowed && (
      !isCollegeAccessLevel
      || (isCollegeAccessLevel && searchAndResultType !== CompareResultType.COLLEGE && searchAndResultType !== CompareResultType.NFL)
    );
  }

  function onXLSDownload() {
    setPageLoading(true);

    exportXLSReport(
      XLSExportType.PLAYERS,
      { ids: comparePlayers.map((player:ComparePlayer) => player.id) },
    )
      .catch(error => {
        if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
          showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
        } else {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        }
      })
      .finally(() => setPageLoading(false));
  }

  function generateAndDownloadPDFReport () {
    setPageLoading(true);

    exportPDFReport(
      PDFExportPage.COMPARE_PLAYER,
      slug,
    )
      .catch(error => {
        console.error(error);
        showToast(<>Failed to download PDF report. <br />({error.message})</>, ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function onAlgorithmCheckBoxChange (checkedItem: string) {
    return (value:boolean) => {
      if (value) {
        setAlgoFilters([...algoFilters, checkedItem]);
      } else {
        if ([COMPARISON_ALGORITHM_FACTORS.POSITION, COMPARISON_ALGORITHM_FACTORS.BODY_TYPE].indexOf(checkedItem) === -1) {
          const selectedItemsWithoutCheckedItem = [...algoFilters];
          const removeIndex = algoFilters.findIndex(item => item === checkedItem);

          if (removeIndex > -1) {
            selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
          }

          setAlgoFilters(selectedItemsWithoutCheckedItem);
        }
      }
    };
  }

  function onPlayerTypeFilterCheckBoxChange (checkedItem: string) {
    return (value:boolean) => {
      if (value) {
        setPlayerTypeFilters([...playerTypeFilters, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...playerTypeFilters];
        const removeIndex = playerTypeFilters.findIndex(item => item === checkedItem);

        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setPlayerTypeFilters(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function onActivePlayerTypeFilterCheckBoxChange () {
    setActivePlayersOnly(!activePlayersOnly);
  };

  function onSelectColumns (selectedColumnNames:string[]) {
    saveComparePlayersColumnsAndType(columns.map((column:Column) => ({
      name: column.name,
      selected: selectedColumnNames.includes(column.name),
    } as Column)));
  }

  function onReorderColumns (reorderedColumnNames:string[]) {
    saveComparePlayersColumnsAndType(reorderedColumnNames
      .map((columnName:string) => columns.find((column:Column) => column.name === columnName))
      .filter(Boolean) as Column[]
    );
  }

  function resetColumns () {
    saveComparePlayersColumnsAndType([], searchAndResultType ? searchAndResultType : undefined);
  }

  function renderCurrentPlayerFilters () {
    switch (searchAndResultType) {
      case CompareResultType.HIGH_SCHOOL:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked
                  disabled
                  onChange={() => {}}
                />
                High School Grad Year
              </div>

              <div className={classes.sliderWrapper}>
                <Slider
                  className={classes.slider}
                  label='HS Class'
                  min={HS_YEAR_RANGE.MIN}
                  step={1}
                  max={HS_YEAR_RANGE.MAX}
                  defaultValue={hsClassYearRangeFilter || [HS_YEAR_RANGE.MIN, HS_YEAR_RANGE.MAX]}
                  marks={[
                    { value: HS_YEAR_RANGE.MIN, label: HS_YEAR_RANGE.MIN },
                    { value: HS_YEAR_RANGE.MAX, label: HS_YEAR_RANGE.MAX },
                  ]}
                  onChange={(value:number|number[]) => setHsClassYearRangeFilter(value)}
                />
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <MultiSelect
                className={classes.dropdown}
                selectorRootClassName={classes.dropdownSelector}
                labelWhenSelectedNone='All States'
                items={states.map((state:StateType) => ({
                  content: `${state.code} - ${state.name}`,
                  value: state.code,
                }))}
                values={selectedStates}
                onChange={(selectedStates:string[]) => {
                  setSelectedStates(selectedStates);
                }}
              />
            </div>
          </div>
        );
      case CompareResultType.COLLEGE:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked
                  disabled
                  onChange={() => {}}
                />
                High School Grad Year
              </div>

              <div className={classes.sliderWrapper}>
                <Slider
                  className={classes.slider}
                  label='HS Class'
                  min={COLLEGE_YEAR_RANGE.MIN}
                  step={1}
                  max={COLLEGE_YEAR_RANGE.MAX}
                  defaultValue={collegeTypeHSClassYearRangeFilter || [COLLEGE_YEAR_RANGE.MIN, COLLEGE_YEAR_RANGE.MAX]}
                  marks={[
                    {value: COLLEGE_YEAR_RANGE.MIN, label: COLLEGE_YEAR_RANGE.MIN},
                    {value: COLLEGE_YEAR_RANGE.MAX, label: COLLEGE_YEAR_RANGE.MAX}
                  ]}
                  onChange={(value: number | number[]) => setCollegeTypeHSClassYearRangeFilter(value)}
                />
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={includeCollegeAllYear}
                  onChange={() => setIncludeCollegeAllYear(!includeCollegeAllYear)}
                />
                All Years
              </div>
            </div>
          </div>
        );
      case CompareResultType.NFL:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked
                  disabled
                  onChange={() => {}}
                />
                NFL Draft Year
              </div>

              <div className={classes.sliderWrapper}>
                <Slider
                  className={classes.slider}
                  label='NFL Draft Year'
                  min={NFL_YEAR_RANGE.MIN}
                  step={1}
                  max={NFL_YEAR_RANGE.MAX}
                  defaultValue={nflDraftYearRangeFilter || [NFL_YEAR_RANGE.MIN, NFL_YEAR_RANGE.MAX]}
                  marks={[
                    {value: NFL_YEAR_RANGE.MIN, label: NFL_YEAR_RANGE.MIN},
                    {value: NFL_YEAR_RANGE.MAX, label: NFL_YEAR_RANGE.MAX}
                  ]}
                  onChange={(value: number | number[]) => setNFLDraftYearRangeFilter(value)}
                />
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.filterLabel}>
                <Checkbox
                  className={classes.checkbox}
                  checked={nflAllYear}
                  onChange={() => setNFLAllYear(!nflAllYear)}
                />
                All Years
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  }

  function renderPlayerTypeFilters () {
    if (searchAndResultType === CompareResultType.COLLEGE || searchAndResultType === CompareResultType.NFL) {
      const playerTypes = FILTER_DRAWER_CONTENT[searchAndResultType].PLAYER_TYPE;

      return (
        <div className={classes.filterContent}>
          <div className={classes.filterWrapper}>
            <div className={classes.algoFilters}>
            {searchAndResultType === CompareResultType.COLLEGE ? renderActiveTypeFilters() : null}
              {playerTypes?.map(playerType => (
                <span
                  key={playerType.value}
                  className={classes.checkboxWrap}
                >
                  <Checkbox
                    className={classes.checkbox}
                    checked={!!playerTypeFilters.find(item => item === playerType.value)}
                    onChange={onPlayerTypeFilterCheckBoxChange(playerType.value)}
                  />
                  {playerType.textToDisplay}
                </span>
              ))}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  function renderActiveTypeFilters () {
      return (
            <div className={classes.algoFilters}>
                <span className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={activePlayersOnly}
                    onChange={() => onActivePlayerTypeFilterCheckBoxChange()}
                  />
                  Active Players Only
                </span>
            </div>
      );
  }

  function renderOfferedByFilters () {
    if (searchAndResultType !== CompareResultType.HIGH_SCHOOL) return null;

    const offeredBy = FILTER_DRAWER_CONTENT[searchAndResultType].OFFERED_BY;

    return (
      <div className={classes.filterContent}>
        <div className={classes.filterWrapper}>
          <div className={classes.algoFilters}>
            {offeredBy && offeredBy.map(group => (
              <span
                key={group.value}
                className={classes.checkboxWrap}
              >
                <Checkbox
                  className={classes.checkbox}
                  checked={playerTypeFilters.findIndex(item => item === group.value) > -1}
                  onChange={onPlayerTypeFilterCheckBoxChange(group.value)}
                />
                {group.textToDisplay}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }

  function onAlert () {
    if (compareSourcePlayer?.hasHighSchool && (compareSourcePlayer?.hasCollegeTeam || compareSourcePlayer?.hasNFLTeam)) {
      if (selectedPlayers.find(sp => sp.id === compareSourcePlayer?.id)) {
        showToast('Alert can only be set for HS player', ToastType.ERROR);
        return;
      }
    }
    setPageLoading(true);

    gql(`
      mutation {
        addAlerts (playerIds: [${selectedPlayers.map((selectedItem:Player) => selectedItem.id)}])
      }
    `)
      .then((data:any) => data && data.addAlerts as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast('Subscribed for updates', ToastType.SUCCESS);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));

      setSelectedPlayers([]);
  }

  function onSave () {
    setPageLoading(true);

    gql(`
      mutation {
        savePlayers (playerIds: [${selectedPlayers.map((selectedItem:Player) => selectedItem.id)}], folderId: null)
      }
    `)
      .then((data:any) => data && data.savePlayers as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast('Saved', ToastType.SUCCESS);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));

      setSelectedPlayers([]);
  }

  function onPin () {
    setPageLoading(true);

    gql(`
      mutation {
        pin (playerIds: [${selectedPlayers.map((selectedItem:Player) => selectedItem.id)}])
      }
    `)
      .then((data:any) => ({
        success: data.pin as boolean,
        errorMessage: data.errorMessage,
      }))
      .then(({ success, errorMessage }:{ success:boolean, errorMessage:string }) => {
        if (success) {
          showToast('Pinned', ToastType.SUCCESS);
        } else if (errorMessage) {
          showToast(errorMessage, ToastType.ERROR);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));

      setSelectedPlayers([]);
  }

  const hasPAIData = compareSourcePlayer && typeof getScoreValue(compareSourcePlayer.pai) === 'number';
  const hasCombineData = compareSourcePlayer && typeof getScoreValue(compareSourcePlayer.combine) === 'number';
  const primaryHS:HighSchool = compareSourcePlayer && (compareSourcePlayer.highSchools || []).find((highSchool:HighSchool) => highSchool && highSchool.isPrimary) || {} as HighSchool;
  const hasMultiSportData = compareSourcePlayer && !!get(primaryHS, 'otherSports', []).length;
  const hasStarRatingData = typeof compareSourcePlayer?._247Star === 'number' || typeof compareSourcePlayer?.compStar === 'number';

  const saveActionDisabled = !selectedPlayers.length;
  const alertActionDisabled = !selectedPlayers.length || ((compareSourcePlayer?.hasHighSchool && (compareSourcePlayer?.hasCollegeTeam || compareSourcePlayer?.hasNFLTeam))&& (selectedPlayers.find(sp => sp.id === compareSourcePlayer?.id) ? true : false));
  const sideBySideActionDisabled = !selectedPlayers.length;

  if (!user || !Object.keys(user).length) {
    return null;
  }
  let isShowAlert = false;
  if (!isNFL) {
    if (searchAndResultType == CompareResultType.HIGH_SCHOOL) {
      isShowAlert = true;
    }
  }
  const loading = pageLoading || divITeamsLoading || nflTeamsLoading || statesLoading;

  return (
    <>
      {!printed && (
        <PageContainer className={classes.comparePlayersPageTemplate}>
          <Paper className={classes.paper}>
            <Loader inProgress={loading} />

            <div className={classes.header}>
              <h1 className={classes.headerTitle}>
                <ComparePlayersIcon className={classes.headerIcon} />
                Compare Players
              </h1>

              <QuickSearchAutoComplete
                className={classes.quickSearchAutoComplete}
                label='Search Players'
                onSelect={(searchedPlayer:QuickSearchPlayer | null) => handleSearch(searchedPlayer)}
              />

              <Button
                className={classes.filterButton}
                primary
                onClick={() => setIsDrawerOpen(true)}
                disabled={!searchAndResultType}
              >
                <FilterIcon className={classes.filterIcon}/>
                Filter
              </Button>
            </div>

            <div className={classes.comparePlayers}>
              <div className={classes.actionsRow}>
                <div className={classes.playerComparisonText}>Player Comparison</div>

                <AddRemoveColumns
                  className={classes.addRemoveColumns}
                  columns={columns.map((column:Column) => ({
                    content: TABLE_COLUMN_TITLE[column.name as CompareTableColumn],
                    value: column.name,
                  })) as MultiSelectItem[]}
                  selectedColumns={columns
                    .filter((column:Column) => column.selected)
                    .map((column:Column) => column.name)
                  }
                  onSelect={onSelectColumns}
                  onReorder={onReorderColumns}
                  onResetColumnsClick={resetColumns}
                />

                <div className={classes.headerDownloads}>
                  {allowedToExportXLS() && (
                    <Download
                      label='XLS'
                      onClick={onXLSDownload}
                    />
                  )}

                  <Download
                    label='PDF'
                    onClick={generateAndDownloadPDFReport}
                  />
                </div>

                <div className={classes.rowActions}>
                  <div className={classes.dropdownWrapper}>
                    <div className={classes.dropdownLabel}>Result Type:</div>

                    <DropDown
                      className={classes.searchTypeDropdown}
                      items={resultTypeItems}
                      value={searchAndResultType}
                      onChange={(value:string) => setSearchAndResultType(value as CompareResultType)}
                    />
                  </div>
                </div>
              </div>

              <div className={classes.actionsRow}>
                <Action
                  className={clsx(classes.anotherAction, sideBySideActionDisabled && classes.disabledAction)}
                  icon={PinIcon}
                  iconClassName={classes.actionIcon}
                  disabled={sideBySideActionDisabled}
                  onClick={onPin}
                >
                  <span className={classes.actionText}>Pin</span>
                </Action>
                <Action
                  className={clsx(classes.anotherAction, saveActionDisabled && classes.disabledAction)}
                  icon={SaveIcon}
                  iconClassName={classes.actionIcon}
                  disabled={saveActionDisabled}
                  onClick={onSave}
                >
                  <span className={classes.actionText}>Save</span>
                </Action>

                {isShowAlert && (
                  <Action
                    className={clsx(classes.anotherAction,  alertActionDisabled && classes.disabledAction)}
                    icon={AlertIcon}
                    iconClassName={classes.actionIcon}
                    disabled={alertActionDisabled}
                    onClick={onAlert}
                  >
                    <span className={classes.actionText}>Alert</span>
                  </Action>
                )}
              </div>

              <ComparePlayersTable
                items={comparePlayers || []}
                selectedColumns={columns
                  .filter((column:Column) => column.selected)
                  .map((column:Column) => column.name)
                }
                selectedItems={selectedPlayers}
                setSelectedItems={setSelectedPlayers}
                setPlayers={(players:ComparePlayer[]) => setComparePlayers(players)}
                showCollegeWeightHeight={showCollegeWeightHeight}
              />
            </div>

            <Toast
              visible={toastVisible}
              type={toastType}
              onHide={() => setToastVisible(false)}
            >
              {toastMessage}
            </Toast>
          </Paper>

          {searchAndResultType && (
            <Drawer
              classes={{ paper: classes.filterDrawer }}
              anchor='right'
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            >
              <Loader inProgress={loading} />

              <div className={classes.drawerHeader}>
                <h2 className={classes.drawerHeaderTitle}>
                  {FILTER_DRAWER_CONTENT[searchAndResultType].TITLE}
                </h2>

                <CloseButton
                  className={classes.closeButton}
                  onClick={() => setIsDrawerOpen(false)}
                />
              </div>

              <Divider variant='fullWidth' light />

              <div className={classes.drawerContent}>
                <div className={classes.filterWrapper}>
                  <div className={classes.filterTitle}>Comparison Algo Included Factors:</div>

                  <div className={classes.algoFilters}>
                    {FILTER_DRAWER_CONTENT[searchAndResultType].COMPARISON_ALGORITHM.map(algo => (
                      <span
                        key={algo.value}
                        className={classes.checkboxWrap}
                      >
                      <Checkbox
                        className={classes.checkbox}
                        disabled={
                          algo.disabled ||
                          (algo.value === COMPARISON_ALGORITHM_FACTORS.PAI && !hasPAIData) ||
                          (algo.value === COMPARISON_ALGORITHM_FACTORS.COMBINE && !hasCombineData) ||
                          (algo.value === COMPARISON_ALGORITHM_FACTORS.MULTI_SPORT && !hasMultiSportData) ||
                          (algo.value === COMPARISON_ALGORITHM_FACTORS.STAR_RATING && !hasStarRatingData)
                        }
                        checked={algoFilters.findIndex(item => item === algo.value) > -1}
                        onChange={onAlgorithmCheckBoxChange(algo.value)}
                      />
                        {algo.textToDisplay}
                    </span>
                    ))}
                  </div>
                </div>

                <Accordion
                  key={COMPARISON_ALGORITHM_FACTORS.CURRENT_PLAYERS}
                  className={classes.accordion}
                  summaryClassName={classes.accordionSummary}
                  summary='Current Players'
                  expanded
                >
                  {renderCurrentPlayerFilters()}
                </Accordion>

                {(searchAndResultType && [CompareResultType.COLLEGE, CompareResultType.NFL].includes(searchAndResultType)) && (
                  <Accordion
                    key={COMPARISON_ALGORITHM_FACTORS.PLAYER_TYPE}
                    className={classes.accordion}
                    summaryClassName={classes.accordionSummary}
                    summary='Player Type'
                    expanded
                  >
                    {renderPlayerTypeFilters()}
                  </Accordion>
                )}

                {(searchAndResultType === CompareResultType.HIGH_SCHOOL) && (
                  <Accordion
                    key={COMPARISON_ALGORITHM_FACTORS.OFFERED_BY}
                    className={classes.accordion}
                    summaryClassName={classes.accordionSummary}
                    summary='Offered By'
                    expanded
                  >
                    {renderOfferedByFilters()}
                  </Accordion>
                )}

                {(FILTER_DRAWER_CONTENT[searchAndResultType]?.TRANSFER_PORTAL || FILTER_DRAWER_CONTENT[searchAndResultType]?.TRANSFER_YEARS) && (
                  <div className={clsx(classes.filterContent, classes.transferPortalSection)}>
                    <div className={classes.filterWrapper}>
                      <div className={classes.algoFilters}>
                        {FILTER_DRAWER_CONTENT[searchAndResultType]?.TRANSFER_PORTAL && (
                          <>
                            <div className={classes.checkboxWrap}>
                              <Checkbox
                                className={classes.checkbox}
                                checked={transferStatusFilter}
                                onChange={(checked:boolean) => {
                                  if (checked) {
                                    setTransferStatusFilter(true);
                                  } else {
                                    setTransferStatusFilter(false);
                                    setSelectedTransferStatusFilters([]);
                                  }
                                }}
                              />
                              Transfer Portal
                            </div>

                            {!!transferStatusFilter && (
                              <div className={classes.checkboxList}>
                                {TRANSFER_STATUSES.map((transferStatus:TransferStatus) => (
                                  <div
                                    key={transferStatus}
                                    className={classes.checkboxWrap}
                                  >
                                    <Checkbox
                                      className={classes.checkbox}
                                      checked={selectedTransferStatusFilters.includes(transferStatus)}
                                      onChange={(checked:boolean) => {
                                        if (checked) {
                                          setSelectedTransferStatusFilters([...selectedTransferStatusFilters, transferStatus]);
                                        } else {
                                          setSelectedTransferStatusFilters(selectedTransferStatusFilters.filter(status => status !== transferStatus));
                                        }
                                      }}
                                    />
                                    {transferStatus.slice(0, 1).toUpperCase() + transferStatus.slice(1)}
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        )}

                        {FILTER_DRAWER_CONTENT[searchAndResultType]?.TRANSFER_YEARS && (
                          <>
                            <div className={classes.checkboxWrap}>
                              <Checkbox
                                className={classes.checkbox}
                                checked={transferYearsFilter}
                                onChange={(checked:boolean) => {
                                  if (checked) {
                                    setTransferYearsFilter(true);
                                  } else {
                                    setTransferYearsFilter(false);
                                    setSelectedTransferYearsFilters([]);
                                  }
                                }}
                              />
                              Transfer Years
                            </div>

                            {!!transferYearsFilter && (
                              <div className={classes.checkboxList}>
                                {TRANSFER_YEARS.map((year:string) => (
                                  <div
                                    key={year}
                                    className={classes.checkboxWrap}
                                  >
                                    <Checkbox
                                      className={classes.checkbox}
                                      checked={selectedTransferYearsFilters.includes(year)}
                                      onChange={(checked:boolean) => {
                                        if (checked) {
                                          setSelectedTransferYearsFilters([...selectedTransferYearsFilters, year]);
                                        } else {
                                          setSelectedTransferYearsFilters(selectedTransferYearsFilters.filter(selectedYear => selectedYear !== year));
                                        }
                                      }}
                                    />
                                    {year}
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {(searchAndResultType !== CompareResultType.NFL) && (
                  <div className={classes.filterWrapper}>
                    <div className={classes.filterTitle}>Conferences:</div>
                    <MultiSelect
                      className={classes.dropdown}
                      selectorRootClassName={classes.dropdownSelector}
                      labelWhenSelectedNone='Div I Conferences'
                      items={divIConferences.map((conference:Conference) => (
                        {
                          content: conference.name,
                          value: conference.id.toString(),
                        }
                      ))}
                      values={selectedConferencesIds.map((id:number) => id.toString())}
                      onChange={(ids:string[]) => {
                        setSelectedConferencesIds(ids.map((id:string) => parseInt(id, 10)));
                      }}
                    />
                  </div>
                )}

                <div className={classes.filterWrapper}>
                  <div className={classes.filterTitle}>Teams:</div>

                  <MultiSelect
                    className={classes.dropdown}
                    selectorRootClassName={classes.dropdownSelector}
                    labelWhenSelectedNone={TEAM_SELECT_LABEL[searchAndResultType]}
                    items={(searchAndResultType !== CompareResultType.NFL ? divITeams : nflTeams).map((team:Team) => (
                      {
                        content: team.name,
                        value: team.id.toString(),
                      }
                    ))}
                    values={selectedTeamIds.map((id:number) => id.toString())}
                    onChange={(ids:string[]) => {
                      setSelectedTeamIds(ids.map((id:string) => parseInt(id, 10)));
                    }}
                  />
                </div>

                <div className={clsx(classes.filterWrapper, classes.actions)}>
                  <Button
                    className={classes.action}
                    primary
                    onClick={applyFilters}
                  >
                    Apply Filters
                  </Button>

                  <Button
                    className={classes.action}
                    onClick={() => resetFiltersAndReload({ resetComparisonFactors: true })}
                  >
                    Reset Filters
                  </Button>
                </div>
              </div>
            </Drawer>
          )}
        </PageContainer>
      )}

      {printed && (
        <PdfPageContainer>
          <Loader inProgress={loading} />

          <div className={classes.page}>
            <div className={classes.pdfHeader}>
              <h1 className={clsx(classes.headerTitle, classes.pdfHeaderTitle)}>
                <ComparePlayersIcon className={classes.headerIcon} />
                Compare Players
              </h1>

              <img
                className={classes.logo}
                src='/images/tracking-football-logo.svg'
                alt='Tracking Football'
              />
            </div>

            <div className={classes.tableWrapper}>
              <div className={classes.titleRow}>
                <div className={classes.playerComparisonText}>Player Comparison</div>
              </div>

              <ComparePlayersTable
                items={comparePlayers || []}
                selectedColumns={columns
                  .filter((column:Column) => column.selected)
                  .map((column:Column) => column.name)
                }
                selectedItems={selectedPlayers}
                setSelectedItems={setSelectedPlayers}
                setPlayers={(players: ComparePlayer[]) => setComparePlayers(players)}
                showCollegeWeightHeight={showCollegeWeightHeight}
                printed
              />
            </div>
          </div>
        </PdfPageContainer>
      )}
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    currentRecruitingYear: state.configurations.currentRecruitingYear,
    nflDraftYear: state.configurations.nflDraftYear,
    dashboardRolloverDay: state.configurations.dashboardRolloverDay,
    dashboardRolloverMonth: state.configurations.dashboardRolloverMonth,
    rolloverDay: state.configurations.rolloverDay,
    rolloverMonth: state.configurations.rolloverMonth,
    isNFL: state.ui.nflAccess,
    user: state.user,
    divITeams: state.teams.divITeams,
    divITeamsLoading: state.teams.divITeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
    states: state.states.allStates,
    statesLoading: state.states.statesLoading,
    userSettingsLoaded: state.userSettings.userSettingsLoaded,
    savedColumns: state.userSettings.comparePlayersColumns,
    comparePlayersResultType: state.userSettings.comparePlayersResultType,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchDivITeams,
      fetchNFLTeams,
      fetchStates,
      fetchUserSettings,
      saveComparePlayersColumnsAndType,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComparePlayersPageTemplate);
//
