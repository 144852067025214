import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SpeedIcon from '../icons/SpeedIcon';
import PowerIcon from '../icons/PowerIcon';
import AgilityIcon from '../icons/AgilityIcon';
import ListItem from '../atoms/ListItem';
import NamedBox from '../atoms/NamedBox';
import ProgressLine from '../atoms/ProgressLine';
import Tooltip from '../atoms/Tooltip';
import CombineRating from '../types/CombineRating';
import EventRating from '../types/EventRating';
import Player from '../types/Player';
import Grid from '@material-ui/core/Grid';
import PowerRecruitIcon from '../icons/PowerRecruitIcon';
import SpeedRecruitIcon from '../icons/SpeedRecruitIcon';
import { addSpaceBetweenNumAndAlpha } from '../services/player';

interface PlayerCombineRatingProps {
  className?: string;
  combineRatings?: CombineRating[];
  isNFL?: boolean;
  player?: Player;
}

export const RECRUIT_TOOLTIP = {
  POWER: 'Player exceeds 75th percentile Division I position performance rating in vertical and or broad jump.',
  SPEED: 'Player exceeds 75th percentile Division I position performance rating in shuttle, 3-cone, or 40 yard dash.',
};

const useStyles = makeStyles((theme:Theme) => ({
  playerCombineRating: {},

  progressLineWrapper: {
    width: '100%',
  },
  progressLine: {
    maxWidth: 350,
    paddingRight: 0,
    marginLeft: 'auto',
    cursor: 'help',
  },
  icon: {
    height: '25px',
    marginRight: theme.spacing(1),
  },
}), { name: PlayerCombineRating.name });

const BETTER_THAN_TOOLTIP_TEXT = 'Better than D-I position';

function aggregateAllEventsOfType (eventType:string, combineRatings: CombineRating[]):EventRating[] {
  return combineRatings
    // @ts-ignore
    .flatMap((combine:CombineRating) => combine[eventType] as EventRating[] || []);
}

function getTheBestEvent (events:EventRating[]):EventRating {
  const sortedEvents = !!events?.length
    ? events
      .sort((firstEvent:EventRating, secondEvent:EventRating) => {
        if (firstEvent?.percentile > secondEvent?.percentile) return -1;
        if (firstEvent?.percentile < secondEvent?.percentile) return 1;
        return 0;
      })
    : [];

  const firstEvent = sortedEvents[0];
  const anotherEventWithTheSamePercentileIndex = sortedEvents?.length > 1
    ? sortedEvents.slice(1).findIndex((event:EventRating) => {
        return event.percentile === firstEvent.percentile && event.name !== firstEvent.name;
      }) + 1
    : 0;

  // show 2 event names if their results are the same (edge case)
  if (anotherEventWithTheSamePercentileIndex > 0) {
    return {
      name: `${firstEvent?.name}/${sortedEvents[anotherEventWithTheSamePercentileIndex]?.name}`,
      percentile: firstEvent.percentile,
    } as EventRating;
  }

  return firstEvent;
}

export default function PlayerCombineRating (props: PlayerCombineRatingProps) {
  const {
    className,
    combineRatings,
    isNFL,
    player
  } = props;
  const classes = useStyles();

  if (!combineRatings?.length) return null;

  const bestSpeedEvent = getTheBestEvent(aggregateAllEventsOfType('speed', combineRatings));
  const bestPowerEvent = getTheBestEvent(aggregateAllEventsOfType('power', combineRatings));
  const bestAgilityEvent = getTheBestEvent(aggregateAllEventsOfType('agility', combineRatings));
  const noEvents = !bestPowerEvent && !bestSpeedEvent && !bestAgilityEvent;

  function isSpeedRecruitEnable ():boolean {
    if (player && player?.combineSpeedTier >= 4) {
      return true;
    }
    return false;
  }
  
  function isPowerRecruitEnable ():boolean {
    if (player && player?.combinePowerTier >= 4) {
      return true;
    }
    return false;
  }

  function isSpeedRecruitEnableForAgility ():boolean {
    if (player && player?.combineAgilityTier >= 4) {
      return true;
    }
    return false;
  }

  if (noEvents) return null;

  return (
    <NamedBox
      className={clsx(classes.playerCombineRating, className)}
      name={isNFL
        ? 'High School Combine Rating'
        : 'Combine Rating'
      }
    >
      {bestSpeedEvent && (
        <ListItem
          icon={SpeedIcon}
          title='Speed:'
        >
          <Grid container>
            <Grid lg={1} xs={2}>
              {
                isSpeedRecruitEnable() && (
                  <Tooltip title={RECRUIT_TOOLTIP.SPEED}>
                    <SpeedRecruitIcon className={'green'} />
                  </Tooltip>
                )
              }
            </Grid>
            <Grid lg={3} xs={5}>
              <b>{ addSpaceBetweenNumAndAlpha(bestSpeedEvent.name)}</b>
            </Grid>
            <Grid lg xs>
              <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
                <div className={classes.progressLineWrapper}>
                  <ProgressLine
                    className={classes.progressLine}
                    value={bestSpeedEvent.percentile}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
      )}

      {bestPowerEvent && (
        <ListItem
          icon={PowerIcon}
          title='Power:'
        >
          <Grid container>
            <Grid lg={1} xs={2}>
              {
              isPowerRecruitEnable() && (
                <Tooltip title={RECRUIT_TOOLTIP.POWER}>
                  <PowerRecruitIcon className={'green'} />
                </Tooltip>
                )
              }
            </Grid>
            <Grid lg={3} xs={5}>
              <b>{ addSpaceBetweenNumAndAlpha(bestPowerEvent.name)}</b>
            </Grid>
            <Grid lg xs>
              <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
                <div className={classes.progressLineWrapper}>
                  <ProgressLine
                    className={classes.progressLine}
                    value={bestPowerEvent.percentile}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
      )}

      {bestAgilityEvent && (
        <ListItem
          icon={AgilityIcon}
          title='Agility:'
        >
          <Grid container>
            <Grid lg={1} xs={2}>
            {
              isSpeedRecruitEnableForAgility() && (
                <Tooltip title={RECRUIT_TOOLTIP.SPEED}>
                  <SpeedRecruitIcon className={'green'} />
                </Tooltip>
                )
              }
            </Grid>
            <Grid lg={3} xs={5}>
              <b>{bestAgilityEvent.name}</b>
            </Grid>
            <Grid lg xs>
              <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
                <div className={classes.progressLineWrapper}>
                  <ProgressLine
                    className={classes.progressLine}
                    value={bestAgilityEvent.percentile}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </NamedBox>
  );
}
