import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SpeedIcon from '../icons/SpeedIcon';
import RedCircle from '../icons/RedCircleIcon';
import EnduranceIcon from '../icons/EnduranceIcon';
import ListItem from '../atoms/ListItem';
import NamedBox from '../atoms/NamedBox';
import ProgressLine from '../atoms/ProgressLine';
import Tooltip from '../atoms/Tooltip';
import InGameRating from '../types/InGameRating';
import {
  COLOR_BLUE,
  COLOR_DARK_BLUE,
} from '../styles/colors';

interface PlayerInGameRatingProps {
  className?: string;
  inGameRating: InGameRating;
  isNFL?: boolean;
}

const BETTER_THAN_TOOLTIP_TEXT = 'Better than D-I position';

const useStyles = makeStyles((theme: Theme) => ({
  PlayerInGameRating: {},

  progressLineWrapper: {
    width: '100%',
  },
  progressLine: {
    maxWidth: 350,
    paddingRight: 0,
    marginLeft: 'auto',
    cursor: 'help',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  ryzerLogo: {
    width: '100px',
    height: '50px',
  },
  headerMiddleContainer: {
    width: '100%',
  },
  disclaimer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    fontStyle: 'italic',
    fontWeight: 400,
  },
  link: {
    cursor: 'pointer',
    color: COLOR_BLUE,

    '&:hover': {
      color: COLOR_DARK_BLUE,
      textDecoration: 'underline',
    }
  },
  padLeft: {
    paddingLeft: theme.spacing(1),
  }
}), { name: PlayerInGameRating.name });

export default function PlayerInGameRating (props: PlayerInGameRatingProps) {
  const {
    className,
    inGameRating,
    isNFL,
  } = props;
  const classes = useStyles();

  if (!inGameRating) return null;

  const noEvents = !(inGameRating?.igaScore || inGameRating?.reelMPH || inGameRating?.reelPosMPH);
  if (noEvents) return null;

  return (
    <NamedBox
      className={clsx(classes.PlayerInGameRating, className)}
      name='High School In-Game Rating'
      headerContent={(
        <div className={classes.headerContent}>
          <div className={classes.headerMiddleContainer}>
          </div>
          <div>
            <img
              className={classes.ryzerLogo}
              src='/images/websites/reel-analytics.jpeg'
              alt='Ryzer'
            />
          </div>
        </div>
      )}
    >
      {(inGameRating?.reelMPH && inGameRating?.reelMPHPerc)
      ? <ListItem
          icon={SpeedIcon}
          title='Player Max Speed: (MPH)'
          extendWith={true}
        >
          <b>{inGameRating?.reelMPH}</b>

          <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
            <div className={classes.progressLineWrapper}>
              <ProgressLine
                className={classes.progressLine}
                value={inGameRating?.reelMPHPerc}
              />
            </div>
          </Tooltip>
        </ListItem>
      : null
      }

      {(inGameRating?.reelPosMPH && inGameRating?.reelPosMPHPerc)
      ? <ListItem
          icon={EnduranceIcon}
          title='Positional Max Speed: (MPH)'
          extendWith={true}
        >
          <b>{inGameRating?.reelPosMPH}</b>

          <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
            <div className={classes.progressLineWrapper}>
              <ProgressLine
                className={classes.progressLine}
                value={inGameRating?.reelPosMPHPerc}
              />
            </div>
          </Tooltip>
        </ListItem>
        : null
      }

        { inGameRating?.igaScore
        ? <ListItem
          icon={RedCircle}
          title='IGA Score:'
          extendWith={true}
        >
          <b>{inGameRating?.igaScore}</b>

          <Tooltip title={BETTER_THAN_TOOLTIP_TEXT}>
            <div className={classes.progressLineWrapper}>
              <ProgressLine
                className={classes.progressLine}
                value={inGameRating?.igaScore}
              />
            </div>
          </Tooltip>
        </ListItem>
        : null
        }
      <div className={classes.disclaimer}>
        <p >All In-Game Metrics and analytics powered by Reel Analytics :- 
        </p>
        <a href={"https://reel-analytics.net/"} target="_blank" rel="noopener noreferrer" className={classes.padLeft}> reel-analytics.net</a>
      </div>
    </NamedBox>
  );
}
