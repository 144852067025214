import React, {useState} from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SeeMoreButton from '../atoms/SeeMoreButton';
import LocationIcon from '../atoms/LocationIcon';
import Score, { SCORE_TYPE } from '../atoms/Score';
import OfferStatus from '../molecules/OfferStatus';
import PlayerNameWithPopover from './PlayerNameWithPopover';
import { getPrimaryHighSchool, getPrimaryCollege, getPrimaryNFLTeam } from '../services/player';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_DARK_GRAY,
  COLOR_WHITE,
  COLOR_BORDER,
  COLOR_TEXT,
} from '../styles/colors';
import DashboardPlayer from '../types/DashboardPlayer';
import HighSchool from '../types/HighSchool';
import College from '../types/College';
import NFLTeam from '../types/NFLTeam';
import { DashboardStatsCount } from '../types/DashboardStats';
import { getScoreValue } from '../atoms/Score';
import SpeedRecruitIcon from '../icons/SpeedRecruitIcon';
import PowerRecruitIcon from '../icons/PowerRecruitIcon';
import OffersIcon from '../icons/OffersIcon';
import HandshakeIcon from '../icons/HandshakeIcon';
import Tooltip from '../atoms/Tooltip';
import TrackIcon from '../icons/TrackIcon';
import Loader from '../atoms/Loader';
import DropDown from '../atoms/DropDown';
import LocationIconN from '../icons/LocationIcon';
import { inchesToFeetAndInches } from '../services/converter';
interface TabulatedPlayersListProps {
  name: string;
  className?: string;
  getSeeMoreUrl?: (selectedTabIndex:number) => string;
  tabs: Tabs;
  counts?: DashboardStatsCount[];
  showStatus?: boolean;
  showMark?: boolean;
  reverseColumn?: boolean;
  isNFL?: boolean
  sort?: string
  showIcon?: string
  selectedTrack?: string;
  setSelectedTrack?: (track: string) => void;
  loading?: boolean;
}

interface Tabs {
  [key:string]: DashboardPlayer[];
}

const useStyles = makeStyles((theme: Theme) => ({
  tabulatedPlayersList: {},

  tabulatedPlayersListContent: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
  },

  header: {
    display: 'flex',
    paddingLeft: theme.spacing(1.5),
    alignItems: 'center',
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: 0,
    paddingBottom: theme.spacing(0),
    display: 'flex',
  },

  mRight: {
  marginRight:'5px',
  marginLeft: 'auto',
   minHeight: 'initial',
 },

  tabs: {
    marginLeft: 'auto',
    minHeight: 'initial',
  },
  tabsIndicator: {
    height: '1px',
  },
  tab: {
    minWidth: 'auto',
    minHeight: '40px',
    padding: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    borderBottom: '1px solid transparent',
    transition: 'border 0.3s',
  },

  tableHeadCell: {
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
    borderBottom: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      paddingLeft: theme.spacing(1.5),
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(1.5),
    },
  },
  tableRow: {
    backgroundColor: COLOR_WHITE,
    transition: 'background 0.3s',

    '&:hover': {
      backgroundColor: COLOR_BACKGROUND_LIGHT,
    },
  },
  tableCell: {
    padding: theme.spacing(1, 0, 1, 1),
    fontSize: theme.typography.pxToRem(16),
    border: 0,

    '&:first-of-type': {
      paddingLeft: theme.spacing(1.5),
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(1.5),
    },
  },
  tableCellFixed: {
    maxWidth: '46px',
  },

  tableSectionTitle: {
    background: COLOR_BACKGROUND_LIGHT,
    color: COLOR_DARK_GRAY,
    padding: theme.spacing(0.5, 2),
    borderTop: '1px solid rgba(0, 0, 0, 0.04)',
    borderBottom: 0,
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '1px',
      background: 'rgba(0, 0, 0, 0.04)',
      bottom: 0,
      left: 0,
    },
  },

  playerInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  playerAvatar: {
    width: '32px',
    height: '32px',
  },
  playerNameAndHometown: {
    display: 'flex',
    flexDirection: 'column',
  },
  playerInfo: {
    fontSize: theme.typography.pxToRem(12),
    color: COLOR_DARK_GRAY,
  },
  playerLocationIcon: {
    marginLeft: 'auto',
  },
  playerUpdate: {
    display: 'flex',
    alignItems: 'center',
  },
  playerUpdateIcon: {
    width: '20px',
    height: '20px',
    color: COLOR_DARK_GRAY,
  },
  playerUpdateText: {
    marginLeft: theme.spacing(1),
  },
  playerScore: {
    margin: '0 auto',
  },

  alertButtonContainer: {
    height: '100%',
    display: 'flex',
  },

  seeMoreContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },

  '@media (min-width: 1601px)': {
    header: {
      paddingLeft: theme.spacing(2.5),
    },

    tab: {
      // padding: theme.spacing(1.5, 2.5),
      paddingBottom: theme.spacing(0),
      fontSize: theme.typography.pxToRem(16),
    },

    tableHeadCell: {
      padding: theme.spacing(1, 0, 1, 2),
      fontSize: theme.typography.pxToRem(16),

      '&:first-of-type': {
        paddingLeft: theme.spacing(2.5),
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(2.5),
      },
    },

    tableCell: {
      '&:first-of-type': {
        paddingLeft: theme.spacing(2),
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(1),
      },
    },
  },
  offersIcon: {
    width: '32px',
    color: '#F98B60',
    padding: '0px 12px 0px 0px',
  },
  commitsIcon: {
    width: '32px',
    color: '#F98B60',
    padding: '0px 12px 0px 0px',
  },
  locationIcon: {
    color: '#F98B60',
    padding: '10px 6px 0px 0px',
    width: '36px',
  },
  trackIcon: {
    color: '#F98B60',
    padding: '11px 9px 0px 0px',
    width: '36px',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  dropdown: {
    height: '30px',
    width: '100%',
    marginTop: theme.spacing(1.5),
  },
  dropdownSelector: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    minHeight: '30px',
    fontSize: theme.typography.pxToRem(16),
  },
  headerCaption: {
    color: COLOR_TEXT,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    fontStyle: 'italic',
  },
}), { name: TabulatedPlayersList.name });

export default function TabulatedPlayersList (props:TabulatedPlayersListProps) {
  const {
    name,
    className,
    getSeeMoreUrl = () => {},
    tabs,
    counts,
    showStatus = false,
    showMark = false,
    reverseColumn,
    isNFL,
    sort,
    showIcon,
    selectedTrack,
    setSelectedTrack,
    loading = false,
  } = props;
  let tabsNames = Object.keys(tabs);
  if (reverseColumn) {
    tabsNames = tabsNames.sort((a: any,b: any) => b - a);
  }
  const [selectedTab, setSelectedTab] = useState(0);
  let players = tabs[tabsNames[selectedTab]];
  const classes = useStyles();

  const trackOptions = [
    { value: 'event_100m', content: '100M' },
    { value: 'event_200m', content: '200M' },
    { value: 'event_long_jump', content: 'Long Jump' },
    { value: 'event_triple_jump', content: 'Triple Jump' },
    { value: 'event_shotput', content: 'Shot Put' },
    { value: 'event_discus', content: 'Discus' },
];

  const isPowerRecruit = selectedTrack && ['event_shotput', 'event_discus', 'event_long_jump', 'event_triple_jump'].includes(selectedTrack);
  if (sort && sort == SCORE_TYPE.PAI) {
    players = players.sort((a: any, b: any) => (getScoreValue(b[SCORE_TYPE.PAI]) || 0) - (getScoreValue(a[SCORE_TYPE.PAI]) || 0) )
  } else if (sort && sort == 'draftRound') {
    players = players.sort((a: any, b: any) => (getPrimaryNFLTeam(a)?.draftRound || 0) - (getPrimaryNFLTeam(b)?.draftRound || 0) )
  } else if (sort && sort == 'performance') {
    if (selectedTrack) {
      players = players.sort((a:any , b: any) => isPowerRecruit ? Number(b?.bestTrackEvent?.[selectedTrack]?.performance) - Number(a?.bestTrackEvent?.[selectedTrack]?.performance)
      : Number(a?.bestTrackEvent?.[selectedTrack]?.performance) - Number(b?.bestTrackEvent?.[selectedTrack]?.performance))
    }
  }

  function onTabChange (event:React.ChangeEvent<any>, newValue:number) {
    setSelectedTab(newValue);
  }

  function renderTableRow (player:DashboardPlayer, index:number) {
    const {
      id,
      firstName,
      lastName,
      pai,
      ppi,
      combine,
      committedToTeam,
      hsCombines,
      nflCombineScore,
      nflCombines,
      bestTrackEvent
    } = player;

    function getPlayerPosition(primaryNFLTeam: NFLTeam|undefined, primaryCollege: College|undefined, primaryHS:HighSchool|undefined): string | undefined {
      if (primaryNFLTeam) {
        const primaryPosition = (primaryNFLTeam?.positions || []).find((position:any) => !!position?.isPrimary);

        return primaryPosition?.code || '';
      } else if (primaryCollege) {
        const primaryPosition = (primaryCollege?.positions || []).find((position:any) => !!position?.isPrimary);

        return primaryPosition?.code || '';
      } else if (primaryHS) {
        const primaryPosition = (primaryHS?.positions || []).find((position:any) => !!position?.isPrimary);

        return primaryPosition?.code || '';
      }
    }
    const primaryHS:HighSchool|undefined = getPrimaryHighSchool(player);
    const primaryCollege:College|undefined = getPrimaryCollege(player);
    const primaryNFLTeam:NFLTeam|undefined = getPrimaryNFLTeam(player);
    const primaryPosition = (primaryHS?.positions || []).find((position:any) => !!position?.isPrimary);
    const primaryPositionCode = getPlayerPosition(primaryNFLTeam, primaryCollege, primaryHS);
    const city = primaryHS?.city;
    const state = primaryHS?.state;
    const name = primaryCollege?.team?.name;
    const eventPerformance = selectedTrack && bestTrackEvent[selectedTrack];

    function getFormatedPerformance(performance:number, selectedTrack:string) {
      if (!performance) return 'N/A';
      if (!selectedTrack) return 'N/A';
      if (selectedTrack === 'event_100m' || selectedTrack === 'event_200m') {
        return Number(performance).toFixed(2);
      } else if (isPowerRecruit) {
        return inchesToFeetAndInches(performance);
      } else {
        return `${performance}`;
      }
    }

    return (
      <TableRow
        key={`${id}_${index}`}
        className={classes.tableRow}
      >
        {
          isNFL
          ? <>
          <TableCell className={classes.tableCell} align='left'>
            <div className={classes.playerInfoContainer}>
              <div className={classes.playerNameAndHometown}>
                <PlayerNameWithPopover
                  playerId={id}
                  name={`${sort == 'draftRound' ? primaryNFLTeam?.draftRound || '' : ''} ${firstName} ${lastName}`}
                />
                <div className={classes.playerInfo}>{primaryPositionCode} – {name}</div>
              </div>
  
              {state && (
                <LocationIcon
                  className={classes.playerLocationIcon}
                  stateCode={state}
                />
              )}
            </div>
          </TableCell>
  
          <TableCell className={clsx(classes.tableCell, classes.tableCellFixed)} align='center'>
            <Score
              className={classes.playerScore}
              type={SCORE_TYPE.PAI}
              scoreList={pai}
            />
          </TableCell>
  
          <TableCell className={clsx(classes.tableCell, classes.tableCellFixed)} align='center'>
            <Score
                className={classes.playerScore}
                type={SCORE_TYPE.PPI}
                value={ppi || undefined}
              />
          </TableCell>

          <TableCell className={clsx(classes.tableCell, classes.tableCellFixed)} align='center'>
            <Score
              className={classes.playerScore}
              type={SCORE_TYPE.PRO_COMBINE}
              scoreList={nflCombineScore}
              combines={nflCombines}
              value={player.nflCombineScore || undefined}
            />
          </TableCell>
            </>
          : <>
          <TableCell className={classes.tableCell} align='left'>
            <div className={classes.playerInfoContainer}>
              <div className={classes.playerNameAndHometown}>
                <PlayerNameWithPopover
                  playerId={id}
                  name={`${firstName} ${lastName}`}
                />
                <div className={classes.playerInfo}>{primaryPositionCode} – {city}, {state}</div>
              </div>
  
              {state && (
                <LocationIcon
                  className={classes.playerLocationIcon}
                  stateCode={state}
                />
              )}
            </div>
          </TableCell>
  
          <TableCell className={clsx(classes.tableCell, classes.tableCellFixed)} align='center'>
            <Score
              className={classes.playerScore}
              type={SCORE_TYPE.PAI}
              scoreList={pai}
            />
          </TableCell>
  
          <TableCell className={clsx(classes.tableCell, classes.tableCellFixed)} align='center'>
            <Score
              className={classes.playerScore}
              type={SCORE_TYPE.COMBINE}
              scoreList={combine}
              combines={hsCombines}
            />
          </TableCell>
  
          {showStatus && (
            <TableCell className={clsx(classes.tableCell, classes.tableCellFixed)} align='center'>
              <OfferStatus committedTo={committedToTeam} />
            </TableCell>
          )}

          {showMark && (
            <TableCell className={clsx(classes.tableCell, classes.tableCellFixed)} align='center'>
              {getFormatedPerformance(eventPerformance?.performance, selectedTrack || '')}
            </TableCell>
          )}
          </>
        }
      </TableRow>
    );
  }

  function renderTable () {
    const tableHeadCellClass = clsx(
      classes.tableHeadCell,
    );

    return (
      <Table>
        <TableHead>
          <TableRow>
            {isNFL
            ? <>
            { (sort && sort == 'draftRound')
                ? <TableCell className={tableHeadCellClass} align='left'>RD | Position | College</TableCell>
                : <TableCell className={tableHeadCellClass} align='left'> Position | College</TableCell>
            }
            <TableCell className={tableHeadCellClass} align='center'>PAI</TableCell>
            <TableCell className={tableHeadCellClass} align='center'>PPI</TableCell>
            <TableCell className={tableHeadCellClass} align='center'>Pro Com.</TableCell>
            </>
            : <>
            <TableCell className={tableHeadCellClass} align='left'>Name | Hometown</TableCell>
            <TableCell className={tableHeadCellClass} align='center'>PAI</TableCell>
            <TableCell className={tableHeadCellClass} align='center'>Com.</TableCell>
            {showStatus && (
              <TableCell className={tableHeadCellClass} align='center'>Status</TableCell>
            )}
            {showMark && (
              <TableCell className={tableHeadCellClass} align='center'>Mark</TableCell>
            )}
            </>
            }
          </TableRow>
        </TableHead>

        <TableBody>
          {players.map(renderTableRow)}
        </TableBody>
      </Table>
    );
  }

  const count = counts?.length === tabsNames?.length ? counts[selectedTab]?.count : 0;

  return (
    <div className={clsx(classes.tabulatedPlayersList, className)}>
      <Loader inProgress={loading} />
      <Paper className={classes.tabulatedPlayersListContent}>
        <div className={classes.header}>
          <h2 className={classes.headerTitle}>
            {
              showIcon && showIcon == 'speed' ?
              <Tooltip title='Speed Recruit'>
                <div>
                  <SpeedRecruitIcon />
                </div>
              </Tooltip>
              : null
            }
            {
              showIcon && showIcon == 'power' ?
              <Tooltip title='Power Recruit'>
                <div>
                  <PowerRecruitIcon />
                </div>
              </Tooltip>
              : null
            }
            {
              showIcon && showIcon == 'offers' ?
              <Tooltip title='Offers'>
                <div className={classes.offersIcon}>
                  <OffersIcon />
                </div>
              </Tooltip>
              : null
            }
            {
              showIcon && showIcon == 'commits' ?
              <Tooltip title='Commits'>
                <div className={classes.commitsIcon}>
                  <HandshakeIcon />
                </div>
              </Tooltip>
              : null
            }
            {
              showIcon && showIcon == 'inState' ?
              <Tooltip title='In-State Players'>
                <div className={classes.locationIcon}>
                <LocationIconN />
                </div>
              </Tooltip>
              : null
            }
            {
              showIcon && showIcon == 'trackers' ?
                <Tooltip title='Track'>
                  <div className={classes.trackIcon}>
                    <TrackIcon />
                  </div>
                </Tooltip>
                : null
            }

            <div>{name}{!!count ? ` (${count})` : ''}</div>
          </h2>

          <div className={showIcon === 'trackers' ? classes.mRight : classes.tab}>
            {showIcon && showIcon == 'trackers' &&
                <DropDown
                className={classes.dropdown}
                selectorClassName={classes.dropdownSelector}
                items={trackOptions}
                value={selectedTrack}
                onChange={(value: string) => setSelectedTrack && setSelectedTrack(value)}
                />
              }

              {showIcon && showIcon == 'inState' &&
                <span className={classes.headerCaption}>0-star rated</span>
              }

            <Tabs
              className={classes.tabs}
              classes={{ indicator: classes.tabsIndicator }}
              value={selectedTab}
              onChange={onTabChange}
            >
              <Tab
                className={classes.tab}
                label={tabsNames[0]}
                disableRipple
              />

              <Tab
                className={classes.tab}
                label={tabsNames[1]}
                disableRipple
              />
            </Tabs>
          </div>
        </div>
        <Divider variant='fullWidth' light />

        {renderTable()}
        <Divider variant='fullWidth' light />

        <div className={classes.seeMoreContainer}>
          <SeeMoreButton href={getSeeMoreUrl(selectedTab) || ''} />
        </div>
      </Paper>
    </div>
  );
}
