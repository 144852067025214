import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import PinIcon from '../icons/PinIcon';
import PDFIcon from '../icons/PDFIcon';
import CompareIcon from '../icons/CompareIcon';
import SaveIcon from '../icons/SaveIcon';
import AlertIcon from '../icons/AlertIcon';
import EditIcon from '../icons/EditIcon';
import Loader from '../atoms/Loader';
import Action from '../atoms/Action';
import TeamLogo from '../atoms/TeamLogo';
import Download from '../atoms/Download';
import Toast, { ToastType } from '../atoms/Toast';
import { getScoreValue } from '../atoms/Score';
import MultiSelect from '../molecules/MultiSelect';
import PlayersAutoComplete from '../molecules/PlayersAutoComplete';
import AddRemoveColumns from './AddRemoveColumns';
import SurfaceToTop, { surfaceToTopPlayers } from '../molecules/SurfaceToTop';
import PageLimitDropdown from '../molecules/PageLimitDropdown';
import PlayersTable, {
  PLAYER_COLUMN,
  PLAYER_COLUMN_TITLE,
  COLLEGE_DEFAULT_COLUMNS,
  NFL_DEFAULT_COLUMNS,
  COLLEGE_COLUMNS,
  NFL_COLUMNS,
} from './PlayersTable';
import { TEAM_TYPE } from '../templates/PlayerPageTemplate';
import { State } from '../redux/reducers';
import gql from '../services/gql';
import exportXLSReport, {
  XLSExportType,
  XLSExportPage,
  ERROR_MESSAGE_CHECK_EMAIL,
} from '../services/export-xls-report';
import { getQueryParameter } from '../services/query-parameters';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { fetchUserSettings, saveTeamPlayersColumns } from '../redux/dispatchers/user-settings';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Player from '../types/Player';
import Team from '../types/Team';
import User from '../types/User';
import Column from '../types/Column';
import { Order } from '../types/Order';

interface TeamProfilePlayersTableProps {
  className?: string;
  team?: Team;
  isNFL?: boolean;
  isAdmin: boolean;
  currentRecruitingYear?: number;
  nflDraftYear?: number;
  user?: User;
  newHSPlayersDays: number;
  newCollegePlayersDays: number;
  fetchUserSettings: () => void;
  savedColumns: Column[] | null;
  saveColumns: (teamPlayersColumns:Column[]) => void;
}

enum NFL_GROUP {
  DRAFTED = 'drafted',
  UDFA = 'udfa',
}

enum NFL_RECRUIT {
  SPEED = 'speed',
  POWER = 'power',
}

const ROSTER_TYPE = {
  CURRENT: 'current',
  FORMER: 'former',
  ALL: 'all',
};

const useStyles = makeStyles((theme: Theme) => ({
  teamProfilePlayersTable: {
    display: 'flex',
    flexDirection: 'column',
    background: COLOR_WHITE,
    position: 'relative',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },
  titleWrapper: {
    position: 'relative',
    padding: theme.spacing(2.5),
  },
  headerLeftColumn: {
    display: 'flex',
  },
  headerRightColumn: {
    display: 'flex',
    flexGrow: 1,
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  logo: {
    width: '25px',
    height: '25px',
  },
  teamName: {
    marginLeft: theme.spacing(2),
    ...theme.typography.h2,
  },

  playersAutoComplete: {
    width: '100%',
    maxWidth: '300px',
  },

  dropdowns: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'auto',
    maxWidth: '940px',
    marginTop: theme.spacing(2),
  },
  dropdownWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: theme.spacing(4),
    color: COLOR_TEXT,

    '&:first-child': {
      marginLeft: 0,
    },
  },
  draftedUdfaDropdown: {
    order: 1,
  },
  speedPowerDropdown: {
    order: 2,
  },
  yearsDropdown: {
    order: 3,
  },
  limit: {
    order: 4,
  },

  dropdownLabel: {
    marginRight: theme.spacing(1),
  },
  dropdown: {
    minWidth: '220px',
  },
  dropdownSelector: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    fontSize: theme.typography.pxToRem(16),
  },
  limitDropDown: {
    minWidth: '60px',
    margin: theme.spacing(0,1),
  },
  multiSelect: {
    minWidth: 138,
    maxWidth: 242,
  },
  yearsMultiSelect: {},

  actions: {
    display: 'flex',
    padding: theme.spacing(2.5),
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  actionsPrimary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    maxWidth: '560px',
    paddingRight: theme.spacing(5),
  },
  actionsSecondary: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    justifyContent: 'space-between',
  },
  action: {
    padding: theme.spacing(0,0,0,2),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',

    '&:first-child': {
      paddingLeft: 0
    },

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $actionText': {
        color: COLOR_BLUE,
      },
    },
  },
  disabledAction: {
    outlineColor: 'transparent',

    '& $actionText': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $actionText': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  actionIcon: {
    width: '20px',
    height: '20px',
  },
  actionText: {
    display: 'none',
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  divider: {
    marginRight: theme.spacing(0),
  },

  addRemoveColumns: {
    margin: theme.spacing(0, 1, 0, 'auto'),
    padding: theme.spacing(0,2,0,0),
  },

  importPageLink: {
    paddingLeft: theme.spacing(0.5),
    textDecoration: 'underline',
    color: COLOR_WHITE,
    fontWeight: 600,
  },

  downloadXLS: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    margin: theme.spacing(0, 1),
  },

  '@media (max-width: 1180px)': {
    actions: {
      display: 'block',
    },
    actionsPrimary: {
      justifyContent: 'flex-start',
      paddingRight: 0,
    },
    actionsSecondary: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
      marginLeft: 0,
      paddingRight: 0,

      '& $dropdownWrapper': {
        margin: theme.spacing(1,0),
      },
    },

    divider: {
      margin: theme.spacing(0,2),
    },

    dropdowns: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    },
    dropdownWrapper: {
      width: 'calc(50% - 24px)',
      flexShrink: 0,
      flexWrap: 'wrap',
      maxWidth: '100%',
      margin: theme.spacing(2,3,0,0),
    },

    speedPowerDropdown: {
      order: 3,
    },
    yearsDropdown: {
      order: 2,
    },

    addRemoveColumns: {
      margin: theme.spacing(1,0),
      padding: 0,
    },

    downloadXLS: {
      margin: theme.spacing(1,0),
      padding: 0,
    },
  },

  [MEDIA.MOBILE]: {
    headerLeftColumn: {
      flexWrap: 'wrap',
    },
    title: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },

    actionText: {
      fontSize: theme.typography.pxToRem(14),
    },

    dropdownWrapper: {
      width: 'auto',
      fontSize: theme.typography.pxToRem(14),
    },
    dropdownLabel: {
      fontSize: theme.typography.pxToRem(14),
    },

    multiSelect: {
      minWidth: 132,
    },

    downloadXLS: {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  [MEDIA.DESKTOP]: {
    actionsPrimary: {
      flexGrow: 1,
    },
    actionText: {
      display: 'block',
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    limit: {
      position: 'absolute',
      right: theme.spacing(3),
      bottom: theme.spacing(2.5),
    },
  },

  '@media (min-width: 1601px)': {
    limitDropDown: {
      minWidth: '86px',
    },
    yearsMultiSelect: {
      minWidth: '200px',
    },
    surfaceToTop: {
      minWidth: '300px',
    },
  },
}), { name: TeamProfilePlayersTable.name });

function TeamProfilePlayersTable (props: TeamProfilePlayersTableProps) {
  const {
    className,
    team,
    isNFL = false,
    isAdmin,
    currentRecruitingYear,
    nflDraftYear,
    user,
    newHSPlayersDays,
    newCollegePlayersDays,
    savedColumns,
    fetchUserSettings,
    saveColumns,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const tableRef = useRef<any>();

  const withQueryParameters = history.location.search
    .replace('?', '').length > 0;

  const [roster, setRoster] = useState<string>(getQueryParameter('roster') || '');

  const [showDraft, setShowDraft] = useState<boolean>(getQueryParameter('draft') === 'true');
  const [draftYear, setDraftYear] = useState<string>(getQueryParameter('draft-year') || '');

  const [showUDFA, setShowUDFA] = useState<boolean>(getQueryParameter('udfa') === 'true');
  const [udfaYear, setUDFAYear] = useState<string>(getQueryParameter('udfa-year') || '');

  const [showSpeedPicks, setShowSpeedPicks] = useState<boolean>(getQueryParameter('speed-picks') === 'true');
  const [speedPicksYear, setSpeedPicksYear] = useState<string>(getQueryParameter('speed-picks-year') || '');

  const [showPowerPicks, setShowPowerPicks] = useState<boolean>(getQueryParameter('power-picks') === 'true');
  const [powerPicksYear, setPowerPicksYear] = useState<string>(getQueryParameter('power-picks-year') || '');

  const [sortByPAI, setSortByPAI] = useState<boolean>(getQueryParameter('sort-by') === 'pai');

  const [loading, setLoading] = useState<boolean>(true);
  const [scrolledToPlayers, setScrolledToPlayers] = useState<boolean>(false);
  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedRosters, setSelectedRosters] = useState<string[]>(
    roster === ROSTER_TYPE.ALL
      ? [ROSTER_TYPE.CURRENT, ROSTER_TYPE.FORMER]
      : roster ? [roster] : [ROSTER_TYPE.CURRENT]
  );
  const [surfaceToTopValues, setSurfaceToTopValues] = useState<string[]>([]);
  const [selectedRecruitingOptions, setSelectedRecruitingOptions] = useState<string[]>([]);
  const [selectedNFLGroups, setSelectedNFLGroups] = useState<NFL_GROUP[]>(
    (showSpeedPicks || speedPicksYear || showPowerPicks || powerPicksYear)
      ? []
      : (showDraft || draftYear)
        ? [NFL_GROUP.DRAFTED]
        : (showUDFA || udfaYear) ? [NFL_GROUP.UDFA] : [NFL_GROUP.DRAFTED]
  );
  const [selectedNFLRecruits, setSelectedNFLRecruits] = useState<NFL_RECRUIT[]>(
    (showUDFA || udfaYear || showDraft || draftYear)
      ? []
      : (showSpeedPicks || speedPicksYear)
        ? [NFL_RECRUIT.SPEED]
        : (showPowerPicks || powerPicksYear) ? [NFL_RECRUIT.POWER] : []
  );
  const [selectedNFLYears, setSelectedNFLYears] = useState<string[]>(
    draftYear || udfaYear || speedPicksYear || powerPicksYear
      ? [draftYear || udfaYear || speedPicksYear || powerPicksYear]
      : getNFLYears()
  );
  const [selectedPlayers, setSelectedPlayers] = useState<Array<Player>>([]);
  const [order, setOrder] = useState<Order | undefined>();
  const [sortedByColumn, setSortedByColumn] = useState<string>('name');

  const [columns, setColumns] = useState<Column[]>((isNFL ? NFL_COLUMNS : COLLEGE_COLUMNS).map((columnName:string) => ({
    name: columnName,
    selected: true,
  })));
  const [defaultColumns, setDefaultColumns] = useState<string[]>(isNFL ? NFL_DEFAULT_COLUMNS : COLLEGE_DEFAULT_COLUMNS);

  const [limit, setLimit] = useState<number>((clientStorage.get(StorageKey.TEAM_PLAYERS_TABLE) || {}).limit || 10);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(StorageKey.TEAM_PLAYERS_TABLE) || {}).limit || 10);

  const [currentPage, setCurrentPage] = useState<number>((clientStorage.get(StorageKey.TEAM_PLAYERS_TABLE) || {}).currentPage || 1);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  useEffect(() => {
    fetchUserSettings();
  }, []);


  useEffect(() => {
    clientStorage.save(StorageKey.TEAM_PLAYERS_TABLE, { limit, currentPage });
  }, [limit, currentPage]);

  useEffect(() => {
    if (prevLimit !== limit && !!players?.length) {
      setCurrentPage(1);
      setPrevLimit(limit);
    }
  }, [players?.length, limit]);

  useEffect(() => {
    if (withQueryParameters && !scrolledToPlayers && !loading && tableRef && tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrolledToPlayers(true);
    }
  }, [
    withQueryParameters,
    scrolledToPlayers,
    loading,
    roster,
    showDraft, draftYear,
    showUDFA, udfaYear,
    showSpeedPicks, speedPicksYear,
    showPowerPicks, powerPicksYear,
  ]);

  useEffect(() => {
    if (sortByPAI && players?.length) {
      setSortedByColumn(PLAYER_COLUMN.PAI_SCORE);
      setOrder(Order.desc);

      const sortedPlayers = [...players].sort((first:Player, second:Player) => {
        const pai1 = getScoreValue(first.pai) || 0;
        const pai2 = getScoreValue(second.pai) || 0;

        let result = 0;
        if (pai1 < pai2) {
          result = 1;
        } else if (pai1 > pai2) {
          result = -1;
        }

        return result;
      })

      setPlayers(sortedPlayers);
    }
  }, [sortByPAI, players?.length]);

  useEffect(() => {
    setPlayers(
      surfaceToTopPlayers(
        players,
        surfaceToTopValues,
        user?.accessLevel,
        newHSPlayersDays,
        newCollegePlayersDays,
      )
    );
  }, [surfaceToTopValues]);

  useEffect(() => {
    if (selectedRosters.includes(ROSTER_TYPE.FORMER)) {
      setColumns(Array.from(new Set([...COLLEGE_COLUMNS, ...NFL_COLUMNS])).map((columnName:string) => ({
        name: columnName,
        selected: (columns.find((column:Column) => column.name === columnName) || { selected: true }).selected,
      } as Column)));
      setDefaultColumns(Array.from(new Set([...COLLEGE_DEFAULT_COLUMNS, ...NFL_DEFAULT_COLUMNS])));
    } else {
      setColumns((isNFL ? NFL_COLUMNS : COLLEGE_COLUMNS).map((columnName:string) => ({
          name: columnName,
          selected: (columns.find((column:Column) => column.name === columnName) || { selected: true }).selected,
        } as Column))
      );
      setDefaultColumns(isNFL ? NFL_DEFAULT_COLUMNS : COLLEGE_DEFAULT_COLUMNS);
    }
  }, [isNFL, selectedRosters]);

  useEffect(() => {
    const savedSelectedCol = savedColumns?.filter(col => col.selected).map(col => col.name);
    setColumns((isNFL ? NFL_COLUMNS : COLLEGE_COLUMNS)?.map((columnName:string) => ({
      name: columnName,
      selected: savedSelectedCol?.length ? savedSelectedCol?.includes(columnName) ? true : false : true,
    })));
  }, [savedColumns]);

  useEffect(() => {
    if (team?.id) {
      fetchPlayers(team.id);
    }
  }, [
    team?.id,
    selectedRosters,
    selectedRecruitingOptions,
    selectedNFLGroups,
    selectedNFLRecruits,
    selectedNFLYears,
    showDraft,
    draftYear,
    showUDFA,
    udfaYear,
    showSpeedPicks,
    speedPicksYear,
    showPowerPicks,
    powerPicksYear,
  ]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  function fetchPlayers (teamId:any) {
    setLoading(true);

    const teamQuery = `
      id
      slug
      firstName
      lastName
      photoUrl
      highSchools
      pai
      ppi
      combine
      nflCombineScore
      nflCombines {
        id
      }
      compStar
      _247Star
      createdAt
      linkMilesplit
      linkMaxpreps
      link247
      linkRivals
      linkAthleticNet
      linkPff
      linkOn3
      linkSSA
      linkInstagram
      linkDirectAthletics
      linkTwitter
      linkHudl
      linkTrackingFootball
      multiSport
      notes {
        colorCode
      }
      paiHasFreshmanData
      combineHasFreshmanData
      playerNFLTeams {
        height
        weight
        isPrimary
        draftYear
        draftRound
        positions
        team {
          id
          shortName
          logoESPN
          logo247
          logoAlt
        }
      }
      playerColleges {
        recruitingClass
        height
        weight
        positions
        isPrimary
        jerseyNumber
        team {
          id
          shortName
          logoESPN
          logo247
          logoAlt
        }
      }
      hsCombines {
        id
      }
      email
      cellPhone
      address
      igaScore
      reelMPH
      reelMPHPerc
      reelPosMPH
      reelPosMPHPerc
    `;

    (team?.type === TEAM_TYPE.NFL
      ? fetchNFLPlayers(teamId, teamQuery)
      : fetchCollegePlayers(teamId, teamQuery)
    )
      .then((players:Player[]) => setPlayers(players.map(player => ({ ...player, selected: false }))))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchCollegePlayers (teamId:string, teamQuery:string) {
    const commitsYears = selectedRecruitingOptions.toString();

    return gql(`
      playersForCollegeTeam (
        teamId: ${teamId},
        isCurrent: ${selectedRosters.includes(ROSTER_TYPE.CURRENT)},
        isFormer: ${selectedRosters.includes(ROSTER_TYPE.FORMER)},
        commitsYears: [${commitsYears}]
      ) {
        ${teamQuery}
      }
    `)
      .then((data:any) => data.playersForCollegeTeam as Player[]);
  }

  function fetchNFLPlayers (teamId:string, teamQuery:string) {
    const draftedYears = (selectedNFLGroups.includes(NFL_GROUP.DRAFTED) ? selectedNFLYears : []).toString();
    const udfaYears = (selectedNFLGroups.includes(NFL_GROUP.UDFA) ? selectedNFLYears : []).toString();
    const speedYears = (selectedNFLRecruits.includes(NFL_RECRUIT.SPEED) ? selectedNFLYears : []).toString();
    const powerYears = (selectedNFLRecruits.includes(NFL_RECRUIT.POWER) ? selectedNFLYears : []).toString();

    return gql(`
      playersForNFLTeam(
        teamId: ${teamId},
        draftedYears: [${draftedYears}],
        udfaYears: [${udfaYears}],
        speedYears: [${speedYears}],
        powerYears: [${powerYears}]
      ) {
        ${teamQuery}
      }
    `)
      .then((data:any) => data.playersForNFLTeam as Player[]);
  }

  function getRecruitingYears () {
    const toYear = currentRecruitingYear || new Date().getFullYear();
    const fromYear = toYear - 6;

    return generateYears(fromYear, toYear)
      .map((year:string) => ({
        content: `${year} Recruits`,
        value: year,
      }));
  }

  function getNFLYears () {
    return generateYears(2010, nflDraftYear || new Date().getFullYear());
  }

  function generateYears (fromYear:number, toYear:number) {
    return Array.from(new Array(toYear - fromYear + 1))
      .map((_, index) => String(toYear - index));
  }

  function onSort (columnName:string, newOrder:Order, sort:(players:Player[], order:Order) => Player[]) {
    setSortedByColumn(columnName);
    setPlayers(sort(players, newOrder));
  }

  function onXLSDownload() {
    setLoading(true);

    exportXLSReport(
      XLSExportType.PLAYERS,
      { ids: players.map(player => player.id) },
      XLSExportPage.TEAM,
    )
      .catch(error => {
        if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
          showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
        } else {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        }
      })
      .finally(() => setLoading(false));
  }

  function onPin () {
    setLoading(true);

    gql(`
      mutation {
        pin (playerIds: [${selectedPlayers.map((selectedItem:Player) => selectedItem.id)}])
      }
    `)
      .then((data:any) => ({
        success: data.pin as boolean,
        errorMessage: data.errorMessage,
      }))
      .then(({ success, errorMessage }:{ success:boolean, errorMessage:string }) => {
        if (success) {
          showToast('Pinned', ToastType.SUCCESS);
        } else if (errorMessage) {
          showToast(errorMessage, ToastType.ERROR);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));

    setSelectedPlayers([]);
  }

  function onSave () {
    setLoading(true);

    gql(`
      mutation {
        savePlayers (playerIds: [${selectedPlayers.map((selectedItem:Player) => selectedItem.id)}], folderId: null)
      }
    `)
      .then((data:any) => ({
        success: data.savePlayers as boolean,
        errorMessage: data.errorMessage,
      }))
      .then(({ success, errorMessage }:{ success:boolean, errorMessage:string }) => {
        if (success) {
          showToast('Saved', ToastType.SUCCESS);
        } else if (errorMessage) {
          showToast(errorMessage, ToastType.ERROR);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));

    setSelectedPlayers([]);
  }

  function onAlert () {
    setLoading(true);

    gql(`
      mutation {
        addAlerts (playerIds: [${selectedPlayers.map((selectedItem:Player) => selectedItem.id)}])
      }
    `)
      .then((data:any) => ({
        success: data.addAlerts as boolean,
        errorMessage: data.errorMessage,
      }))
      .then(({ success, errorMessage }:{ success:boolean, errorMessage:string }) => {
        if (success) {
          showToast('Alert added', ToastType.SUCCESS);
        } else if (errorMessage) {
          showToast(errorMessage, ToastType.ERROR);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));

    setSelectedPlayers([]);
  }

  function onEditPlayers () {
    setLoading(true);

    Promise.all(selectedPlayers.map((selectedPlayer:Player) => editPlayer(selectedPlayer)))
      .then((results:boolean[]) => {
        const successfulResults = results.filter(Boolean);

        if (successfulResults.length === results.length) {
          showToast(
            <>All selected players are sent to the
              <Link
                className={classes.importPageLink}
                to={`/import${successfulResults.length === 1 ? `#scrollto-${selectedPlayers[0].slug}` : ''}`}
                target='_blank'
              >Import page</Link>
            </>, ToastType.SUCCESS);
        } else if (successfulResults.length < results.length) {
          showToast(
            <>{successfulResults.length}/{results.length} players were successfully sent to the
              <Link
                className={classes.importPageLink}
                to='/import'
                target='_blank'
              >Import page</Link>
            </>, ToastType.INFO);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Yikes! Something went wrong. Try again later or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function editPlayer (player:Player):Promise<boolean> {
    if (!player?.slug) return Promise.resolve(false);

    return gql(`
      mutation {
        editPlayer(slug: "${player.slug}")
      }
    `)
      .then((data:any) => data.editPlayer as boolean);
  }

  function onSelectColumns (selectedColumnNames:string[]) {
    saveColumns(columns.map((column:Column) => ({
      name: column.name,
      selected: selectedColumnNames.includes(column.name),
    } as Column)));
  }

  function onReorderColumns (reorderedColumnNames:string[]) {
    saveColumns(reorderedColumnNames
      .map((columnName:string) => columns.find((column:Column) => column.name === columnName))
      .filter(Boolean) as Column[]
    );
  }

  function resetColumns () {
    saveColumns([]);
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const actionDisabled = !selectedPlayers.length;
  const compareActionDisabled = !selectedPlayers.length || selectedPlayers.length > 1;
  const pdfActionDisabled = !selectedPlayers.length || selectedPlayers.length > 1;

  return (
    <>
      <div
        className={clsx(classes.teamProfilePlayersTable, className)}
        ref={tableRef}
      >
        <Loader inProgress={loading} />

        {team && (
          <div className={classes.header}>
            <div className={classes.titleWrapper}>
              <div className={classes.headerLeftColumn}>
                <div className={classes.title}>
                  <TeamLogo
                    className={classes.logo}
                    team={team}
                  />
                  <div className={classes.teamName}>{team.name} Players</div>
                </div>

                <PlayersAutoComplete
                  className={classes.playersAutoComplete}
                  label={`Search ${team.name} Players`}
                  players={players}
                  onSelect={(player:Player | null) => {
                    if (player && player.slug) {
                      window.open(`/player/${player.slug}?`, '_blank');
                    }
                  }}
                />
              </div>

              <div className={classes.headerRightColumn}>
                <div className={classes.dropdowns}>
                  {isNFL && (
                    <div className={clsx(classes.dropdownWrapper, classes.draftedUdfaDropdown)}>
                      <div className={classes.dropdownLabel}>Drafted &&nbsp;UDFA:</div>

                      <MultiSelect
                        className={clsx(classes.dropdown, classes.multiSelect)}
                        selectorRootClassName={classes.dropdownSelector}
                        items={[
                          {
                            content: 'Drafted',
                            value: NFL_GROUP.DRAFTED,
                          },
                          {
                            content: 'UDFA',
                            value: NFL_GROUP.UDFA,
                          },
                        ]}
                        labelWhenSelectedAll='Both'
                        labelWhenSelectedNone='Select'
                        values={selectedNFLGroups}
                        onChange={(nflGroups:string[]) => setSelectedNFLGroups(nflGroups as NFL_GROUP[])}
                      />
                    </div>
                  )}

                  {isNFL && (
                    <div className={clsx(classes.dropdownWrapper, classes.speedPowerDropdown)}>
                      <div className={classes.dropdownLabel}>Speed/Power Picks:</div>

                      <MultiSelect
                        className={clsx(classes.dropdown, classes.multiSelect)}
                        selectorRootClassName={classes.dropdownSelector}
                        items={[
                          {
                            content: 'Speed',
                            value: NFL_RECRUIT.SPEED,
                          },
                          {
                            content: 'Power',
                            value: NFL_RECRUIT.POWER,
                          },
                        ]}
                        labelWhenSelectedAll='Both'
                        labelWhenSelectedNone='Select'
                        values={selectedNFLRecruits}
                        onChange={(selectedNFLRecruits:string[]) => setSelectedNFLRecruits(selectedNFLRecruits as NFL_RECRUIT[])}
                      />
                    </div>
                  )}

                  {isNFL && (
                    <div className={clsx(classes.dropdownWrapper, classes.yearsDropdown)}>
                      <div className={classes.dropdownLabel}>Years:</div>
                      <MultiSelect
                        className={clsx(classes.dropdown, classes.multiSelect, classes.yearsMultiSelect)}
                        selectorRootClassName={classes.dropdownSelector}
                        items={[
                          ...getNFLYears().map(year => (
                            { content: year, value: year }
                          ))
                        ]}
                        labelWhenSelectedAll='All'
                        labelWhenSelectedNone='None'
                        values={selectedNFLYears}
                        onChange={(nflYears:string[]) => setSelectedNFLYears(nflYears)}
                      />
                    </div>
                  )}

                  {!isNFL && (
                    <div className={classes.dropdownWrapper}>
                      <div className={classes.dropdownLabel}>Roster:</div>
                      <MultiSelect
                        className={clsx(classes.dropdown, classes.multiSelect)}
                        selectorRootClassName={classes.dropdownSelector}
                        items={[
                          {
                            content: 'Current',
                            value: ROSTER_TYPE.CURRENT,
                          },
                          {
                            content: 'Former',
                            value: ROSTER_TYPE.FORMER,
                          },
                        ]}
                        labelWhenSelectedAll='All'
                        labelWhenSelectedNone='None'
                        values={selectedRosters}
                        onChange={(rosters:string[]) => {
                          setSelectedRosters(rosters);
                          setSelectedRecruitingOptions([]);
                        }}
                      />
                    </div>
                  )}

                  {!isNFL && (
                    <div className={classes.dropdownWrapper}>
                      <div className={classes.dropdownLabel}>Recruiting:</div>
                      <MultiSelect
                        className={clsx(classes.dropdown, classes.multiSelect)}
                        selectorRootClassName={classes.dropdownSelector}
                        items={getRecruitingYears()}
                        labelWhenSelectedAll='All Commits'
                        labelWhenSelectedNone='Select Years'
                        values={selectedRecruitingOptions}
                        onChange={(selectedRecruitingOptions:string[]) => {
                          setSelectedRecruitingOptions(selectedRecruitingOptions);
                          setSelectedRosters([]);
                        }}
                      />
                    </div>
                  )}

                  <div className={clsx(classes.dropdownWrapper, classes.limit)}>
                    Show
                    <PageLimitDropdown
                      className={clsx(classes.dropdown, classes.limitDropDown)}
                      selectorRootClassName={classes.dropdownSelector}
                      value={String(limit)}
                      onChange={(limit:string) => setLimit(Number(limit))}
                    />
                    Players
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.actions}>
              <div className={classes.actionsPrimary}>
                <Action
                  className={clsx(classes.action, actionDisabled && classes.disabledAction)}
                  icon={PinIcon}
                  iconClassName={classes.actionIcon}
                  disabled={actionDisabled}
                  onClick={onPin}
                >
                  <span className={classes.actionText}>Pin</span>
                </Action>

                <Action
                  className={clsx(classes.action, pdfActionDisabled && classes.disabledAction)}
                  icon={PDFIcon}
                  iconClassName={classes.actionIcon}
                  disabled={pdfActionDisabled}
                  onClick={() => {
                    setSelectedPlayers([]);
                    window.open(`/player/${selectedPlayers[0].slug}/pdf`, '_blank');
                  }}
                >
                  <span className={classes.actionText}>Report</span>
                </Action>

                {user.accessLevel.canComparePlayers && (
                  <Action
                    className={clsx(classes.action, compareActionDisabled && classes.disabledAction)}
                    icon={CompareIcon}
                    iconClassName={classes.actionIcon}
                    disabled={compareActionDisabled}
                    href={!compareActionDisabled ? `/compare/${selectedPlayers[0].slug}` : '/compare'}
                  >
                    <span className={classes.actionText}>Compare</span>
                  </Action>
                )}

                <Action
                  className={clsx(classes.action, actionDisabled && classes.disabledAction)}
                  icon={SaveIcon}
                  iconClassName={classes.actionIcon}
                  disabled={actionDisabled}
                  onClick={onSave}
                >
                  <span className={classes.actionText}>Save</span>
                </Action>

                {!isNFL && (
                  <Action
                    className={clsx(classes.action, actionDisabled && classes.disabledAction)}
                    icon={AlertIcon}
                    iconClassName={classes.actionIcon}
                    disabled={actionDisabled}
                    onClick={onAlert}
                  >
                    <span className={classes.actionText}>Alert</span>
                  </Action>
                )}

                {isAdmin && (
                  <Action
                    className={clsx(classes.action, actionDisabled && classes.disabledAction)}
                    icon={EditIcon}
                    iconClassName={classes.actionIcon}
                    disabled={actionDisabled}
                    onClick={onEditPlayers}
                  >
                    <span className={classes.actionText}>Edit</span>
                  </Action>
                )}
              </div>

              <div className={classes.actionsSecondary}>
                <AddRemoveColumns
                  className={classes.addRemoveColumns}
                  columns={columns.map((column:Column) => ({
                    content: PLAYER_COLUMN_TITLE[column.name],
                    value: column.name,
                    isDefault: defaultColumns.includes(column.name),
                  }))}
                  selectedColumns={columns
                    .filter((column:Column) => column.selected)
                    .map((column:Column) => column.name)
                  }
                  onSelect={onSelectColumns}
                  onReorder={onReorderColumns}
                  onResetColumnsClick={resetColumns}
                />

                {user.accessLevel.teamPageExport && (
                  <>
                    <Divider
                      className={classes.divider}
                      orientation='vertical'
                      light
                      flexItem
                    />

                    <Download
                      className={classes.downloadXLS}
                      label='XLS'
                      onClick={onXLSDownload}
                    />
                  </>
                )}

                <Divider
                  className={classes.divider}
                  orientation='vertical'
                  light
                  flexItem
                />

                <div className={classes.dropdownWrapper}>
                  <div className={classes.dropdownLabel}>Surface to Top:</div>
                  <SurfaceToTop
                    className={classes.surfaceToTop}
                    displayOnlyNew={!user.accessLevel.hasEvalDotAndNotes}
                    values={surfaceToTopValues}
                    onSelect={(values:string[]) => setSurfaceToTopValues(values)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <PlayersTable
          items={players}
          players={players}
          limit={limit}
          columns={columns
            .filter((column:Column) => column.selected)
            .map((column:Column) => column.name)
          }
          selectedPlayers={selectedPlayers}
          setSelectedPlayers={setSelectedPlayers}
          order={order}
          setOrder={setOrder}
          sortedByColumn={sortedByColumn}
          onSort={onSort}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    currentRecruitingYear: state.configurations.currentRecruitingYear,
    nflDraftYear: state.configurations.nflDraftYear,
    user: state.user,
    isAdmin: state.ui.adminAccess,
    newHSPlayersDays: state.configurations.newHSPlayersDays,
    newCollegePlayersDays: state.configurations.newCollegePlayersDays,
    savedColumns: state.userSettings.teamPlayersColumns,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchUserSettings,
      saveColumns: saveTeamPlayersColumns,
    },
    dispatch,
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamProfilePlayersTable);
