import React, { useState, createRef } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Pagination from '../atoms/Pagination';
import TableHeadCell from '../atoms/TableHeadCell';
import Score, { getScoreValue, SCORE_TYPE } from '../atoms/Score';
import NotAvailable from '../atoms/NotAvailable';
import TeamLogo from '../atoms/TeamLogo';
import LocationIcon from '../atoms/LocationIcon';
import MultiSportList from '../molecules/MultiSportList';
import { inchesToFeetAndInches } from '../services/converter';
import {
  getPrimaryCollege,
  getPrimaryHighSchool,
  getPrimaryNFLTeam,
  getPrimaryPosition,
  PlayerType,
} from '../services/player';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_BLUE,
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_LIGHT_RED,
  COLOR_SHADOW,
  COLOR_WHITE,
} from '../styles/colors';
import ComparePlayer from '../types/ComparePlayer';
import College from '../types/College';
import Team from '../types/Team';
import NFLTeam from '../types/NFLTeam';
import { Order } from '../types/Order';
import Avatar from '../atoms/Avatar';
import Checkbox from '../atoms/Checkbox';
import Player from '../types/Player';
import TableWithDoubleScrollBars from '../molecules/TableWithDoubleScrollBars';

interface ComparePlayersTableProps {
  className?: string;
  items: ComparePlayer[];
  selectedColumns: string[];
  showCollegeWeightHeight: boolean;
  printed?: boolean;
  selectedItems: Player[];
  setPlayers: (players:ComparePlayer[]) => void;
  setSelectedItems: (items:Player[]) => void;
}

export const MAX_COMPARE_PLAYERS_NUMBER = 50;

export enum CompareResultType {
  HIGH_SCHOOL = 'highSchool',
  COLLEGE = 'college',
  NFL = 'nfl',
}

export enum CompareTableColumn {
  RANK = 'rank',
  PERCENT = 'percent',
  HS_CLASS = 'hsClass',
  NAME = 'name',
  HIGH_SCHOOL = 'highschool',
  COLLEGE = 'college',
  POSITION = 'position',
  HEIGHT = 'height',
  COLLEGE_HEIGHT = 'collegeHeight',
  WEIGHT = 'weight',
  COLLEGE_WEIGHT = 'collegeWeight',
  PAI = 'pai',
  COMBINE = 'combine',
  STARS = 'stars',
  HS_SPORTS = 'hsSports',
  NFL_TEAMS = 'NflTeams',
  HS_STATE = 'hsState',
}

export const TABLE_ALL_COLUMNS = {
  [CompareResultType.HIGH_SCHOOL]: [
    CompareTableColumn.RANK,
    CompareTableColumn.PERCENT,
    CompareTableColumn.HS_CLASS,
    CompareTableColumn.NAME,
    CompareTableColumn.HIGH_SCHOOL,
    CompareTableColumn.HS_STATE,
    CompareTableColumn.POSITION,
    CompareTableColumn.HEIGHT,
    CompareTableColumn.WEIGHT,
    CompareTableColumn.PAI,
    CompareTableColumn.COMBINE,
    CompareTableColumn.STARS,
    CompareTableColumn.HS_SPORTS,
  ],
  [CompareResultType.COLLEGE]: [
    CompareTableColumn.RANK,
    CompareTableColumn.PERCENT,
    CompareTableColumn.HS_CLASS,
    CompareTableColumn.NAME,
    CompareTableColumn.HIGH_SCHOOL,
    CompareTableColumn.COLLEGE,
    CompareTableColumn.POSITION,
    CompareTableColumn.HEIGHT,
    CompareTableColumn.WEIGHT,
    CompareTableColumn.PAI,
    CompareTableColumn.COMBINE,
    CompareTableColumn.STARS,
    CompareTableColumn.HS_SPORTS,
  ],
  [CompareResultType.NFL]: [
    CompareTableColumn.RANK,
    CompareTableColumn.PERCENT,
    CompareTableColumn.HS_CLASS,
    CompareTableColumn.NAME,
    CompareTableColumn.HIGH_SCHOOL,
    CompareTableColumn.COLLEGE,
    CompareTableColumn.NFL_TEAMS,
    CompareTableColumn.POSITION,
    CompareTableColumn.HEIGHT,
    CompareTableColumn.WEIGHT,
    CompareTableColumn.PAI,
    CompareTableColumn.COMBINE,
    CompareTableColumn.STARS,
    CompareTableColumn.HS_SPORTS,
  ],
};

export const TABLE_COLUMN_TITLE = {
  [CompareTableColumn.RANK]: 'Rank',
  [CompareTableColumn.PERCENT]: '%',
  [CompareTableColumn.HS_CLASS]: <>HS&nbsp;Class</>,
  [CompareTableColumn.NAME]: 'Name',
  [CompareTableColumn.HIGH_SCHOOL]: <>High&nbsp;School</>,
  [CompareTableColumn.COLLEGE]: 'College',
  [CompareTableColumn.POSITION]: 'Pos.',
  [CompareTableColumn.HEIGHT]: <>HS&nbsp;Ht.</>,
  [CompareTableColumn.COLLEGE_HEIGHT]: <>College&nbsp;Ht.</>,
  [CompareTableColumn.WEIGHT]: <>HS&nbsp;Wt.</>,
  [CompareTableColumn.COLLEGE_WEIGHT]: <>College&nbsp;Wt.</>,
  [CompareTableColumn.PAI]: 'PAI',
  [CompareTableColumn.COMBINE]: 'Com.',
  [CompareTableColumn.STARS]: 'Stars',
  [CompareTableColumn.HS_SPORTS]: <>HS&nbsp;Sports</>,
  [CompareTableColumn.NFL_TEAMS]: <>NFL&nbsp;Team</>,
  [CompareTableColumn.HS_STATE]: <>HS&nbsp;State</>,
};

interface ColumnConfiguration {
  value: CompareTableColumn;
  getTitle: (props?:ComparePlayersTableProps) => any;
  sortable: boolean;
  sticky?: boolean;
  renderContent: (player:ComparePlayer) => any;
  sort: (players:ComparePlayer[], order:Order) => ComparePlayer[];
}

const useStyles = makeStyles<Theme, ComparePlayersTableProps>((theme: Theme) => ({
  comparePlayersTable: {
    border: `1px solid ${COLOR_BORDER}`,
    borderBottom: 'none',
    overflow: 'auto',
  },
  printed: {
    overflow: 'visible',
  },

  playerScore: {
    margin: '0 auto',
  },

  multiSport: {
    height: '24px',
    textAlign: 'center'
  },

  stars: {
    height: '32px',
    alignItems: 'center',
  },

  checkBoxCell: {
    position: 'relative',
    width: '50px',
    minWidth: 0,
    height: '50px',
    padding: '5px 10px',
    textAlign: 'center',
  },

  table: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    background: COLOR_WHITE,
  },

  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,
  },
  tableHeadCell: {
    '&:first-of-type': {
      borderLeft: 'none'
    },
    '&:last-of-type': {
      borderRight: 'none'
    }
  },

  tableRow: {
    minHeight: '55px',
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    },

    '&:first-of-type': {
      background: COLOR_LIGHT_RED,

      '& $rankCell': {
        fontWeight: 700
      }
    },

    '&:last-of-type': {
      borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,
    },
  },
  tableCell: {
    padding: (props) => props.printed ? theme.spacing(1) : theme.spacing(1, 2.5),
    fontSize: (props) => props.printed ? theme.typography.pxToRem(14) : theme.typography.pxToRem(16),
    fontFamily: FONT_PROXIMA_NOVA,
    border: 0,
    whiteSpace: 'nowrap',
  },
  emptyTableCell: {
    backgroundColor: COLOR_LIGHT_GRAY,
  },

  noData: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
    backgroundColor: COLOR_LIGHT_GRAY,
  },

  rankCell: {},
  nameCell: {
    minWidth: (props) => props.printed ? '100px' : '180px',
  },

  actions: {
    minWidth: '56px',
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    marginLeft: theme.spacing(0.5),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  playerNameWithLocationIcon: {
    display: 'flex',
    alignItems: 'center',
  },

  playerAvatar: {
    flexShrink: 0,
    width: '32px',
    height: '32px',
  },

  playerLink: {
    color: COLOR_BLUE,
    fontSize: theme.typography.pxToRem(16),
    paddingLeft: '8px',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    }
  },

  locationIcon: {
    marginLeft: 'auto',
  },

  team: {
    display: 'flex',
  },
  teamLogo: {
    height: '24px',
    weight: '24px',
    marginRight: theme.spacing(1.5),
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    margin: 'auto',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },

  textCenter: {
    textAlign: 'center',
  }
}), { name: ComparePlayersTable.name });

export default function ComparePlayersTable (props:ComparePlayersTableProps) {
  const {
    className,
    items = [],
    selectedColumns,
    printed = false,
    showCollegeWeightHeight = false,
    selectedItems,
    setPlayers,
    setSelectedItems = () => {},
  } = props;
  const classes = useStyles(props);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [sortedByColumn, setSortedByColumn] = useState<string>(CompareTableColumn.RANK);
  const [order, setOrder] = useState<Order | undefined>();

  function renderRow (item:ComparePlayer) {
    const checked = !!selectedItems.find(oneOfSelectedItem => oneOfSelectedItem.id === item.id);

    return (
      <TableRow
        key={item.id}
        className={classes.tableRow}
      >
        <TableCell
          key='check-box-cell'
          className={clsx(classes.tableCell, classes.checkBoxCell)}
        >
          <Checkbox
            checked={checked}
            onChange={onCheckBoxChange(item)}
          />
        </TableCell>

        {tableColumns.map((tableColumn:any, index:number) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.value === CompareTableColumn.NAME && classes.nameCell,
              tableColumn.value === CompareTableColumn.RANK && classes.rankCell
            )}
          >
            {tableColumn.renderContent(item, index)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function onSort (columnName:string, newOrder:Order, sort:(players: ComparePlayer[], order: Order) => ComparePlayer[]) {
    setSortedByColumn(columnName);
    setPlayers(sort(items, newOrder));
  }

  function sortPlayers (
    players: ComparePlayer[],
    order: Order,
    getValue: (players: ComparePlayer) => string | number,
  ) {
    return players.sort((first:ComparePlayer, second:ComparePlayer) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  function onSortByColumn (sort:(players: ComparePlayer[], order:Order) => ComparePlayer[]) {
    return (columnName:string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  const limit = MAX_COMPARE_PLAYERS_NUMBER;
  const totalPages = Math.ceil(items.length / limit);

  const itemsFrom = printed ? 0 : (currentPage - 1) * limit;
  const itemsTo = printed
    ? items.length
    : items.length < limit ? items.length : limit * currentPage;

  const tableColumns:ColumnConfiguration[] = (selectedColumns.map((column:string) => {
    switch (column) {
      case CompareTableColumn.RANK:
        return {
          value: CompareTableColumn.RANK,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.RANK],
          sortable: true,
          renderContent: (player:ComparePlayer) => `#${player.index}`,
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:ComparePlayer) => (player.index)
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.PERCENT:
        return {
          value: CompareTableColumn.PERCENT,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.PERCENT],
          sortable: true,
          renderContent: (player:ComparePlayer) => `${Math.round(player.percentMatch * 100)}%`,
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:ComparePlayer) => Math.round(player.percentMatch * 100)
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.HS_CLASS:
        return {
          value: CompareTableColumn.HS_CLASS,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.HS_CLASS],
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => {
            const primaryHS = getPrimaryHighSchool(comparePlayer);
            return primaryHS?.graduatingClass || <NotAvailable />;
          },
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => {
                const primaryHS = getPrimaryHighSchool(comparePlayer);
                return primaryHS?.graduatingClass || '';
              }
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.NAME:
        return {
          value: CompareTableColumn.NAME,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.NAME],
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => {
            const primaryHS = getPrimaryHighSchool(comparePlayer);

            return (
              <div className={classes.playerNameWithLocationIcon}>
                <Avatar
                  className={classes.playerAvatar}
                  src={comparePlayer.photoUrl}
                  alt='Profile picture'
                />
                <Link
                  className={classes.playerLink}
                  to={`/player/${comparePlayer.slug}?`}
                  target='_blank'
                >
                  {comparePlayer.firstName} {comparePlayer.lastName}
                </Link>

                {primaryHS?.state && (
                  <LocationIcon
                    className={classes.locationIcon}
                    stateCode={primaryHS.state}
                  />
                )}
              </div>
            );
          },
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => `${comparePlayer.lastName} ${comparePlayer.firstName}`
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.HIGH_SCHOOL:
        return {
          value: CompareTableColumn.HIGH_SCHOOL,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.HIGH_SCHOOL],
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => {
            const primaryHS = getPrimaryHighSchool(comparePlayer);
            return primaryHS?.name || <NotAvailable />;
          },
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => {
                const primaryHS = getPrimaryHighSchool(comparePlayer);
                return primaryHS?.name || '';
              }
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.COLLEGE:
        return {
          value: CompareTableColumn.COLLEGE,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.COLLEGE],
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => {
            const primaryCollege:College|undefined = getPrimaryCollege(comparePlayer);
            const primaryCollegeTeam:Team|undefined = primaryCollege?.team;

            return primaryCollegeTeam
              ? <div className={classes.team}>
                  <TeamLogo
                    className={classes.teamLogo}
                    team={primaryCollegeTeam}
                  />
                  {primaryCollegeTeam.shortName}
                </div>
              : <NotAvailable />;
          },
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => {
                const primaryCollege:College|undefined = getPrimaryCollege(comparePlayer);
                const primaryCollegeTeam:Team|undefined = primaryCollege?.team;

                return primaryCollegeTeam?.shortName || '';
              },
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.POSITION:
        return {
          value: CompareTableColumn.POSITION,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.POSITION],
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => {
            return getPrimaryPosition(comparePlayer, PlayerType.HIGH_SCHOOL) || <NotAvailable />;
          },
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => {
                return getPrimaryPosition(comparePlayer, PlayerType.HIGH_SCHOOL) || '';
              },
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.HEIGHT:
        return {
          value: CompareTableColumn.HEIGHT,
          getTitle: () => {
            return showCollegeWeightHeight
              ? TABLE_COLUMN_TITLE[CompareTableColumn.COLLEGE_HEIGHT]
              : TABLE_COLUMN_TITLE[CompareTableColumn.HEIGHT]
          },
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => {
            const dataSource = showCollegeWeightHeight
              ? getPrimaryCollege(comparePlayer)
              : getPrimaryHighSchool(comparePlayer);
            return dataSource?.height
              ? inchesToFeetAndInches(dataSource.height)
              : <NotAvailable />;
          },
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => {
                const dataSource = showCollegeWeightHeight
                  ? getPrimaryCollege(comparePlayer)
                  : getPrimaryHighSchool(comparePlayer);
                return dataSource?.height || 0;
              }
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.WEIGHT:
        return {
          value: CompareTableColumn.WEIGHT,
          getTitle: () => {
            return showCollegeWeightHeight
              ? TABLE_COLUMN_TITLE[CompareTableColumn.COLLEGE_WEIGHT]
              : TABLE_COLUMN_TITLE[CompareTableColumn.WEIGHT]
          },
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => {
            const dataSource = showCollegeWeightHeight
              ? getPrimaryCollege(comparePlayer)
              : getPrimaryHighSchool(comparePlayer);
            return dataSource?.weight ? `${dataSource.weight}lbs` : <NotAvailable />;
          },
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => {
                const dataSource = showCollegeWeightHeight
                  ? getPrimaryCollege(comparePlayer)
                  : getPrimaryHighSchool(comparePlayer);
                return dataSource?.weight || 0;
              },
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.PAI:
        return {
          value: CompareTableColumn.PAI,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.PAI],
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => (
            <Score
              className={classes.playerScore}
              type={SCORE_TYPE.PAI}
              scoreList={comparePlayer.pai}
            />
          ),
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => getScoreValue(comparePlayer.pai) || 0,
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.COMBINE:
        return {
          value: CompareTableColumn.COMBINE,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.COMBINE],
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => (
            <Score
              className={classes.playerScore}
              type={SCORE_TYPE.COMBINE}
              scoreList={comparePlayer.combine}
              combines={comparePlayer.hsCombines}
            />
          ),
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => getScoreValue(comparePlayer.combine, { combines: comparePlayer.hsCombines }) || 0,
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.STARS:
        return {
          value: CompareTableColumn.STARS,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.STARS],
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => {
            const stars = (typeof comparePlayer?._247Star === 'number' || typeof comparePlayer?.compStar === 'number')
              ? comparePlayer?._247Star || comparePlayer?.compStar || 0
              : null;
            return (typeof stars === 'number' ?
              (`${stars}/5`)
              : <NotAvailable />
            )
          },
          sort: (players: ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => comparePlayer?._247Star || comparePlayer?.compStar || 0,
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.HS_SPORTS:
        return {
          value: CompareTableColumn.HS_SPORTS,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.HS_SPORTS],
          sortable: false,
          renderContent: (comparePlayer:ComparePlayer) => {
            const primaryHS = getPrimaryHighSchool(comparePlayer);

            return !!primaryHS?.otherSports?.length
              ? <MultiSportList
                  className={classes.multiSport}
                  list={primaryHS.otherSports}
                />
              : <NotAvailable />;
          },
        } as ColumnConfiguration;
      case CompareTableColumn.NFL_TEAMS:
        return {
          value: CompareTableColumn.NFL_TEAMS,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.NFL_TEAMS],
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => {
            const primaryNFL:NFLTeam|undefined = getPrimaryNFLTeam(comparePlayer);
            const primaryNFLTeam:Team|undefined = primaryNFL?.team;

            return primaryNFLTeam
              ? <div className={classes.team}>
                  <TeamLogo
                    className={classes.teamLogo}
                    team={primaryNFLTeam}
                  />
                  {primaryNFLTeam.shortName || ''}
                </div>
              : <NotAvailable />;
          },
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => {
                const primaryNFL:NFLTeam|undefined = getPrimaryNFLTeam(comparePlayer);
                const primaryNFLTeam:Team|undefined = primaryNFL?.team;

                return primaryNFLTeam?.shortName || '';
              },
            );
          },
        } as ColumnConfiguration;
      case CompareTableColumn.HS_STATE:
        return {
          value: CompareTableColumn.HS_STATE,
          getTitle: () => TABLE_COLUMN_TITLE[CompareTableColumn.HS_STATE],
          sortable: true,
          renderContent: (comparePlayer:ComparePlayer) => {
            const primaryHS = getPrimaryHighSchool(comparePlayer);
            return primaryHS?.state || <NotAvailable />;
          },
          sort: (players:ComparePlayer[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (comparePlayer:ComparePlayer) => {
                const primaryHS = getPrimaryHighSchool(comparePlayer);
                return primaryHS?.state || '';
              },
            );
          },
        } as ColumnConfiguration;
      default:
        return null;
    }
  }))
    .filter(Boolean) as ColumnConfiguration[];

  const stickyRefs:any[] = [
    createRef(), // for the checkbox column
    ...tableColumns.map((column:ColumnConfiguration) => column.sticky ? createRef() : undefined),
  ];

  function onCheckBoxChange (checkedItem:Player) {
    return (value:boolean) => {
      if (value) {
        setSelectedItems([...selectedItems, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedItems];
        const removeIndex = selectedItems.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setSelectedItems(selectedItemsWithoutCheckedItem);
      }
    };
  }

  return (
    <>
    <TableWithDoubleScrollBars tableClassName={classes.comparePlayersTable}>
      <div
        className={clsx(
          className,
          classes.comparePlayersTable,
          printed && classes.printed,
        )}
      >
        <Table className={clsx(classes.table, className)}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableHeadCell
                key='check-box-cell'
                className={classes.checkBoxCell}
                // ref={sticky ? undefined : stickyRefs[0]}
              >
                <Checkbox
                  checked={items.length === selectedItems.length}
                  onChange={() => items.length === selectedItems.length
                    ? setSelectedItems([])
                    : setSelectedItems([...items])
                  }
                />
              </TableHeadCell>
              {tableColumns.map((tableColumn:ColumnConfiguration) => (
                <TableHeadCell
                  className={classes.tableHeadCell}
                  key={tableColumn.value}
                  name={tableColumn.sortable ? tableColumn.value : undefined}
                  sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                  order={tableColumn.sortable ? order : undefined}
                  onSort={tableColumn.sortable ? onSortByColumn(tableColumn.sort || ((players: ComparePlayer[]) => players)) : undefined}
                >
                  {tableColumn.getTitle(props)}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!items.length && (
              <TableRow
                key='empty'
                className={classes.tableRow}
              >
                <TableCell
                  className={clsx(classes.tableCell, classes.emptyTableCell)}
                  colSpan={tableColumns.length + 2}
                >
                  <div className={classes.noData}>
                    No results
                  </div>
                </TableCell>
              </TableRow>
            )}

            {items
              .slice(itemsFrom, itemsTo)
              .map(renderRow)
            }
          </TableBody>
        </Table>
      </div>
      </TableWithDoubleScrollBars>
      {(totalPages > 1 && !printed) && (
        <div className={classes.paginationWrapper}>
          <span className={classes.paginationText}>
            Showing {itemsFrom + 1} to {itemsTo} of {items.length}
          </span>

          <Pagination
            className={classes.pagination}
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        </div>
      )}
    </>
  );
}
