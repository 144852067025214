import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DropDown, { DropDownItem } from '../atoms/DropDown';
import Toggle from '../atoms/Toggle';
import Avatar from '../atoms/Avatar';
import CFCLogo from '../atoms/CFCLogo';
import EditButton from '../atoms/EditButton';
import Toast, { ToastType } from '../atoms/Toast';
import LastUpdated from '../molecules/LastUpdated';
import PlayerProfileHeader from '../organisms/PlayerProfileHeader';
import PlayerProfileHighSchoolOverview from '../organisms/PlayerProfileHighSchoolOverview';
import PlayerProfileCollegeOverview from '../organisms/PlayerProfileCollegeOverview';
import PlayerProfileNFLOverview from '../organisms/PlayerProfileNFLOverview';
import PlayerTrackAndFieldRating from '../organisms/PlayerTrackAndFieldRating';
import PlayerInGameRating from '../organisms/PlayerInGameRating';
import PlayerCombineRating from '../organisms/PlayerCombineRating';
import PlayerProCombineRating from '../organisms/PlayerProCombineRating';
import PlayerProfileAbout from '../organisms/PlayerProfileAbout';
import PlayerProfileNotes from '../organisms/PlayerProfileNotes';
import PlayerProfileStats from '../organisms/PlayerProfileStats';
import PlayerProfileTopPlayers from '../organisms/PlayerProfileTopPlayers';
import PlayerProfilePrintable from '../organisms/PlayerProfilePrintable';
import PageContainer from './PageContainer';
import PdfPageContainer from './PdfPageContainer';
import gql from '../services/gql';
import { updatePageTitle } from '../services/page-title';
import { getPrimaryHighSchool } from '../services/player';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { getQueryParameter } from '../services/query-parameters';
import { State } from '../redux/reducers';
import { setSelectedCollegeTeam, setSelectedNFLTeam } from '../redux/dispatchers/ui';
import { fetchDivITeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import { setNFLAccessAction } from '../redux/actions/ui';
import { COLOR_BLUE, COLOR_DARK_GRAY, COLOR_SHADOW, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import User from '../types/User';
import Player from '../types/Player';
import Team from '../types/Team';
import Position from '../types/Position';
import HighSchool from '../types/HighSchool';
import College from '../types/College';
import NFLTeam from '../types/NFLTeam';
import ContactInfoWithPopover from '../organisms/ContactInfoWithPopover';
import { datetimeToDate } from '../services/converter';

interface PlayerPageTemplateProps {
  user?: User;
  nflAccess?: boolean;
  adminAccess?: boolean;
  printed?: boolean;
  divITeams: Team[];
  divITeamsLoading: boolean;
  nflTeams: Team[];
  nflTeamsLoading: boolean;
  selectedCollegeTeam: Team | undefined;
  selectedNFLTeam: Team | undefined;
  setSelectedCollegeTeam: (selectedCollegeTeam:Team | undefined) => void;
  setSelectedNFLTeam: (selectedNFLTeam:Team | undefined) => void;
  fetchDivITeams: () => void;
  fetchNFLTeams: () => void;
  setNFLAccessAction: (isNFL:boolean) => void;
}

export const TEAM_TYPE = {
  HIGH_SCHOOL: 'High School',
  COLLEGE: 'College',
  NFL: 'NFL',
};

export enum PlayerPDFQueryParameter {
  PROFILE_TYPE = 'profile-type',
  SELECTED_COLLEGE_TEAM_ID = 'selected-college-team-id',
  SELECTED_NFL_TEAM_ID = 'selected-nfl-team-id',
  SELECTED_POSITION = 'selected-position',
  SELECTED_TEAM_TYPE = 'selected-team-type',
}

const useStyles = makeStyles(theme => ({
  playerPageTemplate: {
    fontFamily: FONT_PROXIMA_NOVA,
  },
  printed: {
    display: 'none',
  },

  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  content: {
    position: 'relative',
  },

  lastUpdated: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: theme.spacing(6),
    right: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
  },

  createdeAt: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(9.8),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
  },

  contactInfoWithPopover: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: theme.spacing(5),
    right: theme.spacing(30),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
  },

  gridContainer: {
    paddingTop: '100px',
    paddingRight: theme.spacing(5),
  },
  gridContainerColumn: {
    paddingLeft: theme.spacing(5),
  },

  playerProfileStatsWrapper: {},

  panel: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },

  avatarContainer: {
    position: 'absolute',
    zIndex: 1001,
    left: '68px',
    top: '-64px',
  },
  avatar: {
    width: '128px',
    height: '128px',
    position: 'relative',
  },
  editAction: {
    position: 'absolute',
    color: COLOR_BLUE,
    bottom: '5px',
    right: '-5px',
    background: COLOR_WHITE,
    zIndex: 10000,
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_SHADOW}`,
    cursor: 'pointer',
  },

  playerIntro: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  playerName: {
    margin: theme.spacing(0, 2, 1, 0),
    ...theme.typography.h2,
    fontSize: theme.typography.pxToRem(30),
    color: COLOR_TEXT,
  },

  cfcLogo: {
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },

  switch: {
    flexShrink: 0,
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },

  educationDropDown: {
    flexShrink: 0,
    marginBottom: theme.spacing(1),
  },
  educationDropDownSelectorRoot: {
    boxSizing: 'border-box',
    paddingRight: theme.spacing(5),
    minHeight: '40px',
  },

  box: {
    marginTop: theme.spacing(4),

    '&:first-of-type': {
      marginTop: 0,
    },
  },

  topPlayers: {
    width: 'calc(100% - 80px)',
    margin: '40px',
    border: 0,
    boxShadow: 'none',
  },

  '@media (max-width: 1680px)': {
    avatarContainer: {
      left: '40px',
    },
  },

  '@media (max-width: 1260px)': {
    gridContainer: {
      flexDirection: 'column',
    },
    gridContainerColumn: {
      flexBasis: '100%',
      maxWidth: '100%',
    },

    avatarContainer: {
      top: '-40px',
    },

    playerProfileStatsWrapper: {
      marginTop: theme.spacing(4),
    },
  },

  '@media (max-width: 1279px)': {
    lastUpdated: {
      top: '10px',
      right: 'inherit',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(0,2),
    },

    gridContainer: {
      paddingTop: theme.spacing(6),
    },

    panel: {
      justifyContent: 'center',
      margin: theme.spacing(2,0),
    },

    playerIntro: {
      display: 'none',
    },
  },

  [MEDIA.MOBILE]: {
    switch: {
      margin: theme.spacing(1,3),
    },

    educationDropDown: {
      margin: theme.spacing(1,3),
    },

    gridContainer: {
      paddingRight: theme.spacing(2),
    },
    gridContainerColumn: {
      marginTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),

      '&:first-of-type': {
        marginTop: 0,
      },
    },

    topPlayers: {
      width: '100%',
      margin: theme.spacing(2,0,0,0),
      padding: theme.spacing(2),
    },
  },
  createdeAtInfo: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(9.2),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
  }
}), { name: PlayerPageTemplate.name });

function PlayerPageTemplate (props:PlayerPageTemplateProps) {
  const {
    user,
    nflAccess = false,
    adminAccess = false,
    printed = false,
    divITeams,
    divITeamsLoading,
    nflTeams,
    nflTeamsLoading,
    selectedCollegeTeam,
    selectedNFLTeam,
    setSelectedCollegeTeam,
    setSelectedNFLTeam,
    fetchDivITeams,
    fetchNFLTeams,
    setNFLAccessAction,
  } = props;
  const classes = useStyles();
  const { slug } = useParams<any>();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);

  const [player, setPlayer] = useState<Player>();
  const [positions, setPositions] = useState<Position[]>([]);
  const [selectedPosition, setSelectedPosition] = useState<Position>();
  const [selectedTeamType, setSelectedTeamType] = useState<string>(TEAM_TYPE.HIGH_SCHOOL);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  const userProfileTypeFromQueryParameters:string = getQueryParameter(PlayerPDFQueryParameter.PROFILE_TYPE); // nfl | college
  const selectedCollegeTeamIdFromQueryParameters:number = Number(getQueryParameter(PlayerPDFQueryParameter.SELECTED_COLLEGE_TEAM_ID) || 0);
  const selectedNFLTeamIdFromQueryParameters:number = Number(getQueryParameter(PlayerPDFQueryParameter.SELECTED_NFL_TEAM_ID) || 0);
  const selectedPositionFromQueryParameters:string = getQueryParameter(PlayerPDFQueryParameter.SELECTED_POSITION).toUpperCase();
  const selectedTeamTypeFromQueryParameters:string = getQueryParameter(PlayerPDFQueryParameter.SELECTED_TEAM_TYPE); // high-school, college, nfl

  useEffect(() => {
    fetchPlayer(slug);
    fetchDivITeams();
  }, [slug]);

  useEffect(() => {
    if (nflAccess && !nflTeams.length) {
      fetchNFLTeams();
    }
  }, [nflAccess]);

  useEffect(() => {
    if (player) {
      updatePositions(player);
    }
  }, [player, selectedTeamType, selectedPositionFromQueryParameters]);

  useEffect(() => {
    if (printed && user?.id && userProfileTypeFromQueryParameters) {
      if (userProfileTypeFromQueryParameters === 'nfl') {
        setNFLAccessAction(true);
      } else if (userProfileTypeFromQueryParameters === 'college') {
        setNFLAccessAction(false);
      }
    }
  }, [printed, user?.id, userProfileTypeFromQueryParameters]);

  useEffect(() => {
    if (printed && selectedCollegeTeamIdFromQueryParameters && divITeams?.length) {
      setSelectedCollegeTeam(divITeams
        .find((team:Team) => team.id === selectedCollegeTeamIdFromQueryParameters)
      );
    } else if (user && Object.keys(user).length > 0 && divITeams?.length) {
      let defaultCollegeTeamId = user?.collegeTeam?.id;

      if (selectedCollegeTeam?.id) {
        defaultCollegeTeamId = selectedCollegeTeam.id;
      } else {
        const selectedCollegeTeamFromClientStorage = clientStorage.get(StorageKey.SELECTED_COLLEGE_TEAM);

        if (selectedCollegeTeamFromClientStorage?.id) {
          defaultCollegeTeamId = selectedCollegeTeamFromClientStorage.id;
        }
      }

      const defaultCollegeTeam = defaultCollegeTeamId
        ? divITeams.find((team:Team) => team.id === defaultCollegeTeamId)
        : divITeams[0];

      setSelectedCollegeTeam(defaultCollegeTeam);
    }
  }, [
    user,
    divITeams?.length,
    selectedCollegeTeam,
    printed,
    selectedCollegeTeamIdFromQueryParameters,
  ]);

  useEffect(() => {
    if (printed && selectedNFLTeamIdFromQueryParameters && nflTeams?.length) {
      setSelectedNFLTeam(nflTeams.find((team:Team) => team.id === selectedNFLTeamIdFromQueryParameters));
    } else if (user && Object.keys(user).length > 0 && nflTeams?.length) {
      let defaultNFLTeamId = user?.nflTeam?.id;

      if (selectedNFLTeam?.id) {
        defaultNFLTeamId = selectedNFLTeam.id;
      } else {
        const selectedNFLTeamFromClientStorage = clientStorage.get(StorageKey.SELECTED_NFL_TEAM);

        if (selectedNFLTeamFromClientStorage?.id) {
          defaultNFLTeamId = selectedNFLTeamFromClientStorage.id;
        }
      }

      const defaultNFLTeam = defaultNFLTeamId
        ? nflTeams.find((team:Team) => team.id === defaultNFLTeamId)
        : nflTeams[0];

      setSelectedNFLTeam(defaultNFLTeam);
    }
  }, [
    user,
    nflTeams?.length,
    selectedNFLTeam?.id,
    printed,
    selectedNFLTeamIdFromQueryParameters,
  ]);

  useEffect(() => {
    if (player && selectedCollegeTeam?.id) {
      fetchTeam(selectedCollegeTeam.id)
        .then((team:void | Team) => {
          if (team) {
            setSelectedCollegeTeam(team);
          }
        });
    }
  }, [player, selectedCollegeTeam?.id]);

  useEffect(() => {
    if (player && selectedNFLTeam?.id && nflAccess) {
      fetchTeam(selectedNFLTeam.id)
        .then((team:void | Team) => {
          if (team) {
            setSelectedNFLTeam(team);
          }
        });
    }
  }, [player, selectedNFLTeam?.id, nflAccess]);

  useEffect(() => {
    if (player) {
      const primaryHS:HighSchool = (player.highSchools || []).find((highSchool:HighSchool) => highSchool && highSchool.isPrimary) || {} as HighSchool;
      const hsState = primaryHS && primaryHS.state ? primaryHS.state : '';
      const title = `${player.firstName} ${player.lastName}${hsState ? ` from ${hsState}` : ''}`;

      updatePageTitle(title);
    }
  }, [player]);

  useEffect(() => {
    if (player && !printed) {
      const playerHasColleges = player && player.playerColleges && player.playerColleges.length > 0;
      const playerHasNFLTeams = player && player.playerNFLTeams && player.playerNFLTeams.length > 0;

      let selectedTeamType = TEAM_TYPE.HIGH_SCHOOL;
      if (playerHasColleges) {
        selectedTeamType = TEAM_TYPE.COLLEGE;
      }

      if (playerHasNFLTeams) {
        selectedTeamType = nflAccess ? TEAM_TYPE.NFL : TEAM_TYPE.HIGH_SCHOOL;
      }

      setSelectedTeamType(selectedTeamType);
    } else if (player && printed && selectedTeamTypeFromQueryParameters) {
      let selectedTeamType = TEAM_TYPE.HIGH_SCHOOL;

      if (selectedTeamTypeFromQueryParameters === 'college') {
        selectedTeamType = TEAM_TYPE.COLLEGE;
      } else if (selectedTeamTypeFromQueryParameters === 'nfl') {
        selectedTeamType = TEAM_TYPE.NFL;
      }

      setSelectedTeamType(selectedTeamType);
    }
  }, [player, nflAccess, printed, selectedTeamTypeFromQueryParameters]);

  function fetchPlayer(slug:string) {
    if (!loading) setLoading(true);

    gql(`
      mutation {
        viewPlayer (slug: "${slug}") {
          id
          slug
          firstName
          lastName
          photoUrl
          dob
          gpa
          sat
          act
          compStar
          _247Star
          _247Comp
          pai
          combine
          ppi
          nflCombineScore
          highSchools
          multiSport

          linkMilesplit
          linkMaxpreps
          link247
          linkRivals
          linkAthleticNet
          linkPff
          linkOn3
          linkSSA
          linkInstagram
          linkDirectAthletics
          linkTwitter
          linkHudl
          linkTrackingFootball

          offers
          about
          aTierCalculated
          bTierCalculated
          combineSpeedTier
          combinePowerTier
          combineAgilityTier
          trackFieldRatings
          combineRatings
          proCombineRatings
          allAmerican
          allConference
          allPro
          proBowl
          watchList
          committedToTeamId
          paiHasFreshmanData
          combineHasFreshmanData
          isArchived
          lastPublishedDate
          createdAt
          updatedAt
          ourladsId
          ryzerUrl
          miscUrl
          discovery
          cellPhone
          email
          address
          cityStateZip
          parentNames
          parentContact
          headCoach
          headCoachContact
          igaScore
          reelMPH
          reelMPHPerc
          reelPosMPH
          reelPosMPHPerc
          depthChart
          ryzerId
          pffUrl
          transferStatus
          transferYear
          playerColleges {
            id
            isPrimary
            isFormer
            recruitingClass
            positions
            height
            weight
            jerseyNumber
            url
            team {
              id
              name
              slug
              logo247
              logoESPN
              logoAlt
              city
              state
            }
          }
          playerNFLTeams {
            teamId
            isPrimary
            draftYear
            positions
            draftRound
            draftPick
            height
            weight
            team {
              name
              slug
              logo247
              logoESPN
              logoAlt
              city
              state
            }
          }
          hsCombines {
            id
            combineDate
            wingspan
            arm
            hand
            height
            weight
            teamCamp
          }
          nflCombines {
            id
            combineDate
            height
            weight
            wingspan
            arm
            hand
          }
          notes {
            text
            colorCode
          }
        }
      }
    `)
      .then((data:any) => ({
        player: data.viewPlayer as Player,
        errorMessage: data.errorMessage,
      }))
      .then(({ player, errorMessage }:{ player:Player, errorMessage:string }) => {
        if (player) {
          setPlayer(player);
        } else if (errorMessage === '403 Forbidden') {
          history.push(`/dashboard?no-access=${history.location.pathname}`);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchTeam (teamId:number) {
    if (!loading) setLoading(true);

    return gql(`
      team (id: ${teamId}) {
        id
        type
        slug
        name
        logo247
        logoESPN
        logoAlt
        averagePAI
        pai
        combine
        proCombine
        ppi
        conference {
          iconUrl
        }
      }
    `)
      .then((data:any) => data.team as Team)
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  function updatePositions (player:Player) {
    const primarySchool = (player.highSchools || [])
      .find((highSchool:HighSchool) => highSchool && highSchool.isPrimary);
    const schoolPositions = (primarySchool || {}).positions || [];

    let positionsForEducation = schoolPositions;
    if (selectedTeamType === TEAM_TYPE.COLLEGE) {
      const primaryCollege = (player.playerColleges || [])
        .find((college:College) => college && college.isPrimary);
      const collegePositions = (primaryCollege || {}).positions || [];

      positionsForEducation = collegePositions;
    } else if (selectedTeamType === TEAM_TYPE.NFL) {
      const primaryNFLTeam = (player.playerNFLTeams || [])
        .find((nflTeam:NFLTeam) => nflTeam && nflTeam.isPrimary);
      const nflPositions = (primaryNFLTeam || {}).positions || [];

      positionsForEducation = nflPositions;
    }

    const positions:Position[] = positionsForEducation
      .filter((position:any) => position?.code)
      .sort((first:any, second:any) => {
        if (first.isPrimary) return -1;
        if (second.isPrimary) return 1;
        return 0;
      })
      .map((position:any) => position.code);

    setPositions(positions);
    setSelectedPosition(selectedPositionFromQueryParameters as Position || positions[0]);
  }

  function onEditPlayer () {
    if (!player || !player.slug) return;

    setLoading(true);

    gql(`
      mutation {
        editPlayer(slug: "${player.slug}")
      }
    `)
      .then((data:any) => data.editPlayer as boolean)
      .then((success:boolean) => {
        if (success) {
          history.push(`/import#scrollto-${player.slug}`);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setLoading(false));
  }

  function showToast(message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const playerHasHighSchools = player && player.highSchools && player.highSchools.length > 0;
  const playerHasColleges = player && player.playerColleges && player.playerColleges.length > 0;
  const playerHasNFLTeams = player && player.playerNFLTeams && player.playerNFLTeams.length > 0;

  const primaryHighSchool = getPrimaryHighSchool(player);

  const trackFieldRating = (!!player?.trackFieldRatings?.length && selectedPosition)
    ? player.trackFieldRatings.find(rating => rating.positionCode === selectedPosition)
    : undefined;
  const combineRatings = (!!player?.combineRatings?.length && selectedPosition)
    ? player.combineRatings.filter(rating => rating.positionCode === selectedPosition)
    : undefined;
  const proCombineRatings = (nflAccess && !!player?.proCombineRatings?.length && selectedPosition)
    ? player.proCombineRatings.filter(rating => rating.positionCode === selectedPosition)
    : undefined;
  const inGameRating = {
    reelMPH: player && player.reelMPH || 0,
    reelMPHPerc: player && player.reelMPHPerc || 0,
    reelPosMPH: player && player.reelPosMPH || 0,
    reelPosMPHPerc: player && player.reelPosMPHPerc || 0,
    igaScore: player && player.igaScore || 0,
   }
  const isContactInfoAvailable = player && (player?.email || player?.cellPhone || player?.address)

  return (
    <>
      {!printed && (
        <PageContainer
          className={clsx(
            classes.playerPageTemplate,
            printed && classes.printed,
          )}
        >
          <Paper className={classes.paper}>
            <PlayerProfileHeader
              loading={loading || divITeamsLoading || nflTeamsLoading}
              user={user}
              player={player}
              divITeams={divITeams}
              nflTeams={nflTeams}
              selectedTeamType={selectedTeamType}
              selectedCollegeTeam={selectedCollegeTeam}
              selectedNFLTeam={selectedNFLTeam}
              selectedPosition={selectedPosition}
              onCollegeTeamSelected={(collegeTeam:Team | null) => {
                if (collegeTeam) {
                  setSelectedCollegeTeam(collegeTeam);
                }
              }}
              onNFLTeamSelected={(nflTeam:Team | null) => {
                if (nflTeam) {
                  setSelectedNFLTeam(nflTeam);
                }
              }}
              setLoading={setLoading}
              showToast={showToast}
              onEditPlayer={onEditPlayer}
              isNFL={nflAccess}
              isAdmin={adminAccess}
            />

            <div className={classes.content}>
              {
                player && (
                  <ContactInfoWithPopover
                    className={classes.contactInfoWithPopover}
                    name={'Contact Info'}
                    player={player}
                  />
                )
              }
              {
                adminAccess && player && player.createdAt &&
                <div className={!!isContactInfoAvailable ? classes.createdeAtInfo : classes.createdeAt}
                >Created Date: {datetimeToDate(player.createdAt) }</div>
              }
              {player && (
                <>
                  {player.lastPublishedDate && (
                    <LastUpdated
                      className={classes.lastUpdated}
                      playerId={player.id}
                      datetime={player.lastPublishedDate}
                    />
                  )}

                  <Grid
                    className={classes.gridContainer}
                    container
                  >
                    <Grid
                      className={classes.gridContainerColumn}
                      item
                      xs={6}
                    >
                      <div className={classes.panel}>
                        <div className={classes.playerIntro}>
                          <div className={classes.avatarContainer}>
                            <Avatar
                              className={classes.avatar}
                              src={player.photoUrl}
                              initials={`${(player.firstName || ' ')[0]}${(player.lastName || ' ')[0]}`.trim()}
                              alt='Profile picture'
                            />

                            {adminAccess && (
                              <EditButton
                                className={classes.editAction}
                                onClick={onEditPlayer}
                              />
                            )}
                          </div>

                          <h1 className={classes.playerName}>
                            {player.firstName} {player.lastName}
                          </h1>

                          {primaryHighSchool?.state && (
                            <CFCLogo
                              className={classes.cfcLogo}
                              state={primaryHighSchool.state}
                            />
                          )}
                        </div>

                        <Toggle
                          className={classes.switch}
                          items={positions.map((position: Position) => ({
                            value: position,
                            content: position,
                          }))}
                          value={String(selectedPosition)}
                          onSelect={(value:string) => setSelectedPosition(value as Position)}
                        />

                        <DropDown
                          className={classes.educationDropDown}
                          selectorRootClassName={classes.educationDropDownSelectorRoot}
                          items={[
                            (playerHasHighSchools || playerHasColleges || playerHasNFLTeams) && { content: 'High School', value: TEAM_TYPE.HIGH_SCHOOL },
                            (playerHasColleges || playerHasNFLTeams) && { content: 'College', value: TEAM_TYPE.COLLEGE },
                            playerHasNFLTeams && { content: 'NFL', value: TEAM_TYPE.NFL }
                          ].filter(Boolean) as DropDownItem[]}
                          value={selectedTeamType}
                          onChange={(value:string) => setSelectedTeamType(value)}
                        />
                      </div>

                      {selectedTeamType === TEAM_TYPE.HIGH_SCHOOL && (
                        <PlayerProfileHighSchoolOverview
                          className={classes.box}
                          player={player}
                          selectedPosition={selectedPosition}
                        />
                      )}

                      {selectedTeamType === TEAM_TYPE.COLLEGE && (
                        <PlayerProfileCollegeOverview
                          className={classes.box}
                          player={player}
                          selectedPosition={selectedPosition}
                          user={user}
                        />
                      )}

                      {selectedTeamType === TEAM_TYPE.NFL && (
                        <PlayerProfileNFLOverview
                          className={classes.box}
                          player={player}
                          selectedPosition={selectedPosition}
                          user={user}
                        />
                      )}

                      <PlayerTrackAndFieldRating
                        className={classes.box}
                        trackFieldRating={trackFieldRating}
                        player={player}
                        isNFL={nflAccess}
                      />

                      <PlayerCombineRating
                        className={classes.box}
                        combineRatings={combineRatings}
                        player={player}
                        isNFL={nflAccess}
                      />

                      <PlayerProCombineRating
                        className={classes.box}
                        proCombineRatings={proCombineRatings}
                      />

                      {player.about && (
                        <PlayerProfileAbout
                          className={classes.box}
                          about={player.about}
                        />
                      )}

                      <PlayerInGameRating
                        className={classes.box}
                        inGameRating={inGameRating}
                        isNFL={nflAccess}
                      />

                      {(user && user.accessLevel && user.accessLevel.hasEvalDotAndNotes) && (
                        <PlayerProfileNotes
                          className={classes.box}
                          notes={player.notes}
                          playerId={player.id}
                        />
                      )}
                    </Grid>

                    <Grid
                      className={clsx(classes.gridContainerColumn, classes.playerProfileStatsWrapper)}
                      item
                      xs={6}
                    >
                      <PlayerProfileStats
                        player={player}
                        collegeTeam={selectedCollegeTeam}
                        collegeTeamId={selectedCollegeTeam?.id}
                        nflTeam={selectedNFLTeam}
                        nflTeamId={selectedNFLTeam?.id}
                        position={selectedPosition}
                        isNFL={nflAccess}
                      />
                    </Grid>
                  </Grid>

                  <PlayerProfileTopPlayers
                    className={classes.topPlayers}
                    currentPlayer={player}
                    user={user}
                  />
                </>
              )}
            </div>
          </Paper>
        </PageContainer>
      )}

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>

      {printed && (
        <PdfPageContainer>
          <PlayerProfilePrintable
            player={player}
            user={user}
            selectedCollegeTeam={selectedCollegeTeam}
            selectedNFLTeam={selectedNFLTeam}
            selectedPosition={selectedPosition}
            selectedTeamType={selectedTeamType}
            trackFieldRating={trackFieldRating}
            combineRatings={combineRatings}
            proCombineRatings={proCombineRatings}
            isNFL={nflAccess}
          />
        </PdfPageContainer>
      )}
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
    nflAccess: state.ui.nflAccess,
    adminAccess: state.ui.adminAccess,
    divITeams: state.teams.divITeams,
    divITeamsLoading: state.teams.divITeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
    selectedCollegeTeam: state.ui.selectedCollegeTeam,
    selectedNFLTeam: state.ui.selectedNFLTeam,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchDivITeams,
      fetchNFLTeams,
      setNFLAccessAction,
      setSelectedCollegeTeam,
      setSelectedNFLTeam,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayerPageTemplate);
//
