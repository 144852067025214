import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AddProgramIcon from '../icons/AddProgramIcon'
import EditProgramIcon from '../icons/EditProgramIcon';
import Switch from '../atoms/Switch';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import AddEditProgramForm from '../organisms/AddEditProgramForm';
import PageContainer from './PageContainer';
import gql from '../services/gql';
import { State } from '../redux/reducers';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import { COLOR_TEXT } from '../styles/colors';
import MEDIA from '../styles/media';
import Program from '../types/Program';
import User from '../types/User';

interface AddProgramPageTemplateProps {
  user?: User;
  collegeTeamsLoading: boolean;
  nflTeamsLoading: boolean;
}

const useStyles = makeStyles(theme => ({
  addProgramPageTemplate: {
    minHeight: '100vh',
    position: 'relative',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(5, 5, 0),
  },
  headerTitle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1,
    color: COLOR_TEXT,
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },
  switchWrapper: {
    display: 'flex',
    marginLeft: theme.spacing(8),
    marginRight: 'auto',
  },
  switchValue: {
    color: COLOR_TEXT,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    fontFamily: FONT_PROXIMA_NOVA,
  },
  addProgram: {
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(5),
  },

  [MEDIA.MOBILE]: {
    header: {
      display: 'block',
      margin: theme.spacing(2, 2, 0),
    },

    switchWrapper: {
      margin: theme.spacing(2, 0, 0),
    },

    addProgram: {
      padding: theme.spacing(2),
    }
  },
}), { name: AddProgramPageTemplate.name });

function AddProgramPageTemplate (props:AddProgramPageTemplateProps) {
  const classes = useStyles();
  const {
    user,
    collegeTeamsLoading,
    nflTeamsLoading,
  } = props;

  const history = useHistory();
  const { programId } = useParams<any>();

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [program, setProgram] = useState<Program>();
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');

  useEffect(() => {
    if (user && user.accessLevel) {
      if (!user.accessLevel.canManageUsers) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [user]);

  useEffect(() => {
    if (history.location.pathname.startsWith('/manage/program/edit')) {
      setIsEditMode(true);
      fetchProgramById();
    }
  }, []);

  useEffect(() => {
    if (isEditMode && program) {
      setIsEnabled(program.isEnabled);
    }
  }, [isEditMode, program]);

  function fetchProgramById () {
    gql(`
      program (id: ${programId}) {
        id
        name
        accessLevelId
        collegeId
        companyId
        nflTeamId
        startDate
        expirationDate
        apiKey
        standardApiAccess
        ncaaIdApiAccess
        apiAccessCurrentCollegePlayers
        apiAccessDraftEligiblePlayers
        apiAccessNFLPlayers
        apiAccessNFLStartYear
        apiAccessHistoricCollegePlayers
        apiAccessHistoricCollegePlayersStartYear
        apiAccessSpeedPowerOnly
        autoDepthChartEnabled
        invoice
        isEnabled
        isEmailDisabled
        hasTransferPortal
        hasDraftProjector
        createdAt
        updatedAt
        programTeamId
        enableWarRoomResponse
      }
    `)
      .then((data:any) => data.program as Program)
      .then((program:Program) => program && setProgram(program))
      .catch(() => {
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      });
  }

  function showToast (message:string, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const loading = pageLoading || collegeTeamsLoading || nflTeamsLoading;

  return (
    <PageContainer className={classes.addProgramPageTemplate}>
      <Loader inProgress={loading} />

      <Paper className={classes.paper}>
        <div className={classes.header}>
          <h1 className={classes.headerTitle}>
            {isEditMode ?
              <EditProgramIcon className={classes.headerIcon} />
            :
              <AddProgramIcon className={classes.headerIcon} />
            }
            {isEditMode ? 'Edit Program' : 'Add Program'}
          </h1>

          <div className={classes.switchWrapper}>
            <Switch
              checked={isEnabled}
              onChange={() => {setIsEnabled(!isEnabled)}}
            />

            <span className={classes.switchValue}>
              Program Status
              <strong>{isEnabled ? 'Enabled' : 'Disabled'}</strong>
            </span>
          </div>
        </div>

        <AddEditProgramForm
          className={classes.addProgram}
          program={program}
          isEnabled={isEnabled}
          isEditMode={isEditMode}
          loading={loading}
          setLoading={setPageLoading}
        />
      </Paper>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </PageContainer>
  )
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    nflTeamsLoading: state.teams.nflTeamsLoading,
  };
};

export default connect(
  mapStateToProps,
)(AddProgramPageTemplate);
