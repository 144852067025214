import React from 'react';
import Icon from '../atoms/Icon';

function RedCircle (props:any, ref:React.Ref<any>) {
  return (
    <Icon {...props} ref={ref} viewBox="0 0 780 780">
      <g>
        <path fill="#3C416A" d="M390,718.08c-181.18,0-328.58-147.4-328.58-328.58h63.8c0,146,118.78,264.78,264.78,264.78V718.08z"/>
        <path fill="#F15B43" d="M390,718.08v-63.8c146,0,264.78-118.78,264.78-264.78h63.8C718.58,570.68,571.18,718.08,390,718.08z"/>
        <path fill="#3C416A" d="M718.58,389.5h-63.8c0-146-118.78-264.78-264.78-264.78v-63.8C571.18,60.92,718.58,208.32,718.58,389.5z"/>
        <path fill="#F15B43" d="M125.22,389.5h-63.8c0-181.18,147.4-328.58,328.58-328.58v63.8C244,124.72,125.22,243.5,125.22,389.5z"/>
        <path fill="#FFFFFF" d="M390,60.92c181.47,0,328.58,147.11,328.58,328.58S571.47,718.08,390,718.08S61.42,570.97,61.42,389.5
          S208.53,60.92,390,60.92 M390,17.42c-99.39,0-192.82,38.7-263.1,108.98C56.62,196.68,17.92,290.11,17.92,389.5
          s38.7,192.82,108.98,263.1c70.28,70.28,163.71,108.98,263.1,108.98s192.82-38.7,263.1-108.98
          c70.28-70.28,108.98-163.71,108.98-263.1s-38.7-192.82-108.98-263.1C582.82,56.12,489.39,17.42,390,17.42L390,17.42z"/>
      </g>
    </Icon>
  );
}

export default React.forwardRef(RedCircle);
