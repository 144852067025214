import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import get from 'lodash/get';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FolderIcon from '../icons/FolderIcon';
import BackIcon from '../icons/BackIcon';
import FreshmanIcon from '../icons/FreshmanIcon';
import Score, { getScoreValue, SCORE_TYPE, getProCombineScoreValue } from '../atoms/Score';
import LocationIcon from '../atoms/LocationIcon';
import Stars from '../atoms/Stars';
import Pagination from '../atoms/Pagination';
import Checkbox from '../atoms/Checkbox';
import ColorCode from '../atoms/ColorCode';
import Tooltip from '../atoms/Tooltip';
import TableHeadCell from '../atoms/TableHeadCell';
import NotAvailable from '../atoms/NotAvailable';
import TeamLogo from '../atoms/TeamLogo';
import Links from '../molecules/Links';
import NewPlayerColorCode from '../molecules/NewPlayerColorCode';
import MultiSportList from '../molecules/MultiSportList';
import TableWithDoubleScrollBars from '../molecules/TableWithDoubleScrollBars';
import { inchesToFeetAndInches } from '../services/converter';
import {
  getPrimaryHighSchool,
  getPrimaryPosition,
  getPrimaryCollege,
  getPrimaryNFLTeam,
  PlayerType,
  sortDraftRound,
  prepareLinks,
} from '../services/player';
import Player from '../types/Player';
import PlayersFolder from '../types/PlayersFolder';
import { Order } from '../types/Order';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_DARK_BLUE,
  COLOR_DARK_GRAY,
  COLOR_GREEN,
  COLOR_LIGHT_GRAY,
  COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Avatar from '../atoms/Avatar';
import { Color } from '../types/Color';
import AlertIcon from '../icons/AlertIcon';

interface PlayersTableProps {
  className?: string;
  items: Array<PlayersFolder | Player>;
  folders?: PlayersFolder[];
  players: Player[];
  limit: number;
  columns: string[];
  selectedPlayers: Player[];
  setSelectedPlayers: (items:Array<Player>) => void;
  selectedFolders?: PlayersFolder[] | [];
  setSelectedFolders?: (items:Array<PlayersFolder>) => void;
  order: Order | undefined;
  setOrder: (order:Order | undefined) => void;
  sortedByColumn: string;
  onSort: (sortedByColumn:string, order:Order, sort:(players:Player[], order:Order) => Player[]) => void;
  onSelectFolder?: (folder:PlayersFolder) => void;
  onGoBack?: () => void;
  currentPage: number;
  autoSetAlert?: boolean;
  setCurrentPage: (value:number) => void,
}

export const PLAYER_COLUMN = {
  NAME: 'name',
  LINKS: 'links',
  PAI_SCORE: 'pai',
  COMBINE_SCORE: 'combine',
  PRO_COMBINE_SCORE: 'nflCombineScore',
  PPI_SCORE: 'ppi',
  STARS: 'compStar',
  HS_NAME: 'highSchoolName',
  HS_STATE: 'highSchoolState',
  HS_CITY: 'highSchoolCity',
  HS_CLASS: 'highSchoolClass',
  HS_SPORTS: 'highSchoolSports',
  WEIGHT: 'weight',
  HEIGHT: 'height',
  COLLEGE_POSITION: 'collegePosition',
  COLLEGE_NAME: 'collegeName',
  COLLEGE_JERSEY_NUMBER: 'collegeJerseyNumber',
  ROSTER_HEIGHT: 'rosterHeight',
  ROSTER_WEIGHT: 'rosterWeight',
  RECRUITING_CLASS: 'recruitingClass',
  NFL_POSITION: 'nflPosition',
  NFL_TEAM: 'nflTeam',
  DRAFT_YEAR: 'draftYear',
  DRAFT_ROUND: 'draftRound',
  TEAM_HEIGHT: 'teamHeight',
  TEAM_WEIGHT: 'teamWeight',
  HS_POSITION: 'hsPosition',
  ALERT: 'alert',
};

export const SAVED_PLAYER_HS_COLUMNS = [
  PLAYER_COLUMN.NAME,
  PLAYER_COLUMN.ALERT,
  PLAYER_COLUMN.LINKS,
  PLAYER_COLUMN.HS_POSITION,
  PLAYER_COLUMN.PAI_SCORE,
  PLAYER_COLUMN.PPI_SCORE,
  PLAYER_COLUMN.COMBINE_SCORE,
  PLAYER_COLUMN.STARS,
  PLAYER_COLUMN.HS_NAME,
  PLAYER_COLUMN.HS_STATE,
  PLAYER_COLUMN.HS_CLASS,
  PLAYER_COLUMN.WEIGHT,
  PLAYER_COLUMN.HEIGHT,
  PLAYER_COLUMN.HS_SPORTS,
];

export const COLLEGE_DEFAULT_COLUMNS = [
  PLAYER_COLUMN.NAME,
  PLAYER_COLUMN.COLLEGE_POSITION,
  PLAYER_COLUMN.PAI_SCORE,
  PLAYER_COLUMN.COMBINE_SCORE,
  PLAYER_COLUMN.PPI_SCORE,
  PLAYER_COLUMN.HS_SPORTS,
  PLAYER_COLUMN.COLLEGE_NAME,
];

export const NFL_DEFAULT_COLUMNS = [
  PLAYER_COLUMN.NAME,
  PLAYER_COLUMN.NFL_POSITION,
  PLAYER_COLUMN.PAI_SCORE,
  PLAYER_COLUMN.COMBINE_SCORE,
  PLAYER_COLUMN.PPI_SCORE,
  PLAYER_COLUMN.PRO_COMBINE_SCORE,
  PLAYER_COLUMN.HS_SPORTS,
  PLAYER_COLUMN.NFL_TEAM,
  PLAYER_COLUMN.DRAFT_YEAR,
  PLAYER_COLUMN.DRAFT_ROUND,
];

export const COLLEGE_COLUMNS = [
  ...COLLEGE_DEFAULT_COLUMNS,
  PLAYER_COLUMN.COLLEGE_JERSEY_NUMBER,
  PLAYER_COLUMN.ROSTER_HEIGHT,
  PLAYER_COLUMN.ROSTER_WEIGHT,
  PLAYER_COLUMN.RECRUITING_CLASS,
  PLAYER_COLUMN.HS_CLASS,
  PLAYER_COLUMN.HS_NAME,
  PLAYER_COLUMN.HS_CITY,
  PLAYER_COLUMN.HS_STATE,
  PLAYER_COLUMN.STARS,
];

export const NFL_COLUMNS = [
  ...NFL_DEFAULT_COLUMNS,
  PLAYER_COLUMN.TEAM_HEIGHT,
  PLAYER_COLUMN.TEAM_WEIGHT,
  PLAYER_COLUMN.COLLEGE_NAME,
  PLAYER_COLUMN.RECRUITING_CLASS,
  PLAYER_COLUMN.HS_CLASS,
  PLAYER_COLUMN.HS_NAME,
  PLAYER_COLUMN.HS_CITY,
  PLAYER_COLUMN.HS_STATE,
  PLAYER_COLUMN.STARS,
];

export const PLAYER_COLUMN_TITLE = {
  [PLAYER_COLUMN.NAME]: 'Name',
  [PLAYER_COLUMN.ALERT]: 'Alert',
  [PLAYER_COLUMN.LINKS]: 'Links',
  [PLAYER_COLUMN.PAI_SCORE]: 'PAI',
  [PLAYER_COLUMN.COMBINE_SCORE]: <>HS&nbsp;Com.</>,
  [PLAYER_COLUMN.PPI_SCORE]: 'PPI',
  [PLAYER_COLUMN.PRO_COMBINE_SCORE]: <>Pro&nbsp;Com.</>,
  [PLAYER_COLUMN.STARS]: 'Stars',
  [PLAYER_COLUMN.HS_NAME]: <>High&nbsp;School</>,
  [PLAYER_COLUMN.HS_CITY]: <>HS&nbsp;City</>,
  [PLAYER_COLUMN.HS_STATE]: <>HS&nbsp;State</>,
  [PLAYER_COLUMN.HS_CLASS]: 'Class',
  [PLAYER_COLUMN.WEIGHT]: 'Weight',
  [PLAYER_COLUMN.HEIGHT]: 'Height',
  [PLAYER_COLUMN.HS_SPORTS]: <>HS&nbsp;Sports</>,
  [PLAYER_COLUMN.HS_POSITION]: 'Pos.',
  [PLAYER_COLUMN.COLLEGE_NAME]: <>College&nbsp;Name</>,
  [PLAYER_COLUMN.RECRUITING_CLASS]: <>Recruiting&nbsp;Year</>,
  [PLAYER_COLUMN.COLLEGE_POSITION]: <>College&nbsp;Pos.</>,
  [PLAYER_COLUMN.COLLEGE_JERSEY_NUMBER]: <>Jersey&nbsp;#</>,
  [PLAYER_COLUMN.ROSTER_HEIGHT]: <>Roster&nbsp;Height</>,
  [PLAYER_COLUMN.ROSTER_WEIGHT]: <>Roster&nbsp;Weight</>,
  [PLAYER_COLUMN.NFL_TEAM]: <>NFL&nbsp;Team</>,
  [PLAYER_COLUMN.DRAFT_YEAR]: <>Draft&nbsp;Year</>,
  [PLAYER_COLUMN.DRAFT_ROUND]: <>Draft&nbsp;Round</>,
  [PLAYER_COLUMN.NFL_POSITION]: <>NFL&nbsp;Pos.</>,
  [PLAYER_COLUMN.TEAM_HEIGHT]: <>Team&nbsp;Height</>,
  [PLAYER_COLUMN.TEAM_WEIGHT]: <>Team&nbsp;Weight</>,
};

const useStyles = makeStyles((theme: Theme) => ({
  playersTable: {
    width: '100%',
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    background: COLOR_WHITE,
  },

  tableHead: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,

    '& $checkBoxCell': {
      height: 'auto',
    },
  },

  tableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  tableCell: {
    padding: theme.spacing(1.25, 2.5),
    fontSize: theme.typography.pxToRem(14),
    border: 0,
  },
  truncated: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  checkBoxCell: {
    position: 'relative',
    width: '50px',
    minWidth: 0,
    height: '50px',
    padding: '5px 10px',
  },

  nameCell: {
    minWidth: '180px',
  },
  hsName: {
    minWidth: '180px',
  },

  goBackCell: {
    paddingLeft: theme.spacing(1),
  },
  goBack: {
    width: '80px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    textDecoration: 'underline',
    color: COLOR_BLUE,
    cursor: 'pointer',

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },
  goBackIcon: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
    color: 'inherit',
  },

  emptyCell: {
    paddingLeft: theme.spacing(1),
  },
  empty: {
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },

  folder: {
    display: 'flex',
    alignItems: 'center',
  },
  folderIcon: {
    width: '16px',
    height: '16px',
    color: COLOR_TEXT,
  },
  folderName: {
    marginLeft: theme.spacing(1),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: COLOR_BLUE,

    '&:hover': {
      color: COLOR_DARK_BLUE,
    },
  },

  colorCodes: {
    width: '22px',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: 'calc(100% - 4px)',
    transform: 'translateY(-50%)',
  },
  colorCode: {
    marginLeft: '2px',

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  playerNameWithLocationIcon: {
    display: 'flex',
    alignItems: 'center',
  },

  playerLink: {
    color: COLOR_BLUE,
    fontSize: theme.typography.pxToRem(16),
    paddingLeft: '8px',
  },

  locationIcon: {
    marginLeft: 'auto',
  },

  score: {
    display: 'flex',
    alignItems: 'center',
  },
  paiScore: {
    color: COLOR_ORANGE,
  },
  combineScore: {
    color: COLOR_GREEN,
  },

  playerScore: {
    width: '36px',
    height: '36px',
    fontSize: theme.typography.pxToRem(14),
  },
  freshmanIcon: {
    height: '28px',
    width: '28px',
    transform: 'scale(1.2)',
    cursor: 'pointer',
  },

  multiSportIcon: {
    width: '24px',
    height: '24px',
  },

  team: {
    display: 'flex',
    alignItems: 'center',
  },

  teamLogo: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1.5),
  },

  paginationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '24px',
    padding: theme.spacing(3, 0),
    position: 'relative',
  },
  pagination: {
    width: '100%',
  },
  paginationText: {
    marginTop: theme.spacing(2),
  },

  playerAvatar: {
    flexShrink: 0,
    width: '32px',
    height: '32px',
  },

  alertIcon: {
    display: 'block',
    width: '22px',
    height: '22px',
  },

  [MEDIA.DESKTOP]: {
    tableCell: {
      fontSize: theme.typography.pxToRem(16),
    },

    paginationText: {
      boxSizing: 'border-box',
      position: 'absolute',
      left: 0,
      top: '50%',
      maxWidth: '20%',
      marginTop: 0,
      paddingRight: theme.spacing(2),
      transform: 'translateY(-50%)',
    },

    pagination: {
      maxWidth: '60%',
    },
  },
}), { name: PlayersTable.name });

export default function PlayersTable (props:PlayersTableProps) {
  const {
    className,
    items = [],
    folders = [],
    players = [],
    limit,
    columns,
    selectedPlayers = [],
    setSelectedPlayers = () => {},
    selectedFolders = [],
    setSelectedFolders  = () => {},
    order,
    setOrder = () => {},
    sortedByColumn,
    onSort = () => {},
    onSelectFolder = () => {},
    onGoBack,
    currentPage,
    setCurrentPage = () => {},
    autoSetAlert,
  } = props;

  const classes = useStyles();

  function onFolderSelect (checkedItem: PlayersFolder) {
    return (value:boolean) => {
      if (value) {
        setSelectedFolders([...selectedFolders, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedFolders];
        const removeIndex = selectedFolders.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setSelectedFolders(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function onPlayerSelect (checkedItem:Player) {
    return (value:boolean) => {
      if (value) {
        setSelectedPlayers([...selectedPlayers, checkedItem]);
      } else {
        const selectedItemsWithoutCheckedItem = [...selectedPlayers];
        const removeIndex = selectedPlayers.findIndex(item => item.id === checkedItem.id);
        if (removeIndex > -1) {
          selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
        }

        setSelectedPlayers(selectedItemsWithoutCheckedItem);
      }
    };
  }

  function onSortByColumn (sort:(players:Player[], order:Order) => Player[]) {
    return (columnName:string) => {
      let newOrder = Order.desc;
      if (sortedByColumn === columnName) {
        newOrder = order === Order.asc ? Order.desc : Order.asc;
      }
      setOrder(newOrder);

      onSort(columnName, newOrder, sort);
    };
  }

  function sortPlayers (
    players:Player[],
    order:Order,
    getValue: (player:Player) => string | number,
    isDraftRound?: boolean,
  ) {
    return players.sort((first:Player, second:Player) => {
      const value1 = getValue(first);
      const value2 = getValue(second);

      if (isDraftRound) {
        return sortDraftRound(value1, value2, order);
      }

      let result = 0;
      if (value1 < value2) {
        result = -1;
      } else if (value1 > value2) {
        result = 1;
      }

      return result * (order === Order.desc ? -1 : 1);
    });
  }

  function renderFolderRow (folder:PlayersFolder) {
    return (
      <TableRow
        key={`folder_${folder.id}`}
        className={classes.tableRow}
      >
        <TableCell className={clsx(classes.tableCell, classes.checkBoxCell)}>
          <Checkbox
            checked={!!selectedFolders.find(oneOfSelectedItem => oneOfSelectedItem.id === folder.id)}
            onChange={onFolderSelect(folder)}
          />
        </TableCell>

        <TableCell
          className={classes.tableCell}
          colSpan={tableColumns.length}
        >
          <div className={classes.folder}>
            <FolderIcon className={classes.folderIcon} />
            <span
              className={classes.folderName}
              onClick={() => onSelectFolder(folder)}
            >
              {folder.name} ({folder.players.length})
            </span>
          </div>
        </TableCell>
      </TableRow>
    );
  }

  function renderPlayerRow (player:Player) {
    const checked = !!selectedPlayers.find(oneOfSelectedItem => oneOfSelectedItem.id === player.id);
    let colors = [];
    let color = get(player, 'notes[0].colorCode', '');
    colors.push(get(player, 'notes[0].colorCode', ''));
    const isContactInfoAvailable = player && (player?.email || player?.cellPhone || player?.address) ? true : false;
    if (isContactInfoAvailable) {
      color = Color.GREY;
      colors.push(color);
    }

    const inGameRating = {
      reelMPH: player && player.reelMPH || 0,
      reelMPHPerc: player && player.reelMPHPerc || 0,
      reelPosMPH: player && player.reelPosMPH || 0,
      reelPosMPHPerc: player && player.reelPosMPHPerc || 0,
      igaScore: player && player.igaScore || 0,
     }
    if (inGameRating.igaScore > 0 || inGameRating.reelMPH > 0 || inGameRating.reelMPHPerc > 0 || inGameRating.reelPosMPH > 0 || inGameRating.reelPosMPHPerc > 0) {
      color = Color.PURPLE;
      colors.push(color);
    }

    return (
      <TableRow
        key={`player_${player.id}`}
        className={classes.tableRow}
      >
        <TableCell
          key='check-box-cell'
          className={clsx(classes.tableCell, classes.checkBoxCell)}
        >
          <Checkbox
            checked={checked}
            onChange={onPlayerSelect(player)}
          />

          <div className={classes.colorCodes}>
            <NewPlayerColorCode
              className={classes.colorCode}
              player={player}
            />

            {(color || colors.length) && (
              <ColorCode
                className={classes.colorCode}
                color={color}
                colors={colors}
                showSearchTooltip={true}
              />
            )}
          </div>
        </TableCell>

        {tableColumns.map((tableColumn:any) => (
          <TableCell
            key={tableColumn.value}
            className={clsx(
              classes.tableCell,
              tableColumn.value === PLAYER_COLUMN.NAME && classes.nameCell
            )}
          >
            {tableColumn.renderContent(player)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function checkAllItems() {
    if (items.length === [...selectedFolders, ...selectedPlayers].length) {
      setSelectedPlayers([]);
      setSelectedFolders([]);
    } else {
      setSelectedPlayers(players);
      setSelectedFolders(folders);
    }
  }

  const totalPages = Math.ceil(items.length / limit);
  const itemsFrom = (currentPage - 1) * limit;
  const itemsTo = items.length < limit ? items.length : (limit * currentPage);

  const tableColumns = columns.map((column:string) => {
    switch (column) {
      case PLAYER_COLUMN.NAME:
        return {
          value: PLAYER_COLUMN.NAME,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.NAME],
          sortable: true,
          renderContent: (player:Player) => {
            const primaryHighSchool = getPrimaryHighSchool(player);

            return (
              <div className={classes.playerNameWithLocationIcon}>
                {player && (
                  <Avatar
                    className={classes.playerAvatar}
                    src={player.photoUrl}
                    alt='Profile picture'
                  />
                )}
                <Link
                  className={classes.playerLink}
                  to={`/player/${player.slug}?`}
                  target='_blank'
                >
                  {player.firstName}&nbsp;{player.lastName}
                </Link>

                {primaryHighSchool?.state && (
                  <LocationIcon
                    className={classes.locationIcon}
                    stateCode={primaryHighSchool.state}
                  />
                )}
              </div>
            );
          },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => `${player.lastName} ${player.firstName}`
            );
          },
        };
        case PLAYER_COLUMN.ALERT:
          return {
            value: PLAYER_COLUMN.ALERT,
            title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.ALERT],
            sortable: false,
            renderContent: (player:Player) => (
              <div className={classes.alertIcon}>
                { player.hasHighSchool && !player.hasCollegeTeam && !player.hasNFLTeam &&
                  autoSetAlert && <AlertIcon />
                }
              </div>
            ),
          };
        case PLAYER_COLUMN.LINKS:
        return {
          value: PLAYER_COLUMN.LINKS,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.LINKS],
          sortable: false,
          renderContent: (player:Player) => {
            const links = prepareLinks(player);
            return ( <Links links={links} onlySocial />)
          }
        };
      case PLAYER_COLUMN.PAI_SCORE:
        return {
          value: PLAYER_COLUMN.PAI_SCORE,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.PAI_SCORE],
          sortable: true,
          renderContent: (player:Player) => (
            <div className={clsx(classes.score, classes.paiScore)}>
              <Score
                className={classes.playerScore}
                type={SCORE_TYPE.PAI}
                scoreList={player.pai}
              />

              {player.paiHasFreshmanData && (
                <Tooltip title='Freshman Data'>
                  <FreshmanIcon className={classes.freshmanIcon} />
                </Tooltip>
              )}
            </div>
          ),
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => getScoreValue(player.pai) || 0,
            );
          },
        };
      case PLAYER_COLUMN.COMBINE_SCORE:
        return {
          value: PLAYER_COLUMN.COMBINE_SCORE,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.COMBINE_SCORE],
          sortable: true,
          renderContent: (player:Player) => (
            <div className={clsx(classes.score, classes.combineScore)}>
              <Score
                className={classes.playerScore}
                type={SCORE_TYPE.COMBINE}
                scoreList={player.combine}
                combines={player.hsCombines}
              />

              {player.combineHasFreshmanData && (
                <Tooltip title='Freshman Data'>
                  <FreshmanIcon className={classes.freshmanIcon} />
                </Tooltip>
              )}
            </div>
          ),
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => getScoreValue(player?.combine, { combines: player?.hsCombines }) || 0,
            );
          },
        };
      case PLAYER_COLUMN.PPI_SCORE:
        return {
          value: PLAYER_COLUMN.PPI_SCORE,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.PPI_SCORE],
          sortable: true,
          renderContent: (player:Player) => (
            <div className={clsx(classes.score, classes.combineScore)}>
              <Score
                className={classes.playerScore}
                type={SCORE_TYPE.PPI}
                value={player?.ppi || undefined}
              />
            </div>
          ),
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => player?.ppi  || 0,
            );
          },
        };
      case PLAYER_COLUMN.PRO_COMBINE_SCORE:
        return {
          value: PLAYER_COLUMN.PRO_COMBINE_SCORE,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.PRO_COMBINE_SCORE],
          sortable: true,
          renderContent: (player:Player) => (
            <div className={clsx(classes.score, classes.combineScore)}>
              <Score
                className={classes.playerScore}
                type={SCORE_TYPE.PRO_COMBINE}
                value={player?.nflCombineScore || undefined}
                combines={player.nflCombines}
              />
            </div>
          ),
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => getProCombineScoreValue(player?.nflCombineScore, player?.nflCombines) || 0,
            );
          },
        };
      case PLAYER_COLUMN.STARS:
        return {
          value: PLAYER_COLUMN.STARS,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.STARS],
          sortable: true,
          renderContent: (player:Player) => {
            const stars = (typeof player?._247Star === 'number' || typeof player?.compStar === 'number')
              ? player?._247Star || player?.compStar || 0
              : null;
            return typeof stars === 'number'
              ? <Stars value={stars}/>
              : <NotAvailable />
          },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => player?._247Star || player?.compStar || 0,
            );
          },
        };
      case PLAYER_COLUMN.HS_NAME:
        return {
          value: PLAYER_COLUMN.HS_NAME,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_NAME],
          sortable: true,
          renderContent: (player:Player) => (
            <div className={classes.hsName}>
              {(getPrimaryHighSchool(player) || {}).name || <NotAvailable />}
            </div>
          ),
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryHighSchool(player) || {}).name || '',
            );
          },
        };
      case PLAYER_COLUMN.HS_CITY:
        return {
          value: PLAYER_COLUMN.HS_CITY,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_CITY],
          sortable: true,
          renderContent: (player: Player) => (getPrimaryHighSchool(player) || {}).city || <NotAvailable />,
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryHighSchool(player) || {}).city || '',
            );
          },
        };
      case PLAYER_COLUMN.HS_STATE:
        return {
          value: PLAYER_COLUMN.HS_STATE,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_STATE],
          sortable: true,
          renderContent: (player: Player) => (getPrimaryHighSchool(player) || {}).state || <NotAvailable />,
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryHighSchool(player) || {}).state || '',
            );
          },
        };
      case PLAYER_COLUMN.HS_CLASS:
        return {
          value: PLAYER_COLUMN.HS_CLASS,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_CLASS],
          sortable: true,
          renderContent: (player: Player) => (getPrimaryHighSchool(player) || {}).graduatingClass || <NotAvailable />,
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryHighSchool(player) || {}).graduatingClass || 0,
            );
          },
        };
      case PLAYER_COLUMN.WEIGHT:
        return {
          value: PLAYER_COLUMN.WEIGHT,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.WEIGHT],
          sortable: true,
          renderContent: (player: Player) => {
            const highSchool = getPrimaryHighSchool(player);
            return highSchool && highSchool.weight
              ? `${highSchool.weight}lb`
              : <NotAvailable />;
          },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryHighSchool(player) || {}).weight || 0,
            );
          },
        };
      case PLAYER_COLUMN.HEIGHT:
        return {
          value: PLAYER_COLUMN.HEIGHT,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HEIGHT],
          sortable: true,
          renderContent: (player: Player) => {
            const highSchool = getPrimaryHighSchool(player);
            return highSchool && highSchool.height
              ? inchesToFeetAndInches(highSchool.height)
              : <NotAvailable />;
          },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryHighSchool(player) || {}).height || 0,
            );
          },
        };
      case PLAYER_COLUMN.HS_SPORTS:
        return {
          value: PLAYER_COLUMN.HS_SPORTS,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_SPORTS],
          sortable: true,
          renderContent: (player: Player) => {
            return player.multiSport
              ? (
                <MultiSportList
                  iconClassName={classes.multiSportIcon}
                  list={player.multiSport}
                />
              )
              : <NotAvailable />;
          },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => ((player || {}).multiSport || []).length || 0,
            );
          },
        };
      case PLAYER_COLUMN.HS_POSITION:
        return {
          value: PLAYER_COLUMN.HS_POSITION,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.HS_POSITION],
          sortable: true,
          renderContent: (player: Player) => getPrimaryPosition(player, PlayerType.HIGH_SCHOOL) || <NotAvailable />,
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => getPrimaryPosition(player, PlayerType.HIGH_SCHOOL) || '',
            );
          },
        };
      case PLAYER_COLUMN.COLLEGE_NAME:
        return {
          value: PLAYER_COLUMN.COLLEGE_NAME,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.COLLEGE_NAME],
          sortable: true,
          renderContent: (player:Player) => {
            const college = getPrimaryCollege(player);
            return college && college.team
              ? <div className={classes.team}>
                  <TeamLogo
                    className={classes.teamLogo}
                    team={college.team}
                  />
                  {college.team.shortName}
                </div>
              : <NotAvailable />;
            },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryCollege(player) || {}).team?.name || '',
            );
          },
        };
      case PLAYER_COLUMN.COLLEGE_POSITION:
        return {
          value: PLAYER_COLUMN.COLLEGE_POSITION,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.COLLEGE_POSITION],
          sortable: true,
          renderContent: (player: Player) => getPrimaryPosition(player, PlayerType.COLLEGE) || <NotAvailable />,
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => getPrimaryPosition(player, PlayerType.COLLEGE) || '',
            );
          },
        };
      case PLAYER_COLUMN.RECRUITING_CLASS:
        return {
          value: PLAYER_COLUMN.RECRUITING_CLASS,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.RECRUITING_CLASS],
          sortable: true,
          renderContent: (player:Player) => (getPrimaryCollege(player) || {}).recruitingClass || <NotAvailable />,
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryCollege(player) || {}).recruitingClass || 0,
            );
          },
        };
      case PLAYER_COLUMN.COLLEGE_JERSEY_NUMBER:
        return {
          value: PLAYER_COLUMN.COLLEGE_JERSEY_NUMBER,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.COLLEGE_JERSEY_NUMBER],
          sortable: true,
          renderContent: (player: Player) => {
            return (getPrimaryCollege(player) || {}).jerseyNumber || <NotAvailable />;
            },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => {
                const jersey = (getPrimaryCollege(player) || {}).jerseyNumber || '';
                const jerseyNumber = parseInt(jersey);
                return jerseyNumber || 0
              },
            );
        },
      };
      case PLAYER_COLUMN.ROSTER_HEIGHT:
        return {
          value: PLAYER_COLUMN.ROSTER_HEIGHT,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.ROSTER_HEIGHT],
          sortable: true,
          renderContent: (player: Player) => {
            const college = getPrimaryCollege(player);
            return college && college.height
              ? inchesToFeetAndInches(college.height)
              : <NotAvailable />;
            },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryCollege(player) || {}).height || '',
            );
          },
        };
      case PLAYER_COLUMN.ROSTER_WEIGHT:
        return {
          value: PLAYER_COLUMN.ROSTER_WEIGHT,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.ROSTER_WEIGHT],
          sortable: true,
          renderContent: (player: Player) => {
            const college = getPrimaryCollege(player);
            return college && college.weight
              ? `${college.weight}lbs`
              : <NotAvailable />;
            },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryCollege(player) || {}).weight || '',
            );
          },
        };
      case PLAYER_COLUMN.NFL_TEAM:
        return {
          value: PLAYER_COLUMN.NFL_TEAM,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.NFL_TEAM],
          sortable: true,
          renderContent: (player: Player) => {
            const nflTeam = getPrimaryNFLTeam(player);
            return nflTeam && nflTeam.team
              ? <div className={classes.team}>
                  <TeamLogo
                    className={classes.teamLogo}
                    team={nflTeam.team}
                  />
                  {nflTeam.team.shortName}
                </div>
              : <NotAvailable />;
            },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryNFLTeam(player) || {}).team?.name || '',
            );
          },
        };
      case PLAYER_COLUMN.DRAFT_YEAR:
        return {
          value: PLAYER_COLUMN.DRAFT_YEAR,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.DRAFT_YEAR],
          sortable: true,
          renderContent: (player: Player) => {
            const nflTeam = getPrimaryNFLTeam(player);
            return nflTeam && nflTeam.draftYear
              ? nflTeam.draftYear
              : <NotAvailable />;
            },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryNFLTeam(player) || {}).draftYear || '',
            );
          },
        };
      case PLAYER_COLUMN.DRAFT_ROUND:
        return {
          value: PLAYER_COLUMN.DRAFT_ROUND,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.DRAFT_ROUND],
          sortable: true,
          renderContent: (player: Player) => {
            const nflTeam = getPrimaryNFLTeam(player);
            return typeof nflTeam?.draftRound === 'number'
              ? nflTeam.draftRound
              : <NotAvailable />;
            },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => {
                const nflTeam = getPrimaryNFLTeam(player);
                return typeof nflTeam?.draftRound === 'number'
                  ? nflTeam.draftRound
                  : '';
                },
              true
            );
          },
        };
      case PLAYER_COLUMN.TEAM_HEIGHT:
        return {
          value: PLAYER_COLUMN.TEAM_HEIGHT,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.TEAM_HEIGHT],
          sortable: true,
          renderContent: (player:Player) => {
            const nflTeam = getPrimaryNFLTeam(player);
            const collegeTeam = getPrimaryCollege(player);
            const teamHeight = nflTeam?.height || collegeTeam?.height;

            return teamHeight
              ? inchesToFeetAndInches(teamHeight)
              : <NotAvailable />;
            },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryNFLTeam(player) || {}).height || 0
            );
          },
        };
      case PLAYER_COLUMN.TEAM_WEIGHT:
        return {
          value: PLAYER_COLUMN.TEAM_WEIGHT,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.TEAM_WEIGHT],
          sortable: true,
          renderContent: (player:Player) => {
            const nflTeam = getPrimaryNFLTeam(player);
            const collegeTeam = getPrimaryCollege(player);
            const teamWeight = nflTeam?.weight || collegeTeam?.weight;

            return teamWeight
              ? `${teamWeight}lbs`
              : <NotAvailable />;
            },
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => (getPrimaryNFLTeam(player) || {}).weight || 0
            );
          },
        };
      case PLAYER_COLUMN.NFL_POSITION:
        return {
          value: PLAYER_COLUMN.NFL_POSITION,
          title: PLAYER_COLUMN_TITLE[PLAYER_COLUMN.NFL_POSITION],
          sortable: true,
          renderContent: (player: Player) => getPrimaryPosition(player, PlayerType.NFL) || <NotAvailable />,
          sort: (players:Player[], order:Order) => {
            return sortPlayers(
              players,
              order,
              (player:Player) => getPrimaryPosition(player, PlayerType.NFL) || '',
            );
          },
        };
      default:
        return null;
    }
  });

  return (
    <>
      <TableWithDoubleScrollBars tableClassName={clsx(classes.playersTable, className)}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableHeadCell
              key='check-box-cell'
              className={classes.checkBoxCell}
            >
              <Checkbox
                checked={items.length === [...selectedFolders, ...selectedPlayers].length}
                onChange={checkAllItems}
              />
            </TableHeadCell>

            {tableColumns.map((tableColumn:any) => (
              <TableHeadCell
                key={tableColumn.value}
                name={tableColumn.sortable ? tableColumn.value : undefined}
                sortedByColumn={tableColumn.sortable ? sortedByColumn : undefined}
                order={tableColumn.sortable ? order : undefined}
                onSort={tableColumn.sortable
                  ? onSortByColumn(tableColumn.sort || ((players:Player[]) => players))
                  : undefined
                }
              >
                {tableColumn.title}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!!onGoBack && (
            <TableRow
              key='go-back'
              className={classes.tableRow}
            >
              <TableCell
                className={clsx(classes.tableCell, classes.goBackCell)}
                colSpan={tableColumns.length + 1}
              >
                <div
                  className={classes.goBack}
                  onClick={onGoBack}
                >
                  <BackIcon className={classes.goBackIcon} />
                  Back
                </div>
              </TableCell>
            </TableRow>
          )}

          {!items.length && (
            <TableRow
              key='empty'
              className={classes.tableRow}
            >
              <TableCell
                className={clsx(classes.tableCell, classes.emptyCell)}
                colSpan={tableColumns.length + 1}
              >
                <div className={classes.empty}>
                  No players
                </div>
              </TableCell>
            </TableRow>
          )}

          {items
            .slice(itemsFrom, itemsTo)
            .map((item:PlayersFolder | Player) => {
              return ('players' in item)
                ? renderFolderRow(item as PlayersFolder)
                : renderPlayerRow(item as Player);
            })}
        </TableBody>
      </TableWithDoubleScrollBars>

      <div className={classes.paginationWrapper}>
        <span className={classes.paginationText}>
          Showing {itemsFrom + 1} to {itemsTo} of {items.length}
        </span>

        {totalPages > 1 && (
          <Pagination
            className={classes.pagination}
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={page => setCurrentPage(page)}
          />
        )}
      </div>
    </>
  );
}
