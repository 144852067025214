import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import RemoveIcon from '../icons/RemoveIcon';
import Action from '../atoms/Action';
import Loader from '../atoms/Loader';
import Toast, { ToastType } from '../atoms/Toast';
import DepthChartsTable, {
  DEPTH_CHART_ALL_COLUMNS,
  DEPTH_CHART_COLUMN,
} from './DepthChartsTable';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_TEXT,
} from '../styles/colors';
import gql from '../services/gql';
import DepthChart from '../types/DepthChart';
import { Order } from '../types/Order';
import PageLimitDropdown from '../molecules/PageLimitDropdown';

interface SavedDepthChartsTableProps {
  className?: string;
  loading: boolean;
  setLoading: (loading:boolean) => void;
  depthCharts?: DepthChart[];
  setDepthCharts: (depthCharts:DepthChart[]) => void;
  onRefreshData: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  depthChartsTable: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },
  titleRow: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: 0,
  },

  actions: {
    padding: theme.spacing(3, 2.5),
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  action: {
    padding: theme.spacing(0,0,0,2),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:first-of-type': {
      paddingLeft: 0,
    },

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $actionText': {
        color: COLOR_BLUE,
      },
    },
  },
  disabledAction: {
    outlineColor: 'transparent',

    '& $actionText': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $actionText': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  actionIcon: {
    width: '24px',
    height: '24px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
    flexShrink: 0,
  },
  dropdownWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(2),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },

  [MEDIA.MOBILE]: {
    actions: {
      padding: theme.spacing(2),
    },
    action: {
      padding: theme.spacing(0, 1),
      fontSize: theme.typography.pxToRem(14),
    },
    actionText: {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  [MEDIA.DESKTOP]: {
    actionIcon: {
      width: '32px',
      height: '32px',
    },
  },
}), { name: SavedDepthChartsTable.name });

export default function SavedDepthChartsTable (props: SavedDepthChartsTableProps) {
  const {
    className,
    loading = false,
    setLoading = () => {},
    depthCharts = [],
    setDepthCharts = () => {},
    onRefreshData = () => {},
  } = props;
  const classes = useStyles();

  const [selectedItems, setSelectedItems] = useState<DepthChart[]>([]);
  const [editedItems, setEditedItems] = useState<DepthChart[]>([]);
  const [order, setOrder] = useState<Order | undefined>();
  const [sortedByColumn, setSortedByColumn] = useState<string>(DEPTH_CHART_COLUMN.NAME);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);

  function onDelete () {
    if (selectedItems.length) {
      const idsToDelete = selectedItems.map((depthChart:DepthChart) => depthChart.id);

      deleteDepthCharts(idsToDelete)
        .then((success:boolean | void) => {
          if (success) {
            const updatedDepthCharts = [...depthCharts]
              .filter((item:DepthChart) => !(selectedItems.find(depthChart => depthChart.id === item.id)));

            setDepthCharts(updatedDepthCharts);
            setSelectedItems([]);
          } else {
            showToast('Failed to delete selected depth charts. Try again later.');
          }
        })
        .catch(() => {
          showToast('Failed to delete selected depth charts. Try again later.');
        });
    }
  }

  function onUpdateName (updatedItem:DepthChart, value:string) {
    if (value && value.trim()) {
      const updatedDepthCharts = [...depthCharts];
      const updateIndex = depthCharts.findIndex(item => item.id === updatedItem.id);
      if (updateIndex > -1) {
        const newDepthChart = { ...updatedItem, name: value };
        updatedDepthCharts.splice(updateIndex, 1, newDepthChart);
      }

      setDepthCharts(updatedDepthCharts);
    }
  }

  function onRenamed (updatedItem:DepthChart) {
    setLoading(true);

    renameDepthChart(updatedItem.id, updatedItem.name)
      .then((success:boolean | void) => {
        if (!success) {
          showToast('Failed to rename depth chart. Try again later.');
          onRefreshData();
        }
      })
      .catch(() => {
        showToast('Failed to rename depth chart. Try again later.');
        onRefreshData();
      })
      .finally(() => setLoading(false))
  }

  function deleteDepthCharts (ids: number[]):Promise<boolean | void> {
    return gql(`
      mutation {
        deleteDepthCharts (ids: [${ids.toString()}])
      }
    `)
      .then((data:any) => data.deleteDepthCharts as boolean)
      .catch(console.error);
  }

  function renameDepthChart (id:number, name:string):Promise<boolean | void> {
    return gql(`
      mutation {
        renameDepthChart (id: ${id}, name: "${name}")
      }
    `)
      .then((data:any) => data.renameDepthChart as boolean)
      .catch(console.error);
  }

  function showToast (message:string) {
    setToastMessage(message)
    setToastVisible(true);
  }

  function onSort (newOrder:Order, sort:(depthCharts:DepthChart[], order:Order) => DepthChart[]) {
    setDepthCharts(sort(depthCharts, newOrder));
  }
  const deleteActionDisabled = !selectedItems.length;

  return (
    <>
      <div className={clsx(classes.depthChartsTable, className)}>
        <Loader inProgress={loading} />

        <div className={classes.header}>
          <div className={classes.titleRow}>
            <h2 className={classes.headerTitle}>Your Depth Charts</h2>
          </div>

          <div className={classes.actions}>
            <Action
              className={clsx(classes.action, deleteActionDisabled && classes.disabledAction)}
              icon={RemoveIcon}
              iconClassName={classes.actionIcon}
              disabled={deleteActionDisabled}
              onClick={onDelete}
            >
              <span className={classes.actionText}>Delete</span>
            </Action>
          
          <div className={classes.dropdownWrapper}>
              Show
              <PageLimitDropdown
                className={clsx(classes.dropdown, classes.limitDropDown)}
                selectorRootClassName={classes.dropdownSelector}
                value={String(limit)}
                onChange={(limit:string) => setLimit(Number(limit))}
              />
            </div>
          </div>
        </div>

        <DepthChartsTable
          items={depthCharts}
          columns={DEPTH_CHART_ALL_COLUMNS}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          editedItems={editedItems}
          setEditedItems={setEditedItems}
          order={order}
          setOrder={setOrder}
          sortedByColumn={sortedByColumn}
          setSortedByColumn={setSortedByColumn}
          onUpdateName={onUpdateName}
          onRenamed={onRenamed}
          limits = {limit}
          onSort={onSort}
        />
      </div>

      <Toast
        visible={toastVisible}
        type={ToastType.ERROR}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}
