import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Drawer from '@material-ui/core/Drawer';
import UpdateIcon from '../icons/UpdateIcon';
import WeightIcon from '../icons/WeightIcon';
import HeightIcon from '../icons/HeightIcon';
import TrackDataIcon from '../icons/TrackIcon';
import PAIIcon from '../icons/PAIIcon';
import StarRatingIcon from '../icons/StarIcon';
import CombineDataIcon from '../icons/CombineDataIcon';
import CombineIcon from '../icons/CombineIcon';
import Avatar from '../atoms/Avatar';
import LocationIcon from '../atoms/LocationIcon';
import Download from '../atoms/Download';
import CloseButton from '../atoms/CloseButton';
import SeeMoreButton from '../atoms/SeeMoreButton';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import NotAvailable from '../atoms/NotAvailable';
import TeamLogo from '../atoms/TeamLogo';
import AlertButton, { ALERT_MODE } from '../molecules/AlertButton';
import Offers from '../molecules/Offers';
import PlayerNameWithPopover from './PlayerNameWithPopover';
import gql from '../services/gql';
import { State } from '../redux/reducers';
import { fetchCollegeTeams } from '../redux/dispatchers/teams';
import exportXLSReport, { ERROR_MESSAGE_CHECK_EMAIL, XLSExportType } from '../services/export-xls-report';
import { formatDateInDays } from '../services/converter';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_WHITE,
} from '../styles/colors';
import User from '../types/User';
import PlayerUpdate, { PlayerUpdateType } from '../types/PlayerUpdate';
import HighSchool from '../types/HighSchool';
import Team from '../types/Team';
import AlertIcon from '../icons/AlertIcon';
import SaveIcon from '../icons/SaveIcon';
import { Typography } from '@material-ui/core';

interface MyPlayerUpdatesProps {
  className?: string;
  user: User;
  collegeTeams: Team[];
  collegeTeamsLoading: boolean;
  fetchCollegeTeams: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  myPlayerUpdates: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },

  header: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  headerTitleWrap: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
  },
  headerIcon: {
    flexShrink: 0,
    width: '22px',
    height: '22px',
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: theme.spacing(0,0,0,1),
  },
  headerDownloads: {
    display: 'flex',
    marginLeft: 'auto',
  },
  drawerHeaderDownloads: {
    marginRight: theme.spacing(4),
  },

  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '8px',
    transform: 'translate(0%, 50%)',
  },

  noUpdates: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
  },
  noUpdatesIcon: {
    width: '40px',
    height: '40px',
    opacity: 0.6,
  },
  noUpdatesText: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },
  noUpdatesLink: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_BLUE,
  },
  createAlertText: {
    marginTop: theme.spacing(1.25),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: COLOR_DARK_GRAY,
  },

  tableWrap: {
    overflow: 'auto',
  },

  tableHeadCell: {
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    color: COLOR_DARK_GRAY,
    borderBottom: `1px solid ${COLOR_BORDER}`,

    '&:first-of-type': {
      paddingLeft: theme.spacing(1.5),
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(1.5),
    },
  },
  tableHeadCellWithTitleSection: {
    borderColor: 'rgba(0, 0, 0, 0.04)',
  },
  tableRow: {
    backgroundColor: COLOR_WHITE,
    transition: 'background 0.3s',

    '&:hover': {
      backgroundColor: COLOR_BACKGROUND_LIGHT,
    },
  },
  tableCell: {
    padding: theme.spacing(1, 0, 1, 1),
    fontSize: theme.typography.pxToRem(16),
    border: 0,

    '&:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
  },
  tableCellWithAlert: {
    width: '50px',
    height: '50px',
    paddingTop: 0,
    paddingBottom: 0,
  },

  tableSectionTitle: {
    background: COLOR_BACKGROUND_LIGHT,
    color: COLOR_DARK_GRAY,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    padding: theme.spacing(1, 2),
    borderTop: '1px solid rgba(0, 0, 0, 0.04)',
    borderBottom: 0,
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '1px',
      background: 'rgba(0, 0, 0, 0.04)',
      bottom: 0,
      left: 0,
    },
  },

  playerInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  playerAvatar: {
    flexShrink: 0,
    width: '32px',
    height: '32px',
  },
  playerNameAndHometown: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
  },
  playerName: {
    fontSize: theme.typography.pxToRem(16),
  },
  playerHometown: {
    fontSize: theme.typography.pxToRem(12),
    color: COLOR_DARK_GRAY,
  },

  locationIcon: {
    marginLeft: 'auto',
  },

  playerUpdate: {
    display: 'flex',
    alignItems: 'center',
  },

  playerUpdateIcon: {
    width: '32px',
    height: '32px',
    color: COLOR_DARK_GRAY,
  },

  playerUpdateText: {
    display: 'none',
    marginLeft: theme.spacing(1),
  },
  inDrawerPlayerUpdateText: {
    display: 'flex',
  },
  visible: {
    display: 'flex',
  },

  alertButtonContainer: {
    height: '100%',
    display: 'flex',
  },

  seeMoreContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },

  seeMoreDrawer: {
    width: '90%',
    maxWidth: '640px',

    '& .playerUpdateText': {
      display: 'flex',
    },
  },

  '@media (min-width: 1025px) and (max-width: 1260px), (max-width: 480px)': {
    headerTitleWrap: {
      width: '100%',
    },
    headerTitle: {
      whiteSpace: 'nowrap',
    },
    headerDownloads: {
      marginLeft: 0,
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    tableHeadCell: {
      padding: theme.spacing(1, 0, 1, 2),
      fontSize: theme.typography.pxToRem(16),

      '&:first-of-type': {
        paddingLeft: theme.spacing(2.5),
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(2.5),
      },
    },

    playerUpdateText: {
      display: 'flex',
    },
  },
  alertIcon: {
    display: 'inline-block',
    height: '17px',
    width: '17px'
  },
  saveIcon: {
    display: 'inline-block',
    height: '15px',
    width: '15px'
  }
}), { name: MyPlayerUpdates.name });

function MyPlayerUpdates (props: MyPlayerUpdatesProps) {
  const {
    className,
    user,
    collegeTeams,
    collegeTeamsLoading,
    fetchCollegeTeams,
  } = props;
  const classes = useStyles();

  const [updates, setUpdates] = useState<PlayerUpdate[]>([]);
  const [playersUpdatedTimeInDays, setPlayersUpdatedTimeInDays] = useState<string[]>([]);

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  const limit = drawerOpen ? 25 : 5;

  useEffect(() => {
    fetchCollegeTeams();
  }, []);

  useEffect(() => {
    fetchPlayerUpdates();
  }, [drawerOpen]);

  function fetchPlayerUpdates() {
    setPageLoading(true);

    gql(`
      playerUpdates (limit: ${limit}) {
        id
        type
        eventDetails
        player {
          id
          firstName
          lastName
          photoUrl
          highSchools
          offers
          committedToTeamId
          playerColleges {
            positions
            recruitingClass
            isPrimary
            team {
              id
              name
              logo247
              logoESPN
              logoAlt
            }
          }
        }
        createdAt
      }
    `)
      .then((data:any) => data.playerUpdates as PlayerUpdate[])
      .then((updates:PlayerUpdate[]) => {
        setUpdates(updates || []);
        processPlayerUpdates(updates);
      })
      .catch(console.error)
      .finally(() => setPageLoading(false));
  }

  function removeAlert (playerId:number) {
    setPageLoading(true);

    return gql(`
      mutation {
        removeAlerts (playerIds: [${playerId}])
      }
    `)
      .then((data:any) => data.removeAlerts as boolean)
      .then((success:boolean) => {
        if (success) {
          fetchPlayerUpdates();
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
        .catch(() => {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        })
      .finally(() => setPageLoading(false));
  }

  function processPlayerUpdates (updates:PlayerUpdate[]) {
    const playersUpdatedTime = updates
      .map((playerUpdate:PlayerUpdate) => formatDateInDays(playerUpdate.createdAt));

    setPlayersUpdatedTimeInDays(
      playersUpdatedTime.filter((section, index) => playersUpdatedTime.indexOf(section) === index)
    );
  }

  function onXLSDownload () {
    setPageLoading(true);
    const playersIds = updates.map(update => update.player.id);

    exportXLSReport(
      XLSExportType.PLAYERS,
      { ids: playersIds.filter((id, index) => playersIds.indexOf(id) === index)},
    )
      .catch(error => {
        if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
          showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
        } else {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        }
      })
      .finally(() => setPageLoading(false));
  }

  function updateTypeToText (updateType:PlayerUpdateType):string {
    const words = updateType
      .split('-')
      .map(word => word[0].toUpperCase() + word.toLowerCase().slice(1));

    switch (updateType) {
      case PlayerUpdateType.COMMITTED:
      case PlayerUpdateType.OFFERS:
        return words.join(' ');
      default:
        let replacedText = `Updated ${words.join(' ').replace('Pai', 'PAI')}`;
        replacedText = `Updated ${words.join(' ').replace('Ppi', 'PPI')}`;
        return replacedText
    }
  }

  function getIconForUpdate (update:PlayerUpdate) {
    const {
      type: updateType,
      eventDetails,
      player,
    } = update;

    switch (updateType) {
      case PlayerUpdateType.WEIGHT:
        return WeightIcon;
      case PlayerUpdateType.HEIGHT:
        return HeightIcon;
      case PlayerUpdateType.TRACK_DATA:
        return TrackDataIcon;
      case PlayerUpdateType.PAI_SCORE:
        return PAIIcon;
      case PlayerUpdateType.COMBINE_SCORE:
        return CombineIcon;
      case PlayerUpdateType.STAR_RATING:
        return StarRatingIcon;
      case PlayerUpdateType.COMBINE_DATA:
        return CombineDataIcon;
      case PlayerUpdateType.COMMITTED:
        if (player?.committedToTeamId) {
          return () => (
            <TeamLogo
              className={classes.playerUpdateIcon}
              team={collegeTeams.find(team => team.id === player.committedToTeamId)}
            />
          );
        }
        break;
      case PlayerUpdateType.OFFERS:
        if (eventDetails?.offers?.length) {
          return () => (
            <Offers
              offers={eventDetails.offers}
              limit={2}
            />
          );
        }
        break;
      default:
        return UpdateIcon;
    }
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function renderHeader ({ inDrawer = false } = {}) {
    return (
      <div className={classes.header}>
        <div className={classes.headerTitleWrap}>
          <UpdateIcon className={classes.headerIcon} />

          <h2 className={classes.headerTitle}>My Player Updates</h2>
        </div>

        {(user.accessLevel.exportAllowanceType !== 'none') && (
            <div className={clsx(classes.headerDownloads, inDrawer && classes.drawerHeaderDownloads)}>
              <Download
                label='XLS'
                onClick={onXLSDownload}
                disabled={!updates.length}
              />
            </div>
          )
        }

        {inDrawer && (
          <CloseButton
            className={classes.closeButton}
            onClick={() => setDrawerOpen(false)}
          />
        )}
      </div>
    );
  }

  function renderTableSectionTitle (title: string) {
    return (
      <TableRow key={title}>
        <TableCell
          className={classes.tableSectionTitle}
          colSpan={5}
        >
          {title}
        </TableCell>
      </TableRow>
    );
  }

  function renderTableRow (update:PlayerUpdate, index:number) {
    const {
      id,
      type: updateType,
      player,
      player: {
        id: playerId,
        firstName,
        lastName,
        photoUrl,
        highSchools,
      },
    } = update;
    const Icon:any = getIconForUpdate(update) || (() => null);

    const primaryHighSchool = (highSchools || []).find((highSchool:HighSchool) => !!highSchool?.isPrimary);

    const hsPosition = (((primaryHighSchool || {}).positions || [])
      .find((position:any) => position && position.isPrimary) || {}).code || ''

    const hsCity = primaryHighSchool?.city || '';
    const hsState = primaryHighSchool?.state || '';
    const hsClass = primaryHighSchool?.graduatingClass;

    return (
      <TableRow
        key={`${id}_${index}`}
        className={classes.tableRow}
      >
        <TableCell className={classes.tableCell} align='left'>
          <div className={classes.playerInfoContainer}>
            <Avatar
              className={classes.playerAvatar}
              src={photoUrl}
              alt='Profile picture'
            />

            <div className={classes.playerNameAndHometown}>
              <PlayerNameWithPopover
                playerId={player.id}
                name={`${firstName} ${lastName}`}
              />

              {!!(hsCity || hsState) && (
                <div className={classes.playerHometown}>{hsCity}, {hsState}</div>
              )}
            </div>

            <LocationIcon
              className={classes.locationIcon}
              stateCode={hsState}
            />
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} align='center'>
          {!!hsPosition ? hsPosition : <NotAvailable />}
        </TableCell>

        <TableCell className={classes.tableCell} align='center'>
          {!!hsClass ? hsClass : <NotAvailable />}
        </TableCell>

        <TableCell className={classes.tableCell} align='left'>
          <div className={classes.playerUpdate}>
            <Icon className={classes.playerUpdateIcon} />

            <span
              className={clsx(
                classes.playerUpdateText,
                drawerOpen && classes.inDrawerPlayerUpdateText,
                updateType === PlayerUpdateType.COMMITTED && classes.visible,
              )}
            >
              {updateTypeToText(updateType)}
            </span>
          </div>
        </TableCell>

        <TableCell className={clsx(classes.tableCell, classes.tableCellWithAlert)} align='center'>
          <div className={classes.alertButtonContainer}>
            <Divider orientation='vertical' light flexItem />

            <AlertButton
              mode={ALERT_MODE.REMOVE}
              active
              onClick={() => removeAlert(playerId)}
            />
          </div>
        </TableCell>
      </TableRow>
    );
  }

  function renderTable ({ inDrawer = false } = {}) {
    const tableHeadCellClass = clsx(
      classes.tableHeadCell,
      inDrawer && classes.tableHeadCellWithTitleSection,
    );

    return (
      <div className={classes.tableWrap}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={tableHeadCellClass} align='left'>Name | Hometown</TableCell>
              <TableCell className={tableHeadCellClass} align='center'>Pos.</TableCell>
              <TableCell className={tableHeadCellClass} align='center'>Class</TableCell>
              <TableCell className={tableHeadCellClass} align='left'>Update</TableCell>
              <TableCell className={tableHeadCellClass}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!inDrawer && updates.map(renderTableRow)}

            {inDrawer && (
              playersUpdatedTimeInDays.map((timeSlot:string) => ([
                timeSlot && renderTableSectionTitle(timeSlot),
                updates
                  .filter(playerUpdate => timeSlot === formatDateInDays(playerUpdate.createdAt))
                  .map(renderTableRow)
              ]))
            )}
          </TableBody>
        </Table>
      </div>
    );
  }

  const loading = pageLoading || collegeTeamsLoading;

  return (
    <>
      <Paper className={clsx(classes.myPlayerUpdates, className)}>
        <Loader inProgress={loading} />

        {renderHeader()}

        <Divider variant='fullWidth' light />

        {updates.length > 0 && renderTable()}

        {!updates.length && (
          <div className={classes.noUpdates}>
            <UpdateIcon className={classes.noUpdatesIcon} />

            <div className={classes.noUpdatesText}>No Player Updates</div>
            <div className={classes.createAlertText}>
            To create a player update click Alert <AlertIcon className={classes.alertIcon} />
              or Save <SaveIcon className={classes.saveIcon} />
            </div>
          </div>
        )}

        {updates.length > 0 && (
          <>
            <Divider variant='fullWidth' light />

            <div className={classes.seeMoreContainer}>
              <SeeMoreButton onClick={() => setDrawerOpen(true)} />
            </div>
          </>
        )}

        <Drawer
          classes={{ paper: classes.seeMoreDrawer }}
          anchor='right'
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Loader inProgress={loading} />

          {drawerOpen && [
            renderHeader({ inDrawer: true }),
            <Divider variant='fullWidth' light />,
            renderTable({ inDrawer: true }),
          ]}
        </Drawer>
      </Paper>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    collegeTeams: state.teams.collegeTeams,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyPlayerUpdates);
