import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { State } from '../redux/reducers';
import Loader from '../atoms/Loader';
import Toast, { ToastType } from '../atoms/Toast';
import ConferencePAIRatingsChart from '../molecules/ConferencePAIRatingsChart';
import NFLPAIDraftRatingsChart from '../molecules/NFLPAIDraftRatingsChart';
import Achievements from '../organisms/Achievements';
import MyPlayerUpdates from '../organisms/MyPlayerUpdates';
import NewPlayers from '../organisms/NewPlayers';
import TrendingPlayers from '../organisms/TrendingPlayers';
import MyRecruitingBoard from '../organisms/MyRecruitingBoard';
import MyEvaluationBoard from '../organisms/MyEvaluationBoard';
import DashboardTeamSelect from '../organisms/DashboardTeamSelect';
import DiscoveryPlayers from '../organisms/DiscoveryPlayers';
import PageContainer from './PageContainer';
import { getQueryParameter } from '../services/query-parameters';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { isDesktop, isTablet, isMobile, ScreenSize } from '../services/screen-size';
import { setSelectedCollegeTeam, setSelectedNFLTeam } from '../redux/dispatchers/ui';
import { fetchDivITeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import MEDIA from '../styles/media';
import Team from '../types/Team';
import User from '../types/User';
import TransferPortalPlayers from '../organisms/TransferPortalPlayers';
import JucoPlayers from '../organisms/JucoPlayers';

interface DashboardPageTemplateProps {
  isNFL?: boolean;
  user?: User;
  divITeams: Team[];
  divITeamsLoading: boolean;
  nflTeams: Team[];
  nflTeamsLoading: boolean;
  selectedCollegeTeam: Team | undefined;
  selectedNFLTeam: Team | undefined;
  screenSize: ScreenSize;
  setSelectedCollegeTeam: (selectedCollegeTeam: Team | undefined) => void;
  setSelectedNFLTeam: (selectedNFLTeam: Team | undefined) => void;
  fetchDivITeams: () => void;
  fetchNFLTeams: () => void;
}

const useStyles = makeStyles(theme => ({
  gridContainer: {
    width: 'calc(100% + 40px)',
    margin: '0 -20px',
    position: 'relative',
  },
  gridContainerColumn: {
    padding: '0 20px',
  },

  section: {
    margin: theme.spacing(5, 0),

    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },

  '@media (max-width: 1800px)': {
    gridContainer: {
      width: 'calc(100% + 20px)',
      margin: '0 -10px',
    },
    gridContainerColumn: {
      padding: '0 10px',
    },
  },

  [MEDIA.MOBILE_AND_TABLET]: {
    gridContainer: {
      flexDirection: 'column',
    },
    gridContainerColumn: {
      flexBasis: '100%',
      maxWidth: '100%',
      marginTop: theme.spacing(5),

      '&:first-of-type': {
        marginTop: 0,
      },
    },
  },
}), { name: DashboardPageTemplate.name });

function DashboardPageTemplate(props: DashboardPageTemplateProps) {
  const {
    isNFL,
    user,
    divITeams,
    divITeamsLoading,
    nflTeams,
    nflTeamsLoading,
    selectedCollegeTeam,
    selectedNFLTeam,
    screenSize,
    setSelectedCollegeTeam,
    setSelectedNFLTeam,
    fetchDivITeams,
    fetchNFLTeams,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  const noAccessPath = getQueryParameter('no-access');
  const TransferPortalAccessLevel = ['Basic', 'Advanced', 'Advanced Plus', 'NFL Scout', 'Administrator'];
  const JucoAccessLevel = ['Entry', 'Basic', 'Advanced', 'Advanced Plus', 'NFL Scout', 'Administrator'];

  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (!user.accessLevel.canAccessDashboard) {
        history.push(`/search?reset-filters=true`);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!!noAccessPath) {
      showToast(<>You don't have the access to <strong>{noAccessPath}</strong></>, ToastType.ERROR);
      history.replace('/');
    }
  }, [noAccessPath]);

  useEffect(() => {
    if (isNFL) {
      fetchNFLTeams();
    } else {
      fetchDivITeams();
    }
  }, [isNFL]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0 && divITeams?.length) {
      let defaultCollegeTeamId = user?.collegeTeam?.id;

      if (selectedCollegeTeam?.id) {
        defaultCollegeTeamId = selectedCollegeTeam.id;
      } else {
        const selectedCollegeTeamFromClientStorage = clientStorage.get(StorageKey.SELECTED_COLLEGE_TEAM);

        if (selectedCollegeTeamFromClientStorage?.id) {
          defaultCollegeTeamId = selectedCollegeTeamFromClientStorage.id;
        }
      }

      const defaultCollegeTeam = defaultCollegeTeamId
        ? divITeams.find((team: Team) => team.id === defaultCollegeTeamId)
        : divITeams[0];

      setSelectedCollegeTeam(defaultCollegeTeam);
    }
  }, [
    user,
    divITeams?.length,
    selectedCollegeTeam,
  ]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0 && nflTeams?.length) {
      let defaultNFLTeamId = user?.nflTeam?.id;

      if (selectedNFLTeam?.id) {
        defaultNFLTeamId = selectedNFLTeam.id;
      } else {
        const selectedNFLTeamFromClientStorage = clientStorage.get(StorageKey.SELECTED_NFL_TEAM);

        if (selectedNFLTeamFromClientStorage?.id) {
          defaultNFLTeamId = selectedNFLTeamFromClientStorage.id;
        }
      }

      const defaultNFLTeam = defaultNFLTeamId
        ? nflTeams.find((team: Team) => team.id === defaultNFLTeamId)
        : nflTeams[0];

      setSelectedNFLTeam(defaultNFLTeam);
    }
  }, [
    user,
    nflTeams?.length,
    selectedNFLTeam,
  ]);

  function showToast(message: any, type: ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const loading = divITeamsLoading || nflTeamsLoading;

  if (!user || !Object.keys(user).length) {
    return <Loader inProgress />;
  }

  return (
    <PageContainer>
      <Grid
        className={classes.gridContainer}
        container
      >
        <Loader inProgress={loading} />

        <Grid
          className={classes.gridContainerColumn}
          item
          lg={7}
          sm={6}
        >
          {(isMobile(screenSize) || isTablet(screenSize)) && <Achievements className={classes.section} />}

          <DashboardTeamSelect
            teams={isNFL ? nflTeams : divITeams}
            isNFL={isNFL}
          />

          {(!isNFL && selectedCollegeTeam?.id) && (
            <ConferencePAIRatingsChart
              className={classes.section}
              teamId={selectedCollegeTeam.id}
            />
          )}

          {(isNFL && selectedNFLTeam) && (
            <NFLPAIDraftRatingsChart
              className={classes.section}
              team={selectedNFLTeam}
            />
          )}
        </Grid>

        <Grid
          className={classes.gridContainerColumn}
          item
          lg={5}
          sm={6}
        >
          {isDesktop(screenSize) && <Achievements className={classes.section} />}

          {!isNFL && (
            <MyPlayerUpdates
              className={classes.section}
              user={user}
            />
          )}

          <NewPlayers
            className={classes.section}
            isNFL={isNFL}
            user={user}
          />

          <TrendingPlayers
            className={classes.section}
            isNFL={isNFL}
            user={user}
          />

          {
            JucoAccessLevel.includes(user.accessLevel.name) && (
              <JucoPlayers
                className={classes.section}
                isNFL={isNFL}
                user={user}
                selectedCollegeTeam={selectedCollegeTeam}
              />
            )
          }

          {
            TransferPortalAccessLevel.includes(user.accessLevel.name) && (
              <TransferPortalPlayers
                className={classes.section}
                isNFL={isNFL}
                user={user}
                selectedCollegeTeam={selectedCollegeTeam}
              />
            )
          }

          <DiscoveryPlayers
            className={classes.section}
            isNFL={isNFL}
            user={user}
          />

          {(user.accessLevel.recruitingEvaluationBoard) && (
            isNFL ?
              <MyEvaluationBoard
                className={classes.section}
                user={user}
              />
              : <MyRecruitingBoard
                className={classes.section}
                user={user}
              />
          )}
        </Grid>
      </Grid>

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </PageContainer>
  );
}

const mapStateToProps = (state: State) => {
  return {
    isNFL: state.ui.nflAccess,
    currentRecruitingYear: state.configurations.currentRecruitingYear,
    user: state.user,
    selectedCollegeTeam: state.ui.selectedCollegeTeam,
    selectedNFLTeam: state.ui.selectedNFLTeam,
    divITeams: state.teams.divITeams,
    divITeamsLoading: state.teams.divITeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
    screenSize: state.ui.screenSize,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setSelectedCollegeTeam,
      setSelectedNFLTeam,
      fetchDivITeams,
      fetchNFLTeams,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardPageTemplate);
