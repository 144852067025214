import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import CompareIcon from '../icons/CompareIcon';
import NamedBox from '../atoms/NamedBox';
import Loader from '../atoms/Loader';
import { getScoreValue } from '../atoms/Score';
import TopPlayerCard from '../molecules/TopPlayerCard';
import gql from '../services/gql';
import { getHighSchoolAccessYears } from '../services/user';
import {
  COLOR_BLUE,
  COLOR_DARK_GRAY,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import ComparePlayer, { ComparePlayerType } from '../types/ComparePlayer';
import Player from '../types/Player';
import User from '../types/User';
import HighSchool from '../types/HighSchool';

interface PlayerProfileTopPlayersProps {
  className?: string;
  currentPlayer: Player;
  user?: User,
  hideCombinePlayersLink?: boolean;
  printed?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  playerProfileTopPlayers: {
    display: 'flex',
    width: '100%',
  },
  printed: {
    '& $playerCards': {
      overflowX: 'auto',
      paddingBottom: 0,
    },
  },

  compareWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  compareIcon: {
    width: '18px',
    height: '18px',
    color: COLOR_BLUE,
  },
  compareLink: {
    marginLeft: theme.spacing(1),
    fontFamily: FONT_PROXIMA_NOVA,
    fontWeight: 400,
    color: COLOR_BLUE,
  },

  playerCardsWrapper: {
    width: '100%',
    position: 'relative',

    '&:after': {
      content: '""',
      width: '100px',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      background: 'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))',
    },
  },
  playerCards: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    overflowX: 'scroll',
    position: 'relative',
  },
  noData: {
    color: COLOR_DARK_GRAY,
    margin: '0 auto',
    padding: theme.spacing(1, 0, 2),
  },
  playerCard: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  space: {
    width: '2px',
    background: 'none',
    flexShrink: 0,
  },
}), { name: PlayerProfileTopPlayers.name });

export default function PlayerProfileTopPlayers (props: PlayerProfileTopPlayersProps) {
  const {
    className,
    currentPlayer,
    user,
    hideCombinePlayersLink = false,
    printed = false,
  } = props;
  const classes = useStyles();

  const [topPlayers, setTopPlayers] = useState<ComparePlayer[]>([]);
  const [comparePlayerType, setComparePlayerType] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (currentPlayer && user?.accessLevel) {
      let comparePlayerType = ComparePlayerType.HIGH_SCHOOL;
      if (currentPlayer?.playerNFLTeams?.length) {
        comparePlayerType = ComparePlayerType.NFL;
      } else if (currentPlayer?.playerColleges?.length) {
        comparePlayerType = ComparePlayerType.COLLEGE;
      }
      setComparePlayerType(comparePlayerType);

      fetchTopPlayers(comparePlayerType);
    }
  }, [user, currentPlayer]);

  function fetchTopPlayers (comparePlayerType:string) {
    setLoading(true);

    const currentYear:number = new Date().getFullYear();

    const accessYearMapping:any = {
      'senior': currentYear,
      'junior': currentYear + 1,
      'sophomore': currentYear + 2,
      'freshman': currentYear + 3,
    };

    const highSchoolAccessYears = getHighSchoolAccessYears(user);

    const highSchoolGradYearTo:number = highSchoolAccessYears.length > 0
      ? accessYearMapping[highSchoolAccessYears[0]] || 0
      : 0;
    const highSchoolGradYearFrom:number = highSchoolAccessYears.length > 0
      ? accessYearMapping[highSchoolAccessYears[highSchoolAccessYears.length - 1]] || 0
      : 0;

    const hasPAIData = currentPlayer && typeof getScoreValue(currentPlayer.pai) === 'number';
    const hasCombineData = currentPlayer && typeof getScoreValue(currentPlayer.combine) === 'number';
    const primaryHS:HighSchool = (currentPlayer.highSchools || []).find((highSchool:HighSchool) => highSchool && highSchool.isPrimary) || {} as HighSchool;
    const hasMultiSportData = currentPlayer && !!get(primaryHS, 'otherSports', []).length;

    const stars = (typeof currentPlayer?._247Star === 'number' || typeof currentPlayer?.compStar === 'number')
      ? currentPlayer?._247Star || currentPlayer?.compStar || 0
      : null;

    gql(`
      comparePlayersCached(
        sourcePlayerSlug: "${currentPlayer.slug}",
        resultType: "${comparePlayerType}",
        includePai: ${!!hasPAIData},
        includeCombine: ${!!hasCombineData},
        includeStarRating: ${typeof stars === 'number'},
        includeMultiSport: ${hasMultiSportData},
        highSchoolGradYearFrom: ${highSchoolGradYearFrom},
        highSchoolGradYearTo: ${highSchoolGradYearTo},
        includeHighSchoolArchive: false,
        highSchoolStates: [],
        includeAllYears: true,
        nflDraftYearFrom: 0,
        nflDraftYearTo: 0,
        groups: [],
        transferStatus: [],
        transferYears: [],
        conferenceIds: [],
        teamIds: [],
        limit: 5,
        includeSourcePlayer: false,
        trackPageView: false
      ) {
          id
          slug
          firstName
          lastName
          photoUrl
          percentMatch
          highSchools
          _247Star
          compStar
          pai
          combine
          multiSport
          hsCombines {
            id
          }
          playerColleges {
            id
            recruitingClass
            team {
              id
              name
            }
            positions
            isPrimary
          }
          playerNFLTeams {
            id
            draftYear
            team {
              id
              name
            }
            positions
            isPrimary
          }
        }
    `)
      .then((data:any) => data.comparePlayersCached as ComparePlayer[])
      .then((comparePlayers:ComparePlayer[]) => {
        setTopPlayers(comparePlayers && comparePlayers.length
          ? comparePlayers
          : []
        );
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  const canComparePlayers = user?.accessLevel?.canComparePlayers && !hideCombinePlayersLink;

  return (
    <NamedBox
      className={clsx(
        classes.playerProfileTopPlayers,
        printed && classes.printed,
        className,
      )}
      name='Top Player Comparisons'
      headerContent={canComparePlayers
        ? <div className={classes.compareWrapper}>
            <CompareIcon className={classes.compareIcon} />
            <Link
              className={classes.compareLink}
              to={`/compare/${currentPlayer.slug}`}
            >
              Compare Players
            </Link>
          </div>
        : null
      }
    >
      <div className={classes.playerCards}>
        <Loader inProgress={loading} />

        {!topPlayers?.length && (
          <div className={classes.noData}>
            No Top Players data
          </div>
        )}

        {topPlayers.map((player:ComparePlayer, index:number) => (
          <TopPlayerCard
            key={player.id}
            index={index}
            className={classes.playerCard}
            player={player}
            comparePlayerType={comparePlayerType}
          />
        ))}

        <div className={classes.space} />
      </div>
    </NamedBox>
  );
}

