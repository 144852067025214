////
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import SaveIcon from '../icons/SaveIcon';
import Button from '../atoms/Button';
import Checkbox from '../atoms/Checkbox';
import Slider from '../atoms/Slider';
import Accordion from '../atoms/Accordion';
import CloseButton from '../atoms/CloseButton';
import Input from '../atoms/Input';
import { SearchByNamePlayer } from '../organisms/SearchPlayers';
import SearchPlayerNames from '../organisms/SearchPlayerNames';
import TeamAutoComplete from './TeamAutoComplete';
import MultiSelect from './MultiSelect';
import CityAutoComplete from './CityAutoComplete';
import { numberWithCommas } from '../services/converter';
import { getMaxHighSchoolClassYear } from '../services/user';
import { getEventFormatType, FormatType } from '../services/event-names-and-formats';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
} from '../styles/colors';
import MEDIA from '../styles/media';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  HSFilter,
  NFLFilter,
  CollegeFilter,
  PlayerPosition,
} from '../types/SearchPlayer';
import Team from '../types/Team';
import Conference from '../types/Conference';
import SearchCounts from '../types/SearchCounts';
import State from '../types/State';
import User from '../types/User';

export type PositionType = {
  [key: string]: any
};

export type PlayerPositionType = {
  [key: string]: string[];
};

interface SearchPlayerFilterDrawerProps {
  loading: boolean;
  isDrawerOpen: boolean;
  user?: User;
  isAdmin?: boolean;
  isNFL?: boolean;
  currentRecruitingYear: number;
  nflDraftYear: number;
  fbsConferences: Conference[];
  fcsConferences: Conference[];
  nflTeams: Team[];
  collegeTeams: Team[];
  jucoTeams: Team[];
  searchCounts?: SearchCounts;
  searchByNamePlayers: SearchByNamePlayer[];
  setSearchByNamePlayers: (searchByNamePlayers:SearchByNamePlayer[]) => void;
  hsPlayersForbidden: boolean;
  collegePlayersForbidden: boolean;
  nflPlayersForbidden: boolean;
  archivePlayersForbidden: boolean;
  selectedStates: string[];
  states: State[];
  needMoreFilters: boolean;
  positionFilters: PlayerPositionType;
  hsFilters: string[];
  nflFilters: string[];
  collegeFilters: string[];
  playerTypeFilters: string[];
  collegeLevelFilters: string[];
  fbsConferenceFilters: number[];
  fcsConferenceFilters: number[];
  nflDraftTeamFilters: number[];
  transferPortalFilters: string[];
  transferYearsFilters: string[];
  otherSportFilters: string[];
  combineEventFilters: string[];
  collegeRangeFilters: any;
  nflRangeFilters: any;
  trackAndFieldEventFilters: Array<number | number[]>;
  combineRangeFilters: any;
  offerFBSConferenceFilters: number[];
  offerFCSConferenceFilters: number[];
  hsName: string;
  hsCity: string;
  hsCounty: string;
  collegeName: string;
  gpaMinimum: string;
  satMinimum: string;
  actMinimum: string;
  offerSchoolName: string;
  committedSchoolName: string;
  combineCityName: string;
  hsClassYearRangeFilter: number|number[];
  hsArchiveFilter: number|number[];
  prepFilter: number|number[];
  hsHeightRangeFilter: number|number[];
  hsWeightRangeFilter: number|number[];
  hsWingspanRangeFilter: number|number[];
  hsArmLengthRangeFilter: number|number[];
  hsHandSizeRangeFilter: number|number[];
  paiRangeFilter: number|number[];
  ppiRangeFilter: number|number[];
  combineScoreRangeFilter: number|number[];
  proCombineScoreRangeFilter: number|number[];
  rating247RangeFilter: number|number[];
  offerCountRangeFilter: number|number[];
  hsTrackAndFieldYearRangeFilter: number|number[];
  combineYearRangeFilter: number|number[];
  collegeClassYearRangeFilter: number|number[];
  collegeWeightFilter: number|number[];
  collegeHeightFilter: number|number[];
  activePlayersOnly: boolean;
  uncommittedEnabled: boolean;

  setIsDrawerOpen: (value:boolean) => void;
  applyFilters: () => void;
  setDialogOpen: ((isOpen:boolean) => void);
  handleAllPositionChange: (positionType:any, playerType:string) => ((checked:boolean) => void);

  onFilterCheckBoxChange: (key:string, playerType:string) => ((checked:boolean) => void);
  onLevelFilterCheckBoxChange: (value:string) => ((checked:boolean) => void);
  onFBSConferenceFilterCheckBoxChange: (value:number) => ((checked:boolean) => void);
  onFCSConferenceFilterCheckBoxChange: (value:number) => ((checked:boolean) => void);
  onTransferPortalFilterCheckBoxChange: (value:string) => ((checked:boolean) => void);
  onTransferYearsFilterCheckBoxChange: (value:string) => ((checked:boolean) => void);
  onNFLDraftTeamCheckBoxChange: (value:number) => ((checked:boolean) => void);
  onOtherSportCheckBoxChange: (value:string) => ((checked:boolean) => void);
  onCombineEventFilterCheckBoxChange: (value:string) => ((checked:boolean) => void);
  onOfferFBSConferenceCheckBoxChange: (value:number) => ((checked:boolean) => void);
  onOfferFCSConferenceCheckBoxChange: (value:number) => ((checked:boolean) => void);
  handlePlayerTypeChange: (value:string) => ((checked:boolean) => void);
  setCollegeRangeFilters: (value:any) => void;
  setNflRangeFilters: (value:any) => void;
  setTrackAndFieldEventFilters: (value:any) => void;
  setCombineRangeFilters: (value:any) => void;
  setHsClassYearRangeFilter: (value:number | number[]) => void;
  setCollegeHeightFilter: (value:number | number[]) => void;
  setCollegeWeightFilter: (value:number | number[]) => void;
  setHsHeightRangeFilter: (value:number | number[]) => void;
  setCollegeClassYearRangeFilter: (value:number | number[]) => void;
  setHsWeightRangeFilter: (value:number | number[]) => void;
  setHSWingspanRangeFilter: (value:number | number[]) => void;
  setHSHandSizeRangeFilter: (value:number | number[]) => void;
  setHSArmLengthRangeFilter: (value:number | number[]) => void;
  setPaiRangeFilter: (value:number | number[]) => void;
  setPpiRangeFilter: (value:number | number[]) => void;
  setCombineScoreRangeFilter: (value:number | number[]) => void;
  setProCombineScoreRangeFilter: (value:number | number[]) => void;
  setRating247RangeFilter: (value:number | number[]) => void;
  setOfferCountRangeFilter: (value:number | number[]) => void;
  setHSTrackAndFieldYearRangeFilter: (value:number | number[]) => void;
  setCombineYearRangeFilter: (value:number | number[]) => void;
  setHSArchiveFilter: (value:number | number[]) => void;
  setPrepFilter: (value:number | number[]) => void;

  setHSName: (value:string) => void;
  setHSCity: (value:string) => void;
  setHSCounty: (value:string) => void;
  setCollegeName: (value:string) => void;
  setGpaMinimum: (value:string) => void;
  setSatMinimum: (value:string) => void;
  setActMinimum: (value:string) => void;
  setOfferSchoolName: (value:string) => void;
  setCommittedSchoolName: (value:string) => void;
  setCombineCityName: (value:string) => void;
  setSelectedStates: (value:string[]) => void;
  clearAllFilters: () => void;
  setActivePlayersOnly: (value: boolean) => void;
  setUncommittedEnabled: (value: boolean) => void;
}

export const FILTER_TYPE = {
  HIGH_SCHOOL: 'high-school',
  COLLEGE: 'college',
  JUCO: 'juco',
  NFL: 'nfl',
  NFL_TEAM: 'nflTeam',
  PERSONAL_AND_POSITION: 'personalAndPositions',
  SCORE_AND_RATINGS: 'scoresAndRatings',
  OFFERS_AND_COMMITS: 'ScoresAndRatings',
  TRACK_AND_FIELD_EVENTS: 'trackAndFieldEvents',
  COMBINE_EVENTS: 'combineEvents',
  COLLEGE_TEAM: 'collegeTeam',
  JUCO_TEAM: 'jucoTeam',
};

const FILTERS_LIST = [
  FILTER_TYPE.HIGH_SCHOOL,
  FILTER_TYPE.PERSONAL_AND_POSITION,
  FILTER_TYPE.SCORE_AND_RATINGS,
  FILTER_TYPE.OFFERS_AND_COMMITS,
  FILTER_TYPE.TRACK_AND_FIELD_EVENTS,
  FILTER_TYPE.COMBINE_EVENTS,
  FILTER_TYPE.COLLEGE,
  FILTER_TYPE.COLLEGE_TEAM,
  FILTER_TYPE.NFL,
  FILTER_TYPE.NFL_TEAM
];

const FILTERS_LIST_WITH_JUCO = [
  FILTER_TYPE.HIGH_SCHOOL,
  FILTER_TYPE.PERSONAL_AND_POSITION,
  FILTER_TYPE.SCORE_AND_RATINGS,
  FILTER_TYPE.OFFERS_AND_COMMITS,
  FILTER_TYPE.TRACK_AND_FIELD_EVENTS,
  FILTER_TYPE.COMBINE_EVENTS,
  FILTER_TYPE.JUCO,
  FILTER_TYPE.JUCO_TEAM,
];

const FILTERS_TITLE = {
  [FILTER_TYPE.HIGH_SCHOOL]: 'High School',
  [FILTER_TYPE.COLLEGE]: 'College',
  [FILTER_TYPE.NFL]: 'NFL',
  [FILTER_TYPE.OFFERS_AND_COMMITS]: 'Offers & Commits',
  [FILTER_TYPE.SCORE_AND_RATINGS]: 'Scores & Ratings',
  [FILTER_TYPE.PERSONAL_AND_POSITION]: 'Personal & Positions',
  [FILTER_TYPE.TRACK_AND_FIELD_EVENTS]: 'Track & Field Events',
  [FILTER_TYPE.COMBINE_EVENTS]: 'Combine Events',
  [FILTER_TYPE.COLLEGE_TEAM]: 'College Team',
  [FILTER_TYPE.NFL_TEAM]: 'NFL Team',
  [FILTER_TYPE.JUCO]: 'Juco',
  [FILTER_TYPE.JUCO_TEAM]: 'Juco Team',
};

let DEFAULT_PLAYER_TYPES = [
  { content: 'HS', value: 'high-school'},
  { content: 'College', value: 'college'},
  { content: 'NFL', value: 'nfl'}
];

let PLAYER_TYPES = [
  { content: 'HS', value: 'high-school'},
  { content: 'College', value: 'college'},
  { content: 'NFL', value: 'nfl'}
];

const PLAYER_TYPES_WITH_JUCO = [
  { content: 'HS', value: 'high-school'},
  { content: 'JUCO', value: 'college'},
];

const POSITION = {
  OFFENSE: 'offense',
  DEFENSE: 'defense',
  SPECIAL: 'special',
};

const POSITIONS = [
  POSITION.OFFENSE,
  POSITION.DEFENSE,
  POSITION.SPECIAL,
];

const OFFENSIVE_POSITIONS = [
  { content: 'C / Center', value: 'C'},
  { content: 'OG / Offensive Guard', value: 'OG'},
  { content: 'OT / Offensive Tackle', value: 'OT'},
  { content: 'TE / Tight End', value: 'TE'},
  { content: 'FB / Fullback', value: 'FB'},
  { content: 'RB / Running Back', value: 'RB'},
  { content: 'WR / Wide Receiver', value: 'WR'},
  { content: 'QB / Quarterback', value: 'QB'},
];

const DEFENSIVE_POSITIONS = [
  { content: 'DT / Defensive Tackle', value: 'DT'},
  { content: 'DE / Defensive End', value: 'DE'},
  { content: 'LB / Linebacker', value: 'LB'},
  { content: 'DB / Safety', value: 'DB'},
  { content: 'CB / Cornerback', value: 'CB'},
];

const SPECIAL_POSITIONS = [
  { content: 'K / Kicker', value: 'K'},
  { content: 'P / Punter', value: 'P'},
  { content: 'LS / Long Snapper', value: 'LS'},
];

export const POSITION_FILTERS:PositionType = {
  [POSITION.OFFENSE]: {
    title: 'Offensive Positions:',
    listOfPositions: OFFENSIVE_POSITIONS
  },
  [POSITION.DEFENSE]: {
    title: 'Defensive Positions:',
    listOfPositions: DEFENSIVE_POSITIONS
  },
  [POSITION.SPECIAL]: {
    title: 'Special Teams:',
    listOfPositions: SPECIAL_POSITIONS
  },
};

const COLLEGE_LEVELS = [
  'FBS P5',
  'FBS G5',
  'FCS',
  'Div II',
  'Div III',
  'NAIA',
  'JUCO',
];

const TRANSFER_PORTAL_STATUSES = [
  {
    text: 'Active',
    value: 'active',
  },
  {
    text: 'Withdrawn',
    value: 'withdrawn',
  },
  {
    text: 'Matriculated',
    value: 'matriculated'
  },
];

const COMBINE_EVENTS_LIST = [
  'All-American',
  'Armory',
  'CFC',
  'NYS Prospect',
  'Opening',
  'Prep Redzone',
  'Recruit Georgia',
  'Regional',
  'Rivals',
  'Ryzer',
  'Six Star Football',
  'Team Camp',
  // 'UC Report',
  // 'Under Armour',
  'WSO (Europe)',
];

export const PLAYER_POSITION_DEFAULT_FILTERS = {
  [FILTER_TYPE.HIGH_SCHOOL]: [],
  [FILTER_TYPE.COLLEGE]: [],
  [FILTER_TYPE.NFL]: [],
};

export const SPRINTS = [
  {
    textToDisplay: '55 METERS',
    value: HSFilter.S_55_METERS,
    range: {
      min: 6,
      max: 10
    },
    event: 'event_55m',
  },
  {
    textToDisplay: '60 METERS',
    value: HSFilter.S_60_METERS,
    range: {
      min: 6,
      max: 10
    },
    event: 'event_60m',
  },
  {
    textToDisplay: '100 METERS',
    value: HSFilter.S_100_METERS,
    range: {
      min: 9,
      max: 18
    },
    event: 'event_100m',
  },
  {
    textToDisplay: '200 METERS',
    value: HSFilter.S_200_METERS,
    range: {
      min: 18,
      max: 32
    },
    event: 'event_200m',
  },
  {
    textToDisplay: '300 METERS',
    value: HSFilter.S_300_METERS,
    range: {
      min: 33,
      max: 50
    },
    event: 'event_300m'
  },
  {
    textToDisplay: '400 METERS',
    value: HSFilter.S_400_METERS,
    range: {
      min: 40,
      max: 75
    },
    event: 'event_400m',
  },
];

export const HURDLES = [
  {
    textToDisplay: '55 Meter High Hurdles',
    value: HSFilter.HH_55_METERS,
    range: {
      min: 7,
      max: 12
    },
    event: 'event_55hh'
  },
  {
    textToDisplay: '60 Meter High Hurdles',
    value: HSFilter.HH_60_METERS,
    range: {
      min: 7,
      max: 12
    },
    event: 'event_60hh',
  },
  {
    textToDisplay: '110 Meter High Hurdles',
    value: HSFilter.HH_110_METERS,
    range: {
      min: 12,
      max: 25
    },
    event: 'event_110hh',
  },
  {
    textToDisplay: '300 Int. Hurdles',
    value: HSFilter.H_300_INT,
    range: {
      min: 34,
      max: 58
    },
    event: 'event_300ih',
  },
  {
    textToDisplay: '400 Meter Hurdles',
    value: HSFilter.H_400_METERS,
    range: {
      min: 45,
      max: 80
    },
    event: 'event_400h',
  },
];

export const RELAYS = [
  {
    textToDisplay: '400 Meters Relay',
    value: HSFilter.R_400_METERS,
    range: {
      min: 38,
      max: 55
    },
    event: 'event_400r',
  },
  {
    textToDisplay: '800 Meters Relay',
    value: HSFilter.R_800_METERS,
    range: {
      min: 80,
      max: 115
    },
    event: 'event_800r',
  },
  {
    textToDisplay: '1600 Meters Relay',
    value: HSFilter.R_1600_METERS,
    range: {
      min: 180,
      max: 270
    },
    event: 'event_1600r',
  },
  {
    textToDisplay: '3200 Meters Relay',
    value: HSFilter.R_3200_METERS,
    range: {
      min: 450,
      max: 750
    },
    event: 'event_3200r',
  },
];

export const JUMPING_EVENTS = [
  {
    textToDisplay: 'Long Jump',
    value: HSFilter.LONG_JUMP,
    range: {
      min: 168,
      max: 360
    },
    event: 'event_long_jump',
  },
  {
    textToDisplay: 'Triple Jump',
    value: HSFilter.TRIPLE_JUMP,
    range: {
      min: 300,
      max: 720
    },
    event: 'event_triple_jump',
  },
  {
    textToDisplay: 'High Jump',
    value: HSFilter.HIGH_JUMP,
    range: {
      min: 54,
      max: 96
    },
    event: 'event_high_jump',
  },
  {
    textToDisplay: 'Pole Vault',
    value: HSFilter.POLE_VAULT_JUMP,
    range: {
      min: 96,
      max: 228
    },
    event: 'event_pole_vault',
  },
];

export const THROWING_EVENTS = [
  {
    textToDisplay: 'Shot Put',
    value: HSFilter.SHOTPUT,
    range: {
      min: 300,
      max: 1020
    },
    event: 'event_shotput',
  },
  {
    textToDisplay: 'Discus',
    value: HSFilter.DISCUS,
    range: {
      min: 720,
      max: 3000
    },
    event: 'event_discus',
  },
  {
    textToDisplay: 'Javelin',
    value: HSFilter.JAVELIN,
    range: {
      min: 420,
      max: 3120
    },
    event: 'event_javelin',
  },
];

export const MIDDLE_DISTANCE = [
  {
    textToDisplay: '800 Meters',
    value: HSFilter.MD_800_METERS,
    range: {
      min: 90,
      max: 180
    },
    event: 'event_800m'
  },
];

export const WINGSPAN_RANGE = {
  MIN: 50,
  MAX: 120,
};

export const HAND_SIZE_RANGE = {
  MIN: 6,
  MAX: 12,
};

export const ARM_LENGTH_RANGE = {
  MIN: 20,
  MAX: 50,
};

const useStyles = makeStyles((theme: Theme) => ({
  searchPlayerFilterDrawer: {
    width: '100%',
    maxWidth: '440px',
  },
  drawerHeader: {
    display: 'flex',
    minHeight: '70px',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  filterWrapper: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 4),

    '&:last-of-type': {
      marginBottom: theme.spacing(0),
    },
  },
  filterContent: {
    padding: theme.spacing(4, 0),
  },
  drawerHeaderTitle: {
    ...theme.typography.h2,
    margin: '0 0 0 12px',
  },
  drawerHeaderActions: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  clearFiltersButton: {
    padding: 0,
    appearance: 'none',
    background: 'none',
    border: 0,
    marginRight: theme.spacing(2),
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1,
    textDecoration: 'underline',
    color: COLOR_BLUE,
    cursor: 'pointer',
  },
  actionIcon: {
    width: '24px',
    height: '24px',
  },
  closeButton: {
    marginLeft: 'auto',
  },

  drawerContent: {
    padding: theme.spacing(4, 0),
  },

  divider: {
    margin: theme.spacing(2, 0),
  },

  foldersDropdown: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  dropdown:{
    height: '50px',
    width: '100%',
    marginTop: theme.spacing(1.5),
  },
  dropdownSelector: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
    fontSize: theme.typography.pxToRem(16),
  },

  filterTitle: {
    fontWeight: 700,
  },
  filterLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: '30px',
  },

  positionFilters: {
    display: 'flex',
    alignItems: 'center',
  },

  playerTypes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  checkboxList: {
    marginLeft: theme.spacing(3),
  },

  checkboxWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  checkboxWrapDisabled: {
    color: COLOR_DARK_GRAY,
    cursor: 'not-allowed',
  },

  checkbox: {
    marginRight: theme.spacing(1),
  },

  searchPlayerNames: {
    marginTop: theme.spacing(2),
  },

  playerScore: {
    width: '32px',
    height: '32px',
    fontSize: theme.typography.pxToRem(14),
  },
  slider: {
    padding: theme.spacing(3, 0),
    boxSizing: 'border-box',
  },
  marginBottom15: {
    marginBottom: theme.spacing(2),
  },
  drawerActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(4),
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  drawerAction: {
    height: '50px',
    width: '170px',
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& $actionIcon': {
      marginRight: theme.spacing(1.5),
    },

    '&:last-of-type': {
      marginRight: 0,
    },
  },
  drawerFooter: {
    position: 'static',
    bottom: 0,
  },
  accordion: {
    marginBottom: theme.spacing(2),
  },
  accordionSummary: {
    paddingLeft: theme.spacing(3.5),
    fontSize: theme.typography.pxToRem(14),
  },

  input: {
    width: '100%',
    marginTop: theme.spacing(1.5),
  },
  inputField: {
    padding: theme.spacing(2.5, 2, 1),
  },

  [MEDIA.MOBILE]: {
    filterWrapper: {
      padding: theme.spacing(0, 3.5),
    },

    checkboxWrap: {
      marginRight: theme.spacing(3),
      fontSize: theme.typography.pxToRem(14),
    },

    playerTypes: {
      display: 'block',

      '& $checkboxWrap': {
        marginRight: 0,
        marginTop: theme.spacing(1),

        '&:fist-of-type': {
          marginTop: 0,
        },
      },
    },

    drawerActions: {
      padding: theme.spacing(2),
    },
  },
}), { name: SearchPlayerFilterDrawer.name });

export default function SearchPlayerFilterDrawer (props:SearchPlayerFilterDrawerProps) {
  const classes = useStyles();
  const {
    loading = false,
    isDrawerOpen = false,
    user,
    isAdmin,
    isNFL,
    currentRecruitingYear = new Date().getFullYear(),
    nflDraftYear,
    fbsConferences,
    fcsConferences,
    nflTeams,
    collegeTeams,
    jucoTeams,
    searchCounts,
    searchByNamePlayers,
    setSearchByNamePlayers,
    hsPlayersForbidden,
    collegePlayersForbidden,
    nflPlayersForbidden,
    archivePlayersForbidden,
    selectedStates,
    states,
    needMoreFilters,
    positionFilters,
    hsFilters = [],
    collegeFilters = [],
    nflFilters=[],
    collegeLevelFilters = [],
    fbsConferenceFilters=[],
    fcsConferenceFilters=[],
    transferPortalFilters=[],
    transferYearsFilters=[],
    collegeRangeFilters,
    nflRangeFilters,
    nflDraftTeamFilters,
    otherSportFilters,
    trackAndFieldEventFilters,
    combineEventFilters,
    combineRangeFilters,
    playerTypeFilters,
    offerFBSConferenceFilters,
    offerFCSConferenceFilters,
    hsName,
    hsCity,
    hsCounty,
    collegeName,
    gpaMinimum,
    satMinimum,
    actMinimum,
    offerSchoolName,
    committedSchoolName,
    combineCityName,
    hsClassYearRangeFilter,
    hsArchiveFilter,
    prepFilter,
    hsHeightRangeFilter,
    hsWeightRangeFilter,
    hsWingspanRangeFilter,
    hsArmLengthRangeFilter,
    hsHandSizeRangeFilter,
    paiRangeFilter,
    ppiRangeFilter,
    combineScoreRangeFilter,
    proCombineScoreRangeFilter,
    rating247RangeFilter,
    offerCountRangeFilter,
    hsTrackAndFieldYearRangeFilter,
    combineYearRangeFilter,
    collegeClassYearRangeFilter,
    collegeWeightFilter,
    collegeHeightFilter,
    activePlayersOnly,
    uncommittedEnabled,

    setIsDrawerOpen,
    applyFilters,
    setDialogOpen,
    handleAllPositionChange,

    onFilterCheckBoxChange,
    onFBSConferenceFilterCheckBoxChange,
    onFCSConferenceFilterCheckBoxChange,
    onTransferPortalFilterCheckBoxChange,
    onTransferYearsFilterCheckBoxChange,
    onOfferFBSConferenceCheckBoxChange,
    onOfferFCSConferenceCheckBoxChange,
    handlePlayerTypeChange,

    setHsClassYearRangeFilter,
    setCollegeClassYearRangeFilter,
    setCollegeRangeFilters,
    setHsHeightRangeFilter,
    setNflRangeFilters,
    setCollegeHeightFilter,
    setCollegeWeightFilter,
    setHsWeightRangeFilter,
    setHSWingspanRangeFilter,
    setHSHandSizeRangeFilter,
    setHSArmLengthRangeFilter,
    setPaiRangeFilter,
    setPpiRangeFilter,
    setCombineScoreRangeFilter,
    setProCombineScoreRangeFilter,
    setRating247RangeFilter,
    setOfferCountRangeFilter,
    setHSTrackAndFieldYearRangeFilter,
    setTrackAndFieldEventFilters,
    setCombineYearRangeFilter,
    setCombineRangeFilters,
    setHSArchiveFilter,
    setPrepFilter,

    onLevelFilterCheckBoxChange,
    onNFLDraftTeamCheckBoxChange,
    onOtherSportCheckBoxChange,
    onCombineEventFilterCheckBoxChange,
    setHSName,
    setHSCity,
    setHSCounty,
    setCollegeName,
    setGpaMinimum,
    setSatMinimum,
    setActMinimum,
    setOfferSchoolName,
    setCommittedSchoolName,
    setCombineCityName,
    setSelectedStates,
    clearAllFilters,
    setActivePlayersOnly,
    setUncommittedEnabled,
  } = props;

  const positions = POSITIONS;

  const HS_CLASS_YEAR_RANGE = {
    MIN: (playerTypeFilters.includes(FILTER_TYPE.COLLEGE) || playerTypeFilters.includes(FILTER_TYPE.NFL))
      ? 1970
      : currentRecruitingYear,
    MAX: getMaxHighSchoolClassYear(user, currentRecruitingYear),
  };

  const ARCHIVE_YEAR_RANGE = {
    MIN: 2010,
    MAX: currentRecruitingYear,
  };

  const PREP_YEAR_RANGE = {
    MIN: 2010,
    MAX: currentRecruitingYear,
  };

  const TRACK_AND_FIELD_YEAR_RANGE = {
    MIN: 1970,
    MAX: currentRecruitingYear + 4,
  };

  const COMBINE_YEAR_RANGE = {
    MIN: 2010,
    MAX: currentRecruitingYear + 1,
  };

  const HEIGHT_RANGE = {
    MIN: 60,
    MAX: 84,
  };

  const WEIGHT_RANGE = {
    MIN: 125,
    MAX: 400,
  };

  const NFL_DRAFT_YEAR_RANGE = {
    MIN: 1970,
    MAX: nflDraftYear,
  };

  const COLLEGE_YEAR_RANGE = {
    MIN: 1970,
    MAX: currentRecruitingYear,
  };

  const WATCH_LIST_YEAR_RANGE = {
    MIN: 2020,
    MAX: nflDraftYear + 1,
  };

  const ALL_AMERICAN_YEAR_RANGE = {
    MIN: 2010,
    MAX: currentRecruitingYear - 1,
  };

  const ALL_CONFERENCE_YEAR_RANGE = {
    MIN: 2010,
    MAX: currentRecruitingYear - 1,
  };

  const ALL_PRO_YEAR_RANGE = {
    MIN: 2010,
    MAX: nflDraftYear,
  };

  const PRO_BOWL_YEAR_RANGE = {
    MIN: 2010,
    MAX: nflDraftYear,
  };

  const twoDigitCurrentRecruitingYear:number = Number(currentRecruitingYear.toString().slice(-2));
  const TRANSFER_YEARS = [
    {
      text: `${twoDigitCurrentRecruitingYear-1}-${twoDigitCurrentRecruitingYear}`,
      value: `${twoDigitCurrentRecruitingYear-1}-${twoDigitCurrentRecruitingYear}`,
    },
    {
      text: `${twoDigitCurrentRecruitingYear-2}-${twoDigitCurrentRecruitingYear-1}`,
      value: `${twoDigitCurrentRecruitingYear-2}-${twoDigitCurrentRecruitingYear-1}`,
    },
    {
      text: `${twoDigitCurrentRecruitingYear-3}-${twoDigitCurrentRecruitingYear-2}`,
      value: `${twoDigitCurrentRecruitingYear-3}-${twoDigitCurrentRecruitingYear-2}`,
    },
    {
      text: `${twoDigitCurrentRecruitingYear-4}-${twoDigitCurrentRecruitingYear-3}`,
      value: `${twoDigitCurrentRecruitingYear-4}-${twoDigitCurrentRecruitingYear-3}`,
    }
  ];

  const [selectedCommittedSchool, setSelectedCommittedSchool] = useState<Team | null>(null);
  const [selectedCollegeTeam, setSelectedCollegeTeam] = useState<Team | null>(null);
  const [selectedOfferSchool, setSelectedOfferSchool] = useState<Team | null>(null);

  const newPlayersEnabled = !hsPlayersForbidden && playerTypeFilters.length === 1 && playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL);
  const archivePlayersEnabled = !archivePlayersForbidden && (playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL) || isAdmin);
  const internationalPlayersEnabled = !hsPlayersForbidden && playerTypeFilters.length === 1 && playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL);

  const hasUDFAEnabled = ((nflFilters || []).includes(NFLFilter.UDFA) || (
    (nflFilters || []).includes(NFLFilter.DRAFT_ROUND)
      && nflRangeFilters[NFLFilter.DRAFT_ROUND]
      && nflRangeFilters[NFLFilter.DRAFT_ROUND].length > 0
      && nflRangeFilters[NFLFilter.DRAFT_ROUND][0] === 0
    )
  );

  
  useEffect(() => {
    if (selectedCommittedSchool) {
      setCommittedSchoolName(selectedCommittedSchool.shortName);
    }
  }, [selectedCommittedSchool]);

  useEffect(() => {
    if (selectedCollegeTeam) {
      setCollegeName(selectedCollegeTeam.shortName);
    }
  }, [selectedCollegeTeam]);

  useEffect(() => {
    if (selectedOfferSchool) {
      setOfferSchoolName(selectedOfferSchool.shortName);
    }
  }, [selectedOfferSchool]);
  const hasJucoAccessEnabled = user?.accessLevel?.hasJucoAccess && !user?.accessLevel?.hasCollegeAccess;
  const FILTER_LIST_FINAL = hasJucoAccessEnabled ? FILTERS_LIST_WITH_JUCO : FILTERS_LIST;
  if (hasJucoAccessEnabled) {
    PLAYER_TYPES = PLAYER_TYPES_WITH_JUCO;
  } else {
    PLAYER_TYPES = DEFAULT_PLAYER_TYPES;
  }
  const activePlayersEnabled = playerTypeFilters.length === 1 && playerTypeFilters.includes(FILTER_TYPE.COLLEGE) || (hasJucoAccessEnabled && playerTypeFilters.includes(FILTER_TYPE.COLLEGE));

  function renderPositions (playerType:string, filters:string[]) {
    return (
      <>
        <div className={classes.filterWrapper}>
          <div className={classes.positionFilters}>
            <div className={classes.checkboxWrap}>
              <Checkbox
                className={classes.checkbox}
                checked={(filters || []).includes(PlayerPosition.PRIMARY_POSITION)}
                onChange={onFilterCheckBoxChange(PlayerPosition.PRIMARY_POSITION, playerType)}
              />
              Primary
            </div>

            <div className={classes.checkboxWrap}>
              <Checkbox
                className={classes.checkbox}
                checked={(filters || []).includes(PlayerPosition.SECONDARY_POSITION)}
                onChange={onFilterCheckBoxChange(PlayerPosition.SECONDARY_POSITION, playerType)}
              />
              Secondary
            </div>
          </div>
        </div>

        {positions.map((position: string) => (
          <div
            key={position}
            className={classes.filterWrapper}
          >
            <div className={clsx(classes.filterLabel, classes.marginBottom15)}>
              {POSITION_FILTERS[position].title}
            </div>

            {POSITION_FILTERS[position].listOfPositions.map((positionType: any) => (
              <div
                key={positionType.value}
                className={clsx(classes.checkboxWrap, classes.marginBottom15)}
              >
                <Checkbox
                  className={classes.checkbox}
                  checked={!!(positionFilters[playerType] || []).find(selectedFilter => selectedFilter === positionType.value)}
                  onChange={handleAllPositionChange(positionType, playerType)}
                />
                {positionType.content}
              </div>
            ))}
          </div>
        ))}
      </>
    );
  }

  // const isOnlyHighSchoolSelected = playerTypeFilters.length === 1 && playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL);
  const isSelectedHSClassYearFilter = (hsFilters || []).includes(HSFilter.HS_CLASS_YEAR);
  const isDisabledHSClassFilter = hsPlayersForbidden; //hsPlayersForbidden (Need to confirm about hsPlayersForbidden)
  
  function renderResults(playerType: string) {
    switch (playerType) {
      case FILTER_TYPE.HIGH_SCHOOL:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.HS_STATE)}
                  onChange={onFilterCheckBoxChange(HSFilter.HS_STATE, FILTER_TYPE.HIGH_SCHOOL)}
                />
                High School
              </div>

              {(hsFilters || []).includes(HSFilter.HS_STATE) && (
                <>
                  <Input
                    className={classes.input}
                    inputFieldClassName={classes.inputField}
                    label='High School'
                    fontSize={16}
                    value={hsName}
                    autoFocus
                    onChange={setHSName}
                  />
                  <Input
                    className={classes.input}
                    inputFieldClassName={classes.inputField}
                    label='City'
                    fontSize={16}
                    value={hsCity}
                    onChange={setHSCity}
                  />
                  <Input
                    className={classes.input}
                    inputFieldClassName={classes.inputField}
                    label='County'
                    fontSize={16}
                    value={hsCounty}
                    onChange={setHSCounty}
                  />

                  <MultiSelect
                    className={classes.dropdown}
                    selectorRootClassName={classes.dropdownSelector}
                    labelWhenSelectedNone='Select a state'
                    labelWhenSelectedAll='All States'
                    items={states.map((state:State) => ({
                      content: `${state.code} - ${state.name}`,
                      value: state.code,
                    }))}
                    values={selectedStates}
                    onChange={(selectedStates:string[]) => {
                      setSelectedStates(selectedStates);
                    }}
                  />
                </>
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, isDisabledHSClassFilter && classes.checkboxWrapDisabled)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={isSelectedHSClassYearFilter}
                  onChange={onFilterCheckBoxChange(HSFilter.HS_CLASS_YEAR, FILTER_TYPE.HIGH_SCHOOL)}
                  disabled={isDisabledHSClassFilter}
                />
                HS Class Year
              </div>

              {(hsFilters || []).includes(HSFilter.HS_CLASS_YEAR) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.HS_CLASS_YEAR)}
                  className={classes.slider}
                  label='HS Class'
                  min={HS_CLASS_YEAR_RANGE.MIN}
                  step={1}
                  max={HS_CLASS_YEAR_RANGE.MAX}
                  defaultValue={hsClassYearRangeFilter || [HS_CLASS_YEAR_RANGE.MIN, HS_CLASS_YEAR_RANGE.MAX]}
                  marks={[
                    { value: HS_CLASS_YEAR_RANGE.MIN, label: HS_CLASS_YEAR_RANGE.MIN },
                    { value: HS_CLASS_YEAR_RANGE.MAX, label: HS_CLASS_YEAR_RANGE.MAX }
                  ]}
                  onChange={(value:number|number[]) => setHsClassYearRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, !newPlayersEnabled && classes.checkboxWrapDisabled)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.NEW_PLAYER)}
                  onChange={onFilterCheckBoxChange(HSFilter.NEW_PLAYER, FILTER_TYPE.HIGH_SCHOOL)}
                  disabled={!newPlayersEnabled}
                />
                New Player {searchCounts && searchCounts.newCount ? `(${numberWithCommas(searchCounts.newCount)})` : ''}
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, !archivePlayersEnabled && classes.checkboxWrapDisabled)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.ARCHIVES)}
                  onChange={onFilterCheckBoxChange(HSFilter.ARCHIVES, FILTER_TYPE.HIGH_SCHOOL)}
                  disabled={!archivePlayersEnabled}
                />
                Archive {searchCounts && searchCounts.archiveCount ? `(${numberWithCommas(searchCounts.archiveCount)})` : ''}
              </div>

              {(hsFilters || []).includes(HSFilter.ARCHIVES) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.ARCHIVES)}
                  className={classes.slider}
                  label='Archives'
                  min={ARCHIVE_YEAR_RANGE.MIN}
                  step={1}
                  max={ARCHIVE_YEAR_RANGE.MAX}
                  defaultValue={hsArchiveFilter || [ARCHIVE_YEAR_RANGE.MIN, ARCHIVE_YEAR_RANGE.MAX]}
                  marks={[
                    { value: ARCHIVE_YEAR_RANGE.MIN, label: ARCHIVE_YEAR_RANGE.MIN },
                    { value: ARCHIVE_YEAR_RANGE.MAX, label: ARCHIVE_YEAR_RANGE.MAX }
                  ]}
                  onChange={(value:number | number[]) => setHSArchiveFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, !archivePlayersEnabled && classes.checkboxWrapDisabled)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.PREP)}
                  onChange={onFilterCheckBoxChange(HSFilter.PREP, FILTER_TYPE.HIGH_SCHOOL)}
                  disabled={!archivePlayersEnabled}
                />
                Prep {searchCounts && searchCounts.prepCount ? `(${numberWithCommas(searchCounts.prepCount)})` : ''}
              </div>

              {(hsFilters || []).includes(HSFilter.PREP) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.PREP)}
                  className={classes.slider}
                  label='Prep'
                  min={PREP_YEAR_RANGE.MIN}
                  step={1}
                  max={PREP_YEAR_RANGE.MAX}
                  defaultValue={prepFilter || [PREP_YEAR_RANGE.MIN, PREP_YEAR_RANGE.MAX]}
                  marks={[
                    { value: PREP_YEAR_RANGE.MIN, label: PREP_YEAR_RANGE.MIN },
                    { value: PREP_YEAR_RANGE.MAX, label: PREP_YEAR_RANGE.MAX }
                  ]}
                  onChange={(value:number | number[]) => setPrepFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, !internationalPlayersEnabled && classes.checkboxWrapDisabled)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.INTERNATIONAL)}
                  onChange={onFilterCheckBoxChange(HSFilter.INTERNATIONAL, FILTER_TYPE.HIGH_SCHOOL)}
                  disabled={!internationalPlayersEnabled}
                />
                International {searchCounts && searchCounts.internationalCount ? `(${numberWithCommas(searchCounts.internationalCount)})` : ''}
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.PLAYER_NAMES)}
                  onChange={onFilterCheckBoxChange(HSFilter.PLAYER_NAMES, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Multi-Player Search
              </div>

              {(hsFilters || []).includes(HSFilter.PLAYER_NAMES) && (
                <SearchPlayerNames
                  className={classes.searchPlayerNames}
                  playerTypes={playerTypeFilters}
                  searchByNamePlayers={searchByNamePlayers}
                  setSearchByNamePlayers={setSearchByNamePlayers}
                />
              )}
            </div>
          </div>
        );
      case (FILTER_TYPE.COLLEGE):
        return (
          <div className={classes.filterContent}>
            {renderPositions(FILTER_TYPE.COLLEGE, collegeFilters)}

            <Divider
              className={classes.divider}
              variant='fullWidth'
              light
            />

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.HEIGHT)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.HEIGHT, FILTER_TYPE.COLLEGE)}
                />
                College Height
              </div>

              {(collegeFilters || []).includes(CollegeFilter.HEIGHT) && (
                <Slider
                  disabled={!(collegeFilters || []).includes(CollegeFilter.HEIGHT)}
                  className={classes.slider}
                  label='College Height'
                  min={HEIGHT_RANGE.MIN}
                  step={1}
                  max={(HEIGHT_RANGE.MAX)}
                  defaultValue={collegeHeightFilter || [HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]}
                  marks={[
                    {value: HEIGHT_RANGE.MIN, label: HEIGHT_RANGE.MIN},
                    {value: HEIGHT_RANGE.MAX, label: HEIGHT_RANGE.MAX}
                  ]}
                  labelDisplay={FormatType.FEET_AND_INCHES}
                  onChange={(value: number | number[]) => setCollegeHeightFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.WEIGHT)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.WEIGHT, FILTER_TYPE.COLLEGE)}
                />
                College Weight
              </div>

              {(collegeFilters || []).includes(CollegeFilter.WEIGHT) && (
                <Slider
                  disabled={!(collegeFilters || []).includes(CollegeFilter.WEIGHT)}
                  className={classes.slider}
                  label='College Weight'
                  min={WEIGHT_RANGE.MIN}
                  step={1}
                  max={WEIGHT_RANGE.MAX}
                  defaultValue={collegeWeightFilter || [WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]}
                  marks={[
                    {value: WEIGHT_RANGE.MIN, label: WEIGHT_RANGE.MIN},
                    {value: WEIGHT_RANGE.MAX, label: WEIGHT_RANGE.MAX}
                  ]}
                  labelDisplay={FormatType.WEIGHT}
                  onChange={(value: number | number[]) => setCollegeWeightFilter(value)}
                />
              )}
            </div>
          </div>
        );
      case (FILTER_TYPE.COLLEGE_TEAM ):
        return (
          <div className={classes.filterContent}>
            <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={activePlayersOnly}
                  onChange={(value: boolean) => setActivePlayersOnly(value)}
                  disabled={!activePlayersEnabled}
                />
                Active Players Only
              </div>
            </div>
            <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.RECRUITING_CLASS)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.RECRUITING_CLASS, FILTER_TYPE.COLLEGE)}
                />
                Recruiting Class
              </div>

              {(collegeFilters || []).includes(CollegeFilter.RECRUITING_CLASS) && (
                <Slider
                  disabled={!(collegeFilters || []).includes(CollegeFilter.RECRUITING_CLASS)}
                  className={classes.slider}
                  label='Recruiting Class'
                  min={COLLEGE_YEAR_RANGE.MIN}
                  step={1}
                  max={COLLEGE_YEAR_RANGE.MAX}
                  defaultValue={collegeClassYearRangeFilter || [COLLEGE_YEAR_RANGE.MIN, COLLEGE_YEAR_RANGE.MAX]}
                  marks={[
                    {value: COLLEGE_YEAR_RANGE.MIN, label: COLLEGE_YEAR_RANGE.MIN},
                    {value: COLLEGE_YEAR_RANGE.MAX, label: COLLEGE_YEAR_RANGE.MAX}
                  ]}
                  onChange={(value: number | number[]) => setCollegeClassYearRangeFilter(value)}
                />
              )}
            </div>
            <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.COLLEGE_NAME)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.COLLEGE_NAME, FILTER_TYPE.COLLEGE)}
                />
                College Name
              </div>

              {(collegeFilters || []).includes(CollegeFilter.COLLEGE_NAME) && (
                <TeamAutoComplete
                  className={classes.input}
                  label='Select a College'
                  teams={collegeTeams}
                  selectedTeam={collegeTeams.find((collegeTeam:Team) => collegeTeam.shortName === collegeName) || null}
                  onSelect={(team:Team | null) => team && setSelectedCollegeTeam(team)}
                />
              )}
            </div>
            <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.COLLEGE_LEVEL)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.COLLEGE_LEVEL, FILTER_TYPE.COLLEGE)}
                />
                Level
              </div>

              <div className={classes.checkboxList}>
                {((collegeFilters || []).includes(CollegeFilter.COLLEGE_LEVEL)) && (
                  (COLLEGE_LEVELS.map((level:string) => (
                    <div
                      className={clsx(classes.checkboxWrap, classes.marginBottom15)}
                      key={level}
                    >
                      <Checkbox
                        className={classes.checkbox}
                        checked={collegeLevelFilters.includes(level)}
                        onChange={onLevelFilterCheckBoxChange(level)}
                      />
                      {level}
                    </div>
                  )))
                )}
              </div>
            </div>
            <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.FBS_CONFERENCE)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.FBS_CONFERENCE, FILTER_TYPE.COLLEGE)}
                />
                FBS Conference
              </div>

              <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                {(collegeFilters || []).includes(CollegeFilter.FBS_CONFERENCE) && (
                  (fbsConferences
                    .sort((first:Conference, second:Conference) => {
                      if (first.abbr < second.abbr) return -1;
                      if (first.abbr > second.abbr) return 1;
                      return 0;
                    })
                    .map((conference:Conference, index:number) => (
                    <div
                      className={clsx(classes.checkboxWrap, classes.marginBottom15)}
                      key={index}
                    >
                      <Checkbox
                        className={classes.checkbox}
                        checked={fbsConferenceFilters.includes(conference.id)}
                        onChange={onFBSConferenceFilterCheckBoxChange(conference.id)}
                      />
                      {conference.abbr}
                    </div>
                  )))
                )}
              </div>
            </div>
            <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.FCS_CONFERENCE)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.FCS_CONFERENCE, FILTER_TYPE.COLLEGE)}
                />
                FCS Conference
              </div>

              <div className={classes.checkboxList}>
                {(collegeFilters || []).includes(CollegeFilter.FCS_CONFERENCE) && (
                  (fcsConferences
                    .sort((first:Conference, second:Conference) => {
                      if (first.abbr < second.abbr) return -1;
                      if (first.abbr > second.abbr) return 1;
                      return 0;
                    })
                    .map((conference:Conference) => (
                    <div
                      className={clsx(classes.checkboxWrap, classes.marginBottom15)}
                      key={conference.abbr}
                    >
                      <Checkbox
                        className={classes.checkbox}
                        checked={fcsConferenceFilters.includes(conference.id)}
                        onChange={onFCSConferenceFilterCheckBoxChange(conference.id)}
                      />
                      {conference.abbr}
                    </div>
                  )))
                )}
              </div>
            </div>
            <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.TRANSFER_PORTAL)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.TRANSFER_PORTAL, FILTER_TYPE.COLLEGE)}
                />
                Transfer Portal
              </div>

              <div className={classes.checkboxList}>
                {(collegeFilters || []).includes(CollegeFilter.TRANSFER_PORTAL) && (
                  (TRANSFER_PORTAL_STATUSES.map((status:any) => (
                    <div
                      className={clsx(classes.checkboxWrap, classes.marginBottom15)}
                      key={status.text}
                    >
                      <Checkbox
                        className={classes.checkbox}
                        checked={transferPortalFilters.includes(status.value)}
                        onChange={onTransferPortalFilterCheckBoxChange(status.value)}
                      />
                      {status.text}
                    </div>
                  )))
                )}
              </div>
            </div>
            <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.TRANSFER_YEARS)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.TRANSFER_YEARS, FILTER_TYPE.COLLEGE)}
                />
                Transfer Years
              </div>

              <div className={classes.checkboxList}>
                {(collegeFilters || []).includes(CollegeFilter.TRANSFER_YEARS) && (
                  (TRANSFER_YEARS.map((transferYear:any) => (
                    <div
                      className={clsx(classes.checkboxWrap, classes.marginBottom15)}
                      key={transferYear.text}
                    >
                      <Checkbox
                        className={classes.checkbox}
                        checked={transferYearsFilters.includes(transferYear.value)}
                        onChange={onTransferYearsFilterCheckBoxChange(transferYear.value)}
                      />
                      {transferYear.text}
                    </div>
                  )))
                )}
              </div>
            </div>
            <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.AWARDS)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.AWARDS, FILTER_TYPE.COLLEGE)}
                />
                College Awards
              </div>

              {(collegeFilters || []).includes(CollegeFilter.AWARDS) && (
                <>
                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={classes.checkboxWrap}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(collegeFilters || []).includes(CollegeFilter.ALL_AMERICAN)}
                        onChange={onFilterCheckBoxChange(CollegeFilter.ALL_AMERICAN, FILTER_TYPE.COLLEGE)}
                      />
                      All-American
                    </div>

                    {(collegeFilters || []).includes(CollegeFilter.ALL_AMERICAN) && (
                      <Slider
                        disabled={!(collegeFilters || []).includes(CollegeFilter.ALL_AMERICAN)}
                        className={classes.slider}
                        label='All-American'
                        min={ALL_AMERICAN_YEAR_RANGE.MIN}
                        step={1}
                        max={ALL_AMERICAN_YEAR_RANGE.MAX}
                        defaultValue={((collegeFilters || []).includes(CollegeFilter.ALL_AMERICAN) && collegeRangeFilters[CollegeFilter.ALL_AMERICAN])
                          ? collegeRangeFilters[CollegeFilter.ALL_AMERICAN]
                          : [ALL_AMERICAN_YEAR_RANGE.MIN, ALL_AMERICAN_YEAR_RANGE.MAX]
                        }
                        marks={[
                          { value: ALL_AMERICAN_YEAR_RANGE.MIN, label: ALL_AMERICAN_YEAR_RANGE.MIN },
                          { value: ALL_AMERICAN_YEAR_RANGE.MAX, label: ALL_AMERICAN_YEAR_RANGE.MAX }
                        ]}
                        onChange={(value:number | number[]) => setCollegeRangeFilters(
                          {
                            ...collegeRangeFilters,
                            [CollegeFilter.ALL_AMERICAN]: value
                          }
                        )}
                      />
                    )}
                  </div>

                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={classes.checkboxWrap}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(collegeFilters || []).includes(CollegeFilter.ALL_CONFERENCE)}
                        onChange={onFilterCheckBoxChange(CollegeFilter.ALL_CONFERENCE, FILTER_TYPE.COLLEGE)}
                      />
                      All-Conference
                    </div>

                    {(collegeFilters || []).includes(CollegeFilter.ALL_CONFERENCE) && (
                      <Slider
                        disabled={!(collegeFilters || []).includes(CollegeFilter.ALL_CONFERENCE)}
                        className={classes.slider}
                        label='All-Conference'
                        min={ALL_CONFERENCE_YEAR_RANGE.MIN}
                        step={1}
                        max={ALL_CONFERENCE_YEAR_RANGE.MAX}
                        defaultValue={((collegeFilters || []).includes(CollegeFilter.ALL_CONFERENCE) && collegeRangeFilters[CollegeFilter.ALL_CONFERENCE])
                          ? collegeRangeFilters[CollegeFilter.ALL_CONFERENCE]
                          : [ALL_CONFERENCE_YEAR_RANGE.MIN, ALL_CONFERENCE_YEAR_RANGE.MAX]
                        }
                        marks={[
                          { value: ALL_CONFERENCE_YEAR_RANGE.MIN, label: ALL_CONFERENCE_YEAR_RANGE.MIN },
                          { value: ALL_CONFERENCE_YEAR_RANGE.MAX, label: ALL_CONFERENCE_YEAR_RANGE.MAX }
                        ]}
                        onChange={(value: number | number[]) => setCollegeRangeFilters(
                          {
                            ...collegeRangeFilters,
                            [CollegeFilter.ALL_CONFERENCE]: value
                          }
                        )}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(collegeFilters || []).includes(CollegeFilter.WATCH_LIST)}
                  onChange={onFilterCheckBoxChange(CollegeFilter.WATCH_LIST, FILTER_TYPE.COLLEGE)}
                />
                Watch List
              </div>

              {(collegeFilters || []).includes(CollegeFilter.WATCH_LIST) && (
                <>
                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={classes.checkboxWrap}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(collegeFilters || []).includes(CollegeFilter.SENIOR_BOWL)}
                        onChange={onFilterCheckBoxChange(CollegeFilter.SENIOR_BOWL, FILTER_TYPE.COLLEGE)}
                      />
                      Senior Bowl
                    </div>

                    {(collegeFilters || []).includes(CollegeFilter.SENIOR_BOWL) && (
                      <Slider
                        disabled={!(collegeFilters || []).includes(CollegeFilter.SENIOR_BOWL)}
                        className={classes.slider}
                        label='Senior Bowl'
                        min={WATCH_LIST_YEAR_RANGE.MIN}
                        step={1}
                        max={WATCH_LIST_YEAR_RANGE.MAX}
                        defaultValue={((collegeFilters || []).includes(CollegeFilter.SENIOR_BOWL) && collegeRangeFilters[CollegeFilter.SENIOR_BOWL])
                          ? collegeRangeFilters[CollegeFilter.SENIOR_BOWL]
                          : [WATCH_LIST_YEAR_RANGE.MIN, WATCH_LIST_YEAR_RANGE.MAX]
                        }
                        marks={[
                          { value: WATCH_LIST_YEAR_RANGE.MIN, label: WATCH_LIST_YEAR_RANGE.MIN },
                          { value: WATCH_LIST_YEAR_RANGE.MAX, label: WATCH_LIST_YEAR_RANGE.MAX }
                        ]}
                        onChange={(value: number | number[]) => setCollegeRangeFilters(
                          {
                            ...collegeRangeFilters,
                            [CollegeFilter.SENIOR_BOWL]: value
                          }
                        )}
                      />
                    )}
                  </div>

                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={classes.checkboxWrap}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(collegeFilters || []).includes(CollegeFilter.CGS_SHOWCASE)}
                        onChange={onFilterCheckBoxChange(CollegeFilter.CGS_SHOWCASE, FILTER_TYPE.COLLEGE)}
                      />
                      CGS Showcase
                    </div>

                    {(collegeFilters || []).includes(CollegeFilter.CGS_SHOWCASE) && (
                      <Slider
                        disabled={!(collegeFilters || []).includes(CollegeFilter.CGS_SHOWCASE)}
                        className={classes.slider}
                        label='CGS Showcase'
                        min={WATCH_LIST_YEAR_RANGE.MIN}
                        step={1}
                        max={WATCH_LIST_YEAR_RANGE.MAX}
                        defaultValue={((collegeFilters || []).includes(CollegeFilter.CGS_SHOWCASE) && collegeRangeFilters[CollegeFilter.CGS_SHOWCASE])
                          ? collegeRangeFilters[CollegeFilter.CGS_SHOWCASE]
                          : [WATCH_LIST_YEAR_RANGE.MIN, WATCH_LIST_YEAR_RANGE.MAX]
                        }
                        marks={[
                          { value: WATCH_LIST_YEAR_RANGE.MIN, label: WATCH_LIST_YEAR_RANGE.MIN },
                          { value: WATCH_LIST_YEAR_RANGE.MAX, label: WATCH_LIST_YEAR_RANGE.MAX }
                        ]}
                        onChange={(value: number | number[]) => setCollegeRangeFilters(
                          {
                            ...collegeRangeFilters,
                            [CollegeFilter.CGS_SHOWCASE]: value,
                          }
                        )}
                      />
                    )}
                  </div>

                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={classes.checkboxWrap}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(collegeFilters || []).includes(CollegeFilter.EAST_WEST_SHRINE)}
                        onChange={onFilterCheckBoxChange(CollegeFilter.EAST_WEST_SHRINE, FILTER_TYPE.COLLEGE)}
                      />
                      East/West Shrine
                    </div>

                    {(collegeFilters || []).includes(CollegeFilter.EAST_WEST_SHRINE) && (
                      <Slider
                        disabled={!(collegeFilters || []).includes(CollegeFilter.EAST_WEST_SHRINE)}
                        className={classes.slider}
                        label='East/West Shrine'
                        min={WATCH_LIST_YEAR_RANGE.MIN}
                        step={1}
                        max={WATCH_LIST_YEAR_RANGE.MAX}
                        defaultValue={((collegeFilters || []).includes(CollegeFilter.EAST_WEST_SHRINE) && collegeRangeFilters[CollegeFilter.EAST_WEST_SHRINE])
                          ? collegeRangeFilters[CollegeFilter.EAST_WEST_SHRINE]
                          : [WATCH_LIST_YEAR_RANGE.MIN, WATCH_LIST_YEAR_RANGE.MAX]
                        }
                        marks={[
                          { value: WATCH_LIST_YEAR_RANGE.MIN, label: WATCH_LIST_YEAR_RANGE.MIN },
                          { value: WATCH_LIST_YEAR_RANGE.MAX, label: WATCH_LIST_YEAR_RANGE.MAX }
                        ]}
                        onChange={(value: number | number[]) => setCollegeRangeFilters(
                          {
                            ...collegeRangeFilters,
                            [CollegeFilter.EAST_WEST_SHRINE]: value,
                          }
                        )}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        );
      case (FILTER_TYPE.JUCO):
          return (
            <div className={classes.filterContent}>
              {renderPositions(FILTER_TYPE.COLLEGE, collegeFilters)}
  
              <Divider
                className={classes.divider}
                variant='fullWidth'
                light
              />
  
              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(collegeFilters || []).includes(CollegeFilter.HEIGHT)}
                    onChange={onFilterCheckBoxChange(CollegeFilter.HEIGHT, FILTER_TYPE.COLLEGE)}
                  />
                  College Height
                </div>
  
                {(collegeFilters || []).includes(CollegeFilter.HEIGHT) && (
                  <Slider
                    disabled={!(collegeFilters || []).includes(CollegeFilter.HEIGHT)}
                    className={classes.slider}
                    label='College Height'
                    min={HEIGHT_RANGE.MIN}
                    step={1}
                    max={(HEIGHT_RANGE.MAX)}
                    defaultValue={collegeHeightFilter || [HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]}
                    marks={[
                      {value: HEIGHT_RANGE.MIN, label: HEIGHT_RANGE.MIN},
                      {value: HEIGHT_RANGE.MAX, label: HEIGHT_RANGE.MAX}
                    ]}
                    labelDisplay={FormatType.FEET_AND_INCHES}
                    onChange={(value: number | number[]) => setCollegeHeightFilter(value)}
                  />
                )}
              </div>
  
              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(collegeFilters || []).includes(CollegeFilter.WEIGHT)}
                    onChange={onFilterCheckBoxChange(CollegeFilter.WEIGHT, FILTER_TYPE.COLLEGE)}
                  />
                  College Weight
                </div>
  
                {(collegeFilters || []).includes(CollegeFilter.WEIGHT) && (
                  <Slider
                    disabled={!(collegeFilters || []).includes(CollegeFilter.WEIGHT)}
                    className={classes.slider}
                    label='College Weight'
                    min={WEIGHT_RANGE.MIN}
                    step={1}
                    max={WEIGHT_RANGE.MAX}
                    defaultValue={collegeWeightFilter || [WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]}
                    marks={[
                      {value: WEIGHT_RANGE.MIN, label: WEIGHT_RANGE.MIN},
                      {value: WEIGHT_RANGE.MAX, label: WEIGHT_RANGE.MAX}
                    ]}
                    labelDisplay={FormatType.WEIGHT}
                    onChange={(value: number | number[]) => setCollegeWeightFilter(value)}
                  />
                )}
              </div>
            </div>
          );
      case (FILTER_TYPE.JUCO_TEAM):
          return (
            <div className={classes.filterContent}>
              {
                hasJucoAccessEnabled && (
                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={classes.checkboxWrap}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={activePlayersOnly}
                        onChange={() => setActivePlayersOnly(!activePlayersOnly)}
                        disabled={!activePlayersEnabled}
                      />
                      Active Players Only
                    </div>
                  </div>
                )
              }
              {
                hasJucoAccessEnabled && (
                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={classes.checkboxWrap}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(collegeFilters || []).includes(CollegeFilter.RECRUITING_CLASS)}
                        onChange={onFilterCheckBoxChange(CollegeFilter.RECRUITING_CLASS, FILTER_TYPE.COLLEGE)}
                      />
                      Recruiting Class
                    </div>
  
                    {(collegeFilters || []).includes(CollegeFilter.RECRUITING_CLASS) && (
                      <Slider
                        disabled={!(collegeFilters || []).includes(CollegeFilter.RECRUITING_CLASS)}
                        className={classes.slider}
                        label='Recruiting Class'
                        min={COLLEGE_YEAR_RANGE.MIN}
                        step={1}
                        max={COLLEGE_YEAR_RANGE.MAX}
                        defaultValue={collegeClassYearRangeFilter || [COLLEGE_YEAR_RANGE.MIN, COLLEGE_YEAR_RANGE.MAX]}
                        marks={[
                          {value: COLLEGE_YEAR_RANGE.MIN, label: COLLEGE_YEAR_RANGE.MIN},
                          {value: COLLEGE_YEAR_RANGE.MAX, label: COLLEGE_YEAR_RANGE.MAX}
                        ]}
                        onChange={(value: number | number[]) => setCollegeClassYearRangeFilter(value)}
                      />
                    )}
                  </div>
                )
              }
  
  
              {
                hasJucoAccessEnabled && (
                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={classes.checkboxWrap}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(collegeFilters || []).includes(CollegeFilter.COLLEGE_NAME)}
                        onChange={onFilterCheckBoxChange(CollegeFilter.COLLEGE_NAME, FILTER_TYPE.COLLEGE)}
                      />
                      College Name
                    </div>
  
                    {(collegeFilters || []).includes(CollegeFilter.COLLEGE_NAME) && (
                      <TeamAutoComplete
                        className={classes.input}
                        label='Select a College'
                        teams={jucoTeams}
                        selectedTeam={jucoTeams.find((jucoTeam:Team) => jucoTeam.shortName === collegeName) || null}
                        onSelect={(team:Team | null) => team && setSelectedCollegeTeam(team)}
                      />
                    )}
                  </div>
                )
              }
  
              {
                hasJucoAccessEnabled && (
                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(collegeFilters || []).includes(CollegeFilter.AWARDS)}
                        onChange={onFilterCheckBoxChange(CollegeFilter.AWARDS, FILTER_TYPE.COLLEGE)}
                      />
                      College Awards
                    </div>
  
                    {(collegeFilters || []).includes(CollegeFilter.AWARDS) && (
                      <>
                        <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                          <div className={classes.checkboxWrap}>
                            <Checkbox
                              className={classes.checkbox}
                              checked={(collegeFilters || []).includes(CollegeFilter.ALL_AMERICAN)}
                              onChange={onFilterCheckBoxChange(CollegeFilter.ALL_AMERICAN, FILTER_TYPE.COLLEGE)}
                            />
                            All-American
                          </div>
  
                          {(collegeFilters || []).includes(CollegeFilter.ALL_AMERICAN) && (
                            <Slider
                              disabled={!(collegeFilters || []).includes(CollegeFilter.ALL_AMERICAN)}
                              className={classes.slider}
                              label='All-American'
                              min={ALL_AMERICAN_YEAR_RANGE.MIN}
                              step={1}
                              max={ALL_AMERICAN_YEAR_RANGE.MAX}
                              defaultValue={((collegeFilters || []).includes(CollegeFilter.ALL_AMERICAN) && collegeRangeFilters[CollegeFilter.ALL_AMERICAN])
                                ? collegeRangeFilters[CollegeFilter.ALL_AMERICAN]
                                : [ALL_AMERICAN_YEAR_RANGE.MIN, ALL_AMERICAN_YEAR_RANGE.MAX]
                              }
                              marks={[
                                { value: ALL_AMERICAN_YEAR_RANGE.MIN, label: ALL_AMERICAN_YEAR_RANGE.MIN },
                                { value: ALL_AMERICAN_YEAR_RANGE.MAX, label: ALL_AMERICAN_YEAR_RANGE.MAX }
                              ]}
                              onChange={(value:number | number[]) => setCollegeRangeFilters(
                                {
                                  ...collegeRangeFilters,
                                  [CollegeFilter.ALL_AMERICAN]: value
                                }
                              )}
                            />
                          )}
                        </div>
  
                        <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                          <div className={classes.checkboxWrap}>
                            <Checkbox
                              className={classes.checkbox}
                              checked={(collegeFilters || []).includes(CollegeFilter.ALL_CONFERENCE)}
                              onChange={onFilterCheckBoxChange(CollegeFilter.ALL_CONFERENCE, FILTER_TYPE.COLLEGE)}
                            />
                            All-Conference
                          </div>
  
                          {(collegeFilters || []).includes(CollegeFilter.ALL_CONFERENCE) && (
                            <Slider
                              disabled={!(collegeFilters || []).includes(CollegeFilter.ALL_CONFERENCE)}
                              className={classes.slider}
                              label='All-Conference'
                              min={ALL_CONFERENCE_YEAR_RANGE.MIN}
                              step={1}
                              max={ALL_CONFERENCE_YEAR_RANGE.MAX}
                              defaultValue={((collegeFilters || []).includes(CollegeFilter.ALL_CONFERENCE) && collegeRangeFilters[CollegeFilter.ALL_CONFERENCE])
                                ? collegeRangeFilters[CollegeFilter.ALL_CONFERENCE]
                                : [ALL_CONFERENCE_YEAR_RANGE.MIN, ALL_CONFERENCE_YEAR_RANGE.MAX]
                              }
                              marks={[
                                { value: ALL_CONFERENCE_YEAR_RANGE.MIN, label: ALL_CONFERENCE_YEAR_RANGE.MIN },
                                { value: ALL_CONFERENCE_YEAR_RANGE.MAX, label: ALL_CONFERENCE_YEAR_RANGE.MAX }
                              ]}
                              onChange={(value: number | number[]) => setCollegeRangeFilters(
                                {
                                  ...collegeRangeFilters,
                                  [CollegeFilter.ALL_CONFERENCE]: value
                                }
                              )}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )
              }
  
            </div>
          );  
      case FILTER_TYPE.NFL:
        return (
          <div className={classes.filterContent}>
            {renderPositions(FILTER_TYPE.NFL, nflFilters)}

            <Divider
              className={classes.divider}
              variant='fullWidth'
              light
            />

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(nflFilters || []).includes(NFLFilter.HEIGHT)}
                  onChange={onFilterCheckBoxChange(NFLFilter.HEIGHT, FILTER_TYPE.NFL)}
                />
                NFL Height
              </div>

              {(nflFilters || []).includes(NFLFilter.HEIGHT) && (
                <Slider
                  disabled={!(nflFilters || []).includes(NFLFilter.HEIGHT)}
                  className={classes.slider}
                  label='NFL Height'
                  min={HEIGHT_RANGE.MIN}
                  step={1}
                  max={(HEIGHT_RANGE.MAX)}
                  defaultValue={((nflFilters || []).includes(NFLFilter.HEIGHT) && nflRangeFilters[NFLFilter.HEIGHT])
                    ? nflRangeFilters[NFLFilter.HEIGHT]
                    : [HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]}
                  marks={[
                    {value: HEIGHT_RANGE.MIN, label: HEIGHT_RANGE.MIN},
                    {value: HEIGHT_RANGE.MAX, label: HEIGHT_RANGE.MAX}
                  ]}
                  labelDisplay={FormatType.FEET_AND_INCHES}
                  onChange={(value: number | number[]) => setNflRangeFilters(
                    {
                      ...nflRangeFilters,
                      [NFLFilter.HEIGHT]: value
                    }
                  )}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(nflFilters || []).includes(NFLFilter.WEIGHT)}
                  onChange={onFilterCheckBoxChange(NFLFilter.WEIGHT, FILTER_TYPE.NFL)}
                />
                NFL Weight
              </div>

              {(nflFilters || []).includes(NFLFilter.WEIGHT) && (
                <Slider
                  disabled={!(nflFilters || []).includes(NFLFilter.WEIGHT)}
                  className={classes.slider}
                  label='NFL Weight'
                  min={WEIGHT_RANGE.MIN}
                  step={1}
                  max={WEIGHT_RANGE.MAX}
                  defaultValue={((nflFilters || []).includes(NFLFilter.WEIGHT) && nflRangeFilters[NFLFilter.WEIGHT])
                    ? nflRangeFilters[NFLFilter.WEIGHT]
                    : [WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]
                  }
                  marks={[
                    {value: WEIGHT_RANGE.MIN, label: WEIGHT_RANGE.MIN},
                    {value: WEIGHT_RANGE.MAX, label: WEIGHT_RANGE.MAX}
                  ]}
                  labelDisplay={FormatType.WEIGHT}
                  onChange={(value: number | number[]) => setNflRangeFilters(
                    {
                      ...nflRangeFilters,
                      [NFLFilter.WEIGHT]: value
                    }
                  )}
                />
              )}
            </div>
          </div>
        );
      case FILTER_TYPE.NFL_TEAM:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(nflFilters || []).includes(NFLFilter.DRAFT_TEAM)}
                  onChange={onFilterCheckBoxChange(NFLFilter.DRAFT_TEAM, FILTER_TYPE.NFL)}
                />
                Draft Team
              </div>

              <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                {(nflFilters || []).includes(NFLFilter.DRAFT_TEAM) && (
                  (nflTeams.map((team:Team) => (
                    <div
                      className={clsx(classes.checkboxWrap, classes.marginBottom15)}
                      key={team.name}
                    >
                      <Checkbox
                        className={classes.checkbox}
                        checked={nflDraftTeamFilters.includes(team.id)}
                        onChange={onNFLDraftTeamCheckBoxChange(team.id)}
                      />
                      {team.name}
                    </div>
                  )))
                )}
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(nflFilters || []).includes(NFLFilter.DRAFT_YEAR)}
                  onChange={onFilterCheckBoxChange(NFLFilter.DRAFT_YEAR, FILTER_TYPE.NFL)}
                />
                Draft Year
              </div>

              {(nflFilters || []).includes(NFLFilter.DRAFT_YEAR) && (
                <Slider
                  disabled={!(nflFilters || []).includes(NFLFilter.DRAFT_YEAR)}
                  className={classes.slider}
                  label='Draft Year'
                  min={NFL_DRAFT_YEAR_RANGE.MIN}
                  step={1}
                  max={NFL_DRAFT_YEAR_RANGE.MAX}
                  defaultValue={((nflFilters || []).includes(NFLFilter.DRAFT_YEAR) && nflRangeFilters[NFLFilter.DRAFT_YEAR])
                    ? nflRangeFilters[NFLFilter.DRAFT_YEAR]
                    : [NFL_DRAFT_YEAR_RANGE.MIN, NFL_DRAFT_YEAR_RANGE.MAX]
                  }
                  marks={[
                    {value: NFL_DRAFT_YEAR_RANGE.MIN, label: NFL_DRAFT_YEAR_RANGE.MIN},
                    {value: NFL_DRAFT_YEAR_RANGE.MAX, label: NFL_DRAFT_YEAR_RANGE.MAX}
                  ]}
                  onChange={(value: number | number[]) => setNflRangeFilters(
                    {
                      ...nflRangeFilters,
                      [NFLFilter.DRAFT_YEAR]: value
                    }
                  )}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(nflFilters || []).includes(NFLFilter.DRAFT_ROUND)}
                  onChange={onFilterCheckBoxChange(NFLFilter.DRAFT_ROUND, FILTER_TYPE.NFL)}
                />
                Draft Round
              </div>

              {(nflFilters || []).includes(NFLFilter.DRAFT_ROUND) && (
                <Slider
                  disabled={!(nflFilters || []).includes(NFLFilter.DRAFT_ROUND)}
                  className={classes.slider}
                  label='Draft Round'
                  min={1}
                  step={1}
                  max={12}
                  defaultValue={((nflFilters || []).includes(NFLFilter.DRAFT_ROUND) && nflRangeFilters[NFLFilter.DRAFT_ROUND])
                    ? nflRangeFilters[NFLFilter.DRAFT_ROUND]
                    : [1, 12]
                  }
                  marks={[
                    {value: 1, label: 1},
                    {value: 12, label: 12}
                  ]}
                  onChange={(value: number | number[]) => setNflRangeFilters(
                    {
                      ...nflRangeFilters,
                      [NFLFilter.DRAFT_ROUND]: value
                    }
                  )}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={hasUDFAEnabled}
                  onChange={onFilterCheckBoxChange(NFLFilter.UDFA, FILTER_TYPE.NFL)}
                />
                UDFA
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(nflFilters || []).includes(NFLFilter.AWARDS)}
                  onChange={onFilterCheckBoxChange(NFLFilter.AWARDS, FILTER_TYPE.NFL)}
                />
                NFL Awards
              </div>

              {(nflFilters || []).includes(NFLFilter.AWARDS) && (
                <>
                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={classes.checkboxWrap}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(nflFilters || []).includes(NFLFilter.ALL_PRO)}
                        onChange={onFilterCheckBoxChange(NFLFilter.ALL_PRO, FILTER_TYPE.NFL)}
                      />
                      All Pro
                    </div>

                    {(nflFilters || []).includes(NFLFilter.ALL_PRO) && (
                      <Slider
                        disabled={!(nflFilters || []).includes(NFLFilter.ALL_PRO)}
                        className={classes.slider}
                        label='All Pro'
                        min={ALL_PRO_YEAR_RANGE.MIN}
                        step={1}
                        max={ALL_PRO_YEAR_RANGE.MAX}
                        defaultValue={((nflFilters || []).includes(NFLFilter.ALL_PRO) && nflRangeFilters[NFLFilter.ALL_PRO])
                          ? nflRangeFilters[NFLFilter.ALL_PRO]
                          : [ALL_PRO_YEAR_RANGE.MIN, ALL_PRO_YEAR_RANGE.MAX]
                        }
                        marks={[
                          { value: ALL_PRO_YEAR_RANGE.MIN, label: ALL_PRO_YEAR_RANGE.MIN },
                          { value: ALL_PRO_YEAR_RANGE.MAX, label: ALL_PRO_YEAR_RANGE.MAX }
                        ]}
                        onChange={(value:number | number[]) => setNflRangeFilters({
                          ...nflRangeFilters,
                          [NFLFilter.ALL_PRO]: value,
                        })}
                      />
                    )}
                  </div>

                  <div className={clsx(classes.filterWrapper, classes.marginBottom15)}>
                    <div className={classes.checkboxWrap}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(nflFilters || []).includes(NFLFilter.PRO_BOWL)}
                        onChange={onFilterCheckBoxChange(NFLFilter.PRO_BOWL, FILTER_TYPE.NFL)}
                      />
                      Pro Bowl
                    </div>

                    {(nflFilters || []).includes(NFLFilter.PRO_BOWL) && (
                      <Slider
                        disabled={!(nflFilters || []).includes(NFLFilter.PRO_BOWL)}
                        className={classes.slider}
                        label='Pro Bowl'
                        min={PRO_BOWL_YEAR_RANGE.MIN}
                        step={1}
                        max={PRO_BOWL_YEAR_RANGE.MAX}
                        defaultValue={((nflFilters || []).includes(NFLFilter.PRO_BOWL) && nflRangeFilters[NFLFilter.PRO_BOWL])
                          ? nflRangeFilters[NFLFilter.PRO_BOWL]
                          : [PRO_BOWL_YEAR_RANGE.MIN, PRO_BOWL_YEAR_RANGE.MAX]
                        }
                        marks={[
                          { value: PRO_BOWL_YEAR_RANGE.MIN, label: PRO_BOWL_YEAR_RANGE.MIN },
                          { value: PRO_BOWL_YEAR_RANGE.MAX, label: PRO_BOWL_YEAR_RANGE.MAX }
                        ]}
                        onChange={(value: number | number[]) => setNflRangeFilters({
                          ...nflRangeFilters,
                          [NFLFilter.PRO_BOWL]: value,
                        })}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        );
      case FILTER_TYPE.PERSONAL_AND_POSITION:
        return (
          <div className={classes.filterContent}>
            {renderPositions(FILTER_TYPE.HIGH_SCHOOL, hsFilters)}

            <div className={clsx(classes.filterWrapper, classes.filterLabel, classes.marginBottom15)}>
              Personal:
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.HS_HEIGHT)}
                  onChange={onFilterCheckBoxChange(HSFilter.HS_HEIGHT, FILTER_TYPE.HIGH_SCHOOL)}
                />
                HS Height
              </div>

              {(hsFilters || []).includes(HSFilter.HS_HEIGHT) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.HS_HEIGHT)}
                  className={classes.slider}
                  label='HS Height'
                  min={HEIGHT_RANGE.MIN}
                  step={1}
                  max={HEIGHT_RANGE.MAX}
                  defaultValue={hsHeightRangeFilter || [HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]}
                  marks={[
                    {value: HEIGHT_RANGE.MIN, label: HEIGHT_RANGE.MIN},
                    {value: HEIGHT_RANGE.MAX, label: HEIGHT_RANGE.MAX}
                  ]}
                  labelDisplay={FormatType.FEET_AND_INCHES}
                  onChange={(value: number | number[]) => setHsHeightRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.HS_WEIGHT)}
                  onChange={onFilterCheckBoxChange(HSFilter.HS_WEIGHT, FILTER_TYPE.HIGH_SCHOOL)}
                />
                HS Weight
              </div>

              {(hsFilters || []).includes(HSFilter.HS_WEIGHT) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.HS_WEIGHT)}
                  className={classes.slider}
                  label='HS Weight'
                  min={WEIGHT_RANGE.MIN}
                  step={1}
                  max={WEIGHT_RANGE.MAX}
                  defaultValue={hsWeightRangeFilter || [WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]}
                  marks={[
                    {value: WEIGHT_RANGE.MIN, label: WEIGHT_RANGE.MIN},
                    {value: WEIGHT_RANGE.MAX, label: WEIGHT_RANGE.MAX}
                  ]}
                  labelDisplay={FormatType.WEIGHT}
                  onChange={(value: number | number[]) => setHsWeightRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.HS_WINGSPAN)}
                  onChange={onFilterCheckBoxChange(HSFilter.HS_WINGSPAN, FILTER_TYPE.HIGH_SCHOOL)}
                />
                HS Wingspan
              </div>

              {(hsFilters || []).includes(HSFilter.HS_WINGSPAN) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.HS_WINGSPAN)}
                  className={classes.slider}
                  label='HS Wingspan'
                  min={WINGSPAN_RANGE.MIN}
                  step={1}
                  max={WINGSPAN_RANGE.MAX}
                  defaultValue={hsWingspanRangeFilter || [WINGSPAN_RANGE.MIN, WINGSPAN_RANGE.MAX]}
                  marks={[
                    { value: WINGSPAN_RANGE.MIN, label: WINGSPAN_RANGE.MIN },
                    { value: WINGSPAN_RANGE.MAX, label: WINGSPAN_RANGE.MAX },
                  ]}
                  labelDisplay={FormatType.INCHES}
                  onChange={(value: number | number[]) => setHSWingspanRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.HS_HANDSIZE)}
                  onChange={onFilterCheckBoxChange(HSFilter.HS_HANDSIZE, FILTER_TYPE.HIGH_SCHOOL)}
                />
                HS Hand Size
              </div>

              {(hsFilters || []).includes(HSFilter.HS_HANDSIZE) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.HS_HANDSIZE)}
                  className={classes.slider}
                  label='HS Hand Size'
                  min={HAND_SIZE_RANGE.MIN}
                  step={0.1}
                  max={HAND_SIZE_RANGE.MAX}
                  defaultValue={hsHandSizeRangeFilter || [HAND_SIZE_RANGE.MIN, HAND_SIZE_RANGE.MAX]}
                  marks={[
                    { value: HAND_SIZE_RANGE.MIN, label: HAND_SIZE_RANGE.MIN },
                    { value: HAND_SIZE_RANGE.MAX, label: HAND_SIZE_RANGE.MAX },
                  ]}
                  labelDisplay={FormatType.INCHES}
                  onChange={(value: number | number[]) => setHSHandSizeRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.HS_ARM_LENGTH)}
                  onChange={onFilterCheckBoxChange(HSFilter.HS_ARM_LENGTH, FILTER_TYPE.HIGH_SCHOOL)}
                />
                HS Arm Length
              </div>

              {(hsFilters || []).includes(HSFilter.HS_ARM_LENGTH) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.HS_ARM_LENGTH)}
                  className={classes.slider}
                  label='HS Arm Length'
                  min={ARM_LENGTH_RANGE.MIN}
                  step={0.1}
                  max={ARM_LENGTH_RANGE.MAX}
                  defaultValue={hsArmLengthRangeFilter || [ARM_LENGTH_RANGE.MIN, ARM_LENGTH_RANGE.MAX]}
                  marks={[
                    { value: ARM_LENGTH_RANGE.MIN, label: ARM_LENGTH_RANGE.MIN },
                    { value: ARM_LENGTH_RANGE.MAX, label: ARM_LENGTH_RANGE.MAX },
                  ]}
                  labelDisplay={FormatType.INCHES}
                  onChange={(value: number | number[]) => setHSArmLengthRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.OTHER_SPORTS)}
                  onChange={onFilterCheckBoxChange(HSFilter.OTHER_SPORTS, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Other Sports
              </div>

              <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                {(hsFilters || []).includes(HSFilter.OTHER_SPORTS) && (
                  <>
                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.NONE)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.NONE)}
                      />
                      None
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.BASEBALL)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.BASEBALL)}
                      />
                      Baseball
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.BASKETBALL)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.BASKETBALL)}
                      />
                      Basketball
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.GOLF)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.GOLF)}
                      />
                      Golf
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.HOCKEY)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.HOCKEY)}
                      />
                      Hockey
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.LACROSS)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.LACROSS)}
                      />
                      Lacrosse
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.POWER_LIFTING)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.POWER_LIFTING)}
                      />
                      Powerlifting
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.RUGBY)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.RUGBY)}
                      />
                      Rugby
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.SOCCER)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.SOCCER)}
                      />
                      Soccer
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.SWIMMING)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.SWIMMING)}
                      />
                      Swimming
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.TENNIS)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.TENNIS)}
                      />
                      Tennis
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.TRACK)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.TRACK)}
                      />
                      Track & Field
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.VOLLEYBALL)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.VOLLEYBALL)}
                      />
                      Volleyball
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={otherSportFilters.includes(HSFilter.WRESTLING)}
                        onChange={onOtherSportCheckBoxChange(HSFilter.WRESTLING)}
                      />
                      Wrestling
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      case FILTER_TYPE.SCORE_AND_RATINGS:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={classes.positionFilters}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.SPEED)}
                    onChange={onFilterCheckBoxChange(HSFilter.SPEED, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  Speed
                </div>

                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.POWER)}
                    onChange={onFilterCheckBoxChange(HSFilter.POWER, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  Power
                </div>
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.PAI)}
                  onChange={onFilterCheckBoxChange(HSFilter.PAI, FILTER_TYPE.HIGH_SCHOOL)}
                />
                PAI Score
              </div>

              {(hsFilters || []).includes(HSFilter.PAI) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.PAI)}
                  className={classes.slider}
                  label='PAI Score'
                  min={0}
                  step={0.1}
                  max={5}
                  defaultValue={paiRangeFilter || [0, 5]}
                  marks={[
                    {value: 0, label: 0},
                    {value: 5, label: 5}
                  ]}
                  onChange={(value: number | number[]) => setPaiRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.COMBINE)}
                  onChange={onFilterCheckBoxChange(HSFilter.COMBINE, FILTER_TYPE.HIGH_SCHOOL)}
                />
                HS Combine Score
              </div>

              {(hsFilters || []).includes(HSFilter.COMBINE) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.COMBINE)}
                  className={classes.slider}
                  label='HS Combine Score'
                  min={0}
                  step={0.1}
                  max={5}
                  defaultValue={combineScoreRangeFilter || [0, 5]}
                  marks={[
                    {value: 0, label: 0},
                    {value: 5, label: 5}
                  ]}
                  onChange={(value: number | number[]) => setCombineScoreRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(nflFilters || []).includes(NFLFilter.PRO_COMBINE_SCORE)}
                  onChange={onFilterCheckBoxChange(NFLFilter.PRO_COMBINE_SCORE, FILTER_TYPE.NFL)}
                  disabled={!isNFL}
                />
                Pro Combine Score
              </div>

              {(nflFilters || []).includes(NFLFilter.PRO_COMBINE_SCORE) && (
                <Slider
                  disabled={!(nflFilters || []).includes(NFLFilter.PRO_COMBINE_SCORE)}
                  className={classes.slider}
                  label='Pro Combine Score'
                  min={0}
                  step={0.1}
                  max={5}
                  defaultValue={proCombineScoreRangeFilter || [0, 5]}
                  marks={[
                    {value: 0, label: 0},
                    {value: 5, label: 5}
                  ]}
                  onChange={(value: number | number[]) => setProCombineScoreRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(nflFilters || []).includes(NFLFilter.PPI)}
                  onChange={onFilterCheckBoxChange(NFLFilter.PPI, FILTER_TYPE.NFL)}
                  disabled={!user?.accessLevel?.hasCollegeAccess && !user?.accessLevel?.hasNFLAccess}
                />
                PPI College Production Score
              </div>

              {(nflFilters || []).includes(NFLFilter.PPI) && (
                <Slider
                  disabled={!(nflFilters || []).includes(NFLFilter.PPI)}
                  className={classes.slider}
                  label='PPI College Production Score'
                  min={0}
                  step={0.1}
                  max={5}
                  defaultValue={ppiRangeFilter || [0, 5]}
                  marks={[
                    {value: 0, label: 0},
                    {value: 5, label: 5}
                  ]}
                  onChange={(value: number | number[]) => setPpiRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.RATING_247)}
                  onChange={onFilterCheckBoxChange(HSFilter.RATING_247, FILTER_TYPE.HIGH_SCHOOL)}
                />
                247 Star Rating
              </div>

              {(hsFilters || []).includes(HSFilter.RATING_247) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.RATING_247)}
                  className={classes.slider}
                  label='247 Star Rating'
                  min={0}
                  step={1}
                  max={5}
                  defaultValue={rating247RangeFilter || [0, 5]}
                  marks={[
                    {value: 0, label: 0},
                    {value: 5, label: 5}
                  ]}
                  onChange={(value: number | number[]) => setRating247RangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.GPA)}
                  onChange={onFilterCheckBoxChange(HSFilter.GPA, FILTER_TYPE.HIGH_SCHOOL)}
                />
                GPA
              </div>

              {(hsFilters || []).includes(HSFilter.GPA) && (
                <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  label='GPA Minimum'
                  fontSize={16}
                  value={gpaMinimum}
                  autoFocus
                  onChange={setGpaMinimum}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.SAT)}
                  onChange={onFilterCheckBoxChange(HSFilter.SAT, FILTER_TYPE.HIGH_SCHOOL)}
                />
                SAT
              </div>

              {(hsFilters || []).includes(HSFilter.SAT) && (
                <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  label='SAT Minimum'
                  fontSize={16}
                  value={satMinimum}
                  autoFocus
                  onChange={setSatMinimum}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.ACT)}
                  onChange={onFilterCheckBoxChange(HSFilter.ACT, FILTER_TYPE.HIGH_SCHOOL)}
                />
                ACT
              </div>

              {(hsFilters || []).includes(HSFilter.ACT) && (
                <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  label='ACT Minimum'
                  fontSize={16}
                  value={actMinimum}
                  autoFocus
                  onChange={setActMinimum}
                />
              )}
            </div>
          </div>
        );
      case FILTER_TYPE.OFFERS_AND_COMMITS:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.OFFER_COUNT)}
                  onChange={onFilterCheckBoxChange(HSFilter.OFFER_COUNT, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Offers
              </div>

              {(hsFilters || []).includes(HSFilter.OFFER_COUNT) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.OFFER_COUNT)}
                  className={classes.slider}
                  label='Offers'
                  min={0}
                  step={1}
                  max={70}
                  defaultValue={offerCountRangeFilter || [0, 70]}
                  marks={[
                    {value: 0, label: 0},
                    {value: 70, label: 70}
                  ]}
                  onChange={(value: number | number[]) => setOfferCountRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.OFFER_SCHOOL)}
                  onChange={onFilterCheckBoxChange(HSFilter.OFFER_SCHOOL, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Offer School
              </div>

              {(hsFilters || []).includes(HSFilter.OFFER_SCHOOL) && (
                <TeamAutoComplete
                  className={classes.input}
                  label='Offer School'
                  teams={collegeTeams}
                  selectedTeam={collegeTeams.find((collegeTeam:Team) => collegeTeam.shortName === offerSchoolName) || null}
                  onSelect={(team:Team | null) => team && setSelectedOfferSchool(team)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.OFFER_CONFERENCE)}
                  onChange={onFilterCheckBoxChange(HSFilter.OFFER_CONFERENCE, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Offer Conference
              </div>

              <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                {(hsFilters || []).includes(HSFilter.OFFER_CONFERENCE) && (
                  <>
                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(hsFilters || []).includes(HSFilter.FBS)}
                        onChange={onFilterCheckBoxChange(HSFilter.FBS, FILTER_TYPE.HIGH_SCHOOL)}
                      />
                      FBS
                    </div>

                    <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                      {(hsFilters || []).includes(HSFilter.FBS) && (
                        (fbsConferences.map((conference:Conference) => (
                          <div
                            className={clsx(classes.checkboxWrap, classes.marginBottom15)}
                            key={conference.abbr}
                          >
                            <Checkbox
                              className={classes.checkbox}
                              checked={offerFBSConferenceFilters.includes(conference.id)}
                              onChange={onOfferFBSConferenceCheckBoxChange(conference.id)}
                            />
                            {conference.abbr}
                          </div>
                        )))
                      )}
                    </div>

                    <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={(hsFilters || []).includes(HSFilter.FCS)}
                        onChange={onFilterCheckBoxChange(HSFilter.FCS, FILTER_TYPE.HIGH_SCHOOL)}
                      />
                      FCS
                    </div>

                    <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                      {(hsFilters || []).includes(HSFilter.FCS) && (
                        (fcsConferences.map((conference:Conference) => (
                          <div
                            className={clsx(classes.checkboxWrap, classes.marginBottom15)}
                            key={conference.abbr}
                          >
                            <Checkbox
                              className={classes.checkbox}
                              checked={offerFCSConferenceFilters.includes(conference.id)}
                              onChange={onOfferFCSConferenceCheckBoxChange(conference.id)}
                            />
                            {conference.abbr}
                          </div>
                        )))
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            {false && (
              <div className={classes.filterWrapper}>
                <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.HIGHEST_LEVEL_OFFER)}
                    onChange={onFilterCheckBoxChange(HSFilter.HIGHEST_LEVEL_OFFER, FILTER_TYPE.HIGH_SCHOOL)}
                    disabled
                  />
                  Highest Level Offer
                </div>

                <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                  {(hsFilters || []).includes(HSFilter.HIGHEST_LEVEL_OFFER) && (
                    <>
                      <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={(hsFilters || []).includes(HSFilter.HIGHEST_LEVEL_OFFER_P5)}
                          onChange={onFilterCheckBoxChange(HSFilter.HIGHEST_LEVEL_OFFER_P5, FILTER_TYPE.HIGH_SCHOOL)}
                        />
                        FBS P5
                      </div>

                      <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={(hsFilters || []).includes(HSFilter.HIGHEST_LEVEL_OFFER_G5)}
                          onChange={onFilterCheckBoxChange(HSFilter.HIGHEST_LEVEL_OFFER_G5, FILTER_TYPE.HIGH_SCHOOL)}
                        />
                        FBS G5
                      </div>

                      <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={(hsFilters || []).includes(HSFilter.HIGHEST_LEVEL_OFFER_FCS)}
                          onChange={onFilterCheckBoxChange(HSFilter.HIGHEST_LEVEL_OFFER_FCS, FILTER_TYPE.HIGH_SCHOOL)}
                        />
                        FCS
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={uncommittedEnabled}
                  onChange={() => setUncommittedEnabled(!uncommittedEnabled)}
                />
                Uncommitted
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.COMMITTED_SCHOOL)}
                  onChange={onFilterCheckBoxChange(HSFilter.COMMITTED_SCHOOL, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Committed School
              </div>

              {((hsFilters || []).includes(HSFilter.COMMITTED_SCHOOL)) && (
                <TeamAutoComplete
                  className={classes.input}
                  label='Committed School'
                  teams={collegeTeams}
                  selectedTeam={collegeTeams.find((collegeTeam:Team) => collegeTeam.shortName === committedSchoolName) || null}
                  onSelect={(team:Team | null) => team && setSelectedCommittedSchool(team)}
                />
              )}
            </div>
          </div>
        );
      case FILTER_TYPE.TRACK_AND_FIELD_EVENTS:
        return (
          <div className={classes.filterContent}>
            <div className={classes.filterWrapper}>
              <div className={classes.checkboxWrap}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.TRACK_AND_FIELD_DATA_YEAR)}
                  onChange={onFilterCheckBoxChange(HSFilter.TRACK_AND_FIELD_DATA_YEAR, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Track & Field Data Year
              </div>

              {(hsFilters || []).includes(HSFilter.TRACK_AND_FIELD_DATA_YEAR) && (
                <Slider
                  disabled={!(hsFilters || []).includes(HSFilter.TRACK_AND_FIELD_DATA_YEAR)}
                  className={classes.slider}
                  label='Track & Field Data Year'
                  min={TRACK_AND_FIELD_YEAR_RANGE.MIN}
                  step={1}
                  max={TRACK_AND_FIELD_YEAR_RANGE.MAX}
                  defaultValue={hsTrackAndFieldYearRangeFilter || [TRACK_AND_FIELD_YEAR_RANGE.MIN, TRACK_AND_FIELD_YEAR_RANGE.MAX]}
                  marks={[
                    { value: TRACK_AND_FIELD_YEAR_RANGE.MIN, label: TRACK_AND_FIELD_YEAR_RANGE.MIN },
                    { value: TRACK_AND_FIELD_YEAR_RANGE.MAX, label: TRACK_AND_FIELD_YEAR_RANGE.MAX },
                  ]}
                  onChange={(value:number|number[]) => setHSTrackAndFieldYearRangeFilter(value)}
                />
              )}
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.SPRINTS)}
                  onChange={onFilterCheckBoxChange(HSFilter.SPRINTS, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Sprints
              </div>

              <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                {(hsFilters || []).includes(HSFilter.SPRINTS) && (
                  (SPRINTS.map((sprint:any) => (
                    <div
                      className={classes.marginBottom15}
                      key={sprint.value}
                    >
                      <div className={classes.checkboxWrap}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={(hsFilters || []).includes(sprint.value)}
                          onChange={onFilterCheckBoxChange(sprint.value, FILTER_TYPE.HIGH_SCHOOL)}
                        />
                        {sprint.textToDisplay}
                      </div>

                      {(hsFilters || []).includes(sprint.value) && (
                        <Slider
                          disabled={!(hsFilters || []).includes(sprint.value)}
                          className={classes.slider}
                          label='sprints'
                          min={sprint.range.min}
                          step={0.01}
                          max={sprint.range.max}
                          defaultValue={trackAndFieldEventFilters[sprint.value] || [sprint.range.min, sprint.range.max]}
                          marks={[
                            { value: sprint.range.min, label: sprint.range.min },
                            { value: sprint.range.max, label: sprint.range.max }
                          ]}
                          onChange={(value:number | number[]) => setTrackAndFieldEventFilters(
                            {
                              ...trackAndFieldEventFilters,
                              [sprint.value]: value
                            }
                          )}
                        />
                      )}
                    </div>
                  )))
                )}
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.HURDLES)}
                  onChange={onFilterCheckBoxChange(HSFilter.HURDLES, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Hurdles
              </div>

              <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                {(hsFilters || []).includes(HSFilter.HURDLES) && (
                  (HURDLES.map((hurdleEvent:any) => (
                    <div
                      className={classes.marginBottom15}
                      key={hurdleEvent.value}
                    >
                      <div className={classes.checkboxWrap}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={(hsFilters || []).includes(hurdleEvent.value)}
                          onChange={onFilterCheckBoxChange(hurdleEvent.value, FILTER_TYPE.HIGH_SCHOOL)}
                        />
                        {hurdleEvent.textToDisplay}
                      </div>

                      {(hsFilters || []).includes(hurdleEvent.value) && (
                        <Slider
                          disabled={!(hsFilters || []).includes(hurdleEvent.value)}
                          className={classes.slider}
                          label='hurdles'
                          min={hurdleEvent.range.min}
                          step={0.01}
                          max={hurdleEvent.range.max}
                          defaultValue={trackAndFieldEventFilters[hurdleEvent.value] || [hurdleEvent.range.min, hurdleEvent.range.max]}
                          marks={[
                            {value: hurdleEvent.range.min, label: hurdleEvent.range.min},
                            {value: hurdleEvent.range.max, label: hurdleEvent.range.max}
                          ]}
                          onChange={(value: number | number[]) => setTrackAndFieldEventFilters(
                            {
                              ...trackAndFieldEventFilters,
                              [hurdleEvent.value]: value
                            }
                          )}
                        />
                      )}
                    </div>
                  )))
                )}
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.MIDDLE_DISTANCE)}
                  onChange={onFilterCheckBoxChange(HSFilter.MIDDLE_DISTANCE, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Middle Distance
              </div>

              <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                {(hsFilters || []).includes(HSFilter.MIDDLE_DISTANCE) && (
                  (MIDDLE_DISTANCE.map((middleDistanceEvent:any) => (
                    <div
                      className={classes.marginBottom15}
                      key={middleDistanceEvent.value}
                    >
                      <div className={classes.checkboxWrap}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={(hsFilters || []).includes(middleDistanceEvent.value)}
                          onChange={onFilterCheckBoxChange(middleDistanceEvent.value, FILTER_TYPE.HIGH_SCHOOL)}
                        />
                        {middleDistanceEvent.textToDisplay}
                      </div>

                      {(hsFilters || []).includes(middleDistanceEvent.value) && (
                        <Slider
                          disabled={!(hsFilters || []).includes(middleDistanceEvent.value)}
                          className={classes.slider}
                          label='middleDistance'
                          min={middleDistanceEvent.range.min}
                          step={0.01}
                          max={middleDistanceEvent.range.max}
                          defaultValue={trackAndFieldEventFilters[middleDistanceEvent.value] || [middleDistanceEvent.range.min, middleDistanceEvent.range.max]}
                          marks={[
                            {value: middleDistanceEvent.range.min, label: middleDistanceEvent.range.min},
                            {value: middleDistanceEvent.range.max, label: middleDistanceEvent.range.max}
                          ]}
                          labelDisplay={FormatType.MINUTES_SECONDS_MS}
                          onChange={(value: number | number[]) => setTrackAndFieldEventFilters(
                            {
                              ...trackAndFieldEventFilters,
                              [middleDistanceEvent.value]: value
                            }
                          )}
                        />
                      )}
                    </div>
                  )))
                )}
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.RELAYS)}
                  onChange={onFilterCheckBoxChange(HSFilter.RELAYS, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Relays
              </div>

              <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                {(hsFilters || []).includes(HSFilter.RELAYS) && (
                  (RELAYS.map((relayEvent:any) => (
                    <div
                      className={classes.marginBottom15}
                      key={relayEvent.value}
                    >
                      <div className={classes.checkboxWrap}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={(hsFilters || []).includes(relayEvent.value)}
                          onChange={onFilterCheckBoxChange(relayEvent.value, FILTER_TYPE.HIGH_SCHOOL)}
                        />
                        {relayEvent.textToDisplay}
                      </div>

                      {(hsFilters || []).includes(relayEvent.value) && (
                        <Slider
                          disabled={!(hsFilters || []).includes(relayEvent.value)}
                          className={classes.slider}
                          label='relays'
                          min={relayEvent.range.min}
                          step={0.01}
                          max={relayEvent.range.max}
                          defaultValue={trackAndFieldEventFilters[relayEvent.value] || [relayEvent.range.min, relayEvent.range.max]}
                          marks={[
                            {value: relayEvent.range.min, label: relayEvent.range.min},
                            {value: relayEvent.range.max, label: relayEvent.range.max}
                          ]}
                          labelDisplay={getEventFormatType(relayEvent.event)}
                          onChange={(value: number | number[]) => setTrackAndFieldEventFilters(
                            {
                              ...trackAndFieldEventFilters,
                              [relayEvent.value]: value
                            }
                          )}
                        />
                      )}
                    </div>
                  )))
                )}
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.THROWING_EVENTS)}
                  onChange={onFilterCheckBoxChange(HSFilter.THROWING_EVENTS, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Throwing Events
              </div>

              <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                {(hsFilters || []).includes(HSFilter.THROWING_EVENTS) && (
                  (THROWING_EVENTS.map((throwingEvent:any) => (
                    <div
                      className={classes.marginBottom15}
                      key={throwingEvent.value}
                    >
                      <div className={classes.checkboxWrap}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={(hsFilters || []).includes(throwingEvent.value)}
                          onChange={onFilterCheckBoxChange(throwingEvent.value, FILTER_TYPE.HIGH_SCHOOL)}
                        />
                        {throwingEvent.textToDisplay}
                      </div>

                      {(hsFilters || []).includes(throwingEvent.value) && (
                        <Slider
                          disabled={!(hsFilters || []).includes(throwingEvent.value)}
                          className={classes.slider}
                          label={HSFilter.THROWING_EVENTS}
                          min={throwingEvent.range.min}
                          step={1}
                          max={throwingEvent.range.max}
                          defaultValue={trackAndFieldEventFilters[throwingEvent.value] || [throwingEvent.range.min, throwingEvent.range.max]}
                          marks={[
                            {value: throwingEvent.range.min, label: throwingEvent.range.min},
                            {value: throwingEvent.range.max, label: throwingEvent.range.max}
                          ]}
                          labelDisplay={FormatType.FEET_AND_INCHES}
                          onChange={(value: number | number[]) => setTrackAndFieldEventFilters(
                            {
                              ...trackAndFieldEventFilters,
                              [throwingEvent.value]: value
                            }
                          )}
                        />
                      )}
                    </div>
                  )))
                )}
              </div>
            </div>

            <div className={classes.filterWrapper}>
              <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                <Checkbox
                  className={classes.checkbox}
                  checked={(hsFilters || []).includes(HSFilter.JUMPING_EVENTS)}
                  onChange={onFilterCheckBoxChange(HSFilter.JUMPING_EVENTS, FILTER_TYPE.HIGH_SCHOOL)}
                />
                Jumping Events
              </div>

              <div className={clsx(classes.checkboxList, classes.marginBottom15)}>
                {(hsFilters || []).includes(HSFilter.JUMPING_EVENTS) && (
                  (JUMPING_EVENTS.map((jumpingEvent:any) => (
                    <div
                      className={classes.marginBottom15}
                      key={jumpingEvent.value}
                    >
                      <div className={classes.checkboxWrap}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={(hsFilters || []).includes(jumpingEvent.value)}
                          onChange={onFilterCheckBoxChange(jumpingEvent.value, FILTER_TYPE.HIGH_SCHOOL)}
                        />
                        {jumpingEvent.textToDisplay}
                      </div>

                      {(hsFilters || []).includes(jumpingEvent.value) && (
                        <Slider
                          disabled={!(hsFilters || []).includes(jumpingEvent.value)}
                          className={classes.slider}
                          label={HSFilter.JUMPING_EVENTS}
                          min={jumpingEvent.range.min}
                          step={1}
                          max={jumpingEvent.range.max}
                          defaultValue={trackAndFieldEventFilters[jumpingEvent.value] || [jumpingEvent.range.min, jumpingEvent.range.max]}
                          marks={[
                            {value: jumpingEvent.range.min, label: jumpingEvent.range.min},
                            {value: jumpingEvent.range.max, label: jumpingEvent.range.max}
                          ]}
                          labelDisplay={FormatType.FEET_AND_INCHES}
                          onChange={(value: number | number[]) => setTrackAndFieldEventFilters(
                            {
                              ...trackAndFieldEventFilters,
                              [jumpingEvent.value]: value
                            }
                          )}
                        />
                      )}
                    </div>
                  )))
                )}
              </div>
            </div>
          </div>
        );
      case FILTER_TYPE.COMBINE_EVENTS:
          return (
            <div className={classes.filterContent}>
              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_YEAR)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_YEAR, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  Combine Year
                </div>

                {(hsFilters || []).includes(HSFilter.COMBINE_YEAR) && (
                  <Slider
                    disabled={!(hsFilters || []).includes(HSFilter.COMBINE_YEAR)}
                    className={classes.slider}
                    label='Combine Year'
                    min={COMBINE_YEAR_RANGE.MIN}
                    step={1}
                    max={COMBINE_YEAR_RANGE.MAX}
                    defaultValue={combineYearRangeFilter || [COMBINE_YEAR_RANGE.MIN, COMBINE_YEAR_RANGE.MAX]}
                    marks={[
                      { value: COMBINE_YEAR_RANGE.MIN, label: COMBINE_YEAR_RANGE.MIN },
                      { value: COMBINE_YEAR_RANGE.MAX, label: COMBINE_YEAR_RANGE.MAX }
                    ]}
                    onChange={(value:number | number[]) => setCombineYearRangeFilter(value)}
                  />
                )}
              </div>

              <div className={classes.filterWrapper}>
                <div className={clsx(classes.checkboxWrap, classes.marginBottom15)}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_EVENT)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_EVENT, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  Combine Event
                </div>

                <div className={classes.checkboxList}>
                  {(hsFilters || []).includes(HSFilter.COMBINE_EVENT) && (
                    (COMBINE_EVENTS_LIST.map((combineEvent:string) => (
                      <div
                        key={combineEvent}
                        className={clsx(classes.checkboxWrap, classes.marginBottom15)}
                      >
                        <Checkbox
                          className={classes.checkbox}
                          checked={combineEventFilters.includes(combineEvent)}
                          onChange={onCombineEventFilterCheckBoxChange(combineEvent)}
                        />

                        {combineEvent}
                      </div>
                    )))
                  )}
                </div>
              </div>

              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_CITY)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_CITY, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  Combine City
                </div>

                {(hsFilters || []).includes(HSFilter.COMBINE_CITY) && (
                  <CityAutoComplete
                    className={classes.dropdown}
                    itemClassName={classes.dropdownSelector}
                    value={combineCityName}
                    onSelect={setCombineCityName}
                  />
                )}
              </div>

              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_40_M_DASH)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_40_M_DASH, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  40 Time
                </div>

                {(hsFilters || []).includes(HSFilter.COMBINE_40_M_DASH) && (
                  <Slider
                    disabled={!(hsFilters || []).includes(HSFilter.COMBINE_40_M_DASH)}
                    className={classes.slider}
                    label='Combine 40M Dash'
                    min={4}
                    step={0.01}
                    max={7}
                    defaultValue={combineRangeFilters[HSFilter.COMBINE_40_M_DASH] || [4, 7]}
                    marks={[
                      {value: 4, label: 4},
                      {value: 7, label: 7}
                    ]}
                    onChange={(value: number | number[]) => setCombineRangeFilters(
                      {
                        ...combineRangeFilters,
                        [HSFilter.COMBINE_40_M_DASH]: value
                      }
                    )}
                  />
                )}
              </div>

              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_10_SPLIT)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_10_SPLIT, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  10 Split
                </div>

                {(hsFilters || []).includes(HSFilter.COMBINE_10_SPLIT) && (
                  <Slider
                    disabled={!(hsFilters || []).includes(HSFilter.COMBINE_10_SPLIT)}
                    className={classes.slider}
                    label='Combine 10 Split'
                    min={1.00}
                    step={0.01}
                    max={3.00}
                    defaultValue={combineRangeFilters[HSFilter.COMBINE_10_SPLIT] || [1.00, 3.00]}
                    marks={[
                      {value: 1.00, label: 1.00},
                      {value: 3.00, label: 3.00}
                    ]}
                    onChange={(value: number | number[]) => setCombineRangeFilters(
                      {
                        ...combineRangeFilters,
                        [HSFilter.COMBINE_10_SPLIT]: value
                      }
                    )}
                  />
                )}
              </div>

              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_MPH)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_MPH, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  MPH
                </div>

                {(hsFilters || []).includes(HSFilter.COMBINE_MPH) && (
                  <Slider
                    disabled={!(hsFilters || []).includes(HSFilter.COMBINE_MPH)}
                    className={classes.slider}
                    label='Combine MPH'
                    min={1}
                    step={0.1}
                    max={30}
                    defaultValue={combineRangeFilters[HSFilter.COMBINE_MPH] || [1, 30]}
                    marks={[
                      {value: 1, label: 1},
                      {value: 30, label: 30}
                    ]}
                    onChange={(value: number | number[]) => setCombineRangeFilters(
                      {
                        ...combineRangeFilters,
                        [HSFilter.COMBINE_MPH]: value
                      }
                    )}
                  />
                )}
              </div>

              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_SHUTTLE)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_SHUTTLE, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  Shuttle
                </div>

                {(hsFilters || []).includes(HSFilter.COMBINE_SHUTTLE) && (
                  <Slider
                    disabled={!(hsFilters || []).includes(HSFilter.COMBINE_SHUTTLE)}
                    className={classes.slider}
                    label='Combine Shuttle'
                    min={3}
                    step={0.01}
                    max={9}
                    defaultValue={combineRangeFilters[HSFilter.COMBINE_SHUTTLE] || [3, 9]}
                    marks={[
                      {value: 3, label: 3},
                      {value: 9, label: 9}
                    ]}
                    onChange={(value: number | number[]) => setCombineRangeFilters(
                      {
                        ...combineRangeFilters,
                        [HSFilter.COMBINE_SHUTTLE]: value
                      }
                    )}
                  />
                )}
              </div>

              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_3_CONE)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_3_CONE, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  3 Cone
                </div>

                {(hsFilters || []).includes(HSFilter.COMBINE_3_CONE) && (
                  <Slider
                    disabled={!(hsFilters || []).includes(HSFilter.COMBINE_3_CONE)}
                    className={classes.slider}
                    label='Combine 3 Cone'
                    min={3}
                    step={0.01}
                    max={9}
                    defaultValue={combineRangeFilters[HSFilter.COMBINE_3_CONE] || [3, 9]}
                    marks={[
                      {value: 3, label: 3},
                      {value: 9, label: 9}
                    ]}
                    onChange={(value: number | number[]) => setCombineRangeFilters(
                      {
                        ...combineRangeFilters,
                        [HSFilter.COMBINE_3_CONE]: value
                      }
                    )}
                  />
                )}
              </div>

              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_VERTICAL_JUMP)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_VERTICAL_JUMP, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  Vertical Jump
                </div>

                {(hsFilters || []).includes(HSFilter.COMBINE_VERTICAL_JUMP) && (
                  <Slider
                    disabled={!(hsFilters || []).includes(HSFilter.COMBINE_VERTICAL_JUMP)}
                    className={classes.slider}
                    label='Combine Vertical Jump'
                    min={15}
                    step={1}
                    max={50}
                    defaultValue={combineRangeFilters[HSFilter.COMBINE_VERTICAL_JUMP] || [15, 50]}
                    marks={[
                      {value: 15, label: 15},
                      {value: 50, label: 50}
                    ]}
                    labelDisplay={FormatType.INCHES}
                    onChange={(value: number | number[]) => setCombineRangeFilters(
                      {
                        ...combineRangeFilters,
                        [HSFilter.COMBINE_VERTICAL_JUMP]: value
                      }
                    )}
                  />
                )}
              </div>

              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_BROAD_JUMP)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_BROAD_JUMP, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  Broad Jump
                </div>

                {(hsFilters || []).includes(HSFilter.COMBINE_BROAD_JUMP) && (
                  <Slider
                    disabled={!(hsFilters || []).includes(HSFilter.COMBINE_BROAD_JUMP)}
                    className={classes.slider}
                    label='Combine Broad Jump'
                    min={70}
                    step={0.01}
                    max={150}
                    defaultValue={combineRangeFilters[HSFilter.COMBINE_BROAD_JUMP] || [70, 150]}
                    marks={[
                      {value: 70, label: 70},
                      {value: 150, label: 150}
                    ]}
                    labelDisplay={FormatType.INCHES}
                    onChange={(value:number | number[]) => setCombineRangeFilters(
                      {
                        ...combineRangeFilters,
                        [HSFilter.COMBINE_BROAD_JUMP]: value
                      }
                    )}
                  />
                )}
              </div>

              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.COMBINE_POWERTOSS)}
                    onChange={onFilterCheckBoxChange(HSFilter.COMBINE_POWERTOSS, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  Power Toss
                </div>

                {(hsFilters || []).includes(HSFilter.COMBINE_POWERTOSS) && (
                  <Slider
                    disabled={!(hsFilters || []).includes(HSFilter.COMBINE_POWERTOSS)}
                    className={classes.slider}
                    label='Combine Power Toss'
                    min={15}
                    step={1}
                    max={65}
                    defaultValue={combineRangeFilters[HSFilter.COMBINE_POWERTOSS] || [15, 65]}
                    marks={[
                      {value: 15, label: 15},
                      {value: 65, label: 65}
                    ]}
                    labelDisplay={FormatType.INCHES}
                    onChange={(value: number | number[]) => setCombineRangeFilters(
                      {
                        ...combineRangeFilters,
                        [HSFilter.COMBINE_POWERTOSS]: value
                      }
                    )}
                  />
                )}
              </div>

              <div className={classes.filterWrapper}>
                <div className={classes.checkboxWrap}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(hsFilters || []).includes(HSFilter.SPARQ)}
                    onChange={onFilterCheckBoxChange(HSFilter.SPARQ, FILTER_TYPE.HIGH_SCHOOL)}
                  />
                  SPARQ
                </div>

                {(hsFilters || []).includes(HSFilter.SPARQ) && (
                  <Slider
                    disabled={!(hsFilters || []).includes(HSFilter.SPARQ)}
                    className={classes.slider}
                    label='SPARQ'
                    min={0}
                    step={1}
                    max={200}
                    defaultValue={combineRangeFilters[HSFilter.SPARQ] || [0, 200]}
                    marks={[
                      {value: 0, label: 0},
                      {value: 200, label: 200}
                    ]}
                    onChange={(value:number | number[]) => setCombineRangeFilters(
                      {
                        ...combineRangeFilters,
                        [HSFilter.SPARQ]: value
                      }
                    )}
                  />
                )}
              </div>
            </div>
          );
        default:
        break;
    }
  }

  return (
    <Drawer
      classes={{ paper: classes.searchPlayerFilterDrawer }}
      anchor='right'
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <div className={classes.drawerHeader}>
        <h2 className={classes.drawerHeaderTitle}>Apply Filters</h2>
        <div className={classes.drawerHeaderActions}>
          <button
            className={classes.clearFiltersButton}
            onClick={clearAllFilters}
          >
            Clear
          </button>

          <CloseButton
            className={classes.closeButton}
            onClick={() => setIsDrawerOpen(false)}
          />
        </div>
      </div>

      <Divider variant='fullWidth' light />

      <div className={classes.drawerContent}>
        <div className={classes.filterWrapper}>
          <div className={clsx(classes.filterTitle, classes.marginBottom15)}>
            Player Type
          </div>

          <div className={classes.playerTypes}>
            {PLAYER_TYPES.map(({ content, value }:{ content:string; value:string }) => {
              const disabled = (value === FILTER_TYPE.HIGH_SCHOOL && hsPlayersForbidden)
                || (value === FILTER_TYPE.COLLEGE && collegePlayersForbidden && !hasJucoAccessEnabled)
                || (value === FILTER_TYPE.NFL && nflPlayersForbidden)

              return (
                <span
                  key={value}
                  className={clsx(classes.checkboxWrap, disabled && classes.checkboxWrapDisabled)}
                >
                  <Checkbox
                    className={classes.checkbox}
                    disabled={disabled}
                    checked={!!playerTypeFilters.find(selectedFilter => selectedFilter === value)}
                    onChange={handlePlayerTypeChange(value)}
                  />
                    {content}
                </span>
              );
            })}
          </div>
        </div>

        {FILTER_LIST_FINAL
          .filter(filterType => {
            return !(collegePlayersForbidden && (filterType === FILTER_TYPE.COLLEGE || filterType === FILTER_TYPE.COLLEGE_TEAM))
              && !(nflPlayersForbidden && (filterType === FILTER_TYPE.NFL || filterType === FILTER_TYPE.NFL_TEAM));
          })
          .map((filterType:string, filterIndex:number) => (
            <Accordion
              key={filterIndex}
              className={classes.accordion}
              summaryClassName={classes.accordionSummary}
              summary={FILTERS_TITLE[filterType]}
              expanded={filterIndex === 0}
            >
              {renderResults(filterType)}
            </Accordion>
          ))
        }

        <div className={clsx(classes.filterWrapper, classes.drawerActions)}>
          <Button
            className={classes.drawerAction}
            primary
            disabled={loading || !playerTypeFilters.length || needMoreFilters}
            onClick={applyFilters}
          >
            Apply Filters
          </Button>

          <Button
            className={classes.drawerAction}
            disabled={loading || !playerTypeFilters.length}
            onClick={() => setDialogOpen(true)}
          >
            <SaveIcon className={classes.actionIcon}/>
            Save Search
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
