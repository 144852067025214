import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Paper from '@material-ui/core/Paper';
import PlusIcon from '../icons/PlusIcon';
import DepthChartIcon from '../icons/DepthChartIcon';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Loader from '../atoms/Loader';
import Checkbox from '../atoms/Checkbox';
import Toast, { ToastType } from '../atoms/Toast';
import Dialog from '../molecules/Dialog';
import TeamAutoComplete from '../molecules/TeamAutoComplete';
import SavedDepthChartsTable from '../organisms/SavedDepthChartsTable';
import PageContainer from './PageContainer';
import gql from '../services/gql';
import { State } from '../redux/reducers';
import { fetchDivITeams } from '../redux/dispatchers/teams';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import MEDIA from '../styles/media';
import DepthChart from '../types/DepthChart';
import Team from '../types/Team';
import User from '../types/User';
import OurladsIcon from '../icons/OurladsIcon';
import { ButtonBase } from '@material-ui/core';

interface DepthChartsPageTemplateProps {
  user?: User,
  divITeams: Team[];
  divITeamsLoading: boolean;
  fetchDivITeams: () => void;
}

const useStyles = makeStyles(theme => ({
  depthChartsPageTemplate: {
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  headerTitleBlock: {
    padding: 0,
  },

  header: {
    padding: 0,
    margin: theme.spacing(5, 5, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h2,
    margin: 0,
  },
  subHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h4,
    fontSize: '14px', 
    margin: '0 0 0 5px',
  },
  headerIcon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },

  subHeaderIcon: {
    width: '72px',
    height: '72px',
    marginRight: theme.spacing(1),
  },

  headerActions: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
  },

  addDepthChartButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    marginLeft: theme.spacing(3),
    padding: theme.spacing(2, 2.5),
  },
  addIcon: {
    flexShrink: 0,
    width: '12px',
    height: '12px',
    marginRight: theme.spacing(2.5),
  },
  addText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
  },

  table: {
    margin: theme.spacing(3, 5, 5, 5),
  },

  chartTypes: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    position: 'relative',
  },
  chartType: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },

  nameInput: {
    marginTop: theme.spacing(3),
  },

  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },

  checkbox: {
    marginRight: theme.spacing(1),
  },

  [MEDIA.MOBILE]: {
    header: {
      margin: theme.spacing(2, 2, 0),
    },

    table: {
      margin: theme.spacing(2, 2, 0),
    },
  },

  '@media (max-width: 480px)': {
    headerActions: {
      display: 'block',
    },

    addDepthChartButton: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1.5),
      marginLeft: 0,
    },
    addIcon: {
      marginRight: theme.spacing(1),
    },
    addText: {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  [MEDIA.DESKTOP]: {
    header: {
      flexDirection: 'row',
    },

    headerActions: {
      marginTop: 0,
    },
  },
}), { name: DepthChartsPageTemplate.name });

function DepthChartsPageTemplate (props: DepthChartsPageTemplateProps) {
  const {
    user,
    divITeams,
    divITeamsLoading,
    fetchDivITeams,
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [depthCharts, setDepthCharts] = useState<DepthChart[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [depthChartName, setDepthChartName] = useState<string>('');
  const [hasAutoDepthChart, setHasAutoDepthChart] = useState<boolean>(false);
  const [enableAutoDepthChart, setEnableAutoDepthChart] = useState<boolean>(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [hasOurladsPosition, setHasOurladsPosition] = useState<boolean>(false);

  useEffect(() => {
    setPageLoading(true);

    fetchDepthCharts();
    fetchDivITeams();
  }, []);

  useEffect(() => {
    if (user && user.defaultCollegeId && divITeams?.length > 0) {
      const userDefaultTeam = divITeams.find(team => team.id === user.defaultCollegeId);

      if (userDefaultTeam) {
        setSelectedTeam(userDefaultTeam);
      }
    }
  }, [divITeams, user]);

  useEffect(() => {
    if (selectedTeam) {
      setDepthChartName(selectedTeam.name);
      if (user?.program.autoDepthChartEnabled) {
        setEnableAutoDepthChart(true);
        setHasAutoDepthChart(true);
      }
      fetchHasOurladsPosition(selectedTeam.id)
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (user?.program.autoDepthChartEnabled) {
        setEnableAutoDepthChart(true);
        setHasAutoDepthChart(true);
      }
      if (!user.accessLevel.depthChartEnabled) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [user]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  function fetchHasOurladsPosition (teamId:number) {
  setDialogLoading(true);

  gql(`
  mutation {
    hasOurladsPositions (teamId: ${teamId})
  }
  `)
    .then((data:any) => data.hasOurladsPositions as boolean)
    .then((hasOurladsPositionTemp:Boolean) => {
      setDialogLoading(false);
        setHasOurladsPosition(hasOurladsPositionTemp ? true : false);
        if (!hasOurladsPositionTemp) {
          setHasAutoDepthChart(false);
        }
      })
    .catch(console.error)
    .finally(() => setDialogLoading(false));
}

  function fetchDepthCharts () {
    if (!pageLoading) setPageLoading(true);

    gql(`
      listDepthCharts {
        id
        slug
        name
        playerCount
        createdAt
        updatedAt
      }
    `)
      .then((data:any) => data.listDepthCharts as DepthChart[])
      .then((depthCharts:DepthChart[]) => setDepthCharts(depthCharts))
      .catch(console.error)
      .finally(() => setPageLoading(false));
  }

  function createEmptyDepthChart ():Promise<string | void> {
    if (selectedTeam && selectedTeam.id) {
      setDialogLoading(true);

      return gql(`
        mutation {
          createDepthChart (
            name: "${depthChartName}",
            teamId: ${selectedTeam.id},
            autoGenerateDepthChart: ${hasAutoDepthChart},
          )
        }
      `)
        .then((data:any) => data.createDepthChart as string)
        .catch(console.error)
        .finally(() => setDialogLoading(false));
    } else {
      return Promise.reject();
    }
  }

  function onCreateNewDepthChart () {
    createEmptyDepthChart()
      .then((depthChartSlug:string|void) => {
        if (depthChartSlug) {
          history.push(`/depth-chart/${depthChartSlug}`);
        } else {
          setToastVisible(true);
        }
      });
  }

  const toOurlads = () => {
    window.open('https://www.ourlads.com/');
  }

  return (
    <>
      <PageContainer className={classes.depthChartsPageTemplate}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
          <div className={classes.headerTitleBlock}>
              <h1 className={classes.headerTitle}>
                <DepthChartIcon  className={classes.headerIcon} />
                Depth Charts
              </h1>
              <h1 className={classes.subHeaderTitle}>
                Powered By: <ButtonBase onClick={toOurlads}><OurladsIcon className={classes.subHeaderIcon}/></ButtonBase>
              </h1>
            </div>

            <div className={classes.headerActions}>
              <Button
                className={classes.addDepthChartButton}
                primary
                onClick={() => setDialogOpen(true)}
              >
                <PlusIcon className={classes.addIcon} />
                <span className={classes.addText}>Add Depth Chart</span>
              </Button>
            </div>
          </div>

          <SavedDepthChartsTable
            className={classes.table}
            loading={pageLoading}
            setLoading={setPageLoading}
            depthCharts={depthCharts}
            setDepthCharts={setDepthCharts}
            onRefreshData={fetchDepthCharts}
          />
        </Paper>

        <Dialog
          open={dialogOpen}
          title='Add Depth Chart'
          onClose={() => setDialogOpen(false)}
          actions={[
            {
              name: 'Cancel',
              onClick: () => setDialogOpen(false),
            },
            {
              name: 'Confirm',
              primary: true,
              disabled: !depthChartName.trim() || dialogLoading,
              onClick: onCreateNewDepthChart,
            }
          ]}
        >
          <div className={classes.chartTypes}>
            <Loader inProgress={dialogLoading || divITeamsLoading} />

            <TeamAutoComplete
              teams={divITeams}
              selectedTeam={selectedTeam}
              onSelect={(team:Team | null) => team && setSelectedTeam(team)}
            />

            <Input
              className={classes.nameInput}
              label='Depth Chart Name'
              value={depthChartName}
              onChange={(value: string) => setDepthChartName(value)}
            />

            <div className={clsx(
              classes.checkboxWrapper,
              enableAutoDepthChart && selectedTeam && depthChartName && hasOurladsPosition && classes.disabledCheckboxWrapper,
              )}>
            <Checkbox
              className={classes.checkbox}
              disabled={enableAutoDepthChart && selectedTeam && hasOurladsPosition && depthChartName ? false : true}
              checked={hasAutoDepthChart}
              onChange={() => setHasAutoDepthChart(!hasAutoDepthChart)}
            />
            Auto Populate
          </div>
          </div>
        </Dialog>
      </PageContainer>

      <Toast
        visible={toastVisible}
        type={ToastType.ERROR}
        onHide={() => setToastVisible(false)}
      >
        Server error: failed to create a depth chart.
      </Toast>
    </>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
    divITeams: state.teams.divITeams,
    divITeamsLoading: state.teams.divITeamsLoading,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchDivITeams,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DepthChartsPageTemplate);
