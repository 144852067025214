export const COLOR_WHITE = '#ffffff';
export const COLOR_ORANGE = '#f47321';
export const COLOR_LIGHT_ORANGE = '#f98b60';
export const COLOR_BLUE = '#0f89e6';
export const COLOR_DARK_BLUE = '#0b5f9f';
export const COLOR_GREEN = '#5eba6d';
export const COLOR_DARK_GREEN = '#1d812d';
export const COLOR_RED = '#b71313';
export const COLOR_LIGHT_RED = '#fff6f2';
export const COLOR_YELLOW = '#ffbd2e';
export const COLOR_LIGHT_GRAY = '#f0f0f0';
export const COLOR_LIGHTEST_GRAY = '#eff8ff';
export const COLOR_MIDDLE_GRAY = '#d8d8d8';
export const COLOR_DARK_GRAY = '#777777';
export const COLOR_PURPLE = '#A020F0';
export const COLOR_BLACK = '#000000';

export const COLOR_BACKGROUND_COLD = '#e9eff3';
export const COLOR_BACKGROUND_WARM = '#eeeeee';
export const COLOR_BACKGROUND_LIGHT = '#f8f8f8';
export const COLOR_BORDER = 'rgba(0, 0, 0, 0.08)';
export const COLOR_SHADOW = 'rgba(0, 0, 0, 0.05)';
export const COLOR_TEXT = '#070a13';
